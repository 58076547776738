// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled, { css } from 'styled-components';
import Tooltip from '../../Components/Tooltip';
import TooltipMobile from '../../Components/TooltipMobile';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import Popover from '../Popover';
const { innerWidth } = window;

const diff = innerWidth - innerWidth * 0.93;
const customMargin = `0 ${innerWidth - innerWidth * 0.95}px`;

const makeMargin = (width: number) => {
  let x = 0;

  if (width > 400) {
    x = width - width * 0.91;
  }

  if (width === 375) {
    x = width - width * 0.93;
  }

  if (width < 375) {
    x = width - width * 0.94;
  }

  return x;
};

interface WrapperProps {
  isMobile: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;

  ${(props) =>
    props.isMobile &&
    css`
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      /* z-index: 999999999999999; */
      z-index: 999999999;
    `}
`;
interface ContainerProps {
  isMobile: boolean;
}
export const Container = styled.div<ContainerProps>`
  background: ${({ theme }) => theme.colors.redPrimary};
  /* height: ${(props) => (props.isMobile ? '70px' : '120px')}; */
  height: ${(props) => (props.isMobile ? 'auto' : '120px')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  ${(props) =>
    props.isMobile &&
    css`
      align-items: flex-start;
      padding: 16px;
    `}

  a {
    outline: 0;
    text-decoration: none;
  }
`;

interface ContentProps {
  isMobile: boolean;
}

export const Content = styled.div<ContentProps>`
  width: ${(props) => (props.isMobile ? '100%' : '1280px')};
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  align-items: center;
  justify-content: space-between;
  margin: ${(props) => (props.isMobile ? customMargin : '0')};
`;

interface TopContentProps {
  isMobile: boolean;
}

export const TopContent = styled.div<TopContentProps>`
  width: ${(props) => (props.isMobile ? '100%' : '1280px')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* padding: ${(props) => (props.isMobile ? customMargin : '0')}; */
`;

export const WrapperMenuMobile = styled.div`
  position: relative;
  margin-top: -33px;

  .active {
    border: 0px !important;
  }

  span:first-child {
    display: block;
    /* margin-left: ${`-${innerWidth - diff}px`} !important; */
    margin-left: ${`-${makeMargin(innerWidth)}%`} !important;
  }
  /* .horizontal-menu {
    margin-left: ${`-${innerWidth - 200}px`};
  } */
`;

interface LogoProps {
  w: string;
  h: string;
  Mr?: string;
  isMobile?: boolean;
}
export const Logo = styled.img<LogoProps>`
  width: ${(props) => props.w};
  height: ${(props) => props.h};
  margin-right: ${(props) => (props.isMobile ? '0' : props.Mr)};
  cursor: pointer;
`;

export const Menus = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
`;

interface ActionsRightProps {
  isMobile: boolean;
}

export const ActionsRight = styled.ul<ActionsRightProps>`
  list-style: none;
  display: flex;
  align-items: center;
  overflow: ${(props) => (props.isMobile ? 'hidden' : 'visible')};
  margin-bottom: 0px !important;
`;

interface MenuItemProps {
  isMobile: boolean;
  upper?: boolean;
}

export const ActionsRightItem = styled.li<MenuItemProps>`
  display: flex;
  align-items: center;
  font-family: Helvetica Neue;
  justify-content: flex-start;
  font-weight: 100;
  color: #ffffff;
  font-size: 14px;
  z-index: ${(props) => (props.upper ? '99' : '10.5')};

  cursor: pointer;

  margin-right: ${(props) => (props.isMobile ? '18px' : '40px')};

  &:last-child {
    margin-right: 0;
  }
`;

export const PopoverContainer = styled(Popover)`
  svg {
    margin: 0;
  }

  span {
    background: #ffffff;
    color: #666666;

    &::before {
      transform: rotate(180deg);
      border-color: #ffffff transparent;
    }
  }
`;

export const TooltipContainer = styled(Tooltip)`
  svg {
    margin: 0;
  }

  span {
    background: #ffffff;
    color: #666666;

    &::before {
      transform: rotate(180deg);
      border-color: #ffffff transparent;
    }
  }
`;

export const TooltipContainerMobile = styled(TooltipMobile)`
  svg {
    margin: 0;
  }

  span {
    background: #ffffff;
    color: #666666;

    &::before {
      transform: rotate(180deg);
      border-color: #ffffff transparent;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuItemMobile = styled.span`
  font-size: 14px;
  /* font-family: 'Helvetica Neue'; */
  color: #ffffff;
  padding: 10px 15px;
  cursor: pointer;

  /* &:hover {
    background: #cd0114;
    transition: 0.5s;
  } */
`;

export const InfoLogin = styled.div`
  display: flex;
  position: relative;
  margin-left: 7px;

  > p {
    color: #ffffff;
    line-height: 20px;
  }

  > svg {
    position: absolute;
    margin-top: 15px;
    margin-left: 102px;
  }
`;

export const Scroll = styled(ScrollMenu)``;

export const Description = styled.p`
  margin: 0;
  width: 124px;
  font-size: 14px !important;
  line-height: 16px !important;

  font-family: 'Helvetica Neue, Bold';
  font: -webkit-control;
  font-weight: 700;
`;

export const NewBtn = styled.button`
  background: ${({ theme }) => theme.colors.redPrimary};
  border: 0;
  font-family: Helvetica Neue;
  font-size: 14px;
  color: white;
  padding: 3px 5px;
`;
