import styled, { css } from 'styled-components';
import ArrowDown from '../../Assets/arrow-down.svg';

interface Props {
  isMobile?: boolean;
}

export const Container = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${(props) => (props.isMobile ? '36px' : '47px')};
  width: 650px;
`;

export const Select = styled.select<Props>`
  height: ${(props) => (props.isMobile ? '36px' : '47px')};
  color: #666;
  width: 100%;
  /* padding: 0.875em 1.875em 0.875em 0.875em; */
  background: #e5e5e5;
  border: 0;
  -webkit-appearance: none;
  font-family: Helvetica Neue, Regular;
  font-weight: 100;
  padding-left: 10px;
  border-radius: 8px 0px 0px 8px;

  padding-right: 26px;
  cursor: pointer;
  position: relative;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      background-image: url(${ArrowDown});
      background-repeat: no-repeat;
      background-position: 150px 22px;
    `};

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 36px;
      position: relative;
      z-index: 99;
      background-position: 20px 19px;
      border-radius: 8px 0 0 8px;
      /* padding-right: 40px; */
    `};
`;

export const Input = styled.input<Props>`
  height: ${(props) => (props.isMobile ? '36px' : '40px')};
  width: ${({ isMobile }) => (isMobile ? '100%' : '100%')};
  /* border-radius: 0px !important; */
  border-radius: 8px 0px 0px 8px;
  font-size: 1em;
  padding-left: 25px;
  border: 0;
  padding-top: 4px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding-left: 15px;
      padding-top: 1px;
      ::-webkit-input-placeholder {
        font-size: 14px;
        line-height: 3;
      }
    `}
`;

export const SearchBox = styled.div<Props>`
  width: ${(props) => (props.isMobile ? '55px' : '40px')};
  height: ${(props) => (props.isMobile ? '36px' : '40px')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.whitePrimary};
  border-radius: 0px 8px 8px 0px;

  ${(props) =>
    props.isMobile &&
    css`
      border: solid 1px white;
      border-radius: 0 8px 8px 0;
      margin-left: -1px;
    `}
`;

export const Icon = styled.img<Props>`
  width: ${({ isMobile }) => (isMobile ? '17px' : '17px')};
  height: ${({ isMobile }) => (isMobile ? '17px' : '17px')};
  cursor: pointer;
  margin-right: 6px;

  ${(props) =>
    props.isMobile &&
    css`
      /* filter: brightness(0) invert(1); */
      margin-right: 0px;
    `}
`;

export const SelectBox = styled.div<Props>`
  display: flex;
  flex-direction: row;
  background: #e5e5e5;
  align-items: center;

  justify-content: center;
  /* padding: ${({ isMobile }) => (isMobile ? '0px' : '0 0.875em')}; */
  position: relative;
  width: ${({ isMobile }) => (isMobile ? '36px' : '176px')};
  z-index: 9;
  border-radius: 8px 0px 0px 8px;
  height: 47px;

  white-space: nowrap;
  overflow: hidden;
  /* padding-left: 47px; */

  span {
    font-family: Helvetica Neue, Regular;
    color: #333;
    font-size: 15px;
    /* margin-left: 16px;

    text-overflow: clip; */
  }
  /*
  .truncate-overflow {

    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: unset;
    display: -webkit-box;
  } */

  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 36px;
      border-radius: 8px 0 0 8px;
    `}

  ::after {
    content: '';
    display: inline-block;
    cursor: pointer;
    width: 20px;
    height: 47px;
    right: 0;
    position: absolute;
    background: blue;
    pointer-events: none;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #e5e5e5 30%);
  }
`;

interface IconArrowProps {
  isMobile?: boolean;
}

export const IconArrow = styled.img<IconArrowProps>`
  width: 10px;
  height: 10px;
  cursor: pointer;
  align-items: center;
  position: relative;
  right: 13px !important;
  z-index: 99999;

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: relative;
      right: 23px !important;
    `}
`;

interface BoxArrowProps {
  isMobile?: boolean;
}

export const BoxArrow = styled.div<BoxArrowProps>`
  background: blue;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
`;

// export const BoxArrow = styled.div<BoxArrowProps>`
//   background: #e5e5e5;
//   width: 20px;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   ${({ isMobile }) =>
//     isMobile &&
//     css`
//       position: absolute;
//       z-index: 999999;
//       width: 48px;
//       height: 39px;
//     `}
// `;
