import React, { FC, useState, useEffect, useMemo } from 'react';
import { getDay as getDayFns } from 'date-fns';
import { useCookies } from 'react-cookie';
import { StoreProps } from '../../Pages/Details/Sobre';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import { DaysProps } from '../../Utils/HourFunction';
import makeDay from '../../Utils/makeDay';
import { ReactComponent as Clock } from '../../Assets/clock.svg';
import NumberToDay, { ResponseProps } from '../../Utils/NumberToDay';
import useWindowDimensions from '../../Utils/size';
import { Accordion } from '../Accordion';
import { HoursStore } from '../HoursStore';
import {
  Bar,
  BoxHours,
  BoxHoursAccordion,
  Container,
  Day,
  Hours,
  Item,
  Table,
  Title,
  BoxLayer,
} from './styles';
import ListCovid, { CovidProps, getApiListCovid } from '../../Utils/Covid';
import getDay from '../../Utils/getday';

interface Props {
  store: StoreProps;
  hours: DaysProps;
}

interface HourProps {
  hour: string;
  percent: string;
  times: number;
  qtd: number;
  avegrage: string;
}

const TableHoursV4: FC<Props> = ({ store, hours }) => {
  console.log('store: ', store);
  const [listCovid, setListCovid] = useState<CovidProps>({} as CovidProps);
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const [covidStore, setCovidStore] = useState(false);
  const isMobile = useWindowDimensions();
  const today = new Date();
  const day = getDayFns(today);
  const [currentDay, setCurrentDay] = useState<ResponseProps>(() =>
    NumberToDay(0)
  );

  const dayHoursTable = useMemo(() => {
    if (!store?.schedules) return null;
    const d = getDay(new Date());
    console.log('d: ', d);
    if (d) {
      const hours = store?.schedules[d];
      console.log('hours: ', hours);
      if (hours && hours.start && hours.end) {
        const [hourStart, minutesStart] = hours.start.split(':');
        const [hourEnd, minutesEnd] = hours.end.split(':');

        const minutesS = minutesStart === '00' ? '' : `:${minutesStart}`;
        const minutesE = minutesEnd === '00' ? '' : `:${minutesEnd}`;
        return `${hourStart}${minutesS}h às ${hourEnd}${minutesE}h`;
      } else {
        return null;
      }
    }
  }, [store]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListCovid()
        .then((e) => setListCovid(e))
        .catch((e) => console.log('erro ao caregar listaCovid: ', e));
    }
  }, []);

  useEffect(() => {
    const { sellerStoreId } = store;
    ListCovid({ sellerStoreId, wppFromWeb: listCovid })
      .then((e: boolean) => setCovidStore(e))
      .catch((e: any) => console.log('erro2 listaCovid: ', e));
  }, [listCovid, store]);

  return (
    <Container isMobile={isMobile}>
      <>
        <Table isMobile={isMobile}>
          <div>
            <li style={{ marginBottom: 20 }}>
              <Title>horários de funcionamento</Title>
            </li>

            <BoxLayer isMobile={isMobile} open={!!(store.open || covidStore)}>
              <Clock width={16} height={16} style={{ marginRight: 8 }} />
              {store.open ? (
                covidStore ? (
                  <h2>aberta para pedidos online</h2>
                ) : (
                  <h2>
                    aberta&nbsp;
                    <strong>{dayHoursTable || 'Horário Indisponível'}</strong>
                  </h2>
                )
              ) : covidStore ? (
                <h2>aberta para pedidos online</h2>
              ) : (
                <h2>
                  fechada&nbsp; 
                  <strong>{dayHoursTable || 'Horário Indisponível'}</strong>
                </h2>
              )}
            </BoxLayer>
          </div>
          <BoxHours>
            {store.schedules &&
              Object.entries(store.schedules).map((value: any, _: any) => {
                const [dia, horarios] = value;

                return (
                  <Item key={value}>
                    <Day>{makeDay(dia).name}</Day>
                    <Bar />
                    {horarios.start && horarios.end ? (
                      <Hours>
                        {`${horarios.start}h`} às {`${horarios.end}h`}
                      </Hours>
                    ) : (
                      <Hours>fechada</Hours>
                    )}
                  </Item>
                );
              })}
          </BoxHours>
        </Table>

        {/* <HoursStore store={store} hours={hours} /> */}
      </>
    </Container>
  );
};

export default TableHoursV4;
