import listCovid from '../Services/data/covid.json';
import api from '../Services/api';

interface Store {
  Loja: string | number;
  Status: string;
}

export interface CovidProps {
  Plan1: Store[];
}

interface Props {
  sellerStoreId: string | number;
  wppFromWeb: CovidProps;
}

export const getApiListCovid = async () => {
  const response = await api.get(
    `https://${document.location.host}/static/json/lojas_fechadas_covid.json`
  );
  const list: CovidProps = response.data;

  return list;
};

export const listWppElement = async ({
  sellerStoreId,
  wppFromWeb,
}: Props): Promise<Store | undefined> => {
  const customList =
    process.env.NODE_ENV === 'development' ? listCovid : wppFromWeb;
  const exists = customList.Plan1.find((i: Store) => i.Loja === sellerStoreId);
  return exists;
};

const ListCovid = async ({
  sellerStoreId,
  wppFromWeb,
}: Props): Promise<boolean> => {
  const customList =
    process.env.NODE_ENV === 'development' ? listCovid : wppFromWeb;
  const exists = customList.Plan1.find((i: Store) => i.Loja === sellerStoreId);
  return !!exists;
};

export default ListCovid;
