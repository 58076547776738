import React, { FC, useState, useCallback, useEffect } from 'react';
import { Container } from './styles';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';

import Header from '../../Components/Header';
import useWindowDimensions from '../../Utils/size';
import { useHistory, useLocation } from 'react-router-dom';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import Footer from '../../Components/Footer';
import { MyExperiment, TestAB } from '../../Utils/PlanOut';
import ModalHelp from '../../Components/ModalHelp';
import { Loader } from '../List/styles';
import LoadingIcon from '../../Assets/loading.svg';

import HomeNew from '../Home_nova';
import HomeOld from '../Home';

const keyUnusedLastPage = '@nossaslojas:unused-last-page';
const keyLocalStoresList = '@nossaslojas:local-list';
const keyAllStoresList = '@nossaslojas:all-list';
const keyTotalStores = '@nossaslojas:total-stores';
const keyCurrentStoreList = '@nossaslojas:current-list';
const keyTotalPagStores = '@nossaslojas:total-pages-store';
const keyAppendPagCurrent = '@nossaslojas:pag-append';
const keyUsedStores = '@nossaslojas:user-stores';
const keyAppendPagCurrentForMap = '@nossaslojas:pag-append-map';

const PreHome: FC = () => {
  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState<string | undefined>('');
  const [cep, setCep] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const [defaultTab, setDefaultTab] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const isMobile = useWindowDimensions();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    localStorage.removeItem(keyTotalStores);
    localStorage.removeItem(keyCurrentStoreList);
    localStorage.removeItem(keyAppendPagCurrent);
    localStorage.removeItem(keyTotalPagStores);
    localStorage.removeItem(keyUsedStores);
    localStorage.removeItem(keyAppendPagCurrentForMap);
    console.log('storage cleaned');
  }, []);
  const handleEventsGA = useCallback(
    (type: 'atendimento' | 'duvidasfrequentes' | 'politicadetrocas') => {
      const makeType = {
        atendimento: 'atendimento',
        duvidasfrequentes: 'faq',
        politicadetrocas: 'trocas',
      };

      HandleEvents({
        isMobile,
        eventAction: 'footer',
        eventCategory: 'nossaslojas',
        eventLabel: makeType[type],
        eventValue: undefined,
        cookies,
      });
    },
    [isMobile, cookies]
  );

  const handleChangeTab = useCallback(
    (index: number) => {
      setDefaultTab(Number(index));
      setVisibleModal(true);

      let current = location.search;

      if (parsed.help) {
        const remove_after = current.indexOf('&help');
        current = current.substring(0, remove_after);
      }

      const values: any = [
        'atendimento',
        'duvidasfrequentes',
        'politicadetrocas',
      ];

      handleEventsGA(values[index]);

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        window.location.search +
        `&help=${values[index]}`;
      window.history.pushState({ path: newurl }, '', newurl);
    },
    [parsed, location.search, handleEventsGA]
  );

  useEffect(() => {
    if (parsed.help) {
      const values: any = {
        atendimento: 0,
        duvidasfrequentes: 1,
        politicadetrocas: 2,
      };
      setDefaultTab(Number(values[String(parsed.help)]));
      setVisibleModal(true);
    }
  }, [parsed.help]);

  useEffect(() => {
    const tempCep = '22210060';
    // cookies.persistentCep
    if (cookies.persistentCep && !parsed.help) {
      setCep(cookies.persistentCep);
      // setCep('93610330');
    }
  }, [cookies, history, parsed]);

  const getVersion = useCallback(async () => {
    // const getVariantLocal = localStorage.getItem('@b2w:version:home');
    // if (getVariantLocal) {
    //   const variantParsed = JSON.parse(getVariantLocal);
    //   setVariant(variantParsed.version);
    //   setLoading(false);

    //   // chamando o analytics novamente mesmo com variável definida
    //   TestAB({
    //     salt: Math.random(),
    //     experimentId: 'LOJAS_HOME',
    //     variantSetted: variantParsed.version,
    //     cookies,
    //     isMobile,
    //   });
    // } else {
    //   const v = TestAB({
    //     salt: Math.random(),
    //     experimentId: 'LOJAS_HOME',
    //     cookies,
    //     isMobile,
    //   });

    //   const payload = {
    //     version: v,
    //   };
    //   localStorage.setItem('@b2w:version:home', JSON.stringify(payload));
    //   setVariant(v);
    //   setLoading(false);
    // }
    setVariant('NEW');
    setLoading(false);
  }, [cookies, isMobile]);

  useEffect(() => {
    getVersion();
  }, [getVersion]);

  const handleCloseModalHelp = useCallback(() => {
    setVisibleModal(false);

    let current = location.search;

    const remove_after = current.indexOf('&help');
    current = current.substring(0, remove_after);

    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      location.search;

    window.history.pushState({ path: newurl }, '', newurl);
  }, [location.search]);

  return (
    <Container bg={variant === 'NEW' ? '#F1F1F1' : '#FFF'}>
      <ModalHelp
        visible={visibleModal}
        closeFunction={handleCloseModalHelp}
        defaultTab={defaultTab}
        onSelect={handleChangeTab}
      />
      <Header />

      {loading ? (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader bottom={null} src={LoadingIcon} />
        </div>
      ) : variant === 'NEW' ? (
        <HomeNew />
      ) : (
        // validar dessa forma para ele não chamar 2x o analytics
        (variant === 'OLD' || variant === 'CONTROL') && <HomeOld />
      )}

      <Footer
        handleMenu1={() => handleChangeTab(0)}
        handleMenu2={() => handleChangeTab(1)}
        handleMenu3={() => handleChangeTab(2)}
      />
    </Container>
  );
};

export { PreHome };
