/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import React, {
  FC,
  useCallback,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  RefForwardingComponent,
  useRef,
} from 'react';
import { ReactComponent as ChevronRight } from '../../../Assets/chevron-right.svg';
import { ReactComponent as IconPlaceholderEmpty } from '../../../Assets/IconPlaceholderEmpty.svg';
import queryString from 'query-string';
import { RiCloseLine } from 'react-icons/ri';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { ReactComponent as LocalIcon } from '../../../Assets/local_branco.svg';
import { ReactComponent as Aamericanas } from '../../../Assets/a-americanas.svg';
import {
  Data,
  Alignment,
  Card,
  HeaderCard,
  TitleOffer,
  AddressOffer,
  Info,
  Timestamp,
  Total,
  Barrinha,
  Products,
  Item,
  ProductImg,
  Badge,
  AlignmentDescription,
  Description,
  BarrinhaBottom,
  FooterCard,
  HeadBox,
  ContentHeader,
  PhotoStore,
  AlignTime,
  TimeDay,
  TimeMonth,
  BoxDot,
  InfoFooter,
} from './styles';
import { Title, TitlePage } from '../../OrderDetail/styles';
import { useHistory, useLocation } from 'react-router-dom';
import useWindowDimensions from '../../../Utils/size';
import { HandleEvents } from '../../../Utils/AnalyticsGA';
import ModalNFE from '../../../Components/ModalNFE';
import {
  AlignmentNfe,
  BoxNfe,
  CloseButton,
  MoreButton,
  ActionsNfe,
} from '../styles';
import { Nota, getApiListNote, handleNfeLink } from '../../../Utils/NotaFiscal';
import { useCookies } from 'react-cookie';
import {
  BreadCrumbItemProps,
  Breadcrumb,
} from '../../../Components/Breadcrumb';
import { format } from 'date-fns';
import { FilterModal } from '../../../Components/FilterModal';
import { useFilters } from '../../../Hooks/useFilters';

export interface OrderMobileExportedProps {
  openFilter: () => void;
}

interface Props {
  data: any;
  handleDetails: any;
  error: any;
  keysOrders: any;
  yearsOrders: any;
  monthsOrders: any;
  monthsData: any;
  filteredData: any;
}

const OrderMobile: RefForwardingComponent<OrderMobileExportedProps, Props> = (
  {
    data,
    handleDetails,
    error,
    keysOrders,
    yearsOrders,
    monthsOrders,
    monthsData,
    filteredData,
  },
  ref
) => {
  const [orderBreadcrumbs, setOrderBreadcrumbs] = useState<BreadCrumbItemProps>(
    {} as BreadCrumbItemProps
  );
  const [lastBreadcrumbs, setlastBreadcrumbs] = useState<BreadCrumbItemProps>(
    {} as BreadCrumbItemProps
  );
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const [listNote, setListNote] = useState<Nota[]>([]);
  const [visibleModalNFE, setVisibleModalNFE] = useState(false);
  const [selectedStore, setSelectedStore] = useState<any>();
  const [nfe, setNfe] = useState('');
  const [withFilters, setWithFilters] = useState(false);
  const history = useHistory();
  const isMobile = useWindowDimensions();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const {
    addYearFilter,
    yearsFilter,
    removeYearFilter,
    removeMonthFilter,
    addMonthFilter,
    monthsFilter,
    cleanAllFilter,
  } = useFilters();

  const refsCheckboxYear: any = useRef<any>([]);
  const refsCheckboxMonths: any = useRef<any>([]);

  const handleNfe = useCallback((item: any) => {
    setNfe(item.chaveNfe);
    setVisibleModalNFE(true);
    setSelectedStore(item);
  }, []);

  const handleViewNfe = useCallback(() => {
    HandleEvents({
      isMobile,
      eventAction: 'minhas compras',
      eventCategory: 'nossaslojas',
      eventLabel: 'nota fiscal',
      eventValue: undefined,
      store: {
        name: selectedStore?.nomeLoja,
        sellerStoreId: selectedStore?.codigoLoja,
      },
      cookies,
    });

    HandleEvents({
      isMobile,
      eventAction: 'minhas compras',
      eventCategory: 'nossaslojas',
      eventLabel: 'ver nota fiscal',
      eventValue: undefined,
      cookies,
    });

    console.log('selectedStore: ', selectedStore);
    if (selectedStore) {
      console.log('chave: ', nfe);
      handleNfeLink({ uf: selectedStore.uf, chaveNfe: nfe, list: listNote });
    }
  }, [isMobile, nfe, selectedStore, listNote, cookies]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListNote()
        .then((e) => setListNote(e))
        .catch((e) => console.log('erro ao caregar listNote mobile: ', e));
    }
  }, []);

  useEffect(() => {
    const isObj = typeof parsed.breadcrumb === 'object';

    if (parsed.breadcrumbType === 'home') return;
    if (parsed.breadcrumb && parsed.breadcrumbLink) {
      const obj = {
        label: isObj ? String(parsed.breadcrumb[0]) : String(parsed.breadcrumb),
        link: String(parsed.breadcrumbLink),
      };

      // console.log('obj: ', obj);
      setlastBreadcrumbs(obj);
    }
  }, []);

  useEffect(() => {
    setOrderBreadcrumbs({
      label: 'meus pedidos',
      link: `${location.pathname}${location.search}`,
    });
  }, [location]);

  const openFilter = useCallback(async () => {
    setWithFilters(true);
  }, []);

  const handleFilter = useCallback(async () => {
    setWithFilters(false);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      openFilter,
    };
  });

  return (
    <>
      {/* <HeadBox>
        <ChevronLeft onClick={() => history.goBack()} />

        <ChevronLeft style={{ visibility: 'hidden' }} />
      </HeadBox> */}

      {error}
      {withFilters && (
        <FilterModal
          closeFunction={handleFilter}
          keysOrders={keysOrders}
          yearsOrders={yearsOrders}
          monthsOrders={monthsOrders}
          monthsData={monthsData}
          filteredData={filteredData}
        />
      )}

      {visibleModalNFE && (
        <>
          <ModalNFE
            visible={visibleModalNFE}
            onClick={() => setVisibleModalNFE(false)}
          />
          <AlignmentNfe>
            <BoxNfe>
              <CloseButton>
                <RiCloseLine
                  size={25}
                  color="#707070"
                  className="icone"
                  onClick={() => setVisibleModalNFE(false)}
                />
              </CloseButton>
              <h1>
                Acesse e confira através do site<br></br>da nota fiscal
                eletrônica.
              </h1>

              <ActionsNfe>
                <span onClick={() => setVisibleModalNFE(false)}>
                  depois vejo
                </span>
                <MoreButton onClick={handleViewNfe}>
                  <span>ver nota fiscal</span>
                </MoreButton>
              </ActionsNfe>
            </BoxNfe>
          </AlignmentNfe>
        </>
      )}

      {keysOrders.map((key: any, index: number) => {
        const filteredIsEmpty = Object.keys(filteredData).length === 0;
        const validData = filteredIsEmpty ? data : filteredData;

        if (!validData[key]?.length) return;
        return validData[key].map((i: any, indexInterno: number) =>
          i ? (
            <>
              {indexInterno === 0 && (
                <Data>
                  <span>{i.dataDescription}</span>
                </Data>
              )}

              <Alignment>
                <Card>
                  <HeaderCard>
                    <ContentHeader>
                      <AlignTime>
                        <TimeDay>{i.getDayOfMonth}</TimeDay>
                        <TimeMonth>{i.getMonthOfYear}</TimeMonth>
                      </AlignTime>

                      <BoxDot>
                        <span className="dot"></span>
                      </BoxDot>

                      <div>
                        <TitleOffer>
                          {String(i.nomeLoja).toLocaleLowerCase()}
                        </TitleOffer>
                        <AddressOffer className="truncate-overflow">
                          {String(i.logradouro).toLocaleLowerCase()}
                        </AddressOffer>
                      </div>
                    </ContentHeader>
                  </HeaderCard>

                  <FooterCard>
                    <PhotoStore isMobile={isMobile} local={false}>
                      {false ? (
                        <LocalIcon width={60} height={71} />
                      ) : (
                        <Aamericanas width={20} height={71} />
                      )}
                    </PhotoStore>
                    <InfoFooter>
                      <h2>compra concluída</h2>

                      <span>
                        total de produtos:{' '}
                        {String(i.itens.length).padStart(2, '0')}
                      </span>

                      <h1>valor total: R$ {i.valorTotal}</h1>
                    </InfoFooter>

                    <HiOutlineChevronRight
                      size={16}
                      color="#000"
                      onClick={() => handleDetails(i)}
                    />
                  </FooterCard>
                </Card>
              </Alignment>
            </>
          ) : (
            <Card key={`${i}_card_validData`} empty>
              <div />
              <h3>Ops! Falha ao carregar.</h3>
              <FooterCard>
                <div />

                <h2>atualizar</h2>
              </FooterCard>
            </Card>
          )
        );
      })}
    </>
  );
};
export default forwardRef(OrderMobile);
