import React, { FC } from 'react';
import { Container } from './styles';
import useWindowDimensions from '../../Utils/size';

interface Props {
  visible: boolean;
  onClick?: () => void;
  containerStyle?: object;
}

const ModalTooltip: FC<Props> = ({ visible, onClick, containerStyle }) => {
  const isMobile = useWindowDimensions();
  return (
    <Container
      visible={visible}
      onClick={onClick}
      isMobile={isMobile}
      style={containerStyle}
    />
  );
};

export default ModalTooltip;
