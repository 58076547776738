// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  forwardRef,
  InputHTMLAttributes,
  RefForwardingComponent,
  useRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../Services/api';
import useWindowDimensions from '../../Utils/size';
import queryString from 'query-string';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  PropTypes,
} from 'react-places-autocomplete';
import {
  Container,
  Icon,
  Content,
  Error,
  CardResult,
  CardItem,
} from './styles';
import LupaIcon from '../../Assets/ICONE_BUSCA.svg';
import Truncate from '../../Utils/truncate';
import { loadProps, LoadFunctionProps } from '../../Pages/List/index';
import Geo from '../../Utils/Geocode';
import { TitleGenerator } from '../../Utils/TitleGenerator';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import BlacklistPlaces from '../../Services/data/BlacklistPlaces';

const typingTimer: any = null;

export interface InputCustom extends PropTypes {
  styleContainer?: object;
  isActive?: boolean;
  handleOnChange: (e: any) => void;
  isError?: string;
  isMobile?: boolean;
  defaultValue?: string | undefined | null;
  isList?: boolean;
  onClickHandle?: (data: LoadFunctionProps) => void;
  cep?: string;
  cbFunction?: () => void;
  handleSelect?: (params?: any) => void;
  fromModalChange?: boolean;
  fromHome?: boolean;
}

interface CoordsProps {
  lat: number;
  lng: number;
}

interface ChildProps {
  description: string;
}

const Input: FC<InputCustom> = ({
  styleContainer = {},
  isActive = false,
  handleOnChange,
  isError = '',
  defaultValue,
  isList = false,
  onClickHandle,
  cep,
  cbFunction,
  fromModalChange,
  fromHome,
}) => {
  const isMobile = useWindowDimensions();
  const [address, setAddress] = useState(() => {
    if (defaultValue) {
      return defaultValue;
    }

    return '';
  });

  const [firstChild, setFirstChild] = useState({} as ChildProps);
  const [coordinates, setCoordinates] = useState<CoordsProps>(
    {} as CoordsProps
  );
  const location = useLocation();
  const history = useHistory();
  const parsed = queryString.parse(location.search);

  const InputLocationRef = useRef<HTMLInputElement>(null);

  const handleAddressClicked = useCallback(() => {
    HandleEvents({
      isMobile,
      eventAction: 'home',
      eventCategory: 'nossaslojas',
      eventLabel: 'busca',
      eventValue: undefined,
      cookies: {},
    });
  }, [isMobile]);

  const handleSelect = useCallback(
    async (value) => {
      try {
        const { results, coords } = await Geo(value);

        const cepTypes = ['postal_code', 'postal_code_prefix'];

        if (results[0].address_components[0].long_name.length < 9) {
          let forbidden = false;
          results[0].types.map((t: string) => {
            if (cepTypes.includes(t)) {
              forbidden = true;
            }
          });

          if (forbidden) {
            console.log('nao pegou cep completo, bloquear');
            return;
          }
        }

        const nameAddress = cep ? results[0].formatted_address : value;

        // if (fromHome) {
        //   handleAddressClicked(value);
        // }

        setAddress(value);
        setCoordinates(coords);

        const { lat, lng } = coords;

        if (cbFunction) {
          cbFunction();
        }

        const { feather, footer, chave, epar } = parsed;

        const bruteUF = results[0].address_components.find((i: any) =>
          i.types.includes('administrative_area_level_1')
        );

        const bruteCity = results[0].address_components.find((i: any) =>
          i.types.includes('administrative_area_level_2')
        );

        const bruteNeighbor = results[0].address_components.find((i: any) =>
          i.types.includes('sublocality')
        );
        // /:uf/:cidade/:bairro
        let uf = bruteUF?.short_name;
        let cidade = bruteCity?.long_name;
        let bairro = bruteNeighbor?.short_name;

        TitleGenerator({
          uf: uf,
          city: cidade,
          neighborhood: bairro,
        });

        uf = uf?.trim().toLowerCase().replace(/\s/g, '-');
        cidade = cidade?.trim().toLowerCase().replace(/\s/g, '-');
        bairro = bairro?.trim().toLowerCase().replace(/\s/g, '-');

        let makeAddress = `${uf}`;

        if (cidade) {
          makeAddress = makeAddress + `/${cidade}`;
        }

        if (bairro) {
          makeAddress = makeAddress + `/${bairro}`;
        }

        let uri = `?chave=${chave}&lat=${lat}&lng=${lng}&name=${nameAddress}&pag=1&epar=${epar}`;

        if (feather !== 'undefined') {
          uri = uri + `&feather=${feather}`;
        }

        if (feather !== 'undefined') {
          uri = uri + `&footer=${footer}`;
        }

        const { pathname } = location;

        if (pathname === '/local') {
          uri = `${uri}&local=${true}&onlyLocal=${true}`;
        }

        if (parsed.local && parsed.local !== 'undefined') {
          uri = uri + `&local=${true}`;
        }

        if (parsed.onlyLocal && parsed.onlyLocal !== 'undefined') {
          uri = uri + `&onlyLocal=${true}`;
        }

        const near =
          bruteNeighbor?.short_name ||
          bruteCity?.long_name ||
          bruteUF?.short_name;

        uri = uri + `&near=${near}`;

        history.push({
          pathname: `/${makeAddress}`,
          search: uri,
        });
      } catch (error) {
        console.log('erro api places: ', error);
      }
    },
    [history, cep, parsed, location, cbFunction]
  );

  const handleClickLupa = useCallback(() => {
    if (firstChild) {
      const { description } = firstChild;
      handleAddressClicked();

      handleSelect(description);
    }
  }, [handleSelect, firstChild, handleAddressClicked]);

  useEffect(() => {
    if (cep) {
      const first = cep.slice(0, 5);
      const second = cep.slice(5, 8);
      const newCep = `${first}-${second}`;

      handleSelect(newCep);
    }
  }, [cep, handleSelect, InputLocationRef]);

  // useEffect(() => {
  //   console.log('buscando: ', parsed);
  //   if (!parsed.lat && !parsed.lng) {
  //     console.log('nao tem os dois');
  //   }
  // }, [parsed]);

  const makeSuggestions = useCallback(
    (suggestions: any, getSuggestionItemProps: any) => {
      const places: any = [];
      // console.log('suggestions: ', suggestions);
      suggestions.map((s: any) => {
        let forbidden = false;

        BlacklistPlaces.map((b) => {
          if (s.description.includes(b)) {
            forbidden = true;
          }
        });

        if (!forbidden) {
          places.push(s);
        }
      });

      const card = places.map((i: any, index: number) => {
        if (index === 0) {
          setFirstChild(i);
        }

        if (index > 2 && isMobile) {
          return;
        }

        return (
          <CardItem
            fromModalChange={fromModalChange}
            key={index + 1}
            // onClick={() => handleSelect(i)}
            isMobile={isMobile}
            {...getSuggestionItemProps(i)}
            isList={isList}
          >
            <span>{Truncate(i.description, isMobile ? 40 : 45)}</span>
          </CardItem>
        );
      });

      return card;
    },
    [fromModalChange, isList, isMobile]
  );

  // const validateEventFinish = useCallback(() => {
  //   // if (fromHome) {
  //   //   handleFinishType();
  //   // }
  //   // handleAddressClicked();
  // }, [fromHome, handleAddressClicked]);

  // const handleValidateKeyUp = useCallback(
  //   (e: any) => {
  //     clearTimeout(typingTimer);
  //     typingTimer = setTimeout(validateEventFinish, 4000);
  //   },
  //   [validateEventFinish]
  // );

  // const handleValidateKeyDown = useCallback((e: any) => {
  //   clearTimeout(typingTimer);
  // }, []);

  return (
    <Content>
      <PlacesAutocomplete
        value={address}
        onChange={(e) => {
          setAddress(e);
          handleOnChange(e);
        }}
        onSelect={(e) => {
          handleSelect(e);
          handleAddressClicked();
        }}
        searchOptions={{
          componentRestrictions: {
            country: 'br',
          },
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <Container
              style={styleContainer}
              isActive={isActive}
              isError={isError}
              isMobile={isMobile}
            >
              <input
                // onKeyUpCapture={handleValidateKeyUp}
                // onKeyDownCapture={handleValidateKeyDown}
                ref={InputLocationRef}
                style={{ fontSize: 18 }}
                {...getInputProps({
                  placeholder: 'procure por rua, cidade, estado ou CEP',
                })}
              />
              <Icon src={LupaIcon} onClick={handleClickLupa} />
            </Container>

            <CardResult isList={isList}>
              {makeSuggestions(suggestions, getSuggestionItemProps)}
            </CardResult>
          </>
        )}
      </PlacesAutocomplete>

      {/* <Error>{`lat: ${coordinates.lat}, lng: ${coordinates.lng}`}</Error> */}
    </Content>
  );
};

export default Input;
