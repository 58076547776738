import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 206px;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 999999999999;
  position: relative;
  border-radius: 16px;
  /* span {
    font-size: 15px !important;
  } */
`;

interface PropsContent {
  bottomCalc: string;
  mNegativeLeft?: string;
  mLeft?: string;
  mRight: string;
  isMobile?: boolean;
  lastContent?: boolean;
  multiOptions?: boolean;
}

export const Content = styled.span<PropsContent>`
  width: 259px;
  background: #ffffff;
  padding: 20px 21px;
  z-index: 999999999999;
  font-family: Helvetica Neue;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  transition: opacity 0.5s;
  visibility: visible;
  border-radius: 16px;

  ${({ multiOptions }) =>
    multiOptions &&
    css`
      &:nth-child(1) {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      &:nth-child(2) {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    `}

  /* box-shadow: 0px 0px 5px #0000001a; */

  position: absolute;
  height: max-content;

  top: 70px;

  @media only screen and (min-width: 321px) and (max-width: 360px) {
    right: Calc(${(props) => props.mRight} + 2px);
  }

  @media only screen and (max-width: 414px) {
    right: Calc(${(props) => props.mRight} + 7px);

    ${({ lastContent, mRight }) =>
      lastContent &&
      css`
        right: Calc(${mRight} + 12px);
      `}
  }

  @media only screen and (max-width: 375px) {
    /* right: ${(props) => props.mRight}; */
    right: Calc(${(props) => props.mRight} + 44px);

    ${({ lastContent, mRight }) =>
      lastContent &&
      css`
        right: Calc(${mRight} + 49px);
      `}
  }

  @media only screen and (max-width: 320px) {
    /* right: ${(props) => props.mRight}; */
    right: Calc(${(props) => props.mRight} + 99px);

    ${({ lastContent, mRight }) =>
      lastContent &&
      css`
        right: Calc(${mRight} + 104px);
      `}
  }

  color: #707070;
  text-align: left;

  ${({ isMobile }) =>
    isMobile &&
    css`
      top: 54px !important;
    `}

  &:first-child {
    &::before {
      content: '';
      border-style: solid;
      border-color: #ffffff transparent;
      border-width: 11px 11px 0 11px;
      bottom: 100%;
      position: absolute;
      right: ${(props) => props.mLeft};

      /* transform: translateX(-50%); */
      transform: rotate(180deg);

      ${({ lastContent, mRight }) =>
        lastContent &&
        css`
          right: 10px;
          top: -10px;
        `}
    }
  }

  span {
    font-size: 14px;
  }
`;

export const Barrinha = styled.div`
  height: 1px;
  background: #e0e0e0;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin-right: 8.5px;
  margin-left: 8.5px;
`;
