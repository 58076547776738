import styled, { css } from 'styled-components';

interface WrapperProps {
  withTags: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  height: 100%;
  position: relative;
`;

export const ButtonOtherStores = styled.button`
  border: 0px;
  background: #f80032;
  height: 44px;
  width: 143px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  border-radius: 8px;
  position: absolute;
  bottom: 0px;

  span {
    line-height: 20px;
    color: #fff;
    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const WithoutServiceAlert = styled.div`
  margin-top: 16px;
  width: 306px;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2fbff;
  border-radius: 8px;

  svg {
    fill: #666;
  }

  svg path {
    fill: #666;
  }

  p {
    margin-left: 16px;
    line-height: 16px;
    color: #333333;
    font-family: Helvetica Neue, Regular;
    font: -webkit-control;
    font-size: 14px;
  }
`;

interface ContainerProps {
  isPar?: boolean;
  multiploTres?: boolean;
  isMobile?: boolean;
  carousel?: boolean;
  index?: number;
  selected?: boolean;
  isMap?: boolean;
  collapsed?: boolean;
}

interface BoxLayerProps {
  isMobile: boolean;
  open?: boolean;
}
export const BoxLayer = styled.div<BoxLayerProps>`
  margin-top: ${({ isMobile }) => (isMobile ? '-1px' : '4px')};

  position: relative;

  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background-color: ${(props) => (props.open ? '#eff9ea' : '#F7E6E8')}; */

  h2 {
    color: ${(props) => (props.open ? '#008A00' : '#F40033')} !important;
    font-family: 'Helvetica Neue, Bold' !important;
    font: -webkit-control;
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  strong {
    color: #333333 !important;

    font-weight: 400 !important;

    ::before {
      content: '•';
      font-size: 12px;
      color: #666666;
      margin: 0 10px;
    }

    ::after {
      content: '' !important;
    }
  }
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  margin: ${(props) => (props.isMobile ? 'auto' : '')};
  z-index: 10;
`;

export const Content = styled.div`
  display: flex;
  z-index: 200;
  padding-top: 0px;
`;

interface LogoStoreProps {
  isMobile?: boolean;
  isLocal?: boolean;
}
export const LogoStore = styled.div<LogoStoreProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  width: 72px;
  height: 72px;

  @media only screen and (max-width: 374px) {
    min-width: 60px !important;
    width: 60px !important;
  }

  background: ${({ theme }) => theme.colors.redPrimary};
  border: solid 1px #0000000f;
  border-radius: 8px;

  > img {
    width: ${(props) => (props.isMobile ? '61px' : '38px')};
  }

  > svg path {
    fill: #fff;
  }

  /* > svg {
    ${({ isLocal, isMobile }) =>
    isLocal &&
    css`
      width: ${isMobile ? '20px' : '20px'} !important;
    `}
  } */

  #Rectangle-2,
  #Rectangle,
  rect {
    fill: #fff;
  }
`;

interface InfoProps {
  isMobile?: boolean;
  open?: boolean;
  small?: boolean;
}

export const Info = styled.div<InfoProps>`
  display: flex;
  flex: 1;

  flex-direction: column;

  position: relative;
  /* justify-content: space-between; */

  justify-content: space-between;

  .truncate-overflow {
    max-width: 252px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  h1 {
    width: ${({ small }) => (small ? '90%' : '100%')};
    color: #333333;
    font-family: 'Helvetica Neue, Bold';
    font-size: 24px;
    line-height: 27.6px;

    cursor: pointer;
  }

  p {
    font-family: Helvetica Neue, Regular;
    font-weight: 300;
    color: #999999;
    font-size: ${(props) => (props.isMobile ? '14px' : '14px')};
    margin-top: 8px;

    font: -webkit-control;
    line-height: 20px;
  }

  h2 {
    color: ${({ theme, open }) =>
      open ? theme.colors.grenPrimary : theme.colors.redPrimary};
    font-weight: bold;
    font-family: Helvetica Neue, Bold;
    font-size: ${(props) => (props.isMobile ? '14px' : '14px')};
  }

  strong {
    color: #000;
    font-family: Helvetica Neue, Regular;
    font-size: 14px;
    font-weight: 200;

    font: -webkit-control;

    :after {
      content: '•';
      font-size: 12px;
      color: #000000;
      margin: 0 3px;
    }
  }

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }

  /* span {
    color: #000000;
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.regular};

    :after {
      content: '•';
      font-size: 12px;
      color: #000000;
      margin: 0 10px;
    }
  } */
`;

interface ButtonsProps {
  wppStore?: boolean;
  variant: string;
  isMobile?: boolean;
}

export const Buttons = styled.div<ButtonsProps>`
  display: flex;
  align-items: center;
  margin: 0;
  z-index: 9999999;
  width: 100%;
  position: relative;
  margin-top: 53px;

  justify-content: flex-start;

  a {
    text-decoration: none;
  }
`;

interface ProductButtonProps {
  isMobile?: boolean;
  variant: string;
  big?: boolean;
}

export const ProductButton = styled.div<ProductButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: ${(props) => (props.isMobile ? '125px' : '160px')}; */
  height: 44px;
  border: solid 1.5px;
  color: ${({ theme }) => theme.colors.redPrimary};
  padding: 10px 0px;
  border-radius: 8px;
  z-index: 11;
  cursor: pointer;
  margin-left: 5px;
  width: 121.25px;

  ${({ variant, isMobile }) =>
    variant === 'NEW' &&
    css`
      /* width: ${isMobile ? '152px' : '182px'} !important;

      @media only screen and (min-width: 376px) {
        width: 152px !important;
      }

      @media only screen and (max-width: 360px) {
        width: 141px !important;
      } */
    `}
  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 10px 7px 10px 5px;
    `}
    ${({ big, variant, isMobile }) =>
    !big &&
    (variant === 'OLD' || variant === 'CONTROL') &&
    css`
      /* width: 152px !important;

      @media only screen and (max-width: 360px) {
        width: 125px !important;
      }

      @media only screen and (min-width: 376px) {
        width: 152px !important;
      } */
    `}
    ${({ big, variant, isMobile }) =>
    big &&
    (variant === 'OLD' || variant === 'CONTROL') &&
    isMobile &&
    css`
      /* width: 125px !important;

      @media only screen and (min-width: 376px) {
        width: 152px !important;
      } */
    `}
    > svg {
    width: 17px;
    height: 15px;
    margin-right: 7px;

    ${({ isMobile }) =>
      isMobile &&
      css`
        width: 15px;
        height: 13px;
      `}
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.redPrimary};
  }

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: 'Helvetica Neue, Bold';
    font-size: 14px;
    font: -webkit-control;
    font-weight: bold;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.redPrimary};

    span {
      color: ${({ theme }) => theme.colors.redPrimary};
    }

    > svg path {
      fill: ${({ theme }) => theme.colors.redPrimary};
    }
  }
`;

interface BuyButtonProps {
  isMobile?: boolean;
  variant: string;
  big?: boolean;
}
export const BuyButton = styled.div<BuyButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: ${(props) => (props.isMobile ? '125px' : '160px')}; */
  height: 44px;
  border: solid 1.5px;
  border-color: ${({ theme }) => theme.colors.redPrimary};
  padding: 10px 0px;
  border-radius: 8px;
  z-index: 11;
  cursor: pointer;
  /* width: ${({ variant, big }) =>
    variant === 'NEW' && big ? '100%' : '98%'}; */
  width: 119px;
  background-color: ${({ theme }) => theme.colors.redPrimary};

  > svg {
    width: 17px;
    height: 15px;
    margin-right: 7px;
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.whitePrimary};
  }

  span {
    color: ${({ theme }) => theme.colors.whitePrimary};
    font-family: 'Helvetica Neue, Bold';
    font-size: 16px;
    font: -webkit-control;
    font-weight: bold;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.redPrimary};

    span {
      color: ${({ theme }) => theme.colors.whitePrimary};
    }

    > svg path {
      fill: ${({ theme }) => theme.colors.whitePrimary};
    }
  }
`;

interface WhatsButtonProps {
  isMobile?: boolean;
  withProducts?: boolean;
  variant: string;
}

export const WhatsButton = styled.div<WhatsButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ variant }) =>
    variant === 'OLD' || variant === 'CONTROL' ? '44px' : '150px'};
  height: 40px;
  border: solid 1.5px;
  border-color: ${({ theme }) => theme.colors.redPrimary};
  padding: 12px;
  border-radius: 5px;
  margin-left: ${(props) => (props.withProducts ? '10px' : '0px')};
  z-index: 11;
  margin-left: 5px;

  > svg {
    width: 25px;
    height: 25px;
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.redPrimary};
  }

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: 'Helvetica Neue, Bold';
    margin-left: 4px;
    font-size: ${(props) => (props.isMobile ? '14px' : '14px')};
    font-weight: bold;

    margin-top: -2px;
  }

  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.redPrimary};

    span {
      color: ${({ theme }) => theme.colors.redPrimary};
    }

    > svg path {
      fill: ${({ theme }) => theme.colors.redPrimary};
    }
  }
`;

export const Distance = styled.div`
  position: relative;
  width: 80px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1.5px #d5d5d5;
  margin-right: 16px;

  span {
    color: #333333;
    font-family: 'Helvetica Neue, Bold';
    font-size: 13px;
    font-weight: bold;
    font: -webkit-control;
    font-weight: bold;
  }
`;

export const ActionFakeBox = styled.div`
  position: absolute;
  z-index: 11;
  cursor: pointer;
  top: 0;
  left: 0;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`;

interface FootercardProps {
  isMobile?: boolean;
  open?: boolean;
}

export const FooterCard = styled.div<FootercardProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-top: 22px;
  padding-top: 0px;
  z-index: 200;
  s .truncate-overflow {
    max-width: 252px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;

export const MiddleContent = styled.div`
  display: flex;
  /* padding: 16px 0px; */
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: 14px !important;

  h2 {
    font: -webkit-control;
    font-size: 14px;
    font-weight: bold !important;
  }

  p {
    font: -webkit-control;
    color: #999999;
    line-height: 18px;
    width: 83%;
  }

  strong {
    /* font-family: ${({ theme }) => theme.fonts.bold}; */
    color: #666666 !important;
    font-weight: bold !important;
    margin-top: -2px;

    :after {
      content: '•';
      font-size: 12px;
      color: #666666;
      margin: 0 3px;
    }
  }

  .truncate-overflow-middle-content {
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;

export const DudeBox = styled.div`
  position: absolute;
  right: 16px;

  > svg {
    fill: #cccccc;
  }

  > svg path {
    fill: #cccccc;
  }
`;

export const TagsContent = styled.div`
  margin-top: 27px;
  display: flex;
  flex-direction: column;
`;

interface TagProps {
  blue?: boolean;
}
export const Tag = styled.div<TagProps>`
  padding: 6px 10px;
  border-radius: 100px;
  background: rgba(93, 199, 44, 0.1);
  margin-bottom: 14px;
  width: 112px;

  span {
    color: #008a00;
    font-family: ${({ theme }) => theme.fonts.bold};
    font-size: 11px;
    font: -webkit-control;
    font-weight: 600 !important;
  }

  ${({ blue }) =>
    blue &&
    css`
      width: 190px;
      margin-bottom: 0px;
      background: rgba(29, 200, 237, 0.1);
      span {
        color: #157fe8;
      }
    `}
`;

export const Header = styled.div`
  position: relative;
  width: 100%;
  height: 105px;
  z-index: 99;
  border-radius: 16px 16px 0px 0px;
`;

export const HeaderInfo = styled.div`
  width: 100%;
  padding: 16px;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  position: absolute;
  border-radius: 16px 16px 0px 0px;
`;

interface FakeBgProps {
  withTags: boolean;
}
export const FakeBg = styled.div<FakeBgProps>`
  width: 100%;
  height: ${({ withTags }) => (withTags ? '221px' : '191px')};
  background: linear-gradient(
    359.73deg,
    #ffffff 44.81%,
    rgba(255, 255, 255, 0.820312) 71.72%,
    rgba(255, 255, 255, 0.670205) 109.79%,
    rgba(255, 255, 255, 0.5) 123.64%
  );
  position: absolute;
  top: 0;
  left: 0;
  z-index: 150;
  border-radius: 16px;
`;

interface WhatsSolidButtonProps {
  isMobile?: boolean;
  isMapView?: boolean;
}

export const WhatsSolidButton = styled.div<WhatsSolidButtonProps>`
  cursor: pointer;
  width: ${({ isMobile }) => (isMobile ? '98px' : '186px')};
  height: ${({ isMobile }) => (isMobile ? '26px' : '39px')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.redPrimary};

  z-index: 1;
  position: relative;

  background-color: #fff;
  border-color: #e60115 !important;
  border-radius: 54px;
  margin-left: 13px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.05));

  span {
    margin: 0px;
    padding: 0px;
    font: -webkit-control;

    margin-left: ${({ isMobile }) => (isMobile ? '6px' : '10.5px')};
    font-size: 12px;
    line-height: 13.8px;
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.redPrimary} !important;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      svg {
        width: 15px;
        height: 15px;
      }
    `}

  ${({ isMobile, isMapView }) =>
    isMobile &&
    isMapView &&
    css`
      width: 91px;
      height: 25px;
      margin-right: 13px;

      svg {
        width: 13px;
        height: 13px;
      }
    `}


  }

  img {
    width: 8px;
    height: 10px;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
