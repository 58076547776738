/* eslint-disable react/prop-types */
import React, {
  FC,
  useState,
  useCallback,
  useRef,
  useEffect,
  forwardRef,
  RefForwardingComponent,
} from 'react';
import Input from '../Input_old';
import { Container, Box, Barrinha, RedButton } from './styles';
import useWindowDimensions from '../../Utils/size';
import { loadProps, LoadFunctionProps } from '../../Pages/List';
import { ReactComponent as Basket } from '../../Assets/cesta_produtos.svg';

interface OtherProps {
  defaultValueInput?: any;
  visible: boolean;
  onClose: () => void;
  onClickHandle?: (data: LoadFunctionProps) => void;
  cbFunction?: () => void;
}

const ModalProductsDetail: RefForwardingComponent<any, OtherProps> = (
  {
    cbFunction,
    onClickHandle,
    defaultValueInput = '',
    visible = false,
    onClose,
  },
  ref
) => {
  const [activeInput, setActiveInput] = useState(false);
  const [isError, setIsError] = useState('');
  const isMobile = useWindowDimensions();
  // const InputRef = useRef<HTMLInputElement>(null);
  const handleSearch = useCallback((e: string) => {
    if (e && e.length >= 3) {
      setActiveInput(true);

      if (e.length > 6) {
        setActiveInput(false);
        setIsError('CEP inválido, tente novamente');
      }
    } else {
      setActiveInput(false);
      setIsError('');
    }
  }, []);
  return (
    <>
      {visible && (
        <>
          <Container onClick={onClose} />

          <Box>
            <Barrinha />
            <Basket />

            <h1>
              aqui vamos adicionar<br></br>o título do modal
            </h1>
            <p>
              aqui podemos explicar sobre a cesta mínima<br></br> desta loja :)
            </p>

            <RedButton onClick={onClose}>
              <span>ok, entendi</span>
            </RedButton>
          </Box>
        </>
      )}
    </>
  );
};

export default forwardRef(ModalProductsDetail);
