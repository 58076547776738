import React, { FC } from 'react';
import { Container, ActionBtn } from './styles';
import { ReactComponent as BuyBasket } from '../../Assets/buy-basket.svg';
import useWindowDimensions from '../../Utils/size';

interface OrderPlaceholderProps {
  handle: any;
}

const OrderPlaceholder: FC<OrderPlaceholderProps> = ({ handle }) => {
  const isMobile = useWindowDimensions();
  return (
    <Container isMobile={isMobile}>
      {/* <BuyBasket /> */}
      {handle()}

      {/* <span style={{ marginTop: 11 }}>
      Ops! Você ainda não possui um histórico de compras realizadas em nossas
      lojas físicas.
    </span>

    <span style={{ marginTop: 20 }}>
      Pro seu histórico aparecer aqui, lembre sempre de informar seu CPF na hora
      do pagamento em uma de nossas lojas :)
    </span> */}

      {/* <ActionBtn>
      <span>ver loja mais próxima</span>
    </ActionBtn> */}
    </Container>
  );
};

export default OrderPlaceholder;
