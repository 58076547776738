import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background: rgba(0, 0, 0, 0.6);

  position: fixed;
  z-index: 9999999999999999999999;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;

  /* display: flex;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background: blue;
    position: fixed;
    z-index: 9999999999999999999999;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px; */
`;

export const Barrinha = styled.div`
  width: 72px;
  height: 3.5px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  margin: auto;
  margin-bottom: 24px;
`;

export const Box = styled.div`
  width: 100%;
  background: #ffffff;
  min-height: 148px;
  position: fixed;
  bottom: 0px;
  border-radius: 10px 10px 0 0;
  z-index: 99999999999;
  padding: 12px 24px 34px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px 16px 0px 0px;

  transition: all 0.5s ease-out;

  h1 {
    color: #333333;

    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 25px;
    text-align: center;
  }

  p {
    color: #666;
    text-align: center;
    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-size: 12px;
    line-height: 14.4px;
    margin-top: 16px;
  }
`;

export const RedButton = styled.div`
  width: 100%;
  height: 54px;
  background-color: #f80032;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  span {
    color: #fff;

    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }
`;
