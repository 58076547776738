import React, { FC, useState, useEffect, useCallback } from 'react';
import useWindowDimensions from '../../Utils/size';
import { CarousselProductsMobile } from './Mobile';
import { CarousselProductsWeb } from './Web';
import api from '../../Services/api';
import Config from '../../Utils/Config';
import { ProductPopulated, SuggestionProductsProps } from './interfaces';
import { useParams } from 'react-router-dom';

interface ParamProps {
  id: string;
}

interface Props {
  linkBuy: any;
}
const CarousselProducts: FC<Props> = ({ linkBuy }) => {
  const [data, setData] = useState<ProductPopulated[]>([]);
  const [title, setTitle] = useState('');
  const isMobile = useWindowDimensions();
  const { id } = useParams() as ParamProps;
  console.log('store id: ', id);
  const load = useCallback(async () => {
    try {
      const rid = 'RJ_CAPITAL_3301';
      const userId = 'va_1654265607802.0.36255311700892046';
      const storeId = 'L100';

      // const uri = `${Config.suggestionProductsStoreAPI}?rid=${rid}&userId=${userId}&placements=lasa_stores.rr1%7Clasa_stores.rr2&storeId=${storeId}`;

      const uri =
        'https://mars-v1-americanas-npf.b2w.io/rrserver/api/rrPlatform/recsForPlacements?limit=12&includeMVTData=true&includeStrategyData=true&returnMinimalRecItemData=true&sessionId=1654267599967.0.21123756508515323&userId=va_1654265607802.0.36255311700892046&placements=home_page.rr1&userAttribute=device%3Adesktop&rid=RJ_CAPITAL_3301&categoryId=virtual-home';

      console.log('uri: ', uri);
      const { data } = await api.get(uri);

      const products: SuggestionProductsProps[] = data.placements;

      console.log('products: ', products);

      const content: ProductPopulated[] = [];

      const dados: any = await Promise.all(
        products.map(async (a: SuggestionProductsProps) => {
          return await Promise.all(
            // a.recommendedProducts.map(async (i) => {
            [
              '44414152',
              '3923260231',
              '21082999',
              '3234381133',
              '1252571002',
            ].map(async (i) => {
              const sellerStoreId = '33014556000196';

              console.log('i: ', i);

              // const { data: dataProduct } = await api.get(
              //   `${Config.suggestionProductsPopulateAPI}?productid=${i.id}&sellerid=${sellerStoreId}`
              // );
              const { data: dataProduct } = await api.get(
                `${Config.suggestionProductsPopulateAPI}?productid=${i}&sellerid=${sellerStoreId}`
              );

              console.log('dataProduct: ', dataProduct);

              const productPopulated: ProductPopulated =
                dataProduct['product-b2w'].result;

              console.log('productPopulated: ', productPopulated.name);

              const availableInThisStore = !!productPopulated.offer.offers.find(
                (product) =>
                  product.availability._embedded.stock.warehouses.includes(id)
              );
              const payload = {
                ...productPopulated,
                availableInThisStore,
              };

              if (availableInThisStore) {
                content.push(payload);
              }
            })
          );
        })
      );

      console.log('dados final: ', content);

      setData(content);
      setTitle(products[0].strategyMessage);
    } catch (error) {
      console.log('error load Caroussel Products: ', error);
    }
  }, [id]);

  useEffect(() => {
    load();
  }, []);

  return data?.length ? (
    isMobile ? (
      <CarousselProductsMobile title={title} data={data} linkBuy={linkBuy} />
    ) : (
      <CarousselProductsWeb title={title} data={data} linkBuy={linkBuy} />
    )
  ) : (
    <div></div>
  );
};

export { CarousselProducts };
