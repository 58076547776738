import React, { FC } from 'react';
import { Container } from './styles';

interface Props {
  visible: boolean;
  onClick?: () => void;
}

const ModalNFE: FC<Props> = ({ visible, onClick }) => (
  <Container visible={visible} onClick={onClick} />
);

export default ModalNFE;
