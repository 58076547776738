import styled, { css } from 'styled-components';

interface ContainerProps {
  isMobile?: boolean;
}

export const Container = styled.div<ContainerProps>`
  height: ${(props) => (props.isMobile ? '180px' : '265px')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f2f2;
  margin-top: ${(props) => (props.isMobile ? '0px' : '0px')};

  ${({ isMobile }) =>
    isMobile &&
    css`
      position: relative;
      z-index: 99;
    `}
`;

interface ContentProps {
  screen: number;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
