import styled, { css } from 'styled-components';

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  height: 100vh;
  padding: 12px;

  .on-scrollbar {
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  .on-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }

  .on-scrollbar::-webkit-scrollbar {
    width: 6px !important;
    background-color: transparent;
  }

  .on-scrollbar::-webkit-scrollbar-thumb {
    background-color: #acacac;
  }

  .truncate-overflow {
    /* max-width: 252px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;

interface ContainerProps {
  forPrint?: boolean;
}
export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;

  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background-color: #f3f3f3;

  align-items: center;

  ${({ forPrint }) =>
    !forPrint &&
    css`
      height: calc(100vh - 84px);
    `};

  ${({ forPrint }) =>
    forPrint &&
    css`
      height: 100% !important;
      padding-top: 30px;
    `};
`;

export const Name = styled.span`
  color: #666666;
  font-size: 16px;
  font-family: 'Helvetica Neue, Bold';
  line-height: 19px;
  text-align: center;
  margin: 0 51px;
  margin-top: 11px;
`;

export const Address = styled.span`
  color: #666666;
  font-size: 14px;
  font-family: Helvetica Neue, Regular;
  line-height: 17px;
  margin: 0 66px;
  margin-top: 9px;
  text-align: center;
`;

export const Barrinha = styled.div`
  width: 100%;
  height: 1.5px;
  background: #e5e5e5;
  margin-top: 16.7px;
`;

export const InfoHeader = styled.div`
  margin-top: 13.3px;
  width: 100%;
  padding: 0 21px 0px 16px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
`;

export const ItemList = styled.span`
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-size: 14px;
  line-height: 17px;
`;

export const ItemData = styled.span`
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-size: 14px;
  line-height: 17px;
`;

export const Title = styled.span`
  color: #666666;
  font-family: 'Helvetica Neue, Bold';
  font-size: 16px;
  line-height: 17px;
  margin-top: 19px;
  margin-bottom: 16px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  margin-bottom: 12px;

  height: 72px;
`;

export const ActionButton = styled.button`
  border: 0px;
  width: 170px;
  height: 44px;
  background: #ffffff;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: Helvetica Neue, Regular;
    font-size: 16px;
    line-height: 18px;
  }
`;
interface WrapperProps {
  forPrint?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;

  ${({ forPrint }) =>
    !forPrint &&
    css`
      overflow: scroll;
    `}

  ${({ forPrint }) =>
    forPrint &&
    css`
      display: block;
    `}
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

interface CardProps {
  isPar: boolean;
  forPrint: boolean;
}
export const Card = styled.div<CardProps>`
  width: 100%;
  padding: 9px 16px;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ isPar }) =>
    isPar &&
    css`
      background-color: #e5e5e5;
    `}

  ${({ forPrint }) =>
    forPrint &&
    css`
      height: auto;
    `}
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

interface NameProductProps {
  forPrint?: boolean;
  isMobile?: boolean;
}
export const NameProduct = styled.span<NameProductProps>`
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-size: 14px;
  line-height: 17px;
  width: ${({ forPrint }) => (forPrint ? '70%' : '190px')};

  ${({ isMobile, forPrint }) =>
    isMobile &&
    forPrint &&
    css`
      width: 70%;
      margin-bottom: 10px;
    `}
`;
export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-end;
`;

export const Value = styled.span`
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-size: 14px;
  line-height: 17px;
`;
export const Qtd = styled.span`
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-size: 14px;
  line-height: 17px;
`;
export const Ean = styled.span`
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-size: 13px;
  line-height: 17px;
  /* margin-top: 9px; */
`;

export const FooterContent = styled.div`
  width: 100%;
  padding: 22px 16px 16px 16px;
  display: flex;
  justify-content: space-between;

  span {
    color: #666666;
    font-family: 'Helvetica Neue, Bold';
    font-size: 14px;
    line-height: 17px;
  }

  p {
    color: #666666;
    font-family: 'Helvetica Neue, Bold';
    font-size: 20px;
    line-height: 17px;
  }
`;

export const MoreButton = styled.button`
  background: transparent;
  border: solid 2px #e0030e;
  border-radius: 8px;
  width: 160px;
  height: 36px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  cursor: pointer;
  margin-bottom: 30px;

  margin-right: 20px;

  span {
    font-family: Helvetica Neue, Regular;
    color: #df030e;
    font-size: 14px;
  }
`;

export const BuyInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
