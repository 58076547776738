import React, { FC, useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { DaysProps } from '../../../Utils/HourFunction';
import api from '../../../Services/api';
import { MyExperiment } from '../../../Utils/PlanOut';
import { useCookies } from 'react-cookie';
import ModalHelp from '../../../Components/ModalHelp';
import Footer from '../../../Components/Footer';
import LoadingIcon from '../../../Assets/loading.svg';
import V3 from './Escolha/V3';
import V2 from './Escolha/V2';
import ListWpp, { getApiListWpp, WppProps } from '../../../Utils/ListWpp';
import ListCovid, { getApiListCovid, CovidProps } from '../../../Utils/Covid';
import { getApiListFb, FbProps } from '../../../Utils/Fb';
import { Loader } from '../../List/styles';
import useWindowDimensions from '../../../Utils/size';
import { HandleEvents } from '../../../Utils/AnalyticsGA';
import { TitleGenerator } from '../../../Utils/TitleGenerator';
import V4 from './Escolha/V4';

export interface StoreProps {
  name: string;
  address: string;
  open: boolean;
  schedules: any;
  canShipFromStore: boolean;
  canPickUpInStore: boolean;
  sellerStoreId: string;
  geolocation: {
    lat: number;
    lng: number;
  };
  allStore?: any;
}

interface Props {
  id: string;
}

const Sobre: FC = () => {
  const [tmpPath, setTmpPath] = useState('');
  const [loading, setLoading] = useState(false);
  const [listWpp, setListWpp] = useState<WppProps>({} as WppProps);
  const [listCovid, setListCovid] = useState<CovidProps>({} as CovidProps);
  const [listFb, setListFb] = useState<FbProps>({} as FbProps);
  const [store, setStore] = useState<StoreProps>({} as StoreProps);
  const [variant, setVariant] = useState<string | undefined>('NEW');
  const { id } = useParams() as Props;
  const [hours, setHours] = useState<DaysProps>({} as DaysProps);
  const [cep, setCep] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const [defaultTab, setDefaultTab] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const isMobile = useWindowDimensions();

  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const load = useCallback(async () => {
    try {
      const { data } = await api.get(
        `https://restql-api-v2-itg.b2w.io/acom/run-query/yourdev/store-details/2?sellerStoreId=${id}`
      );
      const store = data['store-v2-capacity'].result.stores[0];

      const { address, geolocation } = store;
      const { latitude, longitude } = geolocation;
      const { state, city, neighborhood } = address;

      const payload = {
        name: store.name,
        address: `${address.address} - ${neighborhood}`,
        open: store.open,
        schedules: store.schedules,
        canShipFromStore: store.canShipFromStore,
        canPickUpInStore: store.canPickUpInStore,
        sellerStoreId: store.sellerStoreId,
        geolocation: {
          lat: latitude,
          lng: longitude,
        },
        allStore: store,
      };
      setStore(payload);

      // /:uf/:cidade/:bairro
      let uf = state;
      let cidade = city;
      let bairro = neighborhood;

      TitleGenerator({
        uf: uf,
        city: cidade,
        neighborhood: bairro,
      });

      uf = uf?.trim().toLowerCase().replace(/\s/g, '-');
      cidade = cidade?.trim().toLowerCase().replace(/\s/g, '-');
      bairro = bairro?.trim().toLowerCase().replace(/\s/g, '-');

      let makeAddress = `${uf}`;

      if (cidade) {
        makeAddress = makeAddress + `/${cidade}`;
      }

      if (bairro) {
        makeAddress = makeAddress + `/${bairro}`;
      }

      setTmpPath(makeAddress);
    } catch (e) {
      console.log('erro ao buscar a loja: ', e);
    }
  }, [id]);

  const handleCloseModalHelp = useCallback(() => {
    setVisibleModal(false);

    let current = location.search;

    const remove_after = current.indexOf('&help');
    current = current.substring(0, remove_after);

    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      location.search;

    window.history.pushState({ path: newurl }, '', newurl);
  }, [location.search]);

  const handleEventsGA = useCallback(
    (type: 'atendimento' | 'duvidasfrequentes' | 'politicadetrocas') => {
      const makeType = {
        atendimento: 'atendimento',
        duvidasfrequentes: 'faq',
        politicadetrocas: 'trocas',
      };

      HandleEvents({
        isMobile,
        eventAction: 'footer',
        eventCategory: 'nossaslojas',
        eventLabel: makeType[type],
        eventValue: undefined,
        cookies,
      });
    },
    [isMobile, cookies]
  );

  const handleChangeTab = useCallback(
    (index: number) => {
      setDefaultTab(Number(index));
      setVisibleModal(true);

      let current = location.search;

      if (parsed.help) {
        const remove_after = current.indexOf('&help');
        current = current.substring(0, remove_after);
      }

      const values: any = [
        'atendimento',
        'duvidasfrequentes',
        'politicadetrocas',
      ];

      handleEventsGA(values[index]);

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        window.location.search +
        `&help=${values[index]}`;
      window.history.pushState({ path: newurl }, '', newurl);
    },
    [parsed, location.search, handleEventsGA]
  );

  const loadFileHour = useCallback(
    (file: number) => {
      const uri =
        process.env.NODE_ENV === 'development'
          ? `https://appsos.s3.amazonaws.com/storage/horariosPico/horariosPico_${file}.json`
          : `https://${document.location.host}/static/json/horariosPico/horariosPico_${file}.json`;
      api
        .get(uri)
        .then(({ data }) => {
          setHours(data[id]);
        })
        .catch((e) => console.log('erro: ', e));
    },
    [id]
  );

  useEffect(() => {
    const uri =
      process.env.NODE_ENV === 'development'
        ? `https://appsos.s3.amazonaws.com/storage/horariosPico/horariosPico_index.json`
        : `https://${document.location.host}/static/json/horariosPico/horariosPico_main.json`;

    api
      .get(uri)
      .then((data: any) => {
        const file = data.data[id];

        if (file) {
          loadFileHour(file);
        }
      })
      .catch((e) => {
        console.log('erro ao carregar horários de pico');
      });
    // if (id) {
    //   const file: HorarioPico = horariosPico[id];
    //   console.log('file: ', file);
    // }
  }, [loadFileHour, id]);

  useEffect(() => {
    load();
  }, [load]);

  const getVersion = useCallback(async () => {
    if (!store || !store.sellerStoreId) return;

    const getVariantLocal = localStorage.getItem('@b2w:version');
    if (getVariantLocal) {
      const variantParsed = JSON.parse(getVariantLocal);
      setVariant(variantParsed.version);
      setLoading(false);

      // chamando o analytics novamente mesmo com variável definida
      MyExperiment({
        salt: Math.random(),
        sellerStoreId: store.sellerStoreId,
        experimentId: 'LOJA_NOTABS',
        storeName: store.name,
        variantSetted: variantParsed.version,
        store,
        cookies,
        isMobile,
      });
    } else {
      const v = MyExperiment({
        salt: Math.random(),
        sellerStoreId: store.sellerStoreId,
        experimentId: 'LOJA_NOTABS',
        storeName: store.name,
        store,
        cookies,
        isMobile,
      });

      const payload = {
        version: v,
      };
      localStorage.setItem('@b2w:version', JSON.stringify(payload));
      setVariant(v);
      setLoading(false);
    }
  }, [store, cookies, isMobile]);

  // useEffect(() => {
  //   getVersion();
  // }, [getVersion]);

  useEffect(() => {
    if (parsed.help) {
      const values: any = {
        atendimento: 0,
        duvidasfrequentes: 1,
        politicadetrocas: 2,
      };
      setDefaultTab(Number(values[String(parsed.help)]));
      setVisibleModal(true);
    }
  }, [parsed.help]);

  useEffect(() => {
    getApiListWpp()
      .then((e) => setListWpp(e))
      .catch((e) => console.log('erro ao caregar listaWpp: ', e));
  }, []);

  useEffect(() => {
    getApiListCovid()
      .then((e) => setListCovid(e))
      .catch((e) => console.log('erro ao caregar listaCovid: ', e));
  }, []);

  useEffect(() => {
    getApiListFb()
      .then((e) => setListFb(e))
      .catch((e) => console.log('erro ao caregar listFbs: ', e));
  }, []);

  return (
    <div style={{ backgroundColor: isMobile ? '#E5E5E5' : '#f1f1f1' }}>
      <ModalHelp
        visible={visibleModal}
        closeFunction={handleCloseModalHelp}
        defaultTab={defaultTab}
        onSelect={handleChangeTab}
      />

      {/* <V3
        store={store}
        hours={hours}
        listCovid={listCovid}
        listFb={listFb}
        listWpp={listWpp}
      /> */}

      {loading ? (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader bottom={null} src={LoadingIcon} />
        </div>
      ) : variant === 'NEW' ? (
        <V4
          store={store}
          hours={hours}
          listCovid={listCovid}
          listFb={listFb}
          listWpp={listWpp}
        />
      ) : (
        <V2
          store={store}
          hours={hours}
          listCovid={listCovid}
          listFb={listFb}
          listWpp={listWpp}
          tmpPath={tmpPath}
        />
      )}
    </div>
  );
};

export default Sobre;
