import React, { FC, useState, useRef } from 'react';
import {
  Container,
  HeaderMobile,
  HeaderContentBox,
  Content,
  Footer,
  ProductCard,
  HeaderCard,
  Jumbotron,
  InfoBottom,
  Caroussel,
  RedButton,
  PriceBox,
  Pill,
} from './styles';
import { ScrollingCarousel } from '@trendyol-js/react-carousel';
import useWindowDimensions from '../../../Utils/size';
import { ReactComponent as BasketIcon } from '../../../Assets/basket2.svg';
import { ReactComponent as InfoButton } from '../../../Assets/info-cinza.svg';
import { ReactComponent as ArrowDown } from '../../../Assets/arrow_down_white.svg';
import ModalProductsDetail from '../../ModalProductsDetail';
import { ProductPopulated } from '../interfaces';

const products = [1, 2, 3, 4, 5, 6, 7, 8];

interface Props {
  title?: string;
  data: ProductPopulated[];
  linkBuy: any;
}
const CarousselProductsMobile: FC<Props> = ({ linkBuy, data, title }) => {
  const [viewModal, setViewModal] = useState(false);
  const isMobile = useWindowDimensions();
  const refModal = useRef<any>(null);
  console.log('dataMobile: ', data);
  return (
    <div style={{ position: 'relative' }}>
      <ModalProductsDetail
        ref={refModal}
        visible={viewModal}
        onClose={() => setViewModal(false)}
      />
      <Container>
        <HeaderMobile>
          <HeaderContentBox red>
            <BasketIcon />
            <h1>pedido mínimo</h1>
          </HeaderContentBox>

          <HeaderContentBox>
            <h2>R$ 10</h2>
            {/* <InfoButton onClick={() => setViewModal(true)} /> */}
          </HeaderContentBox>
        </HeaderMobile>
        <Content />
        <Footer>
          <RedButton onClick={linkBuy}>
            <span>ver todos os produtos</span>
          </RedButton>
        </Footer>
      </Container>

      <Caroussel>
        <ScrollingCarousel className="scrolling">
          {data.map((product: any, index: number) => (
            <ProductCard
              key={`${index}-${Math.random()}`}
              style={{
                // marginLeft: index === 0 ? '32px' : '16px',
                marginLeft: index === 0 ? '9%' : '4%',
                // marginRight: index === products.length - 1 ? '32px' : '0px',
                marginRight: index === products.length - 1 ? '9%' : '0px',
              }}
              onClick={linkBuy}
            >
              <HeaderCard>
                <img src={product?.images?.[0]?.medium} />
              </HeaderCard>

              <Jumbotron
                discount={!!product?.offer?.offers[0]?.discount?.rate}
                disabled={
                  !product?.offer.offers[0]?.nextProgressiveDiscount
                    ?.quantity &&
                  Number(
                    product?.offer?.offers[0]?.discount?.rate &&
                      product?.offer?.offers[0]?.discount?.rate
                  ) === 0
                }
              >
                <span>
                  {product?.offer.offers[0]?.nextProgressiveDiscount?.quantity
                    ? `leve ${product?.offer.offers[0]?.nextProgressiveDiscount?.quantity} pague menos`
                    : `${product?.offer?.offers[0]?.discount?.rate}% de desconto`}
                </span>
              </Jumbotron>
              <InfoBottom>
                <text className="truncate-overflow">{product?.name}</text>

                <PriceBox>
                  {product?.offer.offers[0].basePrice !==
                    product?.offer.offers[0].salesPrice && (
                    <div>
                      <span>R$ {product?.offer.offers[0].basePrice}</span>
                      {/* <Pill>
                        <ArrowDown />
                        <text>10%</text>
                      </Pill> */}
                    </div>
                  )}

                  {/* {![3, 5, 8].includes(index) && (
                    <div>
                      <span>R$ 3,99</span>
                      <Pill>
                        <ArrowDown />
                        <text>10%</text>
                      </Pill>
                    </div>
                  )} */}

                  <p>R$ {product?.offer.offers[0].salesPrice}</p>
                </PriceBox>
              </InfoBottom>
            </ProductCard>
          ))}
        </ScrollingCarousel>
      </Caroussel>
    </div>
  );
};

export { CarousselProductsMobile };
