/* eslint-disable react/prop-types */
import React, {
  FC,
  useImperativeHandle,
  RefForwardingComponent,
  useCallback,
  useRef,
  forwardRef,
} from 'react';
import { RiCloseLine } from 'react-icons/ri';
import {
  Container,
  Background,
  Name,
  Address,
  Barrinha,
  InfoHeader,
  Info,
  ItemList,
  ItemData,
  Title,
  Footer,
  ActionButton,
  Content,
  Card,
  CardHeader,
  NameProduct,
  CardInfo,
  Value,
  Qtd,
  Ean,
  FooterContent,
  Wrapper,
  MoreButton,
  BuyInfo,
} from './styles';
import { ReactComponent as Logo } from '../../Assets/logo_dark.svg';
import useWindowDimensions from '../../Utils/size';

interface Props {
  onClose: () => void;
  forPrint?: boolean;
  printFunction?: (type: 'salvar' | 'compartilhar') => any;
  order: any;
}

export interface ImperativeCupomFiscalProps {
  getBoxSize: () => any;
}
const CupomFiscal: RefForwardingComponent<ImperativeCupomFiscalProps, Props> = (
  { onClose, forPrint, printFunction, order },
  ref
) => {
  console.log('order cupom fiscal: ', order);
  const DivBox = useRef<HTMLDivElement>(null);
  const isMobile = useWindowDimensions();
  const getBoxSize = useCallback(() => {
    return DivBox.current?.getBoundingClientRect();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      getBoxSize,
    };
  });

  const createCupom = useCallback(() => {
    return (
      <>
        <Container ref={DivBox} forPrint={forPrint}>
          {!forPrint && (
            <RiCloseLine
              size={30}
              color="#666666"
              className="icone"
              onClick={onClose}
              style={{ margin: 16, marginLeft: 'auto' }}
            />
          )}

          <Logo width={140} height={27} />
          <Name className="truncate-overflow">
            {String(order.nomeLoja).toLocaleLowerCase()}
          </Name>
          <Address className="truncate-overflow">
            {String(order.logradouro).toLocaleLowerCase()}
          </Address>
          <Barrinha />

          <InfoHeader>
            <Info>
              <ItemList>Data:</ItemList>
              <ItemData>{order.data}</ItemData>
            </Info>

            <Info>
              <ItemList>Loja:</ItemList>
              <ItemData>{order.codigoLoja}</ItemData>
            </Info>

            <Info>
              <ItemList>PDV:</ItemList>
              <ItemData>035</ItemData>
            </Info>

            <Info>
              <ItemList>Cupom (COO)</ItemList>
              <ItemData>{order.cupom}</ItemData>
            </Info>
          </InfoHeader>

          <Title>Cupom Fiscal</Title>
          <Wrapper forPrint={forPrint}>
            <Content className="on-scrollbar">
              {[...order.itensFinal].map((i: any, index: number) => (
                <Card key={i} isPar={!!(index % 2 === 0)} forPrint={!!forPrint}>
                  <CardHeader>
                    <BuyInfo>
                      <NameProduct
                        forPrint={forPrint}
                        isMobile={isMobile}
                        className="truncate-overflow"
                      >
                        {i.nome}
                      </NameProduct>
                      <Ean>ean - {i.ean}</Ean>
                    </BuyInfo>

                    <CardInfo>
                      <Value>R$ {i.valor}</Value>
                      <Qtd>{i.quantidade} PCE x</Qtd>
                    </CardInfo>
                  </CardHeader>
                </Card>
              ))}
            </Content>
          </Wrapper>
          <FooterContent>
            <span>Total -</span>
            <p>R$ {order.valorTotal}</p>
          </FooterContent>
        </Container>
        {!forPrint && (
          <Footer>
            <ActionButton
              onClick={() => (printFunction ? printFunction('salvar') : {})}
            >
              <span>salvar</span>
            </ActionButton>
            <ActionButton
              onClick={() =>
                printFunction ? printFunction('compartilhar') : {}
              }
            >
              <span>compartilhar</span>
            </ActionButton>
          </Footer>
        )}
      </>
    );
  }, [forPrint, onClose, printFunction, isMobile, order]);

  return forPrint ? createCupom() : <Background>{createCupom()}</Background>;
};

export default forwardRef(CupomFiscal);
