import styled, { css } from 'styled-components';
import { ReactComponent as CarrousselLeft } from '../../../Assets/caroussel_left.svg';
import { ReactComponent as CarrousselRight } from '../../../Assets/caroussel_right.svg';
export const Container = styled.div`
  margin-top: 44px;
`;

export const Header = styled.div`
  border-radius: 16px 16px 0 0;
  padding: 24px;
  background-color: #fff;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface HeaderContentBoxProps {
  red?: boolean;
}

export const HeaderContentBox = styled.div<HeaderContentBoxProps>`
  display: flex;
  align-items: center;

  h1 {
    color: black;

    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }

  h2 {
    color: black;

    margin-right: 10px;

    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
  }

  svg {
    width: 15px;
    height: 15px;
  }

  ${({ red }) =>
    red &&
    css`
      svg path {
        fill: #f80032;
      }
    `}
`;

export const Content = styled.div`
  background: #f1f1f1;
  padding: 23px 15px;
  height: 263px;
`;

export const ProductCard = styled.button`
  border: 0;
  width: 150px;
  border-radius: 16px;
  /* box-shadow: 0px 2px 8px #00000019; */
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.25);

  background-color: #fff;
  margin-right: 35px;
  position: relative;
  margin-bottom: 19px;
`;

export const HeaderCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  height: 100px;

  img {
    /* width: 150px; */

    height: 80px;
    object-fit: contain;
  }
`;

interface JumbotronProps {
  discount: boolean;
  disabled: boolean;
}
export const Jumbotron = styled.div<JumbotronProps>`
  background: ${({ discount }) => (discount ? '#F2FFE3' : '#f2fbff')};
  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;

  span {
    color: ${({ discount }) => (discount ? '#008000' : '#0071e0')};

    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 13px;
    line-height: 15px;
    font-weight: bold;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #fff;
      span {
        color: #fff;
      }
    `}
`;

export const InfoBottom = styled.div`
  padding: 8px;
  height: 82px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .truncate-overflow {
    max-width: 135px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  text {
    font-family: 'Helvetica Neue, Bold';

    font: -webkit-control;
    color: black;
    font-size: 12px;
    font-weight: bold;
    line-height: 14.06px;
  }

  p {
    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    color: black;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
  }
`;

export const Caroussel = styled.div`
  margin-top: -400px;
  width: 100%;
  position: absolute;

  button {
    display: none;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 24px;
  border-radius: 0 0 16px 16px;
`;

export const RedButton = styled.div`
  cursor: pointer;
  width: 180px;
  height: 44px;
  background-color: #f80032;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;

    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
  }
`;

interface FooterContentBoxProps {
  red?: boolean;
}

export const FooterContentBox = styled.div<FooterContentBoxProps>`
  display: flex;
  align-items: center;
  position: relative;

  h1 {
    margin-left: 10px;
    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    color: #404040;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }

  h2 {
    color: #404040;

    margin-right: 13px;
    margin-left: 7px;

    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }

  svg:nth-child(1) {
    width: 23px;
    height: 23px;
  }
`;

export const LeftArrow = styled(CarrousselLeft)`
  width: 56px;
  height: 56px;
  cursor: pointer;
  position: absolute;
  z-index: 91;
  margin-left: -40px;
  margin-top: 80px;
  :hover {
    filter: brightness(0.9);
  }
`;

export const RightArrow = styled(CarrousselRight)<{ windowWidth: number }>`
  width: 56px;
  height: 56px;
  z-index: 91;
  cursor: pointer;
  position: absolute;
  margin-left: -14px;
  /* right: 284px;
  right: calc(100vw / 2); */
  margin-top: 80px;
  :hover {
    filter: brightness(0.9);
  }
`;

export const PriceBox = styled.div`
  > div {
    display: flex;
    align-items: center;

    span {
      text-decoration: line-through;
      color: #666666;

      margin-right: 5px;

      font-family: 'Helvetica Neue, Regular';
      font: -webkit-control;
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const Pill = styled.div`
  padding: 2px 4px;
  background-color: #008000;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 14px;

    height: 11px;
  }

  text {
    color: #fff;

    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-size: 10px;
    line-height: 12px;
  }
`;

export const FakeButtonDudeIcon = styled.div`
  border: 0;
  background: transparent;

  z-index: 999;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: center;

  svg {
    width: 17px !important;
    height: 17px !important;
    cursor: pointer;
  }
`;

interface TooltipLocalProps {
  opacity?: boolean;
  mLeft: string;
  isMobile?: boolean;
}

export const TooltipLocal = styled.span<TooltipLocalProps>`
  background: white;
  padding: 20px 28px;
  opacity: ${({ opacity }) => (opacity ? 1 : 0)};
  cursor: pointer !important;

  /* opacity: 1; */

  transition: 0.2s;

  position: absolute;
  z-index: 9999999999;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 8px #00000027;

  width: 226px;
  height: 87px;

  /* top: calc(100vh - 61%);
  left: calc(100vw - 55%); */
  top: 38px;
  left: 107px;

  display: ${({ opacity }) => (opacity ? 'flex' : 'none')};
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100% !important;
    `}

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: ${({ mLeft }) => mLeft};

    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid white;
    margin-top: -31px;

    position: absolute;
  }

  h1 {
    margin: 0;
    font-size: 18px;
    color: #333333;
    line-height: 22px;
    font-family: Helvetica Neue, Regular;
    /* margin-bottom: 16px; */
  }

  h2 {
    margin: 0;
    font-size: 14px;
    color: #666666;
    font-family: Helvetica Neue, Regular;
    line-height: 16px;
  }

  @media only screen and (max-width: 414px) {
    width: 386px;
  }

  @media only screen and (max-width: 375px) {
    width: 351px;
  }

  @media only screen and (max-width: 360px) {
    width: 339px;
    height: 243px;
    left: -3px;

    h1 {
      font-size: 17;
    }

    h2 {
      font-size: 13px;
    }
  }

  @media only screen and (max-width: 320px) {
    width: 301px;
    height: 243px;
    left: -3px;

    h1 {
      font-size: 17;
    }

    span {
      font-size: 13px;
    }
  }
`;
