import styled, { css } from 'styled-components';
import { isIOS } from 'react-device-detect';

interface ContainerProps {
  isMobile: boolean;
  isMap?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  justify-content: center;
  background: #eaeaea;
  min-height: 150px;

  width: 100%;
  /* position: absolute;
  bottom: 0; */

  ${({ isMap, isMobile }) =>
    isMap &&
    isMobile &&
    css`
      top: calc(100vh - 44px);
    `}

  ${isIOS &&
  css`
    top: calc(100vh - 44px - 44px);
  `}


  ${({ isMap }) =>
    !isMap &&
    css`
      top: auto;
    `}

  .link-help {
    cursor: pointer;
  }
`;

interface SeparatorProps {
  isMobile: boolean;
}

export const Separator = styled.div<SeparatorProps>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  justify-content: space-between;
  align-items: ${(props) => (props.isMobile ? 'center' : null)};
`;

interface ContentProps {
  isMobile: boolean;
}

export const Content = styled.div<ContentProps>`
  width: ${(props) => (props.isMobile ? '100%' : '1280px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
`;

interface LogoProps {
  w: string;
  h: string;
  Mr?: string;
  Mt?: string;
}
export const Logo = styled.img<LogoProps>`
  width: ${(props) => props.w};
  height: ${(props) => props.h};
  margin-top: ${(props) => props.Mt};
`;

interface MenusProps {
  isMobile?: boolean;
}

export const Menus = styled.ul<MenusProps>`
  list-style: none;
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};

  .menus-textos {
    width: 100%;
    display: flex;
    flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
    margin-bottom: ${(props) => (props.isMobile ? '21px' : '0')};
    justify-content: ${(props) => (props.isMobile ? 'center' : 'flex-end')};

    .no-help {
      display: flex;
      flex-direction: row;
      margin-top: ${(props) => (props.isMobile ? '15px' : '0')};
    }

    div {
      display: flex;
      justify-content: flex-end;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface MenuItemProps {
  isMobile: boolean;
}

export const MenuItem = styled.li<MenuItemProps>`
  color: #707070;
  font-family: Helvetica Neue;
  font-size: 12px;
  margin: ${(props) => (props.isMobile ? '0 7.5px' : '0 10px')};

  p {
    text-decoration: underline;
    font-size: ${(props) => (props.isMobile ? '12px' : '12px')};
  }
`;

interface FooterAddressProps {
  isMobile?: boolean;
}

export const FooterAddress = styled.span<FooterAddressProps>`
  font-family: Helvetica Neue;
  color: #707070;
  font-size: 12px;
  margin-top: ${(props) => (props.isMobile ? '30px' : '58px')};
  margin-bottom: ${(props) => (props.isMobile ? '30px' : '21px')};
  text-align: center;

  margin-left: ${(props) => (props.isMobile ? '15px' : '0px')};
  margin-right: ${(props) => (props.isMobile ? '15px' : '0px')};

  a {
    color: #707070;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    color: rgb(230, 0, 20);
  }
`;

interface LogoBoxProps {
  isMobile?: boolean;
}

export const LogoBox = styled.div<LogoBoxProps>`
  width: 50%;
  display: flex;
  align-items: center;

  img {
    margin-right: 15px;
  }

  span {
    color: #707070;
    font-family: Helvetica Neue, Regular;
    font-size: 13px;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      flex-direction: column;
      margin-top: 22px;
      width: 100%;

      span {
        margin-top: 10px;
      }
    `}
`;
