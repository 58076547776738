/* eslint-disable no-unsafe-optional-chaining */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {
  FC,
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
  ButtonHTMLAttributes,
} from 'react';
import handleClickCardStore from '../../Utils/ClickCardStore';
import { isChrome, isIOS } from 'react-device-detect';
import useDynamicRefs from 'use-dynamic-refs';
import { FiSend } from 'react-icons/fi';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import '@brainhubeu/react-carousel/lib/style.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import queryString from 'query-string';
import api from '../../Services/api';
import { useLocation, useHistory, Redirect, useParams } from 'react-router-dom';
import ModalHelp from '../../Components/ModalHelp';
import Header from '../../Components/Header';
import BuscaBox from '../../Components/BuscaBox';
import Footer from '../../Components/Footer';
import CardStore from '../../Components/CardStore';
import Lojas from '../../Assets/lojas2.json';
import GoogleMaps, { HandleMapsFunctions } from '../../Components/GoogleMaps';
import useWindowDimensions from '../../Utils/size';
import Paginate from '../../Components/Paginate';
import Empty from '../../Assets/empty.png';
import ModalAddress from '../../Components/ModalAddress';
import LoadingIcon from '../../Assets/loading.svg';
import GoogleMapReact from 'google-map-react';
import ViewStoreButton from '../../Components/ViewStoreButton';
import { useCookies } from 'react-cookie';
import RouteIcon from '../../Assets/route_icon.png';
import {
  Container,
  Stores,
  Title,
  Buttons,
  Button,
  MapContainer,
  Left,
  Content,
  AddressMobile,
  ContentMapMobile,
  CarouselContent,
  Placeholder,
  BoxEmptyMobile,
  Box,
  MoreButton,
  Loader,
  BoxPlaceholder,
  ListaBox,
  Cards,
  BoxHeader,
  Filter,
  Warning,
  ItemList,
  ButtonsBox,
  LineButtons,
  WrapperStoresMobile,
  BarrinhaStoresMobile,
  TooltipLocal,
  FakeButtonDudeIcon,
  FakeBoxShadow,
  StoresNear,
  ToggleButtonView,
  WhatsSolidButton,
  TooltipWhatsappMobile,
  RouteButton,
} from './styles';
import ReactOwlCarousel, { OwlCarouselProps } from 'react-owl-carousel';
import OwlCarousel from 'react-owl-carousel';
import { getApiListWpp, WppProps } from '../../Utils/ListWpp';
import { ReactComponent as BurgerIcon } from '../../Assets/burguer.svg';
import { ReactComponent as MapIcon } from '../../Assets/map.svg';
import { ReactComponent as LocalIcon } from '../../Assets/local_cinza.svg';
import { ReactComponent as IconWarning } from '../../Assets/info.svg';
import { ReactComponent as WhatsImg } from '../../Assets/WHATS_ICONE.svg';
import ListLocal, { LocalProps, getApiListLocal } from '../../Utils/Local';
import PlaceholderStores from '../../Components/PlaceholderStores';
import Busca from '../../Components/Busca';
import ModalAddressWeb from '../../Components/ModalAddressWeb';
import HandleAnalyticsEvents from '../../Utils/HandleAnalyticsEvents';
import ListCovid, { getApiListCovid, CovidProps } from '../../Utils/Covid';
import Geo from '../../Utils/Geocode';
import { PassThrough } from 'stream';
import { HandleEvents, HandlePageView } from '../../Utils/AnalyticsGA';
import {
  TitleGenerator,
  TitleGeneratorProps,
} from '../../Utils/TitleGenerator';
import { Breadcrumb, BreadCrumbItemProps } from '../../Components/Breadcrumb';
import { NormalizeBreadCrumb } from '../../Utils/NormalizeBreadCrumb';
import Config from '../../Utils/Config';
import { ReactComponent as DudeIcon } from '../../Assets/dude-icon.svg';
import ModalTooltip from '../../Components/ModalTooltip';
import Tooltip from '../../Components/Tooltip';
import { AsyncLocalStorage } from 'async_hooks';
import { useTheme } from 'styled-components';
import { TestAB } from '../../Utils/PlanOut';
import CardStoreV2 from '../../Components/CardStoreV2';
import Legends from '../../Components/Legends';
import WhatsTooltip from '../../Components/WhatsTooltip';
import { useCaroussel } from '../../Hooks/useCaroussel';
import { CarouselRef } from 'antd/lib/carousel';
import PinWhite from '../../Assets/ping-white.png';
import { BuscaBoxV2 } from '../../Components/BuscaBoxV2';
import { WebContent } from './WebContent';
import { MobileContent } from './MobileContent';

interface handleLoadLocalsProps {
  pag: number;
  loadFromMap?: boolean;
}
export interface LoadAllStoresProps {
  lat: string;
  lng: string;
  pag: number;
  local?: boolean;
  forceAllStores?: boolean;
  name?: string;
}

export interface CoordsInterface {
  lat: number;
  lng: number;
}

export interface loadProps {
  append?: boolean;
  pag: number;
  lat?: number;
  lng?: number;
  name?: string;
  newLocation?: boolean;
  radius?: number;
  selected?: string;
  feather?: string;
  footer?: string;
  chave?: string;
  epar?: string;
  local?: boolean;
  withoutLocal?: boolean;
  lastData?: any[];
  newPag?: boolean;
  clickedPag?: number;
  pagAppendLocal?: number;
  accLocal?: number;
  list?: any[];
  total?: number;
  totalEl?: number;
  ufParam?: string;
  cidadeParam?: string;
  bairroParam?: string;
  removeParamsUri?: boolean;
  loadFromMap?: boolean;
  near?: string;
  pinClicked?: boolean;
}

export interface LoadFunctionProps {
  append?: boolean; // usado na versao mobile para juntar os dados e n substituir
  pag: number; // número da pág que estamos buscando
  lat: number;
  lng: number;
  newLocation?: boolean; // ????
  radius?: number; // padrao 1.91
  name?: string; // usado para definir nome da localizacao
  selected?: boolean; // usado quando um user clica no pin na home
  feather?: string; // usado parar definir se teremos cabecalho
  footer?: string; // usado parar definir se teremos footer
  chave?: string; // usado para CEO;
  epar?: string; // usado para CEO;
  local?: boolean; // definir se a busca será por lojas locais;
  all?: boolean; // definir se a busca será por lojas normais;
  ufParam?: string; // usado para CEO;
  cidadeParam?: string; // usado para CEO;;
  bairroParam?: string; // usado para CEO;;
  removeParamsUri?: boolean; // usado para CEO;;
  loadFromMap?: boolean; // definicoes de busca para o mapa
  near?: boolean; // definicoes de busca para o mapa
  clickedPag?: number; // para acoes da paginacao
  appendItemsFromMap?: boolean; // param que trava o loader no carregamento infinito no mapa
  byPin?: boolean; // validacao do pin no mapa
  limit?: number;
  // clickedPag;
  // pagAppendLocal;
  // accLocal;
  // list;
  // total;
  // totalEl;
}

const keyUnusedLastPage = '@nossaslojas:unused-last-page';
const keyLocalStoresList = '@nossaslojas:local-list';
const keyAllStoresList = '@nossaslojas:all-list';
const keyTotalStores = '@nossaslojas:total-stores';
const keyCurrentStoreList = '@nossaslojas:current-list';
const keyTotalPagStores = '@nossaslojas:total-pages-store';
const keyAppendPagCurrent = '@nossaslojas:pag-append';
const keyUsedStores = '@nossaslojas:user-stores';
const keyAppendPagCurrentForMap = '@nossaslojas:pag-append-map';

const firstCallControl = false;

const List: FC = () => {
  const [currentLegions, setCurrentLegions] = useState([]);
  const [variantCardStore, setVariantCardStore] = useState<any>('OLD');
  const [tooltipLocalView, setTooltipLocalView] = useState(false);
  const [tooltipWhatsView, setTooltipWhatsView] = useState(false);
  const [lastBreadcrumbs, setlastBreadcrumbs] = useState<BreadCrumbItemProps>(
    {} as BreadCrumbItemProps
  );
  const [existsCovid, setExistsCovid] = useState(false);
  const [existsLocal, setExistsLocal] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const [modalAddressView, setModalAddressView] = useState(false);
  const [acc, setAcc] = useState(0);
  const [comparativeList, setComparativeList] = useState<any[]>([]);
  const [pagAppend, setPagAppend] = useState(1);
  const [isLocal, setIsLocal] = useState(false);
  const [filter, setFilter] = useState<'all' | 'normal' | 'local'>('all');
  const [listCovid, setListCovid] = useState<CovidProps>({} as CovidProps);
  const [listLocal, setListLocal] = useState<LocalProps>({} as LocalProps);
  const [lastDiff, setLastDiff] = useState(0);
  const [listFilterLocal, setListFilterLocal] = useState<any[]>([]);
  const [listWpp, setListWpp] = useState<WppProps>({} as WppProps);
  const [wppStore, setWppStore] = useState(false);
  const isMobile = useWindowDimensions();
  const [pinsList, setPinsList] = useState<any>([]);
  const [limitPage, setLimitPage] = useState();
  const [visibleChangeModal, setVisibleChangeModal] = useState(false);
  const [visibleButtonMap, setVisibleButtonMap] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [totalElements, setTotalElements] = useState(0);
  const [defaultTab, setDefaultTab] = useState(0);
  const [activeButton, setActiveButton] = useState<'mapa' | 'lista'>('lista');
  const [totalPag, setTotalPag] = useState(1);
  const [coords, setCoords] = useState({ lat: 0, lng: 0 });
  const [visibleModal, setVisibleModal] = useState(false);
  const [storeSelected, setStoreSelected] = useState(null);
  const [allDadosLocal, setAllDadosLocal] = useState<any[]>([]);
  const [dadosLocal, setDadosLocal] = useState<any[]>([]);
  const [dadosWithoutLocal, setDadosWhitoutLocal] = useState<any[]>([]);
  const [dados, setDados] = useState<any>([]);
  const [loadingFirst, setLoadingFirst] = useState(true);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [controlUpdate, setControlUpdate] = useState(true);
  const history = useHistory();
  const [currentPag, setCurrentPag] = useState(() => {
    return Number(parsed.pag);
  });
  const [coordsCenter, setCoordsCenter] = useState<CoordsInterface>({
    lat: Number(parsed.lat),
    lng: Number(parsed.lng),
  });
  const [coordsTmpCenter, setCoordsTmpCenter] = useState<CoordsInterface>({
    lat: Number(parsed.lat),
    lng: Number(parsed.lng),
  });
  const [selectedStore, setSelectedStore] = useState(parsed.store);
  const [startPositionCarousel, setStartPositionCarousel] = useState(0);
  const [showNavigationOptions, setShowNavigationOptions] = useState(false);
  const { uf, cidade, bairro } = useParams();
  const MapRef = useRef<HandleMapsFunctions>(null);
  const InputRef = useRef<HTMLInputElement>(null);

  const { changeStoreSelected } = useCaroussel();
  const DudeIconRef = useRef<any>(null);
  const { colors } = useTheme();

  const sizeMap = useMemo(() => {
    const { innerHeight: height } = window;

    const percent = height * 0.6;
    const calc = `${height - percent}px`;

    return calc;
  }, []);

  const dudePosition = useCallback(() => {
    const dudeP = DudeIconRef.current?.getBoundingClientRect().x;

    const vw = window.innerWidth;

    const calcDude = vw - dudeP + -13;

    return `${calcDude}px`;
  }, [DudeIconRef]);

  const TypeList = useMemo(() => {
    const isMap = activeButton === 'mapa';
    return isMap;
  }, [activeButton]);

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'development') {
  //     getApiListWpp()
  //       .then((e) => setListWpp(e))
  //       .catch((e) => console.log('erro ao carregar listaWpp: ', e));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'development') {
  //     getApiListLocal()
  //       .then((e) => setListLocal(e))
  //       .catch((e) => console.log('erro ao carregar listaLocal: ', e));
  //   }
  // }, []);

  // useEffect(() => {
  //   if (process.env.NODE_ENV !== 'development') {
  //     getApiListCovid()
  //       .then((e) => setListCovid(e))
  //       .catch((e) => console.log('erro ao carregar listCovid: ', e));
  //   }
  // }, []);

  const loadElements = useCallback(async () => {
    if (process.env.NODE_ENV !== 'development') {
      const [lWpp, lLocal, lCovid] = await Promise.all([
        getApiListWpp(),
        getApiListLocal(),
        getApiListCovid(),
      ]);

      setListWpp(lWpp);
      setListLocal(lLocal);
      setListCovid(lCovid);

      // console.log('todas as listas carregadas');
    }
  }, []);

  useEffect(() => {
    loadElements();
  }, []);

  const normalizeListPinsOnMap = useCallback(
    async ({ localList, allList, local }) => {
      local ? setPinsList(localList) : setPinsList(allList);
      // if (local) {
      //   setPinsList((prev: any) => {
      //     // return [...prev, ...localList];
      //     return localList;
      //   });
      // }

      // if (filter === 'all') {
      //   setPinsList((prev: any) => {
      //     // return [...prev, ...allList];
      //     return allList;
      //   });
      // }
    },
    [filter]
  );

  const loadPins = useCallback(
    async ({ lat, lng, totalElements, local, loadFromMap, near }) => {
      try {
        const calcPage = Math.ceil(totalElements / 30);

        const localList: any = [];
        const allList: any = [];

        for (let i = 1; i <= calcPage; i++) {
          // console.log('CHAMANDO A PAGINA: ', i);
          const limit = totalElements <= 30 ? totalElements : 30;

          const latParam = lat || parsed.lat;
          const lngParam = lng || parsed.lng;

          const newUri = `${Config.loadPinsResume}?lat=${latParam}&lng=${lngParam}&page=${i}&limit=${limit}`;

          const { data } = await api.get(newUri);

          const { content } = data[Config.loadPinsResumeKey].result;

          const fakeLocal: any = [];
          await Promise.all(
            content.map(async (i: any) => {
              const isLocal = await ListLocal({
                wppFromWeb: listLocal,
                sellerStoreId: i.sellerStoreId,
              });

              if (String(i.sellerStoreId) !== '98') {
                allList.push(i);
              }

              if (isLocal) {
                localList.push(i);
                fakeLocal.push(i);
              }
            })
          );

          // if (pinClicked) return;

          if (
            i === calcPage &&
            ((loadFromMap && loadFromMap !== 'undefined') ||
              (near && near !== 'undefined'))
          ) {
            setLoading(false);
          }
        }

        setListFilterLocal(localList);

        const totalPag = Math.ceil(
          (local ? localList.length : allList.length) / 12
        );
        const totalElementsAfterFilter = local
          ? localList.length
          : allList.length;

        setTotalElements(totalElementsAfterFilter);
        setTotalPag(totalPag);

        await normalizeListPinsOnMap({ localList, allList, local });

        localStorage.setItem(keyLocalStoresList, JSON.stringify(localList));
        localStorage.setItem(keyAllStoresList, JSON.stringify(allList));
        localStorage.setItem(keyTotalStores, JSON.stringify(allList.length));
        // localStorage.setItem(keyCurrentStoreList, JSON.stringify(listCalled));
        localStorage.setItem(keyTotalPagStores, JSON.stringify(totalPag));

        setExistsLocal(!!localList.length);
      } catch (e) {
        console.log('erro no load pins: ', e);
      }
    },
    [parsed, listLocal, normalizeListPinsOnMap]
  );

  const handleAnalytics = useCallback(() => {
    HandlePageView({
      cookies,
      query: parsed,
      name: 'ACOM:NossasLojas:lista',
      isMobile,
    });
  }, [parsed, cookies, isMobile]);

  const makeLastBreadcrumb = useCallback(
    ({ uf, city, neighborhood, link }: TitleGeneratorProps) => {
      console.log('makeLastBreadcrumb uf: ', uf);
      console.log('makeLastBreadcrumb city: ', city);
      console.log('makeLastBreadcrumb neighborhood: ', neighborhood);
      console.log('makeLastBreadcrumb link: ', link);
      // setlastBreadcrumbs({ label: String(parsed.name), link: link || '' });
      if (neighborhood) {
        // console.log('makeLastBreadcrumb neighborhood');
        setlastBreadcrumbs({
          label:
            NormalizeBreadCrumb({ name: neighborhood, replace: true }) || '',
          link: link || '',
        });
        return;
      }

      if (city) {
        // console.log('makeLastBreadcrumb city');
        setlastBreadcrumbs({
          label: NormalizeBreadCrumb({ name: city, replace: true }) || '',
          link: link || '',
        });
        return;
      }

      if (uf) {
        // console.log('makeLastBreadcrumb uf');
        setlastBreadcrumbs({
          label: NormalizeBreadCrumb({ name: uf, replace: true }) || '',
          link: link || '',
        });
        return;
      }
    },
    []
  );

  const makeSEO = useCallback(
    ({
      lat,
      lng,
      pag,
      feather,
      footer,
      local,
      onlyLocal,
      loadFromMap,
      uf,
      cidade,
      bairro,
      removeParamsUri,
      ufParam,
      cidadeParam,
      bairroParam,
      chave,
      epar,
      name,
      dataFromApi,
      near,
    }: any) => {
      console.log('entrou no makeseo');
      let uri = `?`;

      if (chave && chave !== 'undefined') {
        uri = uri + `chave=${chave}`;
      }

      if (epar && epar !== 'undefined') {
        uri = uri + `&epar=${epar}`;
      }

      if (lng && lng !== 'undefined') {
        uri = uri + `&lng=${lng}`;
      }

      if (lat && lat !== 'undefined') {
        uri = uri + `&lat=${lat}`;
      }

      if (pag && pag !== 'undefined') {
        uri = uri + `&pag=${pag}`;
      }

      if (feather && feather !== 'undefined') {
        uri = uri + `&feather=${feather}`;
      }

      if (footer && footer !== 'undefined') {
        uri = uri + `&footer=${footer}`;
      }

      if (local && local !== 'undefined') {
        uri = uri + `&local=${local}`;
      }

      if (onlyLocal && onlyLocal !== 'undefined') {
        uri = uri + `&onlyLocal=${onlyLocal}`;
      }

      if (loadFromMap) {
        if (!dataFromApi.length) return;
        const { address } = dataFromApi[0];

        TitleGenerator({
          uf: address.state,
          city: address.city,
          neighborhood: address.neighborhood,
        });

        let makeAddress = `${address.state
          .trim()
          .toLowerCase()
          .replace(/\s/g, '-')}`;
        let makeName = `${address.state}`;
        // console.log('cidadeParam || cidade: ', cidadeParam || cidade);
        if (address.city) {
          makeAddress =
            makeAddress +
            `/${address.city.trim().toLowerCase().replace(/\s/g, '-')}`;
          makeName = makeName + ` - ${address.city}`;
        }
        // console.log('bairroParam || bairro: ', bairroParam || bairro);
        if (address.neighborhood) {
          makeAddress =
            makeAddress +
            `/${address.neighborhood.trim().toLowerCase().replace(/\s/g, '-')}`;
          makeName = makeName + ` - ${address.neighborhood}`;
        }

        // TitleGenerator({
        //   uf: address.state,
        //   city: address.city,
        //   neighborhood: address.neighborhood,
        // });

        makeLastBreadcrumb({
          uf: address.state,
          city: address.city,
          neighborhood: address.neighborhood,
          link: `/${makeAddress}${uri}`,
        });

        uri = uri + `&name=${makeName}`;
        history.push({
          pathname: `/${makeAddress}`,
          search: uri,
        });
      } else {
        if (near && near !== 'undefined') {
          uri = uri + `&near=${near}`;

          TitleGenerator({
            uf: String(near),
          });
        }

        let makeAddress = `${ufParam || uf}`;

        if (cidadeParam || (cidade && !removeParamsUri)) {
          makeAddress = makeAddress + `/${cidadeParam || cidade}`;
        }

        if (bairroParam || (bairro && !removeParamsUri)) {
          makeAddress = makeAddress + `/${bairroParam || bairro}`;
        }

        uri = uri + `&name=${name}`;

        makeLastBreadcrumb({
          uf: ufParam || uf,
          city: cidadeParam || cidade,
          neighborhood: bairroParam || bairro,
          link: `/${makeAddress}${uri}`,
        });
        // console.log('makeAddress:', makeAddress);
        // console.log('makeAddress uri:', uri);

        history.push({
          pathname: `/${makeAddress}`,
          search: uri,
        });
      }
    },
    [history, makeLastBreadcrumb]
  );

  const filteringData2 = useCallback(
    async ({ dataServer, currentList, covidList }) => {
      const filtering: any[] = [];

      await Promise.all(
        dataServer.map(async (i: any) => {
          const exists = currentList.find(
            (a: any) => a.sellerStoreId === i.sellerStoreId
          );

          if (exists) {
            filtering.push(i);
          }

          const isCovid = await ListCovid({
            wppFromWeb: listCovid,
            sellerStoreId: i.sellerStoreId,
          });

          if (isCovid) {
            covidList.push(i);
          }
        })
      );

      setExistsCovid(!!covidList.length);

      return filtering;
    },
    [listCovid]
  );

  const normalizeDataForMap = useCallback(
    async ({ lat, lng }) => {
      if (MapRef.current) {
        MapRef.current?.changeCenterMap({
          lat: lat || Number(parsed.lat),
          lng: lng || Number(parsed.lng),
        });
      }

      setCoords({
        lat: lat || Number(parsed.lat),
        lng: lng || Number(parsed.lng),
      });

      setCoordsCenter({
        lat: lat || Number(parsed.lat),
        lng: lng || Number(parsed.lng),
      });

      setControlUpdate(true);

      setVisibleButtonMap(false);
    },
    [parsed.lat, parsed.lng]
  );

  const sortData = useCallback(async ({ dataServer, selected }) => {
    let sortingData = dataServer;

    sortingData.forEach((i: any) => {
      i.Latitude = i.geolocation.latitude;
      i.Longitude = i.geolocation.longitude;
    });

    if (selected) {
      sortingData = sortingData.sort((a: any, b: any) => {
        if (a.sellerStoreId === selected) {
          return -1;
        } else {
          return 1;
        }
      });
    }

    return sortingData;
  }, []);

  const validateCovidStoreInList = useCallback(
    async ({ dataServer }) => {
      const covidList: any = [];
      console.log('listCovid validateCovidStoreInList: ', listCovid);
      await Promise.all(
        dataServer.map(async (i: any) => {
          const isCovid = await ListCovid({
            wppFromWeb: listCovid,
            sellerStoreId: i.sellerStoreId,
          });

          if (isCovid) {
            covidList.push(i);
          }
        })
      );

      console.log('tem covid: ', !!covidList.length);

      setExistsCovid(!!covidList.length);
    },
    [listCovid]
  );

  const getListFromStorage = useCallback(async (type: 'all' | 'local') => {
    const key = type === 'all' ? keyAllStoresList : keyLocalStoresList;

    const list = localStorage.getItem(key);

    return list ? JSON.parse(list) : [];
  }, []);

  const getCurrentStorageList = useCallback(async () => {
    const list: any = localStorage.getItem(keyCurrentStoreList);

    return list ? JSON.parse(list) : [];
  }, []);

  const getUsedStoresList = useCallback(async () => {
    const list: any = localStorage.getItem(keyUsedStores);

    return list ? JSON.parse(list) : [];
  }, []);

  const getunUsedLastPageStoresList = useCallback(async () => {
    const list: any = localStorage.getItem(keyUnusedLastPage);

    return list ? JSON.parse(list) : [];
  }, []);

  const filteringData = useCallback(
    async ({ list, dataServer, pag, loadFromMap, limit }) => {
      const maximumDataPerRequest = loadFromMap ? 50 : limit || 12;

      const currentStorageList = await getCurrentStorageList();
      let usedStoresList = await getUsedStoresList();

      console.log('fiteringData usedStoresList: ', usedStoresList);
      console.log('filteringData dataServer: ', dataServer);

      if (pag === 1) {
        usedStoresList = [];
      }
      const unUsedLastPageStoresList = await getunUsedLastPageStoresList();

      const data: any[] = [...currentStorageList, ...unUsedLastPageStoresList];

      const unUsedLastPage: any[] = [];

      const diff = maximumDataPerRequest - data.length;
      console.log('filteringData diff: ', diff);
      const dataWithoutAlreadyUsedStores = dataServer.filter((i: any) => {
        const exists = usedStoresList.find(
          (a: any) => a.sellerStoreId === i.sellerStoreId
        );

        return !exists;
      });

      console.log(
        'filteringData dataWithoutAlreadyUsedStores: ',
        dataWithoutAlreadyUsedStores
      );

      const fakeLocal: any = [];

      unUsedLastPageStoresList.forEach((i: any) => {
        data.push(i);
      });

      dataWithoutAlreadyUsedStores.forEach((i: any, index: number) => {
        const exists = list.find(
          (a: any) => a.sellerStoreId === i.sellerStoreId
        );

        if (exists && (pag === 1 ? index + 1 > 0 : index + 1 <= diff)) {
          if (data.length < maximumDataPerRequest) {
            fakeLocal.push(i);
            data.push(i);
          } else {
            unUsedLastPage.push(i);
          }
        }
      });

      console.log('filteringData fakeLocal: ', fakeLocal);
      console.log('filteringData data: ', data);

      // localStorage.setItem(
      //   keyCurrentStoreList,
      //   JSON.stringify([...currentStorageList, ...data])
      // );

      localStorage.setItem(keyUnusedLastPage, JSON.stringify(unUsedLastPage));
      console.log('data filtro antes: ', data);
      const finalData: any = data.filter(
        (element: any, index: number) => data.indexOf(element) === index
      );

      localStorage.setItem(
        keyUsedStores,
        JSON.stringify([...usedStoresList, ...finalData])
      );

      return finalData;
    },
    [getCurrentStorageList, getUsedStoresList, getunUsedLastPageStoresList]
  );

  const getAppendPage = useCallback(async ({ appendItemsFromMap }) => {
    let page = localStorage.getItem(keyAppendPagCurrentForMap);
    console.log('appendPage dentro: ', page);
    return page ? (page = JSON.parse(page)) : false;
  }, []);

  const normalizeFilter = useCallback(
    async ({ local }) => {
      if (parsed.local && parsed.local !== 'undefined' && local) {
        setIsLocal(true);
        setFilter('local');
        return;
      }
    },
    [parsed.local]
  );

  const setFinalData = useCallback(
    async ({ filteredData, local, pag, totalElements }) => {
      if (!filteredData.length) {
        // console.log('não tem dados');

        HandleEvents({
          isMobile,
          eventAction: 'busca lojas proximas',
          eventCategory: 'nossaslojas',
          eventLabel: 'sem resultado',
          eventValue: undefined,
          cookies,
        });

        setHasMore(false);
      }

      console.log('setFinalData filteredData: ', filteredData);
      console.log('setFinalData local: ', local);
      console.log('setFinalData pag: ', pag);
      console.log('setFinalData totalElements: ', totalElements);

      if (pag === 1 && filteredData.length) {
        HandleEvents({
          isMobile,
          eventAction: 'busca lojas proximas',
          eventCategory: 'nossaslojas',
          eventLabel: 'com resultado',
          eventValue: totalElements,
          cookies,
        });
      }

      if (local) {
        console.log('setFinalData entrou no local');
        if (isMobile) {
          setDadosLocal((prev: any) => [...prev, ...filteredData]);
          return;
        }

        if (activeButton === 'mapa') {
          setDadosLocal((prev: any) => [...prev, ...filteredData]);
          return;
        } else {
          setDadosLocal(filteredData);
          return;
        }
      } else {
        console.log('setFinalData nao é local');
        if (isMobile) {
          console.log('setFinalData entrou no isMobile');
          setDados((prev: any) => [...prev, ...filteredData]);
          return;
        }

        if (activeButton === 'mapa') {
          console.log('setFinalData aquii');
          setDados((prev: any) => [...prev, ...filteredData]);
          return;
        } else {
          console.log('setFinalData aquii2');
          setDados(filteredData);
          return;
        }
      }
    },
    [isMobile, activeButton, cookies]
  );

  const manyLocalsOnThisRequest = useCallback(
    async ({ dataServer }: any) => {
      const localList: any = [];
      console.log('listLocal total: ', listLocal);
      await Promise.all(
        dataServer.map(async (i: any) => {
          const isLocal = await ListLocal({
            wppFromWeb: listLocal,
            sellerStoreId: i.sellerStoreId,
          });

          if (isLocal) {
            localList.push(i);
          }
        })
      );

      return { totalLocal: localList.length, stores: localList };
    },
    [listLocal]
  );

  const getPins = useCallback(
    async ({ lat, lng, totalElements, local }) => {
      try {
        const calcPage = Math.ceil(totalElements / 100);
        console.log('calcPage: ', calcPage);
        const localList: any = [];
        const allList: any = [];

        for (let i = 1; i <= calcPage; i++) {
          const newUri = `${
            Config.loadPinsResume
          }?lat=${lat}&lng=${lng}&page=${i}&limit=${100}`;

          const { data } = await api.get(newUri);

          const { content } = data[Config.loadPinsResumeKey].result;
          console.log('content pins: ', content);

          const fakeLocal: any = [];
          await Promise.all(
            content.map(async (i: any) => {
              const isLocal = await ListLocal({
                wppFromWeb: listLocal,
                sellerStoreId: i.sellerStoreId,
              });

              if (String(i.sellerStoreId) !== '98') {
                allList.push(i);
              }

              if (isLocal) {
                localList.push(i);
                fakeLocal.push(i);
              }
            })
          );
        }

        if (local) {
          // monta a paginacao no caso de lojas locais
          setTotalPag(localList.length / 12);
          setTotalElements(localList.length);
          setPinsList(localList);
        } else {
          setPinsList(allList);
        }

        console.log('localList: ', localList);
        setExistsLocal(!!localList.length);

        // setPinsList(content);
      } catch (e) {
        console.log('erro no getPins: ', e);
      }
    },
    [listLocal]
  );

  const handleLoadLocals = useCallback(
    ({ pag, loadFromMap }: handleLoadLocalsProps) => {
      // manda para o topo apenas na web
      if (!isMobile) window.scrollTo(0, 0);

      const init = pag === 1 ? 0 : 12 * pag - 12;
      const limit = pag === 1 ? 12 : 12 * pag;

      const newGroup = allDadosLocal.slice(init, limit);

      const totalMerged = [...dadosLocal, ...newGroup];
      if (loadFromMap) {
        console.log('totalMerged handleLoadLocals: ', totalMerged);
        const final = totalMerged > allDadosLocal ? allDadosLocal : totalMerged;
        console.log('final handleLoadLocals: ', final);
        setDadosLocal(final);

        if (totalMerged >= allDadosLocal) {
          setHasMore(false);
        }
      } else {
        if (!isMobile) {
          // aqui deve aparecer apenas para a web
          setLoadingFirst(true);
        }

        setTimeout(() => {
          if (totalMerged >= allDadosLocal) {
            setHasMore(false);
          }
          setDadosLocal(isMobile ? [...dadosLocal, ...newGroup] : newGroup);
          setLoadingFirst(false);
          setLoading(false);
          setCurrentPag(pag);
        }, 1500);
      }
    },
    [allDadosLocal, dadosLocal, isMobile]
  );

  const loadAllStoresForMap = useCallback(
    async ({
      lat,
      lng,
      pag,
      local,
      forceAllStores,
      name,
    }: LoadAllStoresProps) => {
      setLoadingFirst(true);

      try {
        let isLocalRequest = local || filter === 'local';
        if (forceAllStores) {
          isLocalRequest = false;
        }
        if (pag > 1 && isLocalRequest) {
          // dados das locais já carregados, pegar diretamente do estado
          handleLoadLocals({ pag, loadFromMap: true });
          return;
        }

        const maximumDataPerRequest = 100;
        const newUri = `${Config.loadPins}?lat=${lat}&lng=${lng}&limit=${maximumDataPerRequest}&page=${pag}`;
        console.log('newUri loadAllStoresForMap: ', newUri);
        const { data } = await api.get(newUri);
        const dadosPag = data[Config.loadPinsKey].result;

        const { totalElements, content, totalPages } = dadosPag;

        const dataServer = content;
        console.log('dataServer: ', dataServer);
        console.log('loadAllStoresForMap isLocalRequest:', isLocalRequest);
        if (pag === 1) {
          await getPins({ lat, lng, totalElements, local: isLocalRequest });
        }

        // await validateCovidStoreInList({ dataServer });

        if (isLocalRequest) {
          const localsOnThisRequest = await manyLocalsOnThisRequest({
            dataServer,
          });
          console.log(`localsOnThisRequest pag ${pag}: `, localsOnThisRequest);

          setAllDadosLocal(localsOnThisRequest.stores);

          const localsForThisPage = localsOnThisRequest.stores.slice(0, 12);
          setDadosLocal(localsOnThisRequest.stores);
          setHasMore(false);
        } else {
          setDados(dataServer);

          setCurrentPag(pag);
          setTotalElements(totalElements);
          setTotalPag(totalPages);
        }
      } catch (error) {
        console.log('error loadStores: ', error);
      } finally {
        setLoadingFirst(false);
        setLoading(false);
      }
    },
    [getPins, filter, handleLoadLocals, manyLocalsOnThisRequest]
  );

  const loadAllStores = useCallback(
    async ({
      lat,
      lng,
      pag,
      local,
      forceAllStores,
      name,
    }: LoadAllStoresProps) => {
      if (!isMobile) setLoadingFirst(true); // apenas para a web
      if (isMobile && pag === 1) setLoadingFirst(true); // ajustando para filtros mobile tb
      setLoading(true);

      try {
        let isLocalRequest = local || filter === 'local';

        if (forceAllStores) {
          isLocalRequest = false;
        }
        // fallback para evitar que o race condition da variável do filtro quebre a lógica
        //  if (local) isLocalRequest = false;

        if (pag > 1 && isLocalRequest) {
          // dados das locais já carregados, pegar diretamente do estado
          handleLoadLocals({ pag });
          return;
        }

        // pegando os 12 primeiros dados
        const maximumDataPerRequest = isLocalRequest ? 100 : 12;
        const newUri = `${Config.loadPins}?lat=${lat}&lng=${lng}&limit=${maximumDataPerRequest}&page=${pag}`;

        const { data } = await api.get(newUri);
        const dadosPag = data[Config.loadPinsKey].result;

        const { totalElements, content, totalPages } = dadosPag;

        const dataServer = content;
        console.log('dataServer: ', dataServer);

        makeSEO({
          lat,
          lng,
          pag,
          feather: String(parsed.feather),
          footer: String(parsed.footer),
          local,
          onlyLocal: parsed.onlyLocal,
          // all,
          // loadFromMap,
          uf,
          cidade,
          bairro,
          // removeParamsUri,
          ufParam: uf,
          cidadeParam: cidade,
          bairroParam: bairro,
          chave: String(parsed.chave),
          epar: String(parsed.epar),
          name: name || String(parsed.name),
          dataFromApi: dadosPag.content,
          // near,
        });

        if (pag === 1) {
          // pegando os pins apenas na primeira request. Além disso, apenas na primeira request ele verifica se tem local ou não
          await getPins({
            lat,
            lng,
            totalElements,
            local,
          });
        }

        if (isLocalRequest) {
          const localsOnThisRequest = await manyLocalsOnThisRequest({
            dataServer,
          });
          console.log(`localsOnThisRequest pag ${pag}: `, localsOnThisRequest);

          setAllDadosLocal(localsOnThisRequest.stores);

          const localsForThisPage = localsOnThisRequest.stores.slice(0, 12);

          setDadosLocal(
            isMobile ? [...dadosLocal, ...localsForThisPage] : localsForThisPage
          );
        } else {
          // essas variáveis são definidas apenas quando o filtro é para todas as lojas
          // setDados(
          //   isMobile
          //     ? pag === 1
          //       ? dataServer
          //       : [dados, ...dataServer]
          //     : dataServer
          // );
          setDados(isMobile ? [...dados, ...dataServer] : dataServer);

          setTotalElements(totalElements);
          setTotalPag(totalPages);
        }
        setCurrentPag(pag);
        setLoadingFirst(false);
        setLoading(false);
      } catch (error) {
        console.log('error loadStores: ', error);
        setLoadingFirst(false);
        setLoading(false);
      }
    },
    [
      getPins,
      manyLocalsOnThisRequest,
      handleLoadLocals,
      filter,
      isMobile,
      dados,
      dadosLocal,
      bairro,
      cidade,
      uf,
      parsed,
      makeSEO,
    ]
  );

  useEffect(() => {
    console.log('listLocal: ', listLocal);
    console.log('listWpp: ', listWpp);
    console.log('listCovid: ', listCovid);
    if (!listLocal || !listWpp || !listCovid) return;

    if (!parsed.lat && !parsed.lng) {
      getCoordsFromSEO();
      return;
    }

    const payload = {
      lat: String(parsed.lat),
      lng: String(parsed.lng),
      pag: 1,
    };
    console.log('payload inicial: ', payload);
    loadAllStores(payload);
  }, [listCovid, listWpp, listLocal]);

  const load = useCallback(
    async ({
      append,
      pag,
      lat,
      lng,
      newLocation,
      radius = 1.91,
      name,
      selected,
      feather,
      footer,
      chave,
      epar,
      local,
      all,
      ufParam,
      cidadeParam,
      bairroParam,
      removeParamsUri,
      loadFromMap,
      near,
      appendItemsFromMap = false,
      byPin,
      limit,
    }: LoadFunctionProps) => {
      try {
        console.log('load params local: ', local);
        console.log('load params append: ', append);
        console.log('load params pag: ', pag);
        console.log('load params lat: ', lat);
        console.log('load params lng: ', lng);
        console.log('load params newLocation: ', newLocation);
        console.log('load params all: ', all);
        console.log('load params ufParam: ', ufParam);
        console.log('load params cidadeParam: ', cidadeParam);
        console.log('load params bairroParam: ', bairroParam);
        console.log('load params removeParamsUri: ', removeParamsUri);
        console.log('load params loadFromMap: ', loadFromMap);
        console.log('load params near: ', near);
        console.log('load params appendItemsFromMap: ', appendItemsFromMap);
        console.log('load params byPin: ', byPin);
        console.log(
          'load params ------------------------------------------------'
        );

        // if (!ufParam && !cidadeParam) {
        //   console.log('caiu no fallback');
        //   return;
        // }
        // valor provisorio

        // localStorage.removeItem(keyUsedStores);
        // console.log('limpou');
        // return;
        const appendPage = await getAppendPage({ appendItemsFromMap });
        console.log('appendPage: ', appendPage);
        let makePag = appendPage ? appendPage.pag : pag;
        console.log('make pag apos apend: ', makePag);
        if (newLocation) {
          // setDados([]);
          // setDadosLocal([]);
          setCurrentPag(1);
          setTotalElements(1);
          setHasMore(true);
          setLastDiff(0);
          setPagAppend(1);

          MapRef.current?.clearPins();

          setPinsList((_: any) => {
            return [];
          });

          makePag = 1;
        }

        await normalizeFilter({ local });

        setVisibleChangeModal(false);

        setLoading(true);

        if (!isMobile && !appendItemsFromMap) setLoadingFirst(true);

        if (makePag === 1 || pag < currentPag) {
          // setPinsList((prev: any) => {
          //   return [];
          // });

          localStorage.removeItem(keyUsedStores);
        }

        const latParam = lat || parsed.lat;
        const lngParam = lng || parsed.lng;

        // console.log('makePag: ', makePag);

        // pegando os 12 primeiros dados
        const maximumDataPerRequest = loadFromMap ? 50 : limit || 12;
        const newUri = `${Config.loadPins}?lat=${latParam}&lng=${lngParam}&limit=${maximumDataPerRequest}&page=${makePag}`;
        console.log('newUri: ', newUri);

        const { data } = await api.get(newUri);
        const dadosPag = data[Config.loadPinsKey].result;
        console.log('dadosPag: ', dadosPag);
        const { totalElements, content, totalPages } = dadosPag;

        let dataServer = content;
        console.log('dataServer: ', dataServer);

        if (makePag === totalPages) setHasMore(false);

        dataServer = await sortData({ dataServer, selected });

        await normalizeDataForMap({ lat, lng });
        await validateCovidStoreInList({ dataServer });

        console.log('makeSEO antes');
        makeSEO({
          lat,
          lng,
          pag: makePag,
          feather,
          footer,
          local,
          onlyLocal: parsed.onlyLocal,
          all,
          loadFromMap,
          uf,
          cidade,
          bairro,
          removeParamsUri,
          ufParam,
          cidadeParam,
          bairroParam,
          chave,
          epar,
          name,
          dataFromApi: dadosPag.content,
          near,
        });
        console.log('makeSEO depois');

        if (near) {
          // console.log('return by near');
          setLoading(false);
          setLoadingFirst(false);
          setDados([]);
          setDadosLocal([]);
          return;
        }

        if (byPin) {
          // caso exista o param byPin, deve cancelar o load, pois o effect em cima do parsd,name chamará o load
          return;
        }
        console.log('makePag: ', makePag);
        if (makePag === 1) {
          await loadPins({
            lat,
            lng,
            totalElements,
            local,
            select: selected || selectedStore,
            append,
            pag,
            dataServer,
            loadFromMap,
            near,
          });
        }

        const list = all
          ? await getListFromStorage('all')
          : await getListFromStorage('local');

        const filteredData = await filteringData({
          list,
          dataServer,
          pag,
          loadFromMap,
          limit,
        });

        const totalStoresAlreadyLoaded = makePag * 12;

        const allList = await getListFromStorage('all');
        console.log('filteredData: ', filteredData);
        console.log('filteredData.length: ', filteredData.length);
        console.log(
          'filteredData totalStoresAlreadyLoaded: ',
          totalStoresAlreadyLoaded
        );
        console.log('filteredData allList.length: ', allList.length);
        if (
          filteredData.length < 12 &&
          totalStoresAlreadyLoaded < allList.length
        ) {
          console.log('menos que 12, chamar novamente o load');
          localStorage.setItem(
            keyAppendPagCurrent,
            JSON.stringify({ pag: makePag + 1 })
          );

          localStorage.setItem(
            keyAppendPagCurrentForMap,
            JSON.stringify({ pag: makePag + 1 })
          );

          localStorage.setItem(
            keyCurrentStoreList,
            JSON.stringify(filteredData)
          );

          const payload = {
            append,
            pag,
            lat,
            lng,
            selected,
            local,
            all,
            loadFromMap,
            near,
            ufParam,
            cidadeParam,
            bairroParam,
            removeParamsUri,
            name,
            appendItemsFromMap,
          };

          load(payload);
        } else {
          localStorage.removeItem(keyAppendPagCurrent);
          localStorage.removeItem(keyCurrentStoreList);
          localStorage.removeItem(keyAppendPagCurrentForMap);

          await setFinalData({
            filteredData,
            local,
            pag: makePag,
            totalElements,
          });

          setLoadingFirst(false);
          setLoading(false);
          setCurrentPag(pag);

          if (activeButton === 'lista' && !isMobile) {
            window.scrollTo(0, 0);
          }
        }
      } catch (error) {
        console.log('RESPONSE error: ', error);
        // console.log('caiu no catch');
        // console.log('erro na api load: ', e);

        const status = error.response.data[Config.loadPinsKey].details.status;

        setLoading(false);
        setLoadingFirst(false);
        setVisibleButtonMap(false);
        if (MapRef.current) {
          MapRef.current?.changeCenterMap({
            lat: lat || Number(parsed.lat),
            lng: lng || Number(parsed.lng),
          });
        }

        setCoords({
          lat: lat || Number(parsed.lat),
          lng: lng || Number(parsed.lng),
        });

        if (status === 404) {
          setHasMore(false);

          // if (pag > 1) {
          //   setCurrentPag(clickedPag || pag - 1);
          // }

          if (local) {
            // isMobile || activeButton === 'mapa'
            //   ? setDadosLocal((prev) => {
            //       return lastData ? [...prev, ...lastData] : [...prev];
            //     })
            //   : setDadosLocal(lastData || []);
          }

          if (!append && !local) {
            setDados([]);
          }
        }
      }
    },
    [
      isMobile,
      parsed.lat,
      parsed.lng,
      sortData,
      normalizeDataForMap,
      validateCovidStoreInList,
      selectedStore,
      loadPins,
      getListFromStorage,
      currentPag,
      filteringData,
      getAppendPage,
      makeSEO,
      bairro,
      cidade,
      uf,
      parsed.onlyLocal,
      activeButton,
      setFinalData,
      normalizeFilter,
    ]
  );

  const handleZoom = useCallback((item: any) => {
    if (MapRef) {
      const { lat: Latitude, lng: Longitude }: any =
        MapRef.current?.getCenter();

      setCoordsTmpCenter({
        lat: Latitude,
        lng: Longitude,
      });
    }
  }, []);

  // const handleChangeList = useCallback(
  //   (type) => {
  //     setActiveButton(type);
  //     if (type === 'lista') {
  //       HandleEvents({
  //         isMobile,
  //         eventAction: 'toggle',
  //         eventCategory: 'nossaslojas',
  //         eventLabel: 'lista',
  //         eventValue: undefined,
  //         cookies,
  //       });

  //       HandleAnalyticsEvents({
  //         customPayload: {
  //           detail: {
  //             interaction: 'togglelist',
  //           },
  //         },
  //       });

  //       if (currentPag === 1) return;
  //       setDados([]);
  //       setDadosLocal([]);
  //       setDadosWhitoutLocal([]);

  //       load({
  //         append: false,
  //         pag: 1,
  //         lat: Number(parsed.lat),
  //         lng: Number(parsed.lng),
  //         newLocation: true,
  //         name: String(parsed.name),
  //         feather: String(parsed.feather),
  //         footer: String(parsed.footer),
  //         chave: String(parsed.chave),
  //         epar: String(parsed.epar),
  //         local: false,
  //         all: true,
  //         ufParam: uf,
  //         cidadeParam: cidade,
  //         bairroParam: bairro,
  //       });
  //     } else {
  //       setDados([]);
  //       setDadosLocal([]);
  //       setDadosWhitoutLocal([]);
  //       setFilter('all');
  //       setHasMore(true);

  //       HandleEvents({
  //         isMobile,
  //         eventAction: 'toggle',
  //         eventCategory: 'nossaslojas',
  //         eventLabel: 'mapa',
  //         eventValue: undefined,
  //         cookies,
  //       });

  //       HandleAnalyticsEvents({
  //         customPayload: {
  //           detail: {
  //             interaction: 'togglemap',
  //           },
  //         },
  //       });

  //       const isLocal = parsed.local && parsed.local !== 'undefined';

  //       load({
  //         append: false,
  //         pag: 1,
  //         lat: Number(parsed.lat),
  //         lng: Number(parsed.lng),
  //         newLocation: true,
  //         name: String(parsed.name),
  //         feather: String(parsed.feather),
  //         footer: String(parsed.footer),
  //         chave: String(parsed.chave),
  //         epar: String(parsed.epar),
  //         local: !!parsed.local,
  //         all: !isLocal,
  //         ufParam: uf,
  //         cidadeParam: cidade,
  //         bairroParam: bairro,
  //         loadFromMap: true,
  //       });

  //       setCoordsCenter({
  //         lat: Number(parsed.lat),
  //         lng: Number(parsed.lng),
  //       });

  //       MapRef.current?.changeCenterMap({
  //         lat: Number(parsed.lat),
  //         lng: Number(parsed.lng),
  //       });
  //     }
  //   },
  //   [parsed, load, currentPag, bairro, cookies, cidade, isMobile, uf]
  // );

  const handleChangeList = useCallback(
    (type) => {
      setActiveButton(type);
      if (type === 'lista') {
        HandleEvents({
          isMobile,
          eventAction: 'toggle',
          eventCategory: 'nossaslojas',
          eventLabel: 'lista',
          eventValue: undefined,
          cookies,
        });

        HandleAnalyticsEvents({
          customPayload: {
            detail: {
              interaction: 'togglelist',
            },
          },
        });

        loadAllStores({
          pag: 1,
          lat: String(parsed.lat),
          lng: String(parsed.lng),
        });
      } else {
        loadAllStoresForMap({
          pag: 1,
          lat: String(parsed.lat),
          lng: String(parsed.lng),
        });

        HandleEvents({
          isMobile,
          eventAction: 'toggle',
          eventCategory: 'nossaslojas',
          eventLabel: 'mapa',
          eventValue: undefined,
          cookies,
        });

        HandleAnalyticsEvents({
          customPayload: {
            detail: {
              interaction: 'togglemap',
            },
          },
        });

        setCoordsCenter({
          lat: Number(parsed.lat),
          lng: Number(parsed.lng),
        });

        MapRef.current?.changeCenterMap({
          lat: Number(parsed.lat),
          lng: Number(parsed.lng),
        });
      }
    },
    [parsed, loadAllStores, cookies, isMobile, loadAllStoresForMap]
  );

  const getCoordsFromSEO = useCallback(async () => {
    let uri = `${uf}`;

    if (cidade) {
      uri = uri + `, ${cidade}`;
    }

    if (bairro) {
      uri = uri + `, ${bairro}`;
    }

    const { results, coords } = await Geo(uri);

    const { lat, lng } = coords;
    const name = results[0].formatted_address;

    const isLocal = parsed.local && parsed.local !== 'undefined';

    loadAllStores({
      // append: false,
      pag: 1,
      lat,
      lng,
      name,
      // newLocation: true,
      // radius: 1.91,
      // feather: String(parsed.feather),
      // footer: String(parsed.footer),
      // chave: String(parsed.chave),
      // epar: String(parsed.epar),
      // local: parsed.local === 'true',
      // all: !isLocal,
      // ufParam: uf,
      // cidadeParam: cidade,
      // bairroParam: bairro,
    });
  }, [uf, cidade, bairro, parsed, load]);

  useEffect(() => {
    setTimeout(() => {
      handleAnalytics();
    }, 4000);
  }, []);

  // useEffect(() => {
  //   HandleAnalyticsEvents({
  //     eventCustomName: 'page:load',
  //     customPayload: {
  //       detail: {
  //         page: {
  //           type: 'americanasStores-storeList',
  //           cep: cookies.persistentCep || null,
  //         },
  //       },
  //     },
  //   });
  // }, [cookies.persistentCep]);

  // useEffect(() => {
  //   if (!listLocal || !listWpp || !listCovid) return;
  //   if (activeButton === 'mapa') return;
  //   console.log('chamou o effect');
  //   setLoadingFirst(true);

  //   cleanStorages();

  //   if (!parsed.lat && !parsed.lng) {
  //     getCoordsFromSEO();
  //     return;
  //   }

  //   if (parsed.from) {
  //     const normalizeFrom: any = parsed.from;

  //     setActiveButton(normalizeFrom);
  //   }

  //   const existsLocalInQueryString = !!(
  //     parsed.local && parsed.local !== 'undefined'
  //   );

  //   const payload: any = {
  //     append: !!parsed.local,
  //     pag: 1,
  //     lat: Number(parsed.lat),
  //     lng: Number(parsed.lng),
  //     name: String(parsed.name),
  //     newLocation: true,
  //     feather: String(parsed.feather),
  //     footer: String(parsed.footer),
  //     chave: String(parsed.chave),
  //     epar: String(parsed.epar),
  //     local: !!parsed.local,
  //     all: !existsLocalInQueryString,
  //     near: false,
  //     ufParam: uf || null,
  //     cidadeParam: cidade || null,
  //     limit: 12,
  //   };

  //   if (parsed.byPin) {
  //     payload.loadFromMap = true;
  //     payload.byPin = true;

  //     setActiveButton('mapa');
  //   }

  //   load(payload);
  // }, [
  //   listLocal,
  //   listWpp,
  //   listCovid,
  //   parsed.name, // <-- não remover
  //   parsed.byPin,
  //   cidade,
  //   uf,
  // ]);

  useEffect(() => {
    if (parsed.help) {
      setDefaultTab(Number(parsed.help));
      setVisibleModal(true);
    }
  }, [parsed.help]);

  const handleDrag = useCallback((value: any) => {
    const { center } = value;
    setVisibleButtonMap(true);
    const latCenter = center.lat();
    const lngCenter = center.lng();

    setCoordsTmpCenter({
      lat: Number(latCenter),
      lng: Number(lngCenter),
    });
    // load({
    //   append: false,
    //   pag: 1,
    //   lat: latCenter,
    //   lng: lngCenter,
    //   feather: String(parsed.feather),
    //   footer: String(parsed.footer),
    // });
  }, []);

  const handleChangeMap = useCallback(
    async (values: any) => {
      const bounds = values.getBounds();
      const cen = bounds.getCenter();
      const ne = bounds.getNorthEast();

      const r = 3963.0;

      const lat1 = cen.lat() / 57.2958;
      const lon1 = cen.lng() / 57.2958;
      const lat2 = ne.lat() / 57.2958;
      const lon2 = ne.lng() / 57.2958;

      const dis =
        r *
        Math.acos(
          Math.sin(lat1) * Math.sin(lat2) +
            Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)
        );
      // console.log('dis raio: ', dis);

      const { center, zoom } = values;
      const { lat, lng } = center;

      const Latitude = await lat();
      const Longitude = await lng();

      // history.push({
      //   pathname: `/list`,
      //   search: `?lat=${Latitude}&lng=${Longitude}&name=${parsed.name}&pag=${currentPag}`,
      // });

      // if (zoom <= 17) {
      //   load({
      //     append: false,
      //     pag: 1,
      //     lat: Latitude,
      //     lng: Longitude,
      //     newLocation: false,
      //     name: String(parsed.name),
      //     feather: String(parsed.feather),
      //     footer: String(parsed.footer),
      //   });
      //   setControlUpdate(true);
      // }
    },
    [load, history, currentPag, parsed]
  );

  const handleCloseModalHelp = useCallback(() => {
    setVisibleModal(false);

    let current = location.search;

    const remove_after = current.indexOf('&help');
    current = current.substring(0, remove_after);

    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      location.search;

    window.history.pushState({ path: newurl }, '', newurl);
  }, [location.search]);

  const handleEventsGA = useCallback(
    (type: 'atendimento' | 'duvidasfrequentes' | 'politicadetrocas') => {
      const makeType = {
        atendimento: 'atendimento',
        duvidasfrequentes: 'faq',
        politicadetrocas: 'trocas',
      };

      HandleEvents({
        isMobile,
        eventAction: 'footer',
        eventCategory: 'nossaslojas',
        eventLabel: makeType[type],
        eventValue: undefined,
        cookies,
      });
    },
    [isMobile, cookies]
  );

  const handleChangeTab = useCallback(
    (index: number) => {
      setDefaultTab(Number(index));
      setVisibleModal(true);

      let current = location.search;

      if (parsed.help) {
        const remove_after = current.indexOf('&help');
        current = current.substring(0, remove_after);
      }

      const values: any = [
        'atendimento',
        'duvidasfrequentes',
        'politicadetrocas',
      ];

      handleEventsGA(values[index]);

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        window.location.search +
        `&help=${values[index]}`;
      window.history.pushState({ path: newurl }, '', newurl);
    },
    [parsed, location.search, handleEventsGA]
  );

  const handleChangeAddress = useCallback(() => {
    HandleAnalyticsEvents({
      customPayload: {
        detail: {
          interaction: 'changeaddress',
        },
      },
    });

    HandleEvents({
      isMobile,
      eventAction: 'changeaddress',
      eventCategory: 'nossaslojas',
      eventLabel: undefined,
      eventValue: undefined,
      cookies,
    });

    setVisibleChangeModal(true);
    if (InputRef.current) {
      InputRef.current.focus();
    }
  }, [cookies, isMobile]);

  const handleDetails = useCallback(
    (id: string, item: any, interaction?: string) => {
      const {
        lat,
        lng,
        name,
        pag,
        feather,
        footer,
        chave,
        epar,
        local,
        withoutLocal,
        near,
        breadcrumb,
        breadcrumbLink,
      } = parsed;
      const { name: nameStore, distance } = item;
      const key =
        interaction === 'storecard'
          ? 'o2o_cardloja_00_0_0_nossaslojas'
          : 'o2o_lp_00_0_0_nossaslojas';

      let uri = `?chave=${key}`;

      if (distance && distance !== 'undefined') {
        uri = uri + `&distance=${distance}`;
      }

      if (pag && pag !== 'undefined') {
        uri = uri + `&pag=${pag}`;
      }

      if (name && name !== 'undefined') {
        uri = uri + `&name=${name}`;
      }

      if (lat && lat !== 'undefined') {
        uri = uri + `&lat=${lat}`;
      }

      if (lng && lng !== 'undefined') {
        uri = uri + `&lng=${lng}`;
      }

      if (epar && epar !== 'undefined') {
        uri = uri + `&epar=${epar}`;
      }

      if (feather && feather !== 'undefined') {
        uri = uri + `&feather=${feather}`;
      }

      if (footer && footer !== 'undefined') {
        uri = uri + `&footer=${footer}`;
      }

      if (local && local !== undefined) {
        uri = uri + `&local=${local}`;
      }

      if (withoutLocal && withoutLocal !== undefined) {
        uri = uri + `&withoutLocal=${withoutLocal}`;
      }

      let makeAddress = `${uf}`;

      if (cidade) {
        makeAddress = makeAddress + `/${cidade}`;
      }

      if (bairro) {
        makeAddress = makeAddress + `/${bairro}`;
      }

      uri = uri + `&path=${makeAddress}`;

      if (near && near !== undefined) {
        uri = uri + `&near=${near}`;
      }

      console.log('lastBreadcrumbs handleDetails: ', lastBreadcrumbs);

      uri =
        uri +
        `&breadcrumb=${lastBreadcrumbs.label}&breadcrumbLink=${lastBreadcrumbs.link}`;

      uri = uri + `&storeName=${nameStore}`;
      uri = uri + `&from=${activeButton}`;

      const validInteraction =
        interaction && interaction === 'storecard' ? 'card' : undefined;
      HandleEvents({
        isMobile,
        eventAction: 'storecard',
        eventCategory: 'nossaslojas',
        eventLabel: validInteraction || 'visit',
        eventValue: activeButton,
        store: item,
        cookies,
      });

      HandleAnalyticsEvents({
        customPayload: {
          detail: {
            store: {
              name: nameStore,
              id,
            },
            interaction: interaction || 'storepage',
          },
        },
      });

      // history.push({
      //   pathname: `/loja/${id}/detalhes`,
      //   search: uri,
      // });

      history.push({
        pathname: `/loja/${id}`,
        search: uri,
      });

      // setTimeout(() => {
      //   window.location.reload();
      // }, 10);

      // const win = window.open(`/loja/${id}/detalhes${uri}`, '_blank');
      // win?.focus();
    },
    [
      parsed,
      history,
      activeButton,
      bairro,
      cidade,
      cookies,
      uf,
      isMobile,
      lastBreadcrumbs,
    ]
  );

  const handleBuy = useCallback(
    (id: string, item: any) => {
      const { lat, lng, name, pag, feather, footer, chave, epar } = parsed;
      const { name: nameStore } = item;

      const uri = `https://www.americanas.com.br/lojas-proximas/33014556000196/${id}?chave=o2o_comprar_00_0_0_nossaslojas`;

      if (variantCardStore === 'NEW') {
        HandleEvents({
          isMobile,
          eventAction: 'storecard',
          eventCategory: 'nossaslojas',
          eventLabel: 'ver mais',
          eventValue: activeButton,
          store: item,
          cookies,
        });
      } else {
        HandleEvents({
          isMobile,
          eventAction: 'storecard',
          eventCategory: 'nossaslojas',
          eventLabel: 'buy',
          eventValue: activeButton,
          store: item,
          cookies,
        });

        HandleAnalyticsEvents({
          customPayload: {
            detail: {
              store: {
                name: nameStore,
                id,
              },
              interaction: 'buy',
            },
          },
        });
      }

      const win = window.open(uri, '_blank');
      win?.focus();
    },
    [parsed, isMobile, activeButton, variantCardStore, cookies]
  );

  const makeAddress = useCallback((i: any) => {
    if (!i || !i.address) return '';
    return Number(i.address.number)
      ? `${i.address.address}, ${i.address.number} ${
          i.address.reference ? i.address.reference : ''
        } - ${i.address.neighborhood} - ${i.address.city}`
      : `${i.address.address} -
    ${i.address.neighborhood} - ${i.address.city}`;
  }, []);

  const cleanStorages = useCallback(async () => {
    localStorage.removeItem(keyTotalStores);
    localStorage.removeItem(keyCurrentStoreList);
    localStorage.removeItem(keyAppendPagCurrent);
    localStorage.removeItem(keyTotalPagStores);
    localStorage.removeItem(keyUsedStores);
    localStorage.removeItem(keyAppendPagCurrentForMap);
  }, []);

  const handleSelectFilter = useCallback(
    async (value: 'local' | 'all' | 'normal', disabled?: boolean) => {
      const isDisabled = !disabled;

      if (value !== filter) {
        await cleanStorages();
      }

      // if (
      //   isDisabled &&
      //   value !== 'all'
      //   (value === 'all' && !parsed.local && !parsed.withoutLocal)
      // )
      //   return;
      // setLoadingFirst(true);
      setFilter(value);

      const analytics = {
        all: 'filterallstores',
        local: 'filterlocal',
        normal: 'filteracom',
      };

      const analyticsGA = {
        all: 'allstores',
        local: 'local',
        normal: 'acom',
      };

      HandleEvents({
        isMobile,
        eventAction: 'filter',
        eventCategory: 'nossaslojas',
        eventLabel: analyticsGA[value],
        eventValue: undefined,
        cookies,
      });

      HandleAnalyticsEvents({
        customPayload: {
          detail: {
            interaction: analytics[value],
          },
        },
      });

      if (value === 'local') {
        setDados([]);
        setIsLocal(true);
        // load({
        //   append: false,
        //   lat: Number(parsed.lat),
        //   lng: Number(parsed.lng),
        //   name: String(parsed.name),
        //   pag: 1,
        //   feather: String(parsed.feather),
        //   footer: String(parsed.footer),
        //   chave: String(parsed.chave),
        //   epar: String(parsed.epar),
        //   local: true,
        //   all: false,
        //   newLocation: true,
        // });

        if (activeButton === 'lista') {
          loadAllStores({
            lat: String(parsed.lat),
            lng: String(parsed.lng),
            pag: 1,
            local: true,
          });
        } else {
          loadAllStoresForMap({
            lat: String(parsed.lat),
            lng: String(parsed.lng),
            pag: 1,
            local: true,
          });
        }
      }

      if (value === 'all') {
        console.log('chamou aqui');
        setDadosLocal([]);
        setIsLocal(false);

        if (activeButton === 'lista') {
          loadAllStores({
            lat: String(parsed.lat),
            lng: String(parsed.lng),
            pag: 1,
            local: false,
            forceAllStores: true,
          });
        } else {
          loadAllStoresForMap({
            lat: String(parsed.lat),
            lng: String(parsed.lng),
            pag: 1,
            local: false,
            forceAllStores: true,
          });
        }
      }
    },
    [
      parsed,
      cookies,
      isMobile,
      filter,
      cleanStorages,
      loadAllStores,
      activeButton,
      loadAllStoresForMap,
    ]
  );

  const myList = useMemo(() => {
    const lists = {
      all: dados,
      normal: dadosWithoutLocal,
      local: dadosLocal,
    };

    return lists[filter];
  }, [dados, dadosLocal, filter, dadosWithoutLocal]);

  console.log('myList lengh: ', myList.length);

  const isPar = useMemo(() => {
    const tamanho = myList.length;
    const par = tamanho % 2 === 0;
    return par;
  }, [myList]);

  const handlePinClick = useCallback(
    async (item: any) => {
      HandleEvents({
        isMobile,
        eventAction: 'resultado mapa',
        eventCategory: 'nossaslojas',
        eventLabel: 'pin',
        eventValue: undefined,
        cookies,
      });

      const { sellerStoreId, geolocation } = item;

      const { latitude, longitude } = geolocation;
      setSelectedStore(sellerStoreId);
      setStoreSelected(sellerStoreId);

      MapRef.current?.markers.map((i: any) => {
        if (i.id === sellerStoreId) {
          i.animation = true;
          i.setAnimation(MapRef.current?.animationPin);
        } else {
          i.animation = false;
          i.setAnimation(null);
        }
      });

      // const found = myList.find((i: any) => i.sellerStoreId === sellerStoreId);
      const found = myList.find((i: any) => i.sellerStoreId === sellerStoreId);

      console.log('found myList: ', myList);
      console.log('found dados: ', dados);
      console.log('found dadosWithoutLocal: ', dadosWithoutLocal);
      console.log('found dadosLocal: ', dadosLocal);
      console.log('found: ', found);
      console.log('found sellerStoreId: ', sellerStoreId);
      console.log('found filter: ', filter);

      setCoordsCenter({ lat: latitude, lng: longitude });

      if (!found) {
        setDados([]);
        setDadosLocal([]);
        setDadosWhitoutLocal([]);
        setFilter('all');

        loadAllStoresForMap({
          pag: 1,
          lat: latitude,
          lng: longitude,
        });
      } else {
        const list = [...dados];
        const sortedDados = list.sort((a: any, b: any) => {
          if (a.sellerStoreId === sellerStoreId) {
            return -1;
          } else {
            return 1;
          }
        });

        setDados([...sortedDados]);
      }
    },
    [
      dados,
      cookies,
      isMobile,
      filter,
      dadosWithoutLocal,
      dadosLocal,
      myList,
      loadAllStoresForMap,
    ]
  );

  // const handlePinClick = useCallback(
  //   async (item: any) => {
  //     HandleEvents({
  //       isMobile,
  //       eventAction: 'resultado mapa',
  //       eventCategory: 'nossaslojas',
  //       eventLabel: 'pin',
  //       eventValue: undefined,
  //       cookies,
  //     });

  //     const { sellerStoreId, geolocation } = item;

  //     const { latitude, longitude } = geolocation;
  //     setSelectedStore(sellerStoreId);
  //     setStoreSelected(sellerStoreId);

  //     MapRef.current?.markers.map((i: any) => {
  //       if (i.id === sellerStoreId) {
  //         i.animation = true;
  //         i.setAnimation(MapRef.current?.animationPin);
  //       } else {
  //         i.animation = false;
  //         i.setAnimation(null);
  //       }
  //     });

  //     // const found = myList.find((i: any) => i.sellerStoreId === sellerStoreId);
  //     const found = myList.find((i: any) => i.sellerStoreId === sellerStoreId);

  //     console.log('found myList: ', myList);
  //     console.log('found dados: ', dados);
  //     console.log('found dadosWithoutLocal: ', dadosWithoutLocal);
  //     console.log('found dadosLocal: ', dadosLocal);
  //     console.log('found: ', found);
  //     console.log('found sellerStoreId: ', sellerStoreId);
  //     console.log('found filter: ', filter);

  //     setCoordsCenter({ lat: latitude, lng: longitude });

  //     if (!found) {
  //       setDados([]);
  //       setDadosLocal([]);
  //       setDadosWhitoutLocal([]);
  //       setFilter('all');

  //       load({
  //         append: false,
  //         pag: 1,
  //         lat: latitude,
  //         lng: longitude,
  //         newLocation: true,
  //         name: String(parsed.name),
  //         selected: sellerStoreId,
  //         feather: String(parsed.feather),
  //         footer: String(parsed.footer),
  //         chave: String(parsed.chave),
  //         epar: String(parsed.epar),
  //         local: false,
  //         all: true,
  //         ufParam: uf,
  //         cidadeParam: cidade,
  //         bairroParam: bairro,
  //         // removeParamsUri,
  //         loadFromMap: true,
  //         // pinClicked: true,
  //       });
  //     } else {
  //       const list = [...dados];
  //       const sortedDados = list.sort((a: any, b: any) => {
  //         if (a.sellerStoreId === sellerStoreId) {
  //           return -1;
  //         } else {
  //           return 1;
  //         }
  //       });

  //       setDados([...sortedDados]);
  //     }
  //   },
  //   [
  //     dados,
  //     parsed,
  //     load,
  //     bairro,
  //     cidade,
  //     uf,
  //     cookies,
  //     isMobile,
  //     filter,

  //     dadosWithoutLocal,
  //     dadosLocal,
  //     myList,
  //   ]
  // );

  // const eventTest = useCallback(
  //   (event: any) => {
  //     const { property } = event;
  //     const { value } = property;
  //     console.log('event change: ', event);
  //     const card = myList[value];

  //     if (!card) return;

  //     myList[event.property.value].id

  //     changeStoreSelected(card.id);
  //   },
  //   [changeStoreSelected, myList]
  // );

  const eventChangeCarousel = useCallback(
    async (event: any) => {
      const { property } = event;
      const { value } = property;

      const card = myList[value];

      if (!card) return;

      const { Latitude, Longitude, id } = card;

      const size = myList.length;
      const index = myList.findIndex((i: any) => i.id === id);

      // setControlUpdate(false);
      // const item = getRef(index);
      // console.log('peguei o item: ', item);

      if (index === size - 2) {
        setStartPositionCarousel(index);

        const isLocal = parsed.local && parsed.local !== 'undefined';

        await load({
          append: true,
          pag: currentPag + 1,
          lat: Number(parsed.lat),
          lng: Number(parsed.lng),
          name: String(parsed.name),
          feather: String(parsed.feather),
          footer: String(parsed.footer),
          chave: String(parsed.chave),
          epar: String(parsed.epar),
          local: !!parsed.local,
          all: !isLocal,
        });
      }

      MapRef.current?.changeCenterMap({ lat: Latitude, lng: Longitude });

      // setCoordsCenter({ lat: Latitude, lng: Longitude });
    },
    [currentPag, parsed, load, myList]
  );

  const makeCardStore = useCallback(
    (i: any, index: number, containerStyle?: object) => {
      console.log('card store: ', i);
      return variantCardStore === 'NEW' ? (
        <CardStoreV2
          currentLegions={currentLegions}
          variant={variantCardStore}
          isMap={activeButton}
          containerStyle={containerStyle}
          key={i.id}
          title={i.name}
          address={makeAddress(i)}
          distance={i.distance}
          isPar={isPar}
          index={index}
          schedules={i.schedules}
          sellerStoreId={i.sellerStoreId}
          canPickUpInStore={i.canPickUpInStore}
          canShipFromStore={i.canShipFromStore}
          selected={
            i.id === storeSelected ||
            i.sellerStoreId === selectedStore ||
            i.sellerStoreId === `L${selectedStore}`
          }
          onClickCard={() => handleDetails(i.sellerStoreId, i, 'storecard')}
          onClickProducts={() => handleDetails(i.sellerStoreId, i)}
          onClickWpp={() =>
            handleClickCardStore({
              item: i,
              type: 'whatsapp',
              wppFromWeb: listWpp,
              isMobile,
              cookies,
            })
          }
          item={i}
          onClickBuy={() => handleBuy(i.sellerStoreId, i)}
        />
      ) : (
        <CardStore
          variant={variantCardStore}
          isMap={activeButton}
          containerStyle={containerStyle}
          key={i.id}
          title={i.name}
          address={makeAddress(i)}
          distance={i.distance}
          isPar={isPar}
          index={index}
          schedules={i.schedules}
          sellerStoreId={i.sellerStoreId}
          canPickUpInStore={i.canPickUpInStore}
          canShipFromStore={i.canShipFromStore}
          selected={
            i.id === storeSelected ||
            i.sellerStoreId === selectedStore ||
            i.sellerStoreId === `L${selectedStore}`
          }
          onClickCard={() => handleDetails(i.sellerStoreId, i, 'storecard')}
          onClickProducts={() => handleDetails(i.sellerStoreId, i)}
          onClickWpp={() =>
            handleClickCardStore({
              item: i,
              type: 'whatsapp',
              wppFromWeb: listWpp,
              isMobile,
              cookies,
            })
          }
          item={i}
          onClickBuy={() => handleBuy(i.sellerStoreId, i)}
        />
      );
    },
    [
      activeButton,
      cookies,
      handleBuy,
      handleDetails,
      isMobile,
      isPar,
      listWpp,
      makeAddress,
      selectedStore,
      storeSelected,
      variantCardStore,
      currentLegions,
    ]
  );

  const handleAddress = useCallback(() => {
    setModalAddressView(true);

    HandleAnalyticsEvents({
      customPayload: {
        detail: {
          interaction: 'changeaddress',
        },
      },
    });

    HandleEvents({
      isMobile,
      eventAction: 'changeaddress',
      eventCategory: 'nossaslojas',
      eventLabel: undefined,
      eventValue: undefined,
      cookies,
    });
  }, [isMobile, cookies]);

  const makeDudeButton = useMemo(() => {
    if (isMobile) {
      return (
        <FakeButtonDudeIcon
          onClick={() => setTooltipLocalView(true)}
          ref={DudeIconRef}
        >
          <DudeIcon width="18px" height="18px" />
        </FakeButtonDudeIcon>
      );
    }
    return (
      <FakeButtonDudeIcon
        onMouseOver={() => setTooltipLocalView(true)}
        onMouseOut={() => setTooltipLocalView(false)}
        ref={DudeIconRef}
      >
        <DudeIcon />
      </FakeButtonDudeIcon>
    );
  }, [isMobile]);

  const handleGeoLocation = useCallback(
    (type: 'maps' | 'browser' | 'waze' | 'mapsBrowser') => {
      console.log('type 3');
      setShowNavigationOptions(false);

      if (type === 'mapsBrowser') {
        const showPosition = (position: any) => {
          console.log('position: ', position);
          console.log(
            'Latitude: ' +
              position.coords.latitude +
              '<br>Longitude: ' +
              position.coords.longitudes
          );

          const win = window.open(
            `https://maps.google.com/?daddr=${coordsCenter.lat},${coordsCenter.lng}&saddr=${position.coords.latitude},${position.coords.longitude}&directionsmode=driving`,
            '_blank'
          );
          win?.focus();
        };
        if (navigator.geolocation) {
          console.log('position entrou');
          navigator.geolocation.getCurrentPosition(showPosition);
        } else {
          console.log('Geolocation is not supported by this browser.');
        }
      }

      if (type === 'browser') {
        const showPosition = (position: any) => {
          console.log('position: ', position);
          console.log(
            'Latitude: ' +
              position.coords.latitude +
              '<br>Longitude: ' +
              position.coords.longitude
          );

          const storePosition = coordsCenter;

          const request = {
            origin: { lat: coordsCenter.lat, lng: coordsCenter.lng },
            destination: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
            // waypoints: markerLocations,
            // optimizeWaypoints: true,
            travelMode: 'BICYCLING',
          };

          MapRef?.current?.directionsService.route(
            request,
            (result: any, status: any) => {
              if (status === 'OK') {
                // Session.set('directionsService', result);
                MapRef?.current?.directionsDisplay.setDirections(result);

                // setTimeout(() => {
                //   MapRef?.current?.setOptions({ preserveViewport: true });
                // }, 1000)
              }
            }
          );
        };
        if (navigator.geolocation) {
          console.log('position entrou');
          navigator.geolocation.getCurrentPosition(showPosition);
        } else {
          console.log('Geolocation is not supported by this browser.');
        }
      }

      if (type === 'waze') {
        const win = window.open(
          `https://waze.com/?ll=${coordsCenter.lat},${coordsCenter.lng}&navigate=yes`,
          '_blank'
        );
        // const win = window.open(
        //   `waze://?ll=${coordsCenter.lat},${coordsCenter.lng}&navigate=yes`,
        //   '_blank'
        // );
        win?.focus();
      }

      if (type === 'maps') {
        const win = window.open(
          `https://maps.google.com/?daddr=${coordsCenter.lat},${coordsCenter.lng}&directionsmode=driving`,
          '_blank'
        );
        win?.focus();
      }
    },
    [coordsCenter]
  );

  const makeRouteButton = useMemo(() => {
    if (isMobile) {
      return (
        <WhatsSolidButton
          style={{ height: 30 }}
          isMobile={isMobile}
          onClick={() => {
            // setShowNavigationOptions(true);
            handleGeoLocation('maps');
            HandleEvents({
              isMobile,
              eventAction: 'rota',
              eventCategory: 'nossaslojas',
              eventLabel: undefined,
              eventValue: undefined,
              cookies,
            });
          }}
          // isMapView={activeButton === 'mapa'}
        >
          {/* <FiSend
            size={20}
            color={colors.redPrimary}
            style={{ marginTop: 1 }}
          /> */}
          <img src={RouteIcon} />
          <span style={{ marginTop: 1 }}>traçar rota</span>
        </WhatsSolidButton>
      );
    }

    return <div></div>;
  }, [isMobile, cookies, colors, handleGeoLocation]);

  const makeWppButton = useMemo(() => {
    if (isMobile) {
      return (
        <WhatsSolidButton
          isMobile={isMobile}
          style={{ height: 30 }}
          // onClick={() => {
          //   HandleEvents({
          //     isMobile,
          //     eventAction: 'whatsapp',
          //     eventCategory: 'nossaslojas',
          //     eventLabel: 'whatsapp tooltip',
          //     eventValue: undefined,
          //     cookies,
          //   });
          //   setTooltipWhatsView(true);
          // }}
          onClick={() => {
            const action =
              activeButton === 'mapa'
                ? () =>
                    handleClickCardStore({
                      type: 'whatsapp',
                      wppFromWeb: listWpp,
                      isMobile,
                      cookies,
                      eventAction: 'whatsapp',
                    })
                : () => {
                    HandleEvents({
                      isMobile,
                      eventAction: 'whatsapp',
                      eventCategory: 'nossaslojas',
                      eventLabel: 'whatsapp tooltip',
                      eventValue: undefined,
                      cookies,
                    });
                    setTooltipWhatsView(true);
                  };

            action();
          }}
          isMapView={activeButton === 'mapa'}
          // onMouseOver={() => setTooltipWhatsView(true)}
          // onMouseOut={() => setTooltipWhatsView(false)}
        >
          <WhatsImg style={{ marginTop: 1 }} />
          <span style={{ marginTop: 1, marginLeft: 5 }}>whatsapp</span>
        </WhatsSolidButton>
      );
    }

    return (
      <WhatsSolidButton
        onMouseOver={() => {
          HandleEvents({
            isMobile,
            eventAction: 'whatsapp',
            eventCategory: 'nossaslojas',
            eventLabel: 'whatsapp hover',
            eventValue: undefined,
            cookies,
          });
          setTooltipWhatsView(true);
        }}
        isMobile={isMobile}
        isMapView={activeButton === 'mapa'}
        // onMouseOut={() => setTooltipWhatsView(false)}
      >
        <WhatsImg />
        <span>comprar pelo whatsapp</span>
      </WhatsSolidButton>
    );
  }, [isMobile, activeButton, cookies, listWpp]);

  useEffect(() => {
    const load = async () => {
      try {
        const { data } = await api.get(
          `${Config.legionsApi}?longitude=${parsed?.lng}&latitude=${parsed?.lat}`
        );

        const resp = data.region.result;
        // console.log('legion: ', resp);

        if (resp.length) {
          const legions = resp.map((i: any) => i.legionRegion);
          // console.log('legions: ', legions);
          setCurrentLegions(legions);
        }
      } catch (error) {
        console.log('erro legions: ', error);
      }
    };
    load();
  }, [parsed.lat, parsed.lng]);

  const makeFilter = useCallback(
    (type?: 'mapaMobile') => {
      return (
        <Filter
          isMobile={isMobile}
          disabled={!existsLocal && !parsed.local && !parsed.withoutLocal}
        >
          <h2
            style={
              type === 'mapaMobile'
                ? {
                    marginLeft: 13,
                    marginBottom: 7,
                    marginTop: 11,
                  }
                : {}
            }
          >
            escolha sua loja:
          </h2>
          <ul>
            <ItemList
              // style={{ paddingTop: 5 }}
              active={filter === 'all'}
              isMobile={isMobile}
              onClick={() =>
                handleSelectFilter(
                  'all',
                  !existsLocal && !parsed.local && !parsed.withoutLocal
                )
              }
              disabled={!existsLocal && !parsed.local && !parsed.withoutLocal}
            >
              <span>todas as lojas</span>
            </ItemList>

            <ItemList
              active={filter === 'local'}
              isMobile={isMobile}
              onClick={() => handleSelectFilter('local', existsLocal)}
              disabled={!existsLocal}
            >
              <LocalIcon />
            </ItemList>
            {existsLocal && makeDudeButton}
          </ul>

          {DudeIconRef && (
            <div onClick={() => setTooltipLocalView(false)}>
              <TooltipLocal
                opacity={!!tooltipLocalView}
                // opacity={true}
                mLeft={isMobile ? `0px` : '148px'}
                isMobile={isMobile}
              >
                <h1>já conhece a Local?</h1>
                <h2>
                  Na local você vai encontrar as melhores opções para seu
                  cafezinho ou refeição prática, a maior variedade de cervejas e
                  as principais marcas de vinhos e destilados do mercado.
                </h2>

                <h2>
                  Do lanchinho à reposição de última hora, aqui tem conveniência
                  para você :)
                </h2>
              </TooltipLocal>
              <ModalTooltip visible={!!tooltipLocalView} />
            </div>
          )}

          {tooltipWhatsView && (
            <WhatsTooltip
              listWpp={listWpp}
              sizeFakeBox="187px"
              cbFunction={() => setTooltipWhatsView(false)}
            />
          )}
          {/* <div style={{ marginLeft: 'auto' }}>
            {!isMobile && variantCardStore === 'NEW' && makeWppButton}
          </div> */}
        </Filter>
      );
    },
    [
      existsLocal,
      filter,
      handleSelectFilter,
      isMobile,
      parsed.local,
      parsed.withoutLocal,
      tooltipLocalView,
      makeDudeButton,
      tooltipWhatsView,
      listWpp,
    ]
  );

  const validateFooterPosition = useMemo(() => {
    if (isMobile) {
      if (activeButton === 'mapa') {
        return 'absolute';
      }

      if (window.innerHeight < 700) {
        return 'relative';
      }
      return myList.length > 0 ? 'relative' : 'absolute';
    }
    if (activeButton === 'mapa') {
      return 'relative';
    }

    return myList.length > 0 ? 'relative' : 'absolute';
  }, [activeButton, myList, isMobile]);

  // const getVersionLojasCard = useCallback(async () => {
  //   console.log('chamou o getVersion cardStore');
  //   const getVariantLocal = localStorage.getItem('@b2w:version:card-store-wpp');
  //   if (getVariantLocal) {
  //     const variantParsed = JSON.parse(getVariantLocal);
  //     setVariantCardStore(variantParsed.version);

  //     // chamando o analytics novamente mesmo com variável definida
  //     TestAB({
  //       salt: Math.random(),
  //       experimentId: 'LOJAS_CARDWPP',
  //       variantSetted: variantParsed.version,
  //       cookies,
  //       isMobile,
  //       newWeight: 5,
  //       oldWeight: 5,
  //       controlWeight: 90,
  //     });
  //   } else {
  //     const v = TestAB({
  //       salt: Math.random(),
  //       experimentId: 'LOJAS_CARDWPP',
  //       cookies,
  //       isMobile,
  //       newWeight: 5,
  //       oldWeight: 5,
  //       controlWeight: 90,
  //     });

  //     const payload = {
  //       version: v,
  //     };
  //     localStorage.setItem(
  //       '@b2w:version:card-store-wpp',
  //       JSON.stringify(payload)
  //     );
  //     setVariantCardStore(v);
  //   }
  // }, [cookies, isMobile]);

  // useEffect(() => {
  //   getVersionLojasCard();
  // }, []);

  const makeWppButtonMobile = useMemo(() => {
    return (
      <div
        onClick={() => {
          HandleEvents({
            isMobile,
            eventAction: 'whatsapp',
            eventCategory: 'nossaslojas',
            eventLabel: 'whatsapp tooltip',
            eventValue: undefined,
            cookies,
          });
          setTooltipWhatsView(false);
        }}
        style={{ paddingLeft: 16, paddingRight: 16 }}
      >
        <TooltipWhatsappMobile
          opacity={true}
          mLeft={isMobile ? `0px` : '5px'}
          isMobile={isMobile}
          isViewMap={activeButton === 'mapa'}
        >
          <h1>compre no Whatsapp</h1>
          <h2>Monte sua cesta no Whatsapp e ainda tire suas dúvidas ;)</h2>

          <button
            onClick={() => {
              handleClickCardStore({
                type: 'whatsapp',
                wppFromWeb: listWpp,
                isMobile,
                cookies,
                eventAction: 'whatsapp',
              });
            }}
          >
            comprar
          </button>
        </TooltipWhatsappMobile>
        <ModalTooltip
          visible={!!tooltipWhatsView}
          containerStyle={{ zIndex: activeButton ? '999999999' : '999' }}
        />
      </div>
    );
  }, [cookies, isMobile, listWpp, tooltipWhatsView, activeButton]);

  return (
    <>
      <ModalAddressWeb
        visible={modalAddressView}
        closeFunction={() => setModalAddressView(false)}
        cbFunction={() => {
          setModalAddressView(false);
          setTimeout(() => {
            handleAnalytics();
          }, 4000);
        }}
        onClickHandle={load}
      />
      <ModalAddress
        ref={InputRef}
        visible={visibleChangeModal}
        onClose={() => {
          setVisibleChangeModal(false);
        }}
        cbFunction={() => {
          setTimeout(() => {
            handleAnalytics();
          }, 4000);
        }}
        onClickHandle={load}
      />
      <ModalHelp
        visible={visibleModal}
        closeFunction={handleCloseModalHelp}
        defaultTab={defaultTab}
        onSelect={handleChangeTab}
      />
      <Header />

      {!loading && activeButton === 'lista' && (
        <Breadcrumb
          items={[lastBreadcrumbs]}
          labelStyle={{
            color: colors.grayMedium,
          }}
        />
      )}

      {isMobile ? (
        <MobileContent
          handleChangeAddress={handleChangeAddress}
          activeButton={activeButton}
          coordsCenter={coordsCenter}
          coordsTmpCenter={coordsTmpCenter}
          filter={filter}
          handleAnalytics={handleAnalytics}
          myList={myList}
          visibleButtonMap={visibleButtonMap}
          handleChangeList={handleChangeList}
          load={loadAllStores}
          loadForMap={loadAllStoresForMap}
          dados={dados}
          dadosLocal={dadosLocal}
          makeCardStore={makeCardStore}
          makeFilter={makeFilter}
          loadingFirst={loadingFirst}
          parsed={parsed}
          existsCovid={existsCovid}
          handleBuy={handleBuy}
          handleDetails={handleDetails}
          isPar={isPar}
          listWpp={listWpp}
          makeAddress={makeAddress}
          makeWppButton={makeWppButton}
          totalPag={totalPag}
          changeState={() => setVisibleButtonMap(true)}
          changeStateForMap={() => {
            setControlUpdate(true);
            setVisibleButtonMap(true);
          }}
          MapRef={MapRef}
          totalElements={totalElements}
          TypeList={TypeList}
          cookies={cookies}
          currentLegions={currentLegions}
          loading={loading}
          selectedStore={selectedStore}
          storeSelected={storeSelected}
          tooltipWhatsView={tooltipWhatsView}
          currentPag={currentPag}
          hasMore={hasMore}
          eventChangeCarousel={eventChangeCarousel}
          handleChangeMap={handleChangeMap}
          handleDrag={handleDrag}
          handleGeoLocation={handleGeoLocation}
          handlePinClick={handlePinClick}
          handleZoom={handleZoom}
          makeRouteButton={makeRouteButton}
          pinsList={pinsList}
          showNavigationOptions={showNavigationOptions}
          startPositionCarousel={startPositionCarousel}
          makeWppButtonMobile={makeWppButtonMobile}
        />
      ) : (
        <>
          <BuscaBox openAction={handleAddress} />
          <WebContent
            TypeList={TypeList}
            activeButton={activeButton}
            handleChangeList={handleChangeList}
            totalElements={totalElements}
            selectedStore={selectedStore}
            storeSelected={storeSelected}
            comparativeList={comparativeList}
            cookies={cookies}
            currentLegions={currentLegions}
            currentPag={currentPag}
            dados={dados}
            dadosLocal={dadosLocal}
            existsCovid={existsCovid}
            filter={filter}
            handleBuy={handleBuy}
            handleDetails={handleDetails}
            isPar={isPar}
            listWpp={listWpp}
            load={loadAllStores}
            loadForMap={loadAllStoresForMap}
            loadingFirst={loadingFirst}
            makeAddress={makeAddress}
            makeCardStore={makeCardStore}
            makeFilter={makeFilter}
            makeWppButton={makeWppButton}
            myList={myList}
            parsed={parsed}
            totalPag={totalPag}
            pagAppend={pagAppend}
            changeState={() => setVisibleButtonMap(true)}
            MapRef={MapRef}
            handleGeoLocation={handleGeoLocation}
            visibleButtonMap={visibleButtonMap}
            handleAnalytics={handleAnalytics}
            coordsTmpCenter={coordsTmpCenter}
            pinsList={pinsList}
            coordsCenter={coordsCenter}
            handleDrag={handleDrag}
            handleChangeMap={handleChangeMap}
            handlePinClick={handlePinClick}
            handleZoom={handleZoom}
            hasMore={hasMore}
          />
        </>
      )}

      {(activeButton === 'mapa' && !isMobile) ||
        (activeButton === 'lista' && (
          <Footer
            containerStyle={{
              position: validateFooterPosition,
              bottom:
                !!myList.length && activeButton !== 'lista' ? 'auto' : '0',
            }}
            isMap={false}
            handleMenu1={() => handleChangeTab(0)}
            handleMenu2={() => handleChangeTab(1)}
            handleMenu3={() => handleChangeTab(2)}
          />
        ))}
    </>
  );
};

export default List;
