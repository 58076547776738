import React, { FC, useCallback, useState } from 'react';
import { Container, Content } from './styles';
import ModalTooltip from '../ModalTooltip';
import useWindowDimensions from '../../Utils/size';

interface TooltipProps {
  title: string;
  className?: string;
  bottomCalc: string;
  mNegativeLeft: string;
  mLeft: string;
  link?: string;
}

const Tooltip: FC<TooltipProps> = ({
  title,
  className = '',
  bottomCalc,
  mNegativeLeft,
  mLeft,
  link,
  children,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const isMobile = useWindowDimensions();

  return (
    <>
      <Container
        className={className}
        onMouseOver={() => setModalVisible(true)}
        onMouseLeave={() => setModalVisible(false)}
        isMobile={isMobile}
      >
        <div className="popover__wrapper">
          <a href="#">{children}</a>

          <div className="popover__content">
            <Content
              bottomCalc={bottomCalc}
              mNegativeLeft={mNegativeLeft}
              mLeft={mLeft}
            >
              <a href={link || '#'}>{title}</a>
            </Content>
          </div>
        </div>
      </Container>
      <ModalTooltip visible={modalVisible} />
    </>
  );
};

export default Tooltip;
