import styled, { css } from 'styled-components';

interface ContentProps {
  isMobile: boolean;
}
export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* max-width: 1280px; */

  justify-content: center;
  padding-top: ${(props) => (props.isMobile ? '25px' : '0px')};
  margin: auto;
  position: relative;
  z-index: 99999;

  .sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 90px;
    z-index: 90 !important;
  }

  ${({ isMobile }) =>
    !isMobile &&
    css`
      margin-top: 31px;
    `}
`;

interface BarrinhaProps {
  isMobile: boolean;
}

export const Barrinha = styled.div<BarrinhaProps>`
  width: 100%;
  height: 1px;
  background: #bababa;
  margin-top: ${(props) => (props.isMobile ? '20px' : '40px')};
`;

interface WrapperProps {
  isMobile?: boolean;
}
export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  background-color: ${({ isMobile }) => (isMobile ? 'transparent' : '#f1f1f1')};
`;
