import React, { FC } from 'react';
import { Container } from './styles';

interface Props {
  title: string;
  className?: string;
  bottomCalc: string;
  mNegativeLeft: string;
  mLeft: string;
}
const Popover: FC<Props> = ({
  className,
  bottomCalc,
  mNegativeLeft,
  mLeft,
  title,
  children,
}) => (
  <Container
    className={className}
    bottomCalc={bottomCalc}
    mNegativeLeft={mNegativeLeft}
    mLeft={mLeft}
  >
    <div className="popover__wrapper">
      <a href="#">{children}</a>
      <div className="popover__content">
        <p className="popover__message">{title}</p>
      </div>
    </div>
  </Container>
);

export default Popover;
