import React, { FC, useEffect, useCallback, useState } from 'react';

import Header from '../../Components/Header';
import Busca from '../../Components/Busca_old';
import Footer from '../../Components/Footer';
import Map from '../../Components/Map_old';
import Config from '../../Utils/Config';
import { useHistory, useLocation } from 'react-router-dom';
import ModalHelp from '../../Components/ModalHelp';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import api from '../../Services/api';
import ModalAddressWeb from '../../Components/ModalAddressWeb';
import HandleAnalyticsEvents from '../../Utils/HandleAnalyticsEvents';
import { HandlePageView, HandleEvents } from '../../Utils/AnalyticsGA';
import useWindowDimensions from '../../Utils/size';

const Home: FC = () => {
  const [cep, setCep] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const [defaultTab, setDefaultTab] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const isMobile = useWindowDimensions();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const handleCloseModalHelp = useCallback(() => {
    setVisibleModal(false);

    let current = location.search;

    const remove_after = current.indexOf('&help');
    current = current.substring(0, remove_after);

    history.push({
      pathname: '/',
      search: current,
    });
  }, [history, location.search]);

  // const handleEventsGA = useCallback(
  //   (type: 'atendimento' | 'duvidasfrequentes' | 'politicadetrocas') => {
  //     const makeType = {
  //       atendimento: 'atendimento',
  //       duvidasfrequentes: 'faq',
  //       politicadetrocas: 'trocas',
  //     };

  //     HandleEvents({
  //       isMobile,
  //       eventAction: 'footer',
  //       eventCategory: 'nossaslojas',
  //       eventLabel: makeType[type],
  //       eventValue: undefined,
  //       cookies,
  //     });
  //   },
  //   [isMobile, cookies]
  // );

  // const handleChangeTab = useCallback(
  //   (index: number) => {
  //     setDefaultTab(Number(index));
  //     setVisibleModal(true);

  //     let current = location.search;

  //     if (parsed.help) {
  //       const remove_after = current.indexOf('&help');
  //       current = current.substring(0, remove_after);
  //     }

  //     const values: any = [
  //       'atendimento',
  //       'duvidasfrequentes',
  //       'politicadetrocas',
  //     ];

  //     handleEventsGA(values[index]);

  //     history.push({
  //       pathname: '/',
  //       search: `${current}&help=${values[index]}`,
  //     });
  //   },
  //   [history, parsed, location.search, handleEventsGA]
  // );

  useEffect(() => {
    HandleAnalyticsEvents({
      eventCustomName: 'page:load',
      customPayload: {
        detail: {
          page: {
            type: 'americanasStores-Home',
            cep: cookies.persistentCep || null,
          },
        },
      },
    });
  }, [cookies]);

  const loadGA = useCallback(() => {
    HandlePageView({
      cookies,
      query: parsed,
      name: 'ACOM:NossasLojas:Home',
      isMobile,
    });
  }, [cookies, parsed, isMobile]);

  useEffect(() => {
    loadGA();
  }, []);

  // useEffect(() => {
  //   if (parsed.help) {
  //     const values: any = {
  //       atendimento: 0,
  //       duvidasfrequentes: 1,
  //       politicadetrocas: 2,
  //     };
  //     setDefaultTab(Number(values[String(parsed.help)]));
  //     setVisibleModal(true);
  //   }
  // }, [parsed.help]);

  // useEffect(() => {
  //   const tempCep = '22210060';
  //   // cookies.persistentCep
  //   if (cookies.persistentCep && !parsed.help) {
  //     setCep(cookies.persistentCep);
  //     // setCep('93610330');
  //   }
  // }, [cookies, history, parsed]);

  return (
    <div style={{ backgroundColor: '#FFF' }}>
      {/* <ModalHelp
        visible={visibleModal}
        closeFunction={handleCloseModalHelp}
        defaultTab={defaultTab}
        onSelect={handleChangeTab}
      /> */}

      {/* <Header /> */}
      <Busca cep={cep} />
      <Map />

      {/* <Footer
        handleMenu1={() => handleChangeTab(0)}
        handleMenu2={() => handleChangeTab(1)}
        handleMenu3={() => handleChangeTab(2)}
      /> */}
    </div>
  );
};

export default Home;
