import React, {
  FC,
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import {
  Container,
  Banner,
  Catalog,
  CatalogBox,
  ShareBox,
  Alinhador,
  Title,
  CustomArrow,
  Arrow,
  HeaderCatalog,
} from './styles';
import { useHistory } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import ClickCardStore from '../../../Utils/ClickCardStore';
import { StickyContainer, Sticky } from 'react-sticky';
import api from '../../../Services/api';
import { ReactComponent as ShareIcon } from '../../../Assets/icon-share.svg';
import { StoreProps } from '../Sobre/';
import { useLocation } from 'react-router-dom';
import samplePDF from './pdfexample.pdf';
import queryString from 'query-string';
import { Document, Page, pdfjs } from 'react-pdf';
import { getApiListWpp, WppProps } from '../../../Utils/ListWpp';
import Header from '../../../Components/Header';
import useWindowDimensions from '../../../Utils/size';
import LoadingIcon from '../../../Assets/loading.svg';
import { ReactComponent as ChevronLeft } from '../../../Assets/icon-arrow.svg';
import { useCookies } from 'react-cookie';
import { Loader } from '../../List/styles';
import { CatalogProps } from '../../../Utils/GetCatalog';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  isMobile: boolean;
  store: StoreProps;
  catalog: CatalogProps | undefined;
  bannerImg?: any;
}

const Encarte: FC<Props> = ({ store, catalog, bannerImg }) => {
  const [pagesPDF, setPagesPDF] = useState([]);
  const [listWpp, setListWpp] = useState<WppProps>({} as WppProps);
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfLink, setPdfLink] = useState('');
  const [activeShare, setActiveShare] = useState(false);
  // const [bannerImg, setBannerImg] = useState('');
  // const [catalog, setCatalog] = useState('');
  const [catalogView, setCatalogView] = useState(false);
  const location = useLocation();
  const imgRef = useRef<HTMLImageElement>(null);
  const isMobile = useWindowDimensions();
  const history = useHistory();
  const { v3, storeId } = queryString.parse(location.search);

  const handleShare = useCallback(async () => {
    const current = location.search;
    const shareData = {
      title: 'Encarte',
      text: `Encarte da ${store.name}`,
      url: window.location.href,
    };

    setActiveShare(true);

    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => {
          ClickCardStore({
            item: store,
            type: 'share',
            wppFromWeb: listWpp,
            isMobile,
            cookies,
          });
        })
        .catch((error: any) => console.log('Error sharing', error));
    } else {
      alert('Não é possível a ação de compartilhar neste dispositivo.');
    }
  }, [location.search, store, listWpp, isMobile]);

  // useEffect(() => {
  //   api
  //     .get(
  //       `https://spacey-api-v2-americanas.b2w.io/spacey-api/publication/app/maintop1/americanas/nossaslojas`
  //     )
  //     .then(({ data }) => {
  //       console.log('data catalog: ', data);
  //       const banner = data.component.children[0].children[0].small;
  //       let catalogLink = data.component.children[0].children[0].linkUrl;

  //       catalogLink = catalogLink.split('com://webview/')[1];
  //       console.log('catalogLink: ', catalogLink);
  //       const searchRegExp = /(?:https?:\/\/)|(?:https?\/\/)/;
  //       catalogLink = catalogLink.replace(searchRegExp, '');
  //       console.log('catalog link: ', catalogLink);
  //       if (catalogLink) {
  //         setCatalog(`https://${catalogLink}`);
  //       }

  //       if (banner) {
  //         setBannerImg(banner);
  //       }
  //     })
  //     .catch((e) => console.log('erro na api spacey: ', e));
  // }, []);

  const makePages = useMemo(() => {
    if (!numPages) return [];
    const p: any = Array.from({ length: numPages }, (curr, index) => index + 1);

    return p;
    // setPagesPDF(p);
  }, [numPages]);

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
  }, []);

  useEffect(() => {
    getApiListWpp()
      .then((e) => setListWpp(e))
      .catch((e) => console.log('erro ao caregar listaWpp: ', e));
  }, []);

  return (
    <Container isMobile={isMobile}>
      <>
        {catalog?.type === 'jpg' ? (
          <>
            <StickyContainer style={{ width: '100%' }}>
              <Sticky topOffset={-160} style={{ width: '100%' }}>
                {({ style, isSticky }) => {
                  const s = isSticky
                    ? ({
                        top: 160,
                        position: 'fixed',
                        bottom: 38,
                      } as React.CSSProperties)
                    : { top: 0 };
                  const sBox = isSticky
                    ? ({
                        paddingTop: 0,
                        marginBottom: 5,
                      } as React.CSSProperties)
                    : { paddingTop: 0 };
                  return (
                    <CatalogBox style={{ ...sBox }}>
                      <Alinhador style={{ ...s }}>
                        <ShareBox onClick={handleShare} active={activeShare}>
                          <ShareIcon className="share" />
                        </ShareBox>
                      </Alinhador>
                    </CatalogBox>
                  );
                }}
              </Sticky>
              <div style={{ width: '100%' }}>
                <Catalog src={catalog?.catalog} ref={imgRef} />
              </div>
            </StickyContainer>
          </>
        ) : (
          <Document
            file={{ url: catalog?.catalog }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {makePages.map((i: any, index: number) => (
              <Page key={i} width={window.innerWidth} pageNumber={index + 1} />
            ))}
          </Document>
        )}
      </>
    </Container>
  );
};

export default Encarte;
