import React, {
  FC,
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from 'react';
import Header from '../../Components/Header';
import Busca from '../../Components/Busca';
import Footer from '../../Components/Footer';
import Map from '../../Components/Map_old';
import Config from '../../Utils/Config';
import { useHistory, useLocation } from 'react-router-dom';
import ModalHelp from '../../Components/ModalHelp';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import api from '../../Services/api';
import { RiCloseLine } from 'react-icons/ri';
import ModalAddressWeb from '../../Components/ModalAddressWeb';
import HandleAnalyticsEvents from '../../Utils/HandleAnalyticsEvents';
import { ReactComponent as ChevronLeft } from '../../Assets/icon-arrow.svg';
import { ReactComponent as FilterIcon } from '../../Assets/filter.svg';
import { ptBR } from 'date-fns/locale';

import LoadingIcon from '../../Assets/loading.svg';
import {
  Container,
  Title,
  Data,
  Card,
  TitleOffer,
  AddressOffer,
  Alignment,
  Info,
  Timestamp,
  Total,
  Barrinha,
  HeaderCard,
  Products,
  Item,
  Description,
  ProductImg,
  AlignmentDescription,
  BarrinhaBottom,
  FooterCard,
  HeadBox,
  Badge,
  Loader,
  LoaderBox,
  BoxNfe,
  AlignmentNfe,
  ActionsNfe,
  MoreButton,
  CloseButton,
  AlignmentError,
  BoxAlignmentTitle,
  WrapperHeader,
  FilterBox,
  BoxActionsFilter,
  FilterItem,
} from './styles';
import OrderPlaceholder from '../../Components/OrderPlaceholder';
import {
  loginLasa,
  populateProducts,
  getOrders,
} from '../../Services/orders/orders';
import {
  LoginLasaResponseProps,
  GetOrdersResponseProps,
  ItemOrderProps,
} from '../../Services/orders/interfaces';
import { parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { AsyncLocalStorage } from 'async_hooks';
import ModalTooltip from '../../Components/ModalTooltip';
import ModalNFE from '../../Components/ModalNFE';
import useWindowDimensions from '../../Utils/size';
import OrderMobile, { OrderMobileExportedProps } from './OrderMobile';
import OrderWeb from './OrderWeb';
import { HandlePageView, HandleEvents } from '../../Utils/AnalyticsGA';
import { TitlePage } from '../OrderDetail/styles';
import { Breadcrumb, BreadCrumbItemProps } from '../../Components/Breadcrumb';
import { useFilters } from '../../Hooks/useFilters';
import { MockData } from './mockData';

interface ErrorProps {
  error: boolean;
  handle: any;
}

const Home: FC = () => {
  const [orderBreadcrumbs, setOrderBreadcrumbs] = useState<BreadCrumbItemProps>(
    {} as BreadCrumbItemProps
  );
  const [lastBreadcrumbs, setlastBreadcrumbs] = useState<BreadCrumbItemProps>(
    {} as BreadCrumbItemProps
  );
  const [error, setError] = useState<any>(false);
  const [withFilters, setWithFilters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [defaultTab, setDefaultTab] = useState(0);
  const [data, setData] = useState<any>({});
  const [filteredData, setFilteredData] = useState<any>({});
  const [keysOrders, setKeysOrders] = useState<any[]>([]);
  const [YearsOrders, setYearsOrders] = useState<any>({});
  const [MonthsOrders, setMonthsOrders] = useState<any>({});
  const [MonthsData, setMonthsData] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [cookies, setCookie, removeCookie] = useCookies<any>([
    'customer.id',
    'customer.api.token',
  ]);
  const refOrderMobile = useRef<OrderMobileExportedProps>(null);
  const {
    yearsFilter,
    monthsFilter,
    removeMonthFilter,
    removeYearFilter,
    cleanAllFilter,
  } = useFilters();

  useEffect(() => {
    const newData: any = {};
    console.log('yearsFilter: ', yearsFilter);
    console.log('monthsFilter: ', monthsFilter);
    if (!yearsFilter.length && !monthsFilter.length) {
      const reversedKeysData = Object.keys(data).reverse();
      setKeysOrders(reversedKeysData);
      setFilteredData({});
      return;
    }

    if (yearsFilter.length && monthsFilter.length) {
      console.log('entrou no filtro cruzado');
      const tempYearData: any = [];
      const tempData: any = [];
      // first, filter by year
      Object.entries(data).map((key: any) => {
        const year = key[0].slice(0, 4);
        if (yearsFilter?.includes(year)) {
          tempYearData[key[0]] = key[1];
        }
      });

      console.log('depois do filtro por ano: ', tempYearData);

      // filter by month
      Object.entries(tempYearData).map((key: any) => {
        const month = key[0].slice(4, 6);
        const year = key[0].slice(0, 4);
        console.log('month: ', month);
        console.log('month monthsFilter: ', monthsFilter);
        // if (monthsFilter?.includes(month)) {
        //   tempData[key[0]] = key[1];
        // }

        monthsFilter.forEach((m: any) => {
          const groupData = `${String(year)}${String(m)}`;
          const groupDataFilter = `${String(year)}${String(month)}`;
          if (groupData === groupDataFilter) {
            tempData[key[0]] = key[1];
          }
        });
      });

      console.log('depois do filtro por mes: ', tempData);

      const reversedKeysData = Object.keys(tempData).reverse();

      console.log('depois do reverse: ', tempData);

      setKeysOrders(reversedKeysData);
      setFilteredData(tempData);

      return;
    }

    if (yearsFilter.length) {
      // filter by year
      Object.entries(data).map((key: any) => {
        const year = key[0].slice(0, 4);
        if (yearsFilter?.includes(year)) {
          newData[key[0]] = key[1];
        }
      });
    }

    if (monthsFilter.length) {
      // filter by month
      Object.entries(data).map((key: any) => {
        const month = key[0].slice(4, 6);
        if (monthsFilter?.includes(month)) {
          newData[key[0]] = key[1];
        }
      });
    }

    console.log('newData final: ', newData);

    const reversedKeysData = Object.keys(newData).reverse();

    setKeysOrders(reversedKeysData);
    setFilteredData(newData);
  }, [yearsFilter, monthsFilter]);

  const isMobile = useWindowDimensions();

  const HistoryEmpty = useMemo(() => {
    return (
      <OrderPlaceholder
        handle={() => (
          <AlignmentError>
            <span
              style={{
                marginTop: isMobile ? 0 : 0,
                fontSize: isMobile ? 16 : 24,
              }}
            >
              seu histórico de compras em nossas lojas físicas não foi
              identificado
            </span>

            {isMobile ? (
              <span style={{ marginTop: 15, fontSize: 13, lineHeight: '17px' }}>
                No histórico são exibidas apenas as compras feitas<br></br>a
                partir de janeiro de 2021, nas quais você tenha informado seu
                CPF junto ao caixa da loja.
              </span>
            ) : (
              <span style={{ marginTop: 25, fontSize: 24, lineHeight: '32px' }}>
                No histórico são exibidas apenas as compras feitas a partir de
                janeiro de 2021, nas quais você tenha informado seu CPF junto ao
                caixa da loja.
              </span>
            )}

            <MoreButton
              style={{
                marginTop: isMobile ? 24 : 70,
                width: isMobile ? 160 : 238,
                height: isMobile ? 36 : 54,
                marginLeft: 'auto',
              }}
              onClick={() => history.push('/')}
            >
              <span
                style={{ fontSize: isMobile ? 14 : 19 }}
                // className="fontBold"
              >
                visitar lojas próximas
              </span>
            </MoreButton>
          </AlignmentError>
        )}
      />
    );
  }, [isMobile, history]);

  const handleShoppingEmpty = useCallback(() => {
    setLoading(false);
    setData([]);

    HandleEvents({
      isMobile,
      eventAction: 'minhas compras',
      eventCategory: 'nossaslojas',
      eventLabel: 'sucesso sem compras',
      eventValue: undefined,
      cookies,
    });

    setError(HistoryEmpty);
  }, [isMobile, cookies, HistoryEmpty]);

  const handleLoginError500 = useCallback(() => {
    setLoading(false);
    setData([]);

    HandleEvents({
      isMobile,
      eventAction: 'minhas compras',
      eventCategory: 'nossaslojas',
      eventLabel: 'acesso erro 500',
      eventValue: undefined,
      cookies,
    });

    setError(HistoryEmpty);
  }, [isMobile, cookies, HistoryEmpty]);

  const handlGetOrdersError500 = useCallback(() => {
    setLoading(false);
    setData([]);

    HandleEvents({
      isMobile,
      eventAction: 'minhas compras',
      eventCategory: 'nossaslojas',
      eventLabel: 'acesso erro 500',
      eventValue: undefined,
      cookies,
    });

    setError(
      <OrderPlaceholder
        handle={() => (
          <span style={{ marginTop: 11 }}>Handle para o erro 500</span>
        )}
      />
    );
  }, [cookies, isMobile]);

  const handleCloseModalHelp = useCallback(() => {
    setVisibleModal(false);

    let current = location.search;

    const remove_after = current.indexOf('&help');
    current = current.substring(0, remove_after);

    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      location.search;

    window.history.pushState({ path: newurl }, '', newurl);
  }, [location.search]);

  const handleEventsGA = useCallback(
    (type: 'atendimento' | 'duvidasfrequentes' | 'politicadetrocas') => {
      const makeType = {
        atendimento: 'atendimento',
        duvidasfrequentes: 'faq',
        politicadetrocas: 'trocas',
      };

      HandleEvents({
        isMobile,
        eventAction: 'footer',
        eventCategory: 'nossaslojas',
        eventLabel: makeType[type],
        eventValue: undefined,
        cookies,
      });
    },
    [isMobile, cookies]
  );

  const handleChangeTab = useCallback(
    (index: number) => {
      setDefaultTab(Number(index));
      setVisibleModal(true);

      let current = location.search;

      if (parsed.help) {
        const remove_after = current.indexOf('&help');
        current = current.substring(0, remove_after);
      }

      const values: any = [
        'atendimento',
        'duvidasfrequentes',
        'politicadetrocas',
      ];

      handleEventsGA(values[index]);

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        window.location.search +
        `&help=${values[index]}`;
      window.history.pushState({ path: newurl }, '', newurl);
    },
    [parsed, location.search, handleEventsGA]
  );

  useEffect(() => {
    if (parsed.help) {
      const values: any = {
        atendimento: 0,
        duvidasfrequentes: 1,
        politicadetrocas: 2,
      };
      setDefaultTab(Number(values[String(parsed.help)]));
      setVisibleModal(true);
    }
  }, [parsed.help]);

  const handleDetails = useCallback(
    async (order: any) => {
      localStorage.setItem('@b2w:order-offline', JSON.stringify(order));

      HandleEvents({
        isMobile,
        eventAction: 'minhas compras',
        eventCategory: 'nossaslojas',
        eventLabel: 'total de produtos',
        eventValue: undefined,
        store: {
          name: order?.nomeLoja,
          sellerStoreId: order?.codigoLoja,
        },

        cookies,
      });

      history.push('/pedidos/detalhes');
    },
    [history, isMobile, cookies]
  );

  const handleGetOrdersError400 = useCallback(() => {
    setLoading(false);
    setData([]);

    HandleEvents({
      isMobile,
      eventAction: 'minhas compras',
      eventCategory: 'nossaslojas',
      eventLabel: 'acesso erro 400',
      eventValue: undefined,
      cookies,
    });

    setError(
      <OrderPlaceholder
        handle={() => (
          <span style={{ marginTop: 11 }}>Handle para o erro 400</span>
        )}
      />
    );
  }, [cookies, isMobile]);

  const handleGetOrdersSuccess = useCallback(
    async (data: GetOrdersResponseProps[]) => {
      if (!data) {
        handleShoppingEmpty();
        return;
      }
      const dados = data || [];

      const items = await Promise.all(
        dados.map(async (i: GetOrdersResponseProps) => {
          const obj: any = { ...i };

          obj.valorTotal = String(
            Number(String(obj.valorTotal)).toFixed(2)
          ).replace('.', ',');

          // String(obj.valorTotal).replace('.', ',');
          obj.data = parseISO(obj.data);
          obj.originalData = obj.data;
          obj.dataDescription = format(obj.data, "MMMM 'de' yyyy", {
            locale: pt,
          });
          obj.getDayOfMonth = format(obj.data, 'dd');
          obj.getMonthOfYear = format(obj.data, 'MMMM', {
            locale: pt,
          }).slice(0, 3);
          obj.dataWeb = format(obj.data, "dd 'de' MMMM 'de' yyyy 'às' HH:mm", {
            locale: pt,
          });
          obj.data = format(obj.data, 'dd/MM/yyyy');

          const ean = obj.itens.map((m: ItemOrderProps) => m.ean);

          obj.itensPopulate = await populateProducts({
            store: i.codigoLoja,
            ean,
          });

          console.log('obj.itens: ', obj.itens);

          obj.itensFinal = obj.itens.map(
            (item: ItemOrderProps, index: number) => {
              console.log('obj?.itensPopulate[index]: ', obj?.itensPopulate);
              const detail = obj?.itensPopulate
                ? obj?.itensPopulate[index] || null
                : null;

              const finalObj = {
                codigoItem: item.codigoItem,
                ean: item.ean,
                nome: item.item,
                imagem:
                  detail && Array.isArray(detail)
                    ? detail[0]?.images[0]?.large || null
                    : null,
                quantidade: item.qtde,
                valor: String(item.valorTotalItem).replace('.', ','),
                dataDescription: obj.dataDescription,
                data: obj.data,
                dataWeb: obj.dataWeb,
                getDayOfMonth: obj.getDayOfMonth,
                getMonthOfYear: obj.getMonthOfYear,
              };

              return finalObj;
            }
          );

          return obj;
        })
      );

      console.log('items: ', items);

      const newItems: any = {};
      const yearsOrders: any = {};
      const monthsOrders: any = {};

      items.forEach((curr: any) => {
        const getMonth = format(curr.originalData, 'MM');
        const getYear = format(curr.originalData, 'yyyy');
        const keyObject = `${getYear}${getMonth}`;

        const existsOrder = newItems[keyObject];
        const existsYearOrder = yearsOrders[getYear];
        const existsMonthOrder = monthsOrders[getMonth];

        existsOrder
          ? (newItems[keyObject] = [...newItems[keyObject], curr])
          : (newItems[keyObject] = [curr]);

        existsYearOrder
          ? (yearsOrders[getYear] = [...yearsOrders[getYear], curr])
          : (yearsOrders[getYear] = [curr]);

        existsMonthOrder
          ? (monthsOrders[getMonth] = [...monthsOrders[getMonth], curr])
          : (monthsOrders[getMonth] = [curr]);
      });

      const reversedKeys = Object.keys(newItems).reverse();

      const makeOrderMonths: any = [];

      Object.keys(monthsOrders).forEach((key) => {
        makeOrderMonths.push(key);
      });

      const monthsSorted = makeOrderMonths.sort((a: any, b: any) => {
        return Number(a) - Number(b);
      });

      setMonthsData(monthsOrders);
      setMonthsOrders(monthsSorted);
      setYearsOrders(yearsOrders);
      setKeysOrders(reversedKeys);
      setData(newItems);
      setLoading(false);

      HandleEvents({
        isMobile,
        eventAction: 'minhas compras',
        eventCategory: 'nossaslojas',
        eventLabel: 'sucesso com compras',
        eventValue: undefined,
        cookies,
      });
    },
    [cookies, isMobile, handleShoppingEmpty]
  );

  const handleLoginSuccess = useCallback(
    async (data: LoginLasaResponseProps) => {
      const { token } = data;
      // setError(true);

      HandleEvents({
        isMobile,
        eventAction: 'minhas compras',
        eventCategory: 'nossaslojas',
        eventLabel: 'acesso sucesso',
        eventValue: undefined,
        cookies,
      });

      const response = await getOrders(token);

      // const response = await getOrders(token);

      if (!response.status) {
        console.log('ERRO SEM STATUS DEFINIDO (CORS) 2');
        return;
      }

      // 500 - falha ao carregar
      // 404 - n encontrou o cliente
      // 308 - email cadastrado é igual
      // response.status = 500;

      switch (response.status) {
        case 500:
          handlGetOrdersError500();
          break;
        case 400:
          handleGetOrdersError400();
          break;
        case 200:
          handleGetOrdersSuccess(response.data);
          break;
        default:
          break;
      }
    },
    [
      handleGetOrdersError400,
      handlGetOrdersError500,
      handleGetOrdersSuccess,
      cookies,
      isMobile,
    ]
  );

  const handleLoginError404 = useCallback(() => {
    console.log('entrou no error 404');
    setLoading(false);
    setData([]);

    HandleEvents({
      isMobile,
      eventAction: 'minhas compras',
      eventCategory: 'nossaslojas',
      eventLabel: 'acesso erro 404',
      eventValue: undefined,
      cookies,
    });

    setError(HistoryEmpty);

    // setError(
    //   <OrderPlaceholder
    //     handle={() => (
    //       <AlignmentError>
    //         <span
    //           style={{
    //             marginTop: isMobile ? 22 : 60,
    //             fontSize: isMobile ? 16 : 24,
    //           }}
    //         >
    //           Ops! Falha ao carregar.
    //         </span>

    //         <MoreButton
    //           style={{
    //             marginTop: isMobile ? 24 : 45,
    //             width: isMobile ? 130 : 204,
    //             height: isMobile ? 36 : 54,
    //             marginLeft: 'auto',
    //           }}
    //           onClick={() => window.location.reload()}
    //         >
    //           <span
    //             style={{ fontSize: isMobile ? 14 : 19 }}
    //             // className="fontBold"
    //           >
    //             atualizar
    //           </span>
    //         </MoreButton>
    //       </AlignmentError>
    //     )}
    //   />
    // );
  }, [isMobile, cookies, HistoryEmpty]);

  const handleLoginError308 = useCallback(() => {
    setLoading(false);
    setData([]);

    HandleEvents({
      isMobile,
      eventAction: 'minhas compras',
      eventCategory: 'nossaslojas',
      eventLabel: 'acesso erro 308',
      eventValue: undefined,
      cookies,
    });

    setError(
      <OrderPlaceholder
        handle={() => (
          <AlignmentError>
            {isMobile ? (
              <span
                style={{
                  marginTop: isMobile ? 11 : 60,
                  fontSize: isMobile ? 16 : 24,
                }}
              >
                poooxa, ainda não temos o seu<br></br> cadastro completo.
              </span>
            ) : (
              <span
                style={{
                  marginTop: isMobile ? 11 : 60,
                  fontSize: isMobile ? 16 : 24,
                }}
              >
                poooxa, ainda não temos o seu cadastro completo.
              </span>
            )}

            {isMobile ? (
              <>
                <span style={{ marginTop: 15, fontSize: 16 }}>
                  E pra mantermos a segurança dos seus dados, você poderá
                  consultar mais informações com nosso atendimento, através do
                  e-mail:
                </span>
                <span
                  style={{ fontSize: 16, color: '#e0030e' }}
                  className="fontBold"
                >
                  atendimento.acom@americanas.com
                </span>
              </>
            ) : (
              <>
                <span
                  style={{ marginTop: 45, fontSize: 24, lineHeight: '32px' }}
                >
                  E pra mantermos a segurança dos seus dados, você poderá
                  consultar <br />
                  mais informações com nosso atendimento, através do e-mail:
                </span>
                <span
                  style={{ fontSize: 24, color: '#e0030e', lineHeight: '32px' }}
                  className="fontBold"
                >
                  atendimento.acom@americanas.com
                </span>
              </>
            )}

            <MoreButton
              style={{
                marginTop: isMobile ? 24 : 40,
                width: isMobile ? 159 : 161,
                height: isMobile ? 37 : 54,
                marginLeft: 'auto',
              }}
              onClick={() => null}
            >
              <span
                style={{ fontSize: isMobile ? 14 : 19 }}
                // className="fontBold"
              >
                ok, entendi
              </span>
            </MoreButton>
          </AlignmentError>
        )}
      />
    );
  }, [isMobile, cookies]);

  const loadLogin = useCallback(
    async (id_cliente: string, access_code_cliente: string) => {
      const response = await loginLasa({ id_cliente, access_code_cliente });
      console.log('response loadLogin: ', response);
      if (!response.status) {
        console.log('ERRO SEM STATUS DEFINIDO (CORS)');
        return;
      }

      // 500 - falha ao carregar
      // 404 - n encontrou o cliente
      // 308 - email cadastrado é igual
      // response.status = 308;

      switch (response.status) {
        case 500:
          handleLoginError500();
          break;
        case 404:
          handleLoginError404();
          break;
        case 308:
          handleLoginError308();
          break;
        case 200:
          handleLoginSuccess(response.data);
          break;
        default:
          break;
      }
    },
    [
      handleLoginError308,
      handleLoginError404,
      handleLoginError500,
      handleLoginSuccess,
    ]
  );

  useEffect(() => {
    if (cookies['customer.id'] && cookies['customer.api.token']) {
      loadLogin(cookies['customer.id'], cookies['customer.api.token']);
    } else {
      window.location.href = `https://cliente.americanas.com.br/simple-login/?guest=true&next=${window.location.href}`;
    }
  }, [cookies]);

  // useEffect(() => {
  // 404
  // loadLogin(
  //   '62b399be606ea222917ca982',
  //   'orL8krjUGb0X7jNfhfe0lUzi3La1t5bPgGdIp08e_IHX5zhtERFVfCsWK_jzQysvYryQNp5FroNTh2ohh_fVGevBGnU1jiEnHk-AUyWhvUNUTkDNab_-etGDdKwSjGbA0-FlV36V0MP7g1Yq8X5gBKNYf9Zv5fvac00cVbbDi0cR5hkcCS7KTMvURfl_cczwtMDfqUcaKr2FNu71d1B8nw'
  // );

  // 308
  // loadLogin(
  //   '60aee2d9fc89375a5a809a3d',
  //   'rN7QhWHDCRxZTLqBNg2N1_nUkoYZT3vU2NW27CY41nVs4YW-W39I6bQZR2LvyXYQpzT4BFlbzxr_EzZtRRo8XSGy7dlGS2sw08cG7-znEg8mwB5LAAhHZQ24gQDYZNHERp_nGHP4-jJgbziO-9wZtRJdLkZmZdZdF0UT2vQrd6BgctMsTYvfH4JHm0rOJ2OryqF5huNOzg-VDbKg4eFn1Q'
  // );

  // 200 sem compras
  // loadLogin(
  //   '6005f38d9656d1214402f2f3',
  //   'GiXbsSaSUhiRtM_KIUS0pV7Sm_8gbRPES3g67RKW7gIVtHLCpoEob7dLwIc47Gn8IQ4H0KEEe6YHc7QG2p4wPigXz0fx4LFhj8NmYUT52KvDjpEzctSgeNRJR6uGjUqvpAJ-rYUYGgFAaQPPWpidfED9-td-U2I2fahGBBBLz6-B2ubhbZdRCm1jeikhFU4RE7zsb5zsPBTtMgz4BH4Vxw'
  // );

  // 200 com compras
  // loadLogin(
  //   '5e29e6b08fa562000d0020cc',
  //   'PIQZof2DmOeqm9ZqEav9hUfamuWyBNKiGvw7u-7Mq8Vdh_gL9acEe-GPyQjvpq8iihDynugWS3klnJxjv1WAMeb6ZqYJ3E6EPU4RSBuFFvkAvJlu1oQqKOr4ARHKei_Wz18oUy1sjoJg-P_5ODdcNTefrJTlE_Pt25tRlptrZIGFYz-qb-ay0mKUJAIS8El6BYxcdVlzjOh40veectGLVg'
  // );
  // }, []);

  // useEffect(() => {
  //   try {
  //     handleGetOrdersSuccess(MockData);
  //   } catch (e) {
  //     console.log('error no effect: ', e);
  //   }
  // }, [handleGetOrdersSuccess]);

  const pageView = useCallback(() => {
    HandlePageView({
      query: parsed,
      cookies,
      name: 'ACOM:NossasLojas:Minhascompras',
      isMobile,
    });
  }, [cookies, isMobile, parsed]);

  useEffect(() => {
    pageView();
  }, []);

  useEffect(() => {
    const isObj = typeof parsed.breadcrumb === 'object';

    if (parsed.breadcrumbType === 'home') return;
    if (parsed.breadcrumb && parsed.breadcrumbLink) {
      const obj = {
        label: isObj ? String(parsed.breadcrumb[0]) : String(parsed.breadcrumb),
        link: String(parsed.breadcrumbLink),
      };

      // console.log('obj: ', obj);
      setlastBreadcrumbs(obj);
    }
  }, []);

  useEffect(() => {
    setOrderBreadcrumbs({
      label: 'minhas compras nas lojas',
      link: `${location.pathname}${location.search}`,
    });
  }, [location]);

  const handleRemoveYearFilter = useCallback(
    (year: string) => {
      removeYearFilter(year);
      // refsCheckboxYear.current[year].checked = false;
    },
    [removeYearFilter]
  );

  const handleRemoveMonthFilter = useCallback(
    (month: string) => {
      removeMonthFilter(month);
      // refsCheckboxMonths.current[Number(month)].checked = false;
    },
    [removeMonthFilter]
  );

  const handleDeleteAllFilters = useCallback(() => {
    // console.log('refsCheckboxMonths: ', refsCheckboxMonths);
    // console.log('refsCheckboxYear: ', refsCheckboxYear);
    // refsCheckboxMonths.current.forEach((i: any) => {
    //   console.log('i month: ', i);
    //   i.checked = false;
    // });
    // refsCheckboxYear.current.forEach((i: any) => (i.checked = false));

    cleanAllFilter();
  }, [cleanAllFilter]);

  const itemsFound = useMemo(() => {
    let items = 0;

    keysOrders.map((key: any, index: number) => {
      const filteredIsEmpty = Object.keys(filteredData).length === 0;
      const validData = filteredIsEmpty ? data : filteredData;

      if (!validData[key]?.length) return;
      validData[key].forEach((i: any) => {
        items += 1;
      });
    });

    return items;
  }, [keysOrders, data, filteredData]);

  const dataEmpty = useMemo(() => {
    const result = typeof data === 'object' && data === null;
    console.log('result: ', result);
    return !!result;
  }, [data]);

  return (
    <>
      <ModalHelp
        visible={visibleModal}
        closeFunction={handleCloseModalHelp}
        defaultTab={defaultTab}
        onSelect={handleChangeTab}
      />

      <Header />

      <Container>
        {loading ? (
          <LoaderBox>
            <Loader src={LoadingIcon} />
          </LoaderBox>
        ) : isMobile ? (
          <>
            <WrapperHeader
              hasFilter={!!yearsFilter?.length || !!monthsFilter?.length}
            >
              <Breadcrumb
                items={[lastBreadcrumbs, orderBreadcrumbs]}
                containerStyle={{ marginBottom: 17 }}
              />
              <BoxAlignmentTitle>
                <TitlePage>minhas compras nas lojas</TitlePage>

                <FilterBox
                  hasFilter={!!yearsFilter?.length || !!monthsFilter?.length}
                >
                  <FilterIcon
                    onClick={() => refOrderMobile?.current?.openFilter()}
                  />
                  {(!!yearsFilter?.length || !!monthsFilter?.length) && (
                    <span>{yearsFilter?.length + monthsFilter?.length}</span>
                  )}
                </FilterBox>
              </BoxAlignmentTitle>

              {(!!yearsFilter?.length || !!monthsFilter?.length) && (
                <>
                  <p>
                    {itemsFound}{' '}
                    {Number(itemsFound) === 1
                      ? 'compra encontrada'
                      : 'compras encontradas'}
                  </p>

                  <BoxActionsFilter>
                    {yearsFilter.map((i: string) => (
                      <FilterItem
                        key={i}
                        onClick={() => handleRemoveYearFilter(i)}
                      >
                        <span>{i}</span>
                        <RiCloseLine color="#666" size={18} />
                      </FilterItem>
                    ))}

                    {monthsFilter.map((i: string) => (
                      <FilterItem
                        key={i}
                        onClick={() => handleRemoveMonthFilter(i)}
                      >
                        <span>
                          {String(
                            format(
                              new Date(
                                `${2022}-${String(Number(i)).padStart(
                                  2,
                                  '0'
                                )}-${10}`
                              ),
                              'MMMM',
                              {
                                locale: ptBR,
                              }
                            )
                          ).toLowerCase()}
                        </span>
                        <RiCloseLine color="#666" size={18} />
                      </FilterItem>
                    ))}

                    <FilterItem filter onClick={handleDeleteAllFilters}>
                      <h2>limpar todos</h2>
                    </FilterItem>
                  </BoxActionsFilter>
                </>
              )}
            </WrapperHeader>

            <OrderMobile
              data={data}
              handleDetails={handleDetails}
              error={error}
              ref={refOrderMobile}
              keysOrders={keysOrders}
              yearsOrders={YearsOrders}
              monthsOrders={MonthsOrders}
              monthsData={MonthsData}
              filteredData={filteredData}
            />
          </>
        ) : (
          <OrderWeb
            data={data}
            error={error}
            keysOrders={keysOrders}
            yearsOrders={YearsOrders}
            monthsOrders={MonthsOrders}
            monthsData={MonthsData}
            filteredData={filteredData}
          />
        )}
        {/* {console.log('data:', data)}
        <Footer
          containerStyle={{
            bottom: 0,
            position: dataEmpty ? 'relative' : 'absolute',
          }}
          handleMenu1={() => handleChangeTab(0)}
          handleMenu2={() => handleChangeTab(1)}
          handleMenu3={() => handleChangeTab(2)}
        /> */}
      </Container>
    </>
  );
};

export default Home;
