// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useRef, useCallback, useEffect } from 'react';
import queryString from 'query-string';
import Config from '../../Utils/Config';
import { useLocation } from 'react-router-dom';
import AmericanasPin from '../../Assets/PIN.svg';
import useSupercluster from 'use-supercluster';
import Data from '../../Assets/lojas_mapahome.json';
import { useHistory } from 'react-router-dom';
import './Map.css';
import { Mapa, MapBox, Pin, Alinhador } from './styles';
import { getApiListHome } from '../../Utils/ListHome';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import useWindowDimensions from '../../Utils/size';
import GoogleMapReact from 'google-map-react';

interface listProps {
  Loja: string;
  Nome: string;
  Longitude: string;
  Latitude: string;
}

const GoogleMapsHome = () => {
  const [Data, setData] = useState<any>([]);
  const mapRef = useRef<any>();
  const [bounds, setBounds] = useState<any>(null);
  const [zoom, setZoom] = useState<any>(10);
  const history = useHistory();
  const Marker = ({ children }: any) => children;
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const isMobile = useWindowDimensions();

  useEffect(() => {
    getApiListHome()
      .then((e) => setData(e))
      .catch((e) => console.log('erro ao carregar listaHome: ', e));
  }, []);

  const points = Data.map((store: listProps) => ({
    type: 'Feature',
    properties: { cluster: false, crimeId: store.Loja },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(store.Longitude), parseFloat(store.Latitude)],
    },
    dados: store,
  }));

  const handleClick = useCallback(
    (pin: any) => {
      const { dados } = pin;

      const { Latitude, Longitude, Loja } = dados;

      const current = location.search;

      // history.push({
      //   pathname: '/list',
      //   search: current,
      // });
      let uri =
        current && current !== '?'
          ? `/list${current}&lat=${Latitude}&lng=${Longitude}&store=${Loja}&pag=1`
          : `/list?lat=${Latitude}&lng=${Longitude}&store=${Loja}&pag=1&byPin=${true}`;

      uri = uri + `&chave=${Config.key}`;

      if (parsed.newUser) {
        uri = uri + `&byPin=${true}`;
      }

      HandleEvents({
        isMobile,
        eventAction: 'home',
        eventCategory: 'nossaslojas',
        eventLabel: 'mapapin',
        eventValue: undefined,
        cookies: {},
      });

      history.push(uri);
    },
    [history, location, parsed.newUser, isMobile]
  );

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 200, maxZoom: 20 },
  });

  return (
    <MapBox width="1280px" height="600px" style={{ marginTop: -54 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: Config.key_google_maps }}
        defaultCenter={{ lat: -10.3333333, lng: -53.2 }}
        defaultZoom={5}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {
          mapRef.current = map;
        }}
        options={{ disableDefaultUI: true, gestureHandling: 'greedy' }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ]);
        }}
      >
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } =
            cluster.properties;

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div className="border3">
                  <div className="border2">
                    <div className="border1">
                      <div
                        className="cluster-marker"
                        // style={{
                        //   width: `${10 + (pointCount / points.length) * 20}px`,
                        //   height: `${10 + (pointCount / points.length) * 20}px`,
                        // }}
                        onClick={() => {
                          const expansionZoom = Math.min(
                            supercluster.getClusterExpansionZoom(cluster.id),
                            20
                          );

                          mapRef.current?.setZoom(expansionZoom);

                          mapRef.current?.panTo({
                            lat: latitude,
                            lng: longitude,
                          });
                        }}
                      >
                        {pointCount}
                      </div>
                    </div>
                  </div>
                </div>
              </Marker>
            );
          }

          return (
            <Marker
              key={`crime-${cluster.properties.crimeId}`}
              lat={latitude}
              lng={longitude}
            >
              <Alinhador>
                <button
                  className="crime-marker"
                  onClick={() => handleClick(cluster)}
                >
                  <Pin src={AmericanasPin} />
                </button>
              </Alinhador>
            </Marker>
          );
        })}
      </GoogleMapReact>
    </MapBox>
  );
};

export default GoogleMapsHome;
