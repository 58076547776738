export default (dayWeek: string) => {
  let x;

  switch (dayWeek) {
    case 'monday':
      x = { day: 1, name: 'segunda-feira' };
      break;
    case 'tuesday':
      x = { day: 2, name: 'terça-feira' };
      break;
    case 'wednesday':
      x = { day: 3, name: 'quarta-feira' };
      break;
    case 'thursday':
      x = { day: 4, name: 'quinta-feira' };
      break;
    case 'friday':
      x = { day: 5, name: 'sexta-feira' };
      break;
    case 'saturday':
      x = { day: 6, name: 'sábado' };
      break;
    case 'sunday':
      x = { day: 0, name: 'domingo' };
      break;

    default:
      x = { day: 1, name: 'segunda-feira' };
      break;
  }

  return x;
};
