import styled from 'styled-components';

interface ContainerProps {
  isMobile: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  height: ${(props) => (props.isMobile ? 'auto' : '80px')};

  display: flex;
  align-items: center;

  span {
    opacity: 1;
    visibility: visible;
  }

  a {
    color: #666666;
  }

  &:hover {
    z-index: 100000;
  }

  /* &:hover span {
    opacity: 1;
    visibility: visible;
  } */

  /* .popover__content:hover {
    opacity: 1;
    visibility: visible;
    background: blue;
    display: block;
  } */
`;

interface PropsContent {
  bottomCalc: string;
  mNegativeLeft: string;
  mLeft: string;
}

export const Content = styled.span<PropsContent>`
  width: 206px;
  background: #ffffff;
  padding: 20px 21px;

  font-size: 14px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.5s;
  visibility: hidden;
  box-shadow: 0px 0px 5px #0000001a;

  position: absolute;
  bottom: calc(${(props) => props.bottomCalc} + 12px);
  left: ${(props) => props.mNegativeLeft};
  transform: translateX(-50%);
  color: #666666;
  font-family: Helvetica Neue;
  text-align: center;

  &::before {
    content: '';
    border-style: solid;
    border-color: #ffffff;
    border-width: 11px 11px 0 11px;
    bottom: 100%;
    position: absolute;
    left: ${(props) => props.mLeft};
    transform: translateX(-50%);
  }
`;
