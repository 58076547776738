import React, { FC } from 'react';

import { Container, Content } from './styles';
import useWindowDimensions from '../../Utils/size';
import { loadProps, LoadFunctionProps } from '../../Pages/List';
import SearchInput from '../SearchInput';

interface BuscaProps {
  defaultValueInput?: any;
  onClickHandle?: (data: LoadFunctionProps) => void;
  cep?: string;
  cbFunction?: () => void;
  fromHome?: boolean;
}

const Busca: FC<BuscaProps> = ({
  defaultValueInput = '',
  onClickHandle,
  cep,
  cbFunction,
  fromHome,
}) => {
  const isMobile = useWindowDimensions();

  return (
    // <Container isMobile={isMobile}>
    <Content screen={window.innerWidth}>
      <SearchInput
        fromModalChange={false}
        defaultValueInput={defaultValueInput}
        onClickHandle={onClickHandle}
        cep={cep}
        cbFunction={cbFunction}
        fromHome={fromHome}
      />
    </Content>
    // </Container>
  );
};

export default Busca;
