import React, { FC, ReactNode } from 'react';
import BuscaBox from '../../../../Components/BuscaBox';
import useWindowDimensions from '../../../../Utils/size';
import handleClickCardStore from '../../../../Utils/ClickCardStore';
import {
  ButtonsBox,
  Loader,
  BoxHeader,
  BoxEmptyMobile,
  Title,
  Warning,
  Stores,
  MoreButton,
} from '../../styles';
import LoadingIcon from '../../../../Assets/loading.svg';
import PlaceholderStores from '../../../../Components/PlaceholderStores';
import { ReactComponent as IconWarning } from '../../../../Assets/info.svg';
import WhatsTooltip from '../../../../Components/WhatsTooltip';
import CardStoreV2 from '../../../../Components/CardStoreV2';
import { WppProps } from '../../../../Utils/ListWpp';

interface Props {
  loadingFirst: boolean;
  activeButton: 'mapa' | 'lista';
  myList: any[];
  makeFilter: () => ReactNode;
  parsed: any;
  dadosLocal: any[];
  dados: any[];
  makeCardStore: (a: any, b: any, c?: any) => ReactNode;
  existsCovid: boolean;
  totalElements: number;
  makeWppButton: ReactNode;
  tooltipWhatsView: boolean;
  listWpp: WppProps;
  changeState: (data: boolean) => void;
  loading: boolean;
  hasMore: boolean;
  TypeList: boolean;
  isPar: boolean;
  currentLegions: any;
  storeSelected: any;
  selectedStore: any;
  makeAddress: (data: any) => string;
  handleDetails: (a: any, b: any, c?: any) => void;
  cookies: any;
  handleBuy: (data?: any, b?: any) => void;
  totalPag: number;
  load: (data?: any) => Promise<any>;
  currentPag: number;
  filter: 'all' | 'normal' | 'local';
}

const ListMode: FC<Props> = ({
  loadingFirst,
  activeButton,
  myList,
  makeFilter,
  parsed,
  dadosLocal,
  dados,
  makeCardStore,
  existsCovid,
  totalElements,
  makeWppButton,
  tooltipWhatsView,
  listWpp,
  changeState,
  loading,
  hasMore,
  TypeList,
  isPar,
  currentLegions,
  storeSelected,
  selectedStore,
  makeAddress,
  handleDetails,
  cookies,
  handleBuy,
  totalPag,
  load,
  currentPag,
  filter,
}) => {
  const isMobile = useWindowDimensions();

  return (
    <>
      {loadingFirst && activeButton === 'lista' ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          <Loader src={LoadingIcon} />
        </div>
      ) : (
        <>
          {!!myList.length && activeButton === 'lista' && (
            <BoxHeader isMobile={isMobile}>{makeFilter()}</BoxHeader>
          )}

          {!myList.length && activeButton === 'lista' && !loadingFirst && (
            <>
              <BoxEmptyMobile>
                <PlaceholderStores local={!!parsed.local} isMobile={isMobile} />

                {parsed.local && !dadosLocal.length && (
                  <div
                    style={{
                      marginTop: 24,
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Title
                        style={{
                          marginBottom: 14,
                          marginLeft: 14,
                          height: 'auto',
                        }}
                        size="14px"
                      >{`Mas aproveite tem ${
                        dados.length > 2 ? 2 : dados.length
                      } Americanas perto de você`}</Title>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {dados.map((i: any, index: any) => {
                        if (index < 2) {
                          return makeCardStore(i, index, {
                            marginBottom: 14,
                          });
                        }
                      })}
                    </div>
                  </div>
                )}
              </BoxEmptyMobile>
            </>
          )}

          {activeButton === 'lista' && !!myList.length && !loadingFirst && (
            <>
              {existsCovid && (
                <Warning isMobile={isMobile}>
                  <IconWarning />
                  <span>
                    Temporariamente, algumas lojas encontram-se fechadas para a
                    visita. Aproveite e compre online!
                  </span>
                </Warning>
              )}

              <BoxHeader
                isMobile={isMobile}
                style={{ flexDirection: 'row', alignItems: 'center' }}
              >
                <Title isMobile={isMobile}>
                  {totalElements} Americanas perto de você
                </Title>
                {makeWppButton}
              </BoxHeader>
              {tooltipWhatsView && (
                <WhatsTooltip
                  sizeFakeBox="187px"
                  cbFunction={changeState}
                  listWpp={listWpp}
                />
              )}

              <Stores isMap={TypeList} className="on-scrollbar" isPar={isPar}>
                {myList.map((i: any, index: any) => (
                  <CardStoreV2
                    currentLegions={currentLegions}
                    variant="NEW"
                    isMap={activeButton}
                    key={i.id}
                    title={i.name}
                    address={makeAddress(i)}
                    distance={i.distance}
                    isPar={isPar}
                    index={index}
                    schedules={i.schedules}
                    sellerStoreId={i.sellerStoreId}
                    canPickUpInStore={i.canPickUpInStore}
                    canShipFromStore={i.canShipFromStore}
                    selected={
                      i.id === storeSelected ||
                      i.sellerStoreId === selectedStore ||
                      i.sellerStoreId === `L${selectedStore}`
                    }
                    onClickCard={() =>
                      handleDetails(i.sellerStoreId, i, 'storecard')
                    }
                    onClickProducts={() => handleDetails(i.sellerStoreId, i)}
                    onClickWpp={() =>
                      handleClickCardStore({
                        item: i,
                        type: 'whatsapp',
                        wppFromWeb: listWpp,
                        isMobile,
                        cookies,
                      })
                    }
                    item={i}
                    onClickBuy={() => handleBuy(i.sellerStoreId, i)}
                  />
                ))}

                {totalPag > 1 && (
                  <>
                    {!loading && hasMore && (
                      <MoreButton
                        onClick={() =>
                          load({
                            append: true,
                            pag: currentPag + 1,
                            lat: Number(parsed.lat),
                            lng: Number(parsed.lng),
                            feather: String(parsed.feather),
                            footer: String(parsed.footer),
                            chave: String(parsed.chave),
                            epar: String(parsed.epar),
                            name: String(parsed.name),
                            local: filter === 'local',
                            all: filter === 'all',
                            clickedPag: currentPag + 1,
                            // newPag: true,
                            // list: comparativeList,
                            // pagAppendLocal:
                            //   filter === 'local' ? pagAppend : undefined,
                          })
                        }
                      >
                        <span>carregar mais</span>
                      </MoreButton>
                    )}
                    {loading && <Loader src={LoadingIcon} />}
                  </>
                )}
              </Stores>
            </>
          )}
        </>
      )}
    </>
  );
};

export { ListMode };
