import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  background-color: #f1f1f1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin-top: 27px;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 29px;
`;

export const FilterBox = styled.div`
  h1 {
    color: #333;
    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 20px;
    line-height: 24px !important;
    font-weight: bold;
  }

  h2 {
    color: #f80032;
    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-size: 14px;
    line-height: 16px !important;
    margin-top: 6px;
  }
`;

export const Info = styled.div``;

export const List = styled.ul`
  list-style: none;
  background-color: #fff;

  border-radius: 0 0 8px 8px;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.05);
  margin-top: 0px;
`;

export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 17px 19px;
  border-top: solid 1px #e8e8e8;

  span {
    color: #666;
    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-size: 14px;
    line-height: 16px !important;
  }

  .container-label {
    display: block;
    position: relative;
    padding-left: 20px;
    font-size: 12px;
    color: #424242;
    font-weight: 400;
  }

  .container-label input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 5px;
    left: 4px;
    height: 18px;
    width: 18px;
    border-radius: 6px;
    background-color: white;
    border: 2px solid #999;
    border-radius: 6px;
  }
  .container-label input:checked ~ .checkmark {
    background-color: #f80032;
    border: none;
  }

  .checkmark::after {
    display: none;
    content: '';
    position: absolute;
  }

  .container-label .checkmark::after {
    content: '';
    left: 6px;
    top: 2px;
    width: 4px;
    height: 7px;
    transform: rotate(45deg);
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
  }

  .container-label input:checked ~ .checkmark::after {
    display: block;
  }
`;

interface BottomContentProps {
  disabled: boolean;
}

export const BottomContent = styled.div<BottomContentProps>`
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 85px;
  border-radius: 16px 16px 0px 0px;
  background-color: #fafafa;
  padding: 20px 25px;

  button {
    background-color: transparent;
    border: 0px;
    padding: 12px;
    border-radius: 8px;
    background-color: #f80032;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 16px;
    line-height: 20px !important;
    font-weight: bold;

    ${({ disabled }) =>
      disabled &&
      css`
        background-color: #e8e8e8;
        color: #cccccc;
      `}
  }
`;
