import styled from 'styled-components';

interface ContainerProps {
  bg: string;
}

export const Container = styled.div<ContainerProps>`
  flex: 1;
  background-color: ${({ bg }) => bg};
`;
