import styled, { css } from 'styled-components';

interface ContainerProps {
  isMobile: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;

  height: 100%;

  /* max-width: 375px; */
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  align-items: center;
  justify-content: flex-start;

  opacity: 1;

  /* ${(props) =>
    !props.isMobile &&
    css`
      max-width: 781px;
      position: absolute;
      top: 21px;
      z-index: 999;
      left: 24px;
      border: 0px;
    `} */

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0px 0px !important;
    `}
`;

export const Title = styled.span`
  font-size: 20px;
  font-family: 'Helvetica Neue, Bold';

  line-height: 24px;
  color: #333;
`;

interface TableProps {
  isMobile?: boolean;
}
export const Table = styled.ul<TableProps>`
  list-style: none;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  width: 100%;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      max-width: 335px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0 20px !important;
    `}
`;

export const Item = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Day = styled.span`
  font-family: 'Helvetica Neue, Bold';
  font-size: 14px;
  color: #666666;
`;

export const Bar = styled.div`
  display: flex;
  flex: 1;
  border-bottom: 1.3px dashed #ccc;
  margin: 0px 5px;
`;

export const Hours = styled.span`
  font-family: Helvetica Neue;
  font-size: 14px;
  color: #666666;
`;

interface HoursTableProps {
  isMobile?: boolean;
  empty?: boolean;
}

export const HoursTable = styled.div<HoursTableProps>`
  margin-left: 32px;

  height: 227px;
  width: 100%;
  max-width: 372px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-left: 0px !important;
      max-width: 100%;
      margin-top: 20px;
      padding: 0 20px;
    `}

  ${({ empty }) =>
    empty &&
    css`
      justify-content: flex-start;
      align-items: center;
    `}
`;

export const BoxHoursAccordion = styled.div`
  width: 100%;
  padding: 27px 17px 32px 17px;
  background: #fff;
  box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 16px 16px;
  margin-top: -4px;
  z-index: 10;
  position: relative;
`;

export const BoxHours = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 44px;
`;

export const DaysBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Arrow = styled.img`
  width: 18px;
  height: 12px;
  margin-top: -2px;
  cursor: pointer;
`;

export const Graphic = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: -61px;
`;

interface HourTileProps {
  size: string;
  isMobile: boolean;
  active: boolean;
}

export const HourTile = styled.div<HourTileProps>`
  background: ${({ active, theme }) =>
    active ? theme.colors.redPrimary : theme.colors.pinkPrimary};
  width: 21px;
  height: ${(props) => 100 * Number(`0.${props.size}`)}px;
  max-height: 100px;
  border-radius: 5px 5px 0px 0px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: Calc(${window.innerWidth} * 0.05px) !important;
    `}
`;

interface HeaderProps {
  isMobile: boolean;
}
export const Header = styled.div<HeaderProps>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const BoxTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1px;
`;

interface HourTextProps {
  active: boolean;
}
export const HourText = styled.div<HourTextProps>`
  font-size: 12px;
  font-family: 'Helvetica Neue, Bold';
  color: #666666;
  margin-top: 14px;

  ${({ active }) =>
    !active &&
    css`
      opacity: 0;
    `}
`;

interface BoxDescriptionProps {
  customMargin?: string | undefined;
  opacity: boolean;
}

export const BoxDescription = styled.div<BoxDescriptionProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #b2b2b2;
  border-radius: 5px;
  width: max-content;
  inline-size: max-content;
  padding: 11px;
  position: relative;
  z-index: 9;
  background: #ffffff;
  margin-top: 24px;

  opacity: ${(props) => (props.opacity ? 1 : 0)};
  /* margin-bottom: 140px !important; */
`;

export const Description = styled.span`
  color: #707070;
  font-family: Helvetica Neue;
  font-weight: 200;
  font-size: 14px;
`;

interface DotsProps {
  size?: string;
}
export const Dots = styled.div<DotsProps>`
  height: ${(props) => props.size || '50%'};
  width: 2px;
  border-left: dashed 1px #666666;
`;

export const CurrentHourBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  justify-content: flex-end;
  position: relative;
`;

export const LoaderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
`;

interface LoaderProps {
  size?: string;
  bottom?: string | null;
}

export const Loader = styled.img<LoaderProps>`
  width: ${(props) => props.size || '80px'};
  height: ${(props) => props.size || '80px'};
  margin: auto;
`;

export const EmptyLabel = styled.span`
  font-size: 14px;
  font-family: 'Helvetica Neue, Bold';
  color: #666666;
  margin-top: 70px;
  text-align: center;
  line-height: 18px;
`;

interface BoxLayerProps {
  isMobile: boolean;
  open?: boolean;
}
export const BoxLayer = styled.div<BoxLayerProps>`
  margin-top: ${({ isMobile }) => (isMobile ? '-1px' : '4px')};

  position: relative;

  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background-color: ${(props) => (props.open ? '#eff9ea' : '#F7E6E8')}; */

  h2 {
    color: ${(props) => (props.open ? '#008A00' : '#F40033')} !important;
    font-family: 'Helvetica Neue, Bold' !important;
    font: -webkit-control;
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  strong {
    color: #333333 !important;

    font-weight: 400 !important;

    ::before {
      content: '•';
      font-size: 12px;
      color: #666666;
      margin: 0 10px;
    }

    ::after {
      content: '' !important;
    }
  }
`;
