import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.span`
  color: #666666;
  margin: 20px 0px;
  font-family: Helvetica Neue;
  font-size: 16px;
`;

export const Data = styled.div`
  background-color: #fff;
  padding: 6px 11px;

  /* width: 120px;
  height: 20px; */
  box-shadow: 0px 0px 20px #00000026;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 19px;

  span {
    color: #666;
    font-size: 12px;
    font-family: 'Helvetica Neue, Regular';
    margin: 0;
    padding: 0;
    /* margin-top: 3px; */
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface CardProps {
  empty?: boolean;
}

export const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 20px #00000026;
  width: 100%;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }

  ${({ empty }) =>
    empty &&
    css`
      justify-content: space-between;
    `}

  h3 {
    text-align: center;
    font-size: 14px;
    color: #666666;
    font-family: 'Helvetica Neue';
  }
`;

export const TitleOffer = styled.span`
  font-size: 14px;
  font-family: 'Helvetica Neue, Bold';
  color: #333333;
  text-transform: capitalize;
  line-height: 16px;
`;

export const AddressOffer = styled.span`
  font-size: 12px;
  font-family: 'Helvetica Neue';
  color: #666666;

  text-transform: capitalize;
  line-height: 14px;
`;

export const Alignment = styled.div`
  width: 100%;
  padding: 0 24px;
  margin-bottom: 20px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 21px;

  svg path {
    fill: #4e4e4e;
  }

  svg {
    width: 13px;
    margin-right: 6px;
    transform: rotate(-90deg);
    margin-left: 10px;
  }
`;

export const Timestamp = styled.span`
  font-size: 14px;
  color: #666666;
  font-family: 'Helvetica Neue';
`;

export const Total = styled.span`
  font-size: 14px;
  color: #333;
  font-family: 'Helvetica Neue';
`;

export const Barrinha = styled.div`
  width: 90%;
  height: 1px;
  background: #d8d8d8;
  margin: 16px 0px 13px 0px;
`;

export const BarrinhaBottom = styled.div`
  height: 1px;
  background: #70707012;
  margin: 16px;
`;

export const ContentHeader = styled.div`
  display: flex;
  border-radius: 16px 16px 0 0;
  background: #fafafa;
  padding: 13px 12px;
`;

interface PhotoStoreProps {
  isMobile: boolean;
  local?: boolean;
}

export const PhotoStore = styled.div<PhotoStoreProps>`
  min-width: 71px;
  width: ${(props) => (props.isMobile ? '71px' : '150px')};
  height: ${(props) => (props.isMobile ? '71px' : '120px')};
  border: 1px solid #0000000f;
  background-color: ${({ theme }) => theme.colors.redPrimary};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ local }) => (local ? '27px' : '0px')};

  margin-right: 12px;

  img {
    width: ${(props) => (props.isMobile ? '62px' : '107px')};
  }

  > svg path {
    fill: #fff;
  }

  > svg {
    fill: #fff;
    /* width: ${({ isMobile }) => (isMobile ? '65px' : '115px')}; */
    height: auto;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0px;
    `}
`;

export const HeaderCard = styled.div`
  width: 100%;

  .truncate-overflow {
    /* max-width: 252px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;

export const Products = styled.div`
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;

  .truncate-overflow {
    /* max-width: 252px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .placeholder-icon-empty {
    width: 45px;
    height: 45px;
    transform: rotate(0deg) !important;
  }

  svg {
    margin-right: 10px;
  }
`;

interface ProductImgProps {
  marginRight: string;
}

export const ProductImg = styled.div<ProductImgProps>`
  width: 45px;
  height: 45px;
  border-radius: 9px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.marginRight};
  position: relative;

  img {
    width: 100%;
  }
`;
export const Description = styled.span`
  font-size: 14px;
  color: #666666;
  font-family: 'Helvetica Neue';
  font-weight: 200;
`;

interface AlignmentDescriptionProps {
  size: string;
  unique: boolean;
}

export const AlignmentDescription = styled.div<AlignmentDescriptionProps>`
  /* flex: 1; */
  width: 100%;
  max-width: ${(props) => props.size};

  ${({ unique }) =>
    unique &&
    css`
      max-width: 100%;
      flex: 1;
    `}
`;

export const FooterCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;

  > div {
    display: flex;
    align-items: flex-end;
  }

  span {
    color: #666666;
    font-family: 'Helvetica Neue';
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  b {
    color: #333333;
    font-family: 'Helvetica Neue, Bold';
    font-size: 14px;
  }

  h1 {
    color: #333333;
    font-family: 'Helvetica Neue, Bold';
    font-size: 14px;
    margin: 0;
    padding: 0;
    margin-bottom: 3px;
    margin-left: 4px;
  }

  h2 {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: 'Helvetica Neue';
    font-size: 14px;
    margin: 0;
    padding: 0;
    margin-top: -4px;
  }
`;

export const HeadBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  svg {
    width: 13px;
    margin-right: 6px;
    transform: rotate(90deg);
  }

  svg path {
    fill: #4e4e4e;
  }
`;

export const Badge = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.redPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8px;
  right: -8px;

  span {
    color: white;
    font-size: 12px;
    font-family: Helvetica Neue;
  }
`;

export const LoaderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 158px;
`;

interface LoaderProps {
  size?: string;
  bottom?: string | null;
}

export const Loader = styled.img<LoaderProps>`
  width: ${(props) => props.size || '80px'};
  height: ${(props) => props.size || '80px'};
  margin: auto;
`;

export const BoxNfe = styled.div`
  width: 100%;
  height: 147px;
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: #666666;
    font-size: 16px;
    margin: 0;
    font-family: Helvetica Neue, Regular;
    text-align: center;
    line-height: 21px;
    font-weight: 100;
  }

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: Helvetica Neue, Regular;
    font-size: 14px;
  }
`;

export const AlignmentNfe = styled.div`
  position: absolute;
  z-index: 99;
  margin-top: 272px;
  padding: 0 5px;
  width: 100%;
`;

export const ActionsNfe = styled.div`
  display: flex;
  margin-top: 22px;
  align-items: center;
`;

export const MoreButton = styled.div`
  border: solid 2px #e0030e;
  border-radius: 6px;
  width: 109px;
  height: 37px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;

  span {
    font-family: Helvetica Neue, Regular;
    color: #df030e;
    font-size: 14px;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 6px;
  right: 10px;
`;

export const AlignTime = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
`;

export const TimeDay = styled.span`
  color: #333;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Helvetica Neue, Bold';
`;

export const TimeMonth = styled.span`
  color: #333;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Helvetica Neue, Bold';
  text-transform: capitalize;
`;

export const BoxDot = styled.div`
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  .dot {
    height: 3px;
    width: 3px;
    background-color: #c4c4c4;
    border-radius: 50%;
    display: inline-block;
  }
`;

export const InfoFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start !important;

  h2 {
    color: #008000;
    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 14px;
    line-height: 16px !important;
    margin: 0px !important;
    padding: 0px !important;
    margin-bottom: 4px !important;
    font-weight: bold;
  }

  span {
    color: #333;
    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-size: 14px;
    line-height: 16px !important;
    margin: 0px !important;
    padding: 0px !important;
    margin-bottom: 4px !important;
  }

  h1 {
    color: #333333;
    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 14px;
    line-height: 16px !important;
    margin: 0px !important;
    padding: 0px !important;
    margin-bottom: 4px !important;
    font-weight: bold;
  }
`;
