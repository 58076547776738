/* eslint-disable no-useless-escape */
interface Props {
  name: string;
  replace?: boolean;
}

const NormalizeBreadCrumb = ({ name, replace }: Props): string | void => {
  if (!name) return;

  if (replace) {
    const newName = name.replace(/\-/g, ' ');
    return newName.toLocaleLowerCase();
  } else {
    return name.toLocaleLowerCase();
  }
};

export { NormalizeBreadCrumb };
