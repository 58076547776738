import { createGlobalStyle } from 'styled-components';
export default createGlobalStyle`
    * {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing:border-box;
    }

		button {
				cursor: pointer;
		}

    body {
      max-width: 100%;
      overflow-x: hidden;
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: #F1F1F1;

    }

    #root {
      width: 100%;
      background-color: #F1F1F1;
    }
    /* @font-face {
    font-family: 'teste';
    src: url('/Applications/MAMP/htdocs/yourdev/projetos/b2w/materiais/HelveticaNeue.ttc');
  } */

    /* @font-face {
      font-family: 'Helvetica Neue, Bold';
      src: local('Helvetica Neue, Bold'), url(../Fonts/HelveticaBold.otf) format('otf');
    } */


`;
