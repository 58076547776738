import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  height: 100vh;
`;

export const Barrinha = styled.div`
  width: 38px;
  height: 4px;
  background: #aaaaaa;
  border-radius: 30px;
  margin: auto;
  margin-top: 10px;
`;

export const Box = styled.div`
  width: 100%;
  background: #ffffff;
  min-height: 148px;
  position: fixed;
  bottom: 0px;
  border-radius: 10px 10px 0 0;
  z-index: 99999999999;

  span {
    color: #666666;
    font-size: 14px;
    font-family: Helvetica Neue;
    font-weight: bold;
    margin-left: 22px;
    display: block;
    margin-top: 25px;
    margin-bottom: 8px;
  }
`;
