import styled from 'styled-components';

export const Container = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  a {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
