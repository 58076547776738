import React, { FC } from 'react';

import { Container, Content } from './styles';
import useWindowDimensions from '../../Utils/size';
import { loadProps, LoadFunctionProps } from '../../Pages/List';
import SearchInput from '../SearchInput_old';

interface BuscaProps {
  defaultValueInput?: any;
  onClickHandle?: (data: LoadFunctionProps) => void;
  cep?: string;
  cbFunction?: () => void;
}

const Busca: FC<BuscaProps> = ({
  defaultValueInput = '',
  onClickHandle,
  cep,
  cbFunction,
}) => {
  const isMobile = useWindowDimensions();

  return (
    <Container isMobile={isMobile}>
      <Content screen={window.innerWidth}>
        <SearchInput
          defaultValueInput={defaultValueInput}
          onClickHandle={onClickHandle}
          cep={cep}
          cbFunction={cbFunction}
        />
      </Content>
    </Container>
  );
};

export default Busca;
