import React, {
  FC,
  useState,
  useCallback,
  useRef,
  useEffect,
  forwardRef,
  RefForwardingComponent,
} from 'react';
import Input from '../Input_old';
import { Container, Box, Header, ButtonClose } from './styles';
import useWindowDimensions from '../../Utils/size';
import { loadProps, LoadFunctionProps } from '../../Pages/List';
import { CatalogProps, getCatalog } from '../../Utils/GetCatalog';
import Encarte from '../../Pages/Details/Encarte';
import { RiCloseLine } from 'react-icons/ri';
import { useTheme } from 'styled-components';
import { MdClose } from 'react-icons/md';

interface Props {
  defaultValueInput?: any;
  visible: boolean;
  onClose: () => void;
  onClickHandle?: (data: LoadFunctionProps) => void;
  cbFunction?: () => void;
  store: any;
}

const ModalEncarte: FC<Props> = ({
  cbFunction,
  onClickHandle,
  defaultValueInput = '',
  visible = false,
  onClose,
  store,
}) => {
  const [catalog, setCatalog] = useState<CatalogProps | undefined>(
    {} as CatalogProps
  );
  const [activeInput, setActiveInput] = useState(false);
  const [isError, setIsError] = useState('');
  const isMobile = useWindowDimensions();
  const { colors } = useTheme();

  useEffect(() => {
    const load = async () => {
      const catalogLink = await getCatalog();
      setCatalog(catalogLink?.catalog ? catalogLink : undefined);
    };

    load();
  }, []);

  return (
    <>
      {visible && (
        <>
          <Container onClick={onClose} />
          <Box>
            <Header>
              <span>folheto de ofertas</span>
              <ButtonClose onClick={onClose}>
                <MdClose size={20} color={colors.whitePrimary} />
              </ButtonClose>
            </Header>
            {catalog && (
              <Encarte store={store} isMobile={isMobile} catalog={catalog} />
            )}
          </Box>
        </>
      )}
    </>
  );
};

export { ModalEncarte };
