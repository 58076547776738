import { experiment, SaltType } from 'planout-ts';
import { HandleTestAB } from './AnalyticsGA';
import store from '../Pages/Details/store';

interface Props {
  experimentId: string;
  project?: string;
  salt: SaltType;
  sellerStoreId: string;
  storeName?: string;
  variantSetted?: string;
  cookies: any;
  isMobile: boolean;
  store: any;
}

interface PropsTestAB {
  experimentId: string;
  project?: string;
  salt: SaltType;
  variantSetted?: string;
  cookies: any;
  isMobile: boolean;
  oldWeight?: number;
  newWeight?: number;
  controlWeight?: number;
}

interface AnalyticsProps {
  body?: any;
  project?: any;
  experimentId?: any;
  variant: string;
  sellerStoreId?: string;
  storeName?: string;
}

const eventHandled = (e: any) => {
  console.log('disparou o evento: ', e);
};

const cleanEvent = (e: any) => {
  console.log('removeu o evento: ', e);
};

const SendToAnalytics = ({
  body,
  project,
  experimentId,
  variant,
  sellerStoreId,
  storeName,
}: AnalyticsProps): void => {
  const nameEvent = 'experiment:exposure';

  const DefaultBody: any = {
    detail: {
      experiment: {
        project: project || 'nossaslojas',
        experimentId: experimentId || 'LOJA_TEST',
        variant: variant.toUpperCase(),
      },
    },
  };

  if (sellerStoreId) {
    DefaultBody['store'] = {
      name: storeName,
      id: sellerStoreId,
    };
  }

  const experimentEvent = new CustomEvent(nameEvent, body || DefaultBody);

  window.addEventListener(nameEvent, eventHandled);
  document.addEventListener(nameEvent, eventHandled);

  window.dispatchEvent(experimentEvent);
  document.dispatchEvent(experimentEvent);

  window.removeEventListener(nameEvent, cleanEvent);
  document.removeEventListener(nameEvent, cleanEvent);
};

export const MyExperiment = ({
  salt,
  project,
  experimentId,
  sellerStoreId,
  storeName,
  variantSetted,
  cookies,
  isMobile,
  store,
}: Props): string | undefined => {
  if (!sellerStoreId) return;

  const Ex = experiment(experimentId);

  const Variant =
    variantSetted ||
    Ex.weightedChoice(['OLD', 'NEW', 'CONTROL'], [45, 45, 10], salt);

  SendToAnalytics({
    project: project || 'nossaslojas',
    experimentId,
    variant: Variant,
    sellerStoreId,
    storeName,
  });

  HandleTestAB({
    isMobile,
    experimentId,
    variant: Variant,
    cookies,
    store,
  });

  return Variant;
};

export const TestAB = ({
  salt,
  project,
  experimentId,
  variantSetted,
  cookies,
  isMobile,
  newWeight,
  oldWeight,
  controlWeight,
}: PropsTestAB): string => {
  const Ex = experiment(experimentId);

  const Variant =
    variantSetted ||
    Ex.weightedChoice(
      ['OLD', 'NEW', 'CONTROL'],
      [oldWeight || 45, newWeight || 45, controlWeight || 10],
      salt
    );

  SendToAnalytics({
    project: project || 'nossaslojas',
    experimentId,
    variant: Variant,
  });

  HandleTestAB({
    isMobile,
    experimentId,
    variant: Variant,
    cookies,
  });
  console.log(`chamou teste ab da ${experimentId}`);
  return Variant;
};
