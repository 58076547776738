import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  height: 100vh;

  justify-content: center;

  .tabela-faq {
    span {
      color: #707070;
      margin-bottom: 20px;
      line-height: 2;
    }

    div {
      margin-bottom: 20px;
    }
  }

  .table-attendent {
    span {
      color: #707070;
      line-height: 1.2;
    }

    div {
      margin-bottom: 4px;
    }
  }

  .table-polititions {
    span {
      color: #707070;
      line-height: 2;
    }

    div {
      margin-bottom: 4px;
    }
  }
`;

interface ContentProps {
  isMobile?: boolean;
}

export const Content = styled.div<ContentProps>`
  max-width: 1000px;
  width: 100%;
  background: #efefef;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 204px);
  margin-top: 102px;
  margin-bottom: 102px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 0px;
      height: inherit;
    `}

  .menu-item-wrapper {
    margin-left: -35px !important;
  }

  .react-tabs__tab {
    font-weight: bold;
    font-size: 14px;
  }

  .react-tabs__tab--selected {
    color: ${({ theme }) => theme.colors.redPrimary} !important;
  }

  .on-scrollbar {
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  .on-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }

  .on-scrollbar::-webkit-scrollbar {
    width: 6px !important;
    background-color: transparent;
  }

  .on-scrollbar::-webkit-scrollbar-thumb {
    background-color: #acacac;
  }

  .table {
    background: #ffffff !important;
    border-radius: 0 0 8px 8px;
    /* height: ${(props) => (props.isMobile ? '100%' : 'auto')}; */
  }

  /* #react-tabs-1 {
    border-radius: 0 0 8px 8px;
  } */

  .tabela {
    border-radius: 0 0 8px 8px;
  }

  .icone {
    margin-left: auto;
  }

  .react-tabs__tab-list {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 0px;

    li {
      color: #707070;
      font-family: Helvetica Neue;
      font-size: 14px;
      border-radius: 0px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .react-tabs__tab-panel {
    background: #ffffff;
    padding: ${(props) => (props.isMobile ? '22px 10px' : '72px 100px')};

    border-radius: 0 0 8px 8px;
  }

  h2 {
    font-family: Helvetica Neue;
    font-size: 18px;
    color: #707070;
    font-weight: bold;
  }

  button {
    width: 141px;
    height: 45px;
    background: #e20014;
    color: #ffffff;
    border: 0px;
    font-family: Helvetica Neue;
    font-size: 18px;
    font-weight: bold;
    margin-top: 18px;
    margin-bottom: 35px;
  }
`;

interface AlinhadorProps {
  isMobile?: boolean;
}

export const Alinhador = styled.div<AlinhadorProps>`
  background: #ffffff;
  padding: ${(props) => (props.isMobile ? '22px 10px' : '72px 100px')};
  height: ${(props) => (props.isMobile ? '100%' : 'auto')};
  border-radius: 0 0 8px 8px;
`;

interface TabelaProps {
  isMobile?: boolean;
}
export const Tabela = styled.div<TabelaProps>`
  background: white;
  overflow: scroll;
  height: calc(100vh - 204px - 85px - 57px - 144px);

  overflow-x: hidden;
  padding: 0 30px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      height: calc(100vh - 85px - 57px - 44px);
    `}

  font-family: Helvetica Neue;
  font: -webkit-control;

  span {
    color: #707070;
    margin-bottom: 20px;
  }

  div {
    margin-bottom: 15px;
  }
`;

export const Header = styled.div`
  margin: 30px;
  display: flex;

  svg {
    cursor: pointer;
  }
`;

interface TextProps {
  size?: string;
  weight?: string | number;
  color?: string;
  top?: string;
  bottom?: string;
  align?: string;
  bold?: boolean;
}
export const Text = styled.span<TextProps>`
  font-family: Helvetica Neue;
  font-size: ${(props) => props.size || '18px'};
  color: ${(props) => props.color || '#707070'};
  font-weight: ${(props) => props.weight || 'bold'};
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  display: block;
  text-align: ${(props) => props.align || 'left'};

  ${({ bold }) =>
    bold &&
    css`
      font-family: 'Helvetica Neue, Bold';
    `}
`;

export const Lista = styled.ul`
  margin-top: 20px;

  li {
    margin-bottom: 10px;
  }
`;
