import styled from 'styled-components';

interface Props {
  bottomCalc: string;
  mNegativeLeft: string;
  mLeft: string;
}

export const Container = styled.div<Props>`
  a {
    text-decoration: none;
  }

  /* .popover__title {
    font-size: 24px;
    line-height: 36px;
    text-decoration: none;
    color: rgb(228, 68, 68);
    text-align: center;
    padding: 15px 0;
  } */

  .popover__wrapper {
    position: relative;
    margin-top: 1.5rem;
    display: inline-block;
  }
  .popover__content {
    width: 206px;
    background: #ffffff;
    padding: 20px 21px;

    font-size: 14px;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.4s;
    visibility: visible;
    box-shadow: 0px 0px 5px #0000001a;

    position: absolute;
    bottom: calc(${(props) => props.bottomCalc} + 12px);
    left: ${(props) => props.mNegativeLeft};
    transform: translateX(-50%);
    color: #666666;
    font-family: Helvetica Neue;
    text-align: center;

    &::before {
      content: '';
      border-style: solid;
      border-color: #ffffff;
      border-width: 11px 11px 0 11px;
      bottom: 100%;
      position: absolute;
      left: ${(props) => props.mLeft};
      transform: translateX(-50%);
    }
  }
  /* .popover__content:before {
    content: '';
    border-style: solid;
    border-color: #ffffff;
    border-width: 11px 11px 0 11px;
    bottom: 100%;
    position: absolute;
    left: ${(props) => props.mLeft};
    transform: translateX(-50%);
  } */
  .popover__wrapper:hover .popover__content {
    z-index: 10;
    opacity: 1;
    visibility: visible;
    /* transform: translate(0, -20px); */
    transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
  }
  .popover__message {
    text-align: center;
  }
`;
