import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1280px;
`;

export const HeaderMap = styled.div`
  width: 100%;
  max-width: 1280px;
  height: 438px;
  border-radius: 20px;
  margin-top: -120px;
  position: absolute;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 44px !important;
  margin-bottom: 49px;
`;

interface FakeBgProps {
  withTags: boolean;
}
export const FakeBg = styled.div<FakeBgProps>`
  width: 100%;
  height: ${({ withTags }) => (withTags ? '438px' : '438px')};
  background: linear-gradient(
    2.05deg,
    #ffffff 4.05%,
    rgba(255, 255, 255, 0.99304) 36.86%,
    rgba(255, 255, 255, 0.949889) 57.74%,
    rgba(255, 255, 255, 0.880276) 69.02%,
    rgba(255, 255, 255, 0.698205) 76.92%,
    rgba(255, 255, 255, 0) 110.22%
  );
  position: absolute;
  top: 0;
  left: 0;
  z-index: 150;
  border-radius: 16px;
`;

interface LogoStoreProps {
  isMobile: boolean;
  local?: boolean;
}

export const LogoStore = styled.div<LogoStoreProps>`
  width: 111px;
  height: 111px;
  border: 1px solid #0000000f;
  background-color: ${({ theme }) => theme.colors.redPrimary};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ local }) => (local ? '27px' : '0px')};
  z-index: 999999;
  margin-bottom: 10px;

  img {
    width: ${(props) => (props.isMobile ? '62px' : '107px')};
  }

  > svg path {
    fill: #fff;
  }

  > svg {
    fill: #fff;
    /* width: ${({ isMobile }) => (isMobile ? '65px' : '37px')}; */
    height: 111px;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 8px;
    `}

  ${({ isMobile, local }) =>
    local &&
    !isMobile &&
    css`
      padding: 0px !important;

      svg {
        width: 150px;
        height: 50px !important;
      }
    `}
`;

export const Content = styled.div`
  display: flex;
  min-height: 318px;
  padding: 0px 44px 36px 44px;
`;
export const LeftInfo = styled.div`
  z-index: 99999;
  width: 33%;
`;
export const MiddleInfo = styled.div`
  width: 33%;
  z-index: 99999;
`;
export const RightInfo = styled.div`
  width: 33%;
  z-index: 99999;
`;

interface StoreButtonProps {
  isMobile?: boolean;
  isMapView?: boolean;
  width?: string;
}

export const StoreButton = styled.div<StoreButtonProps>`
  cursor: pointer;
  /* width: ${({ isMobile }) => (isMobile ? '98px' : '186px')};
  height: ${({ isMobile }) => (isMobile ? '26px' : '39px')}; */
  width: ${({ width }) => width || 'auto'} !important;
  height: 37px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.redPrimary};

  padding: 0px 16px;
  z-index: 9999999;
  position: relative;

  background-color: #fff;
  border-color: #e60115 !important;
  border-radius: 54px;

  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.05));

  span {
    margin: 0px;
    padding: 0px;
    font: -webkit-control;

    margin-left: ${({ isMobile }) => (isMobile ? '6px' : '10.5px')};
    font-size: 14px;
    line-height: 13.8px;
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.redPrimary} !important;
  }

  img {
    width: auto;
    height: 13px;
  }
`;

export const RightActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 428px;
`;
