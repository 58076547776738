import listLocal from '../Services/data/local.json';
import api from '../Services/api';

interface Store {
  Loja: string | number;
}

export interface LocalProps {
  Local_lojas: Store[];
}

interface Props {
  sellerStoreId: string | number;
  wppFromWeb: LocalProps;
}

export const getApiListLocal = async () => {
  const response = await api.get(
    `https://${document.location.host}/static/json/ids_local.json`
  );

  const list: LocalProps = response.data;

  return list;
};

const ListLocal = async ({
  sellerStoreId,
  wppFromWeb,
}: Props): Promise<boolean> => {
  const customList =
    process.env.NODE_ENV === 'development' ? listLocal : wppFromWeb;

  const exists = customList.Local_lojas.find(
    (i: Store) => i.Loja === sellerStoreId
  );

  return !!exists;
};

export default ListLocal;
