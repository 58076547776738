import styled, { css } from 'styled-components';

interface ContainerProps {
  isMobile: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  flex-direction: column;
  /* margin-top: 10px; */

  .owl-prev {
    border: 0 !important;
    width: 82px !important;
    height: 82px !important;
    background: #ffffffcc 0% 0% no-repeat padding-box !important;
    border-radius: 50% !important;
    color: #ef1324 !important;
  }

  .owl-next {
    border: 0 !important;
    width: 82px !important;
    height: 82px !important;
    background: #ffffffcc 0% 0% no-repeat padding-box !important;
    border-radius: 50% !important;
    color: #ef1324 !important;
  }

  .disabled {
    opacity: 0 !important;
  }

  .owl-nav {
    margin-top: -470px;
    position: relative;
    z-index: 999999999;
    width: 100%;
    max-width: 1280px;
    margin-left: calc((100vw - 1280px) / 2);
    display: flex;
    justify-content: space-between;
  }

  .react-pdf__Page {
    height: 100%;
  }

  .react-pdf__Page__annotations {
    height: 100%;
  }

  .annotationLayer {
    height: 100%;
  }

  .linkAnnotation {
    height: 100% !important;
  }

  .active {
    border: 0px !important;
  }
`;

export const Banner = styled.img`
  /* margin-top: 10px; */
  max-width: 90%;
  cursor: pointer;
`;

export const Catalog = styled.img`
  /* margin-top: 8px; */
  max-width: 100%;
  margin-top: -38px;
`;

export const CatalogBox = styled.div`
  width: 100%;
  /* transition: margin-bottom 0.5s; */
`;

interface ShareBoxProps {
  active: boolean;
}
export const ShareBox = styled.button<ShareBoxProps>`
  border: 0;

  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  /* background: ${(props) => (props.active ? 'red' : 'white')}; */
  background: white;
  margin-top: 26px;
  box-shadow: 0px 0px 5px #0000001a;

  .share {
    width: 25px;
    height: 25px;

    /* ${(props) =>
      props.active &&
      css`
        filter: invert(100%);
      `} */

    :hover {
      filter: invert(100%);
    }
  }

  :hover {
    background: red;
  }
`;

export const Alinhador = styled.div`
  width: 100%;
  height: 50px;
  position: -webkit-sticky;
  position: sticky;

  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

export const Title = styled.span`
  font-size: 18;
  color: #4e4e4e;
  font-family: 'Helvetica Neue, Bold';
  margin: 12px 0px 9px 0px;
`;

export const CustomArrow = styled.button`
  border: 0 !important;
  width: 82px !important;
  height: 82px !important;
  background: #ffffffcc 0% 0% no-repeat padding-box !important;
`;

export const Arrow = styled.span`
  width: 26px !important;
  height: 42px !important;
  background: #ef1324 !important;
  font-size: 40px !important;
`;

export const HeaderCatalog = styled.div`
  height: 42.4px;
  width: 100%;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: fixed;
  z-index: 9999999999;
  top: 120px;

  svg {
    width: 13px;
    margin-right: 6px;
    transform: rotate(90deg);
  }

  svg path {
    fill: #4e4e4e;
  }

  span {
    font-family: 'Helvetica Neue, Bold';
    font-size: 14px;
    color: #4e4e4e;
    margin-top: 7px;
  }
`;
