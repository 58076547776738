/* eslint-disable */
import React, { FC, useRef, useCallback, useState, useMemo } from 'react';
import { Container, Title, SubTitle } from './styles';
import useWindowDimensions from '../../Utils/size';
import { loadProps, LoadFunctionProps } from '../../Pages/List';
import Input from '../Input_old';
import { HandleEvents } from '../../Utils/AnalyticsGA';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  PropTypes,
} from 'react-places-autocomplete';

interface Props {
  defaultValueInput?: any;
  onClickHandle?: (data: LoadFunctionProps) => void;
  cep?: string;
  cbFunction?: () => void;
  fromModalChange?: boolean;
  fromHome?: boolean;
}

let intervalType: any = 0;

const SearchInput: FC<Props> = ({
  defaultValueInput = '',
  onClickHandle,
  cep,
  cbFunction,
  fromModalChange,
  fromHome,
}) => {
  const [activeInput, setActiveInput] = useState(false);
  const [isError, setIsError] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const isMobile = useWindowDimensions();

  const handleFinishType = useCallback(
    (e: string) => {
      // console.log('e: ', e);
      // HandleEvents({
      //   isMobile,
      //   eventAction: 'home',
      //   eventCategory: 'nossaslojas',
      //   eventLabel: 'busca',
      //   eventValue: undefined,
      //   cookies: {},
      // });
      // console.log('');
    },
    [isMobile]
  );

  const getSize = useMemo(() => {
    if (window.innerWidth <= 320) {
      return 300;
    }

    if (isMobile) {
      return 320;
    } else {
      return '100%';
    }
  }, [isMobile]);

  const handleSearch = useCallback((e: string) => {
    if (e && e.length >= 3) {
      clearTimeout(intervalType);

      intervalType = setTimeout(() => {
        handleFinishType(e);
      }, 1000);
      setActiveInput(true);

      if (e.length > 6) {
        setActiveInput(false);
        setIsError('CEP inválido, tente novamente');
      }
    } else {
      setActiveInput(false);
      setIsError('');
    }
  }, []);

  const InputRef = useRef<PropTypes>(null);

  return (
    <Container>
      {isMobile ? (
        <>
          <Title style={{ fontSize: 14 }} isMobile={isMobile}>
            encontre uma Americanas perto de você
          </Title>
        </>
      ) : (
        <Title isMobile={isMobile} fromModalChange={fromModalChange}>
          encontre uma Americanas perto de você
        </Title>
      )}

      <SubTitle
        style={{ fontSize: isMobile ? 14 : 18, marginTop: isMobile ? 0 : 12 }}
        isMobile={isMobile}
        fromModalChange={fromModalChange}
      >
        São mais de 1.627 lojas em todo o Brasil :)
      </SubTitle>
      {/*
  // @ts-ignore */}
      <Input
        fromHome={fromHome}
        fromModalChange={fromModalChange}
        onClickHandle={onClickHandle}
        styleContainer={{
          marginTop: '16px',
          border: 'solid 1px #CCCCCC',
          width: getSize,
          margin: fromModalChange ? 'auto' : 0,
          maxWidth: fromModalChange ? 405 : '100%',
        }}
        isActive={activeInput}
        handleOnChange={handleSearch}
        isError={isError}
        isMobile={isMobile}
        defaultValue={defaultValueInput}
        cep={cep}
        cbFunction={cbFunction}
      />
    </Container>
  );
};

export default SearchInput;
