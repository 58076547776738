import styled from 'styled-components';

interface ContentProps {
  isMobile: boolean;
}
export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;

  justify-content: center;
  padding-top: ${(props) => (props.isMobile ? '25px' : '40px')};
  margin: auto;
  position: relative;
  z-index: 99999;
  margin-top: -423px;

  .sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 90px;
    z-index: 90 !important;
  }
`;

interface BarrinhaProps {
  isMobile: boolean;
}

export const Barrinha = styled.div<BarrinhaProps>`
  width: 100%;
  height: 1px;
  background: #bababa;
  margin-top: ${(props) => (props.isMobile ? '20px' : '40px')};
`;

interface WrapperProps {
  isMobile?: boolean;
}
export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  background-color: ${({ isMobile }) => (isMobile ? 'transparent' : '#fff')};
  height: 455px;
`;
