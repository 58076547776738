import React, { FC, useState } from 'react';
import {
  Container,
  Content,
  Header,
  Text,
  Tabela,
  Alinhador,
  Lista,
  AlignBox,
} from './styles';
import { RiCloseLine } from 'react-icons/ri';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import useWindowDimensions from '../../Utils/size';
import SearchInput from '../SearchInput_old';
import { loadProps, LoadFunctionProps } from '../../Pages/List';

interface Props {
  visible: boolean;
  closeFunction?: () => void;
  defaultTab?: number;
  onSelect?: (value: number) => void;

  defaultValueInput?: any;
  onClickHandle?: (data: LoadFunctionProps) => void;
  cep?: string;
  cbFunction?: () => void;
}

const ModalAddressWeb: FC<Props> = ({
  visible = false,
  closeFunction,
  defaultTab = 0,
  onSelect,
  defaultValueInput = '',
  onClickHandle,
  cep,
  cbFunction,
}) => {
  const isMobile = useWindowDimensions();
  return (
    <>
      {visible && (
        <>
          <Container onClick={closeFunction} />

          <Content isMobile={isMobile}>
            <Header>
              <RiCloseLine
                size={39}
                color="#707070"
                className="icone"
                onClick={closeFunction}
              />
            </Header>
            <SearchInput
              fromModalChange
              defaultValueInput={defaultValueInput}
              onClickHandle={onClickHandle}
              cep={cep}
              cbFunction={cbFunction}
            />
          </Content>
        </>
      )}
    </>
  );
};

export default ModalAddressWeb;
