import styled from 'styled-components';

interface ContainerProps {
  visible: boolean;
  isMobile?: boolean;
}

export const Container = styled.div<ContainerProps>`
  /* display: ${(props) => (props.visible ? 'block' : 'none')}; */
  display:block;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? '1' : '0')};
  position: fixed; /* Stay in place */
  z-index: ${(props) => (props.isMobile ? '999' : '11')};
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
  -webkit-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;

`;
