import React, { FC } from 'react';
import { Container } from './styles';

interface Props {
  onClick: () => void;
  isMobile: boolean;
  dados?: boolean;
  styleContainer?: any;
}

const ViewStoreButton: FC<Props> = ({
  isMobile,
  onClick,
  dados,
  styleContainer,
}) => {
  return (
    <Container
      isMobile={isMobile}
      onClick={onClick}
      dados={dados}
      style={styleContainer}
    >
      <span>ver lojas nessa área</span>
    </Container>
  );
};

export default ViewStoreButton;
