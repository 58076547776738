import React, { FC } from 'react';

import { Container, Box, Info, Action, ContentBox } from './styles';

import useWindowDimensions from '../../Utils/size';
import { ReactComponent as PinIcon } from '../../Assets/icon-map-reference.svg';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

interface BuscaProps {
  openAction: () => void;
}

const Busca: FC<BuscaProps> = ({ openAction }) => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const isMobile = useWindowDimensions();

  return (
    <Container isMobile={isMobile}>
      <Box isMobile={isMobile}>
        <ContentBox isMobile={isMobile}>
          {!isMobile && <PinIcon width={28} height={37} />}

          <Info isMobile={isMobile}>
            <h2>você está vendo lojas perto de:</h2>
            <h3 className="truncate-overflow">{parsed.name}</h3>
          </Info>

          <Action isMobile={isMobile}>
            <span onClick={() => openAction()}>
              {isMobile ? 'trocar' : 'trocar endereço'}
            </span>
          </Action>
        </ContentBox>
      </Box>
    </Container>
  );
};

export default Busca;
