import listFb from '../Services/data/fb.json';
import api from '../Services/api';

interface Store {
  SellerStoreId: string;
  FacebookId: string;
}

export interface FbProps {
  Planilha1: Store[];
}

interface Props {
  sellerStoreId: string | number;
  listFromWeb: FbProps;
}

export const getApiListFb = async () => {
  const response = await api.get(
    `https://${document.location.host}/static/json/idloja_idfacebook.json`
  );
  const list: FbProps = response.data;

  return list;
};

const ListFb = async ({
  sellerStoreId,
  listFromWeb,
}: Props): Promise<Store | undefined> => {
  const customList =
    process.env.NODE_ENV === 'development' ? listFb : listFromWeb;
  const exists = customList.Planilha1.find(
    (i: Store) => i.SellerStoreId === sellerStoreId
  );
  return exists;
};

export default ListFb;
