import React, { FC, useMemo, useCallback, useEffect, useState } from 'react';
import Config from '../../Utils/Config';
import {
  Container,
  PhotoStore,
  Info,
  Title,
  Address,
  List,
  Item,
  BackButton,
  Seta,
  Actions,
  WhatsButton,
  Content,
  BuyButton,
  WrapperButton,
  FolderButton,
  ButtonOtherStores,
  WithoutServiceAlert,
} from './styles';
import { ReactComponent as BasketIcon } from '../../Assets/ICONE_CESTA.svg';
import { ReactComponent as WhatsImg } from '../../Assets/WHATS_ICONE.svg';
import { ReactComponent as LocalIcon } from '../../Assets/local_branco.svg';
import { ReactComponent as LogoBranco } from '../../Assets/logo_branco.svg';
import { ReactComponent as Aamericanas } from '../../Assets/a-americanas.svg';
import { ReactComponent as InfoIcon } from '../../Assets/info.svg';

import SetaAlterar from '../../Assets/alterar-loja.png';
import AmericanasLogo from '../../Assets/logo_menor.png';
import useWindowDimensions from '../../Utils/size';
import getDay from '../../Utils/getday';
import { StoreProps } from '../../Pages/Details/Sobre/';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import { ReactComponent as ArrowRed } from '../../Assets/arrow_change_red.svg';
import { ReactComponent as BuyTag } from '../../Assets/tag_red.svg';

import CovidList from '../../Services/data/covid.json';
import { LojasWppProps } from '../../Services/interfaces/lojas_wpp';
// const listWpp =
//   process.env.NODE_ENV === 'development'
//     ? require(`../../Services/lojasWpp.json`).Lojas_ativas_whatsapp
//     : require(`${window.location.hostname}/static/json/lojas_wpp.json`)
//         .Lojas_ativas_whatsapp;
import ListWpp, { getApiListWpp, WppProps } from '../../Utils/ListWpp';
import ListCovid, { CovidProps, getApiListCovid } from '../../Utils/Covid';
import HandleAnalyticsEvents from '../../Utils/HandleAnalyticsEvents';
import ListLocal, { LocalProps, getApiListLocal } from '../../Utils/Local';
import { HandleEvents } from '../../Utils/AnalyticsGA';

interface Props {
  store: StoreProps;
  distance: number;
  onClickWpp?: (item: any) => void;
  canPickUpInStore?: boolean;
  canShipFromStore?: boolean;
  tmpPath?: string;
  onClickFolder: () => Promise<void>;
  showCatalog: boolean;
}

const CardDetail: FC<Props> = ({
  showCatalog,
  store,
  distance,
  onClickWpp,
  canPickUpInStore,
  canShipFromStore,
  tmpPath,
  onClickFolder,
}) => {
  const [validatePNLAtag, setValidatePNLAtag] = useState(false);
  const [validateSFStag, setValidateSFStag] = useState(false);
  const [listLocal, setListLocal] = useState<LocalProps>({} as LocalProps);
  const [localStore, setLocalStore] = useState(false);
  const [listCovid, setListCovid] = useState<CovidProps>({} as CovidProps);
  const [listWpp, setListWpp] = useState<WppProps>({} as WppProps);
  const isMobile = useWindowDimensions();
  const location = useLocation();
  const [manyKm, setManyKm] = useState(false);
  const [covidStore, setCovidStore] = useState(false);
  const [wppStore, setWppStore] = useState(false);
  const parsed = queryString.parse(location.search);
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);

  const day = useMemo(() => {
    if (!store.schedules) return;
    const d = getDay(new Date());

    if (d) {
      const hours = store.schedules[d];

      if (hours.start && hours.end) {
        const [hourStart, minutesStart] = hours.start.split(':');
        const [hourEnd, minutesEnd] = hours.end.split(':');

        const minutesS = minutesStart === '00' ? '' : `:${minutesStart}`;
        const minutesE = minutesEnd === '00' ? '' : `:${minutesEnd}`;
        return `${hourStart}${minutesS}h às ${hourEnd}${minutesE}h`;
      } else {
        return null;
      }
    }
  }, [store]);

  const handleBack = useCallback(() => {
    const {
      lat,
      lng,
      name,
      pag,
      feather,
      footer,
      chave,
      epar,
      path,
      local,
      withoutLocal,
    } = parsed;

    const uri = lat
      ? `?lat=${typeof lat === 'object' ? lat[0] : lat}&lng=${
          lng && typeof lng === 'object' ? lng[0] : lng
        }&name=${
          name && typeof name === 'object' ? name[0] : name
        }&pag=${pag}&feather=${feather}&footer=${footer}&chave=${Config.key}`
      : `?lat=${store.geolocation.lat}&lng=${store.geolocation.lng}&name=${
          store.name
        }&pag=${1}&feather=${feather}&footer=${footer}&epar=${epar}&local=${local}&withoutLocal=${withoutLocal}`;

    history.push({
      pathname: `/${tmpPath || path}`,
      search: uri,
    });
  }, [history, parsed, store, tmpPath]);

  const distanceParsed = useMemo(() => {
    let d = '0 m';

    if (distance) {
      d = distance > 999 ? `${(distance / 1000).toFixed(1)}km` : `${distance}m`;
    }

    if (distance > 6) {
      setManyKm(true);
    }

    return d;
  }, [distance]);

  useEffect(() => {
    ListCovid({ sellerStoreId: store.sellerStoreId, wppFromWeb: listCovid })
      .then((e: boolean) => setCovidStore(e))
      .catch((e: any) => console.log('erro listaCovid: ', e));
  }, [listCovid, store]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListCovid()
        .then((e) => setListCovid(e))
        .catch((e) => console.log('erro ao caregar listaCovid: ', e));
    }
  }, []);

  useEffect(() => {
    ListWpp({ sellerStoreId: store.sellerStoreId, wppFromWeb: listWpp })
      .then((e: boolean) => setWppStore(e))
      .catch((e: any) => console.log('erro2: ', e));
  }, [listWpp, store]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListWpp()
        .then((e) => setListWpp(e))
        .catch((e) => console.log('erro ao caregar listaWpp: ', e));
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListLocal()
        .then((e) => setListLocal(e))
        .catch((e) => console.log('erro ao carregar listaLocal: ', e));
    }
  }, []);

  useEffect(() => {
    ListLocal({ sellerStoreId: store.sellerStoreId, wppFromWeb: listLocal })
      .then((e: boolean) => setLocalStore(e))
      .catch((e: any) => console.log('erro2: ', e));
  }, [listLocal, store]);

  const onClickBuy = useCallback(() => {
    HandleEvents({
      isMobile,
      eventAction: 'storepage',
      eventCategory: 'nossaslojas',
      eventLabel: 'buy',
      eventValue: undefined,
      store,
      cookies,
    });

    const uri = `https://www.americanas.com.br/lojas-proximas/33014556000196/${store.sellerStoreId}?chave=o2o_comprar_00_0_0_nossaslojas`;

    const win = window.open(uri, '_blank');
    win?.focus();
  }, [store, cookies, isMobile]);

  useEffect(() => {
    const load = async () => {
      console.log('store: ', store);
      if (!store?.allStore) return;

      const {
        canPickUpInStore,
        canPickUpInStoreScheduled,
        distance,
        active,
        deliveryDaysPNLA,
      } = store.allStore;

      if ((!canPickUpInStore && !canPickUpInStoreScheduled) || !active) {
        setValidatePNLAtag(false);
        return;
      }

      if (distance > 35) {
        setValidatePNLAtag(false);
        return;
      }

      if (deliveryDaysPNLA !== null && deliveryDaysPNLA >= 0) {
        setValidatePNLAtag(true);
        return;
      }
    };

    load();
  }, [store]);

  const handleOtherStore = useCallback(() => {
    HandleEvents({
      isMobile,
      eventAction: 'storepage',
      eventCategory: 'nossaslojas',
      eventLabel: 'comprar em outra loja',
      eventValue: undefined,
      cookies,
    });

    const win = window.open(
      `https://www.americanas.com.br/lojas-proximas?type=americanas&chave=o2o_compraremoutraloja_00_0_0_nossaslojas`,
      '_blank'
    );
    win?.focus();
  }, [cookies, isMobile]);

  return (
    <Container isMobile={isMobile}>
      <Content>
        <PhotoStore isMobile={isMobile} local={localStore}>
          {localStore ? (
            <LocalIcon width={isMobile ? 120 : 150} />
          ) : (
            <Aamericanas width={isMobile ? 20 : 37} />
          )}
        </PhotoStore>
        <Info isMobile={isMobile}>
          {isMobile ? (
            <Title isMobile={isMobile} className="truncate-overflow">
              {store.name}
            </Title>
          ) : (
            <Title isMobile={isMobile}>{store.name}</Title>
          )}

          {isMobile ? (
            <Address isMobile={isMobile} className="truncate-overflow">
              {store.address}
            </Address>
          ) : (
            <Address isMobile={isMobile}>{store.address}</Address>
          )}

          <List>
            {!!distance && (
              <Item isMobile={isMobile} manyKm={!!(manyKm && covidStore)}>
                {distanceParsed}
              </Item>
            )}

            <Item
              color={store.open || covidStore ? '#38B62B' : '#F40033'}
              isMobile={isMobile}
              manyKm={!!(manyKm && covidStore)}
            >
              {store.open
                ? covidStore
                  ? 'aberta para pedidos online'
                  : 'aberta'
                : covidStore
                ? 'aberta para pedidos online'
                : 'fechada'}
            </Item>

            {!covidStore && (
              <Item isMobile={isMobile}>
                <span>
                  <strong>{day || ' - Horário Indisponível'}</strong>
                </span>
              </Item>
            )}
          </List>

          <BackButton isMobile={isMobile}>
            <ArrowRed
              width={17}
              height={16.5}
              onClick={handleBack}
              style={{ cursor: 'pointer' }}
            />
            {/* <Seta src={SetaAlterar} isMobile={isMobile} onClick={handleBack} style={{cursor: 'pointer'}} /> */}
            <span onClick={handleBack}>alterar loja</span>
          </BackButton>
        </Info>
      </Content>

      {!validateSFStag && !validatePNLAtag && (
        <WithoutServiceAlert>
          <InfoIcon width={20} height={20} />
          <p>A loja está com a entrega e retirada indisponível no momento.</p>
        </WithoutServiceAlert>
      )}

      <Actions
        isMobile={isMobile}
        withWpp={!wppStore}
        withTags={validateSFStag && validatePNLAtag}
      >
        {validateSFStag ||
          (validatePNLAtag && (
            <>
              {wppStore && (
                <WrapperButton onClick={onClickWpp} isMobile={isMobile}>
                  <WhatsButton
                    isMobile={isMobile}
                    withProducts={!!(canPickUpInStore || canShipFromStore)}
                  >
                    <WhatsImg />
                    <span>whatsapp</span>
                  </WhatsButton>
                </WrapperButton>
              )}

              <WrapperButton onClick={onClickBuy} isMobile={isMobile}>
                <BuyButton isMobile={isMobile}>
                  <BasketIcon />
                  <span>comprar</span>
                </BuyButton>
              </WrapperButton>
            </>
          ))}

        {!validatePNLAtag && !validateSFStag && (
          <ButtonOtherStores onClick={handleOtherStore}>
            <span>ver outras lojas</span>
          </ButtonOtherStores>
        )}

        {!isMobile && showCatalog && (
          <WrapperButton onClick={onClickFolder} isMobile={isMobile} big>
            <FolderButton isMobile={isMobile}>
              <BuyTag />
              <span>folheto de ofertas</span>
            </FolderButton>
          </WrapperButton>
        )}
      </Actions>
    </Container>
  );
};

export default CardDetail;
