/* eslint-disable react/prop-types */
import React, {
  FC,
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useImperativeHandle,
  RefForwardingComponent,
  forwardRef,
} from 'react';
import {
  parseISO,
  format,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  subDays,
  startOfDay,
} from 'date-fns';
import queryString from 'query-string';
import LoadingIcon from '../../../../../../Assets/loading.svg';
import PlayIcon from '../../../../../../Assets/icon-play.svg';
import Masonry from 'react-masonry-css';
import {
  Container,
  BoxMap,
  BoxFeed,
  TitleFeed,
  Posts,
  CardPost,
  Photo,
  Data,
  Description,
  Info,
  ButtonArrow,
  MoreButton,
  Content,
  Banner,
  Frame,
  Loader,
  LoaderBox,
  ContentFb,
  HeaderFB,
  LogoHaeder,
  HeaderInfo,
  TitleFbStore,
  LinkFb,
  HeaderInfoPost,
  FbIcon,
  FadeBox,
  PhotoImg,
  ContainerPhoto,
  ContentPhoto,
  Play,
  CustomArrow,
  Arrow,
  BoxTitleFeed,
  Nav,
  ArrowButtonRight,
  ArrowButtonLeft,
  BoxCarousel,
  BoxSocial,
  EncarteBanner,
  LeftEncarteBanner,
  RightEncarteBanner,
  EncarteLine,
  EncarteAlert,
  WrapperMobile,
  TitleSection,
  CarouselContent,
  ContentAlignment,
} from './styles';
import WithoutFb from '../../../../../../Assets/without_fb.png';
import UriFacebook from '../../../../../../Assets/facebook.json';
import { StoreProps } from '../../..';
import TableHoursV2 from '../../../../../../Components/TableHoursV2';
import BannerEncarte from '../../../../../../Assets/banner_encarte.png';
import GoogleMaps, {
  HandleMapsFunctions,
} from '../../../../../../Components/GoogleMaps';
import ArrowDown from '../../../../../../Assets/arrow-down.svg';
import FbIconSvg from '../../../../../../Assets/fb_icon.svg';
import useWindowDimensions from '../../../../../../Utils/size';
import api from '../../../../../../Services/api';
import { Link, useLocation } from 'react-router-dom';
import { endOfDay } from 'date-fns/esm';
import Config from '../../../../../../Utils/Config';
import HandleAnalyticsEvents from '../../../../../../Utils/HandleAnalyticsEvents';
import { DaysProps } from '../../../../../../Utils/HourFunction';
import { MyExperiment } from '../../../../../../Utils/PlanOut';
import FbStores, { getApiListFb, FbProps } from '../../../../../../Utils/Fb';
import { ReactComponent as ArrowImgIcon } from '../../../../../../Assets/icon-arrow.svg';
import { ReactComponent as StoreIcon } from '../../../../../../Assets/store.svg';
import TestIcon from '../../../../../../Assets/icon-small.png';
import ReactOwlCarousel from 'react-owl-carousel';
import { CovidProps } from '../../../../../../Utils/Covid';
import { WppProps } from '../../../../../../Utils/ListWpp';
import { HandleEvents } from '../../../../../../Utils/AnalyticsGA';
import { useCookies } from 'react-cookie';
import {
  BreadCrumbItemProps,
  Breadcrumb,
} from '../../../../../../Components/Breadcrumb';
import { getCatalog } from '../../../../../../Utils/GetCatalog';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { getDistance } from 'geolib';
import { Accordion } from '../../../../../../Components/Accordion';
import { ModalEncarte } from '../../../../../../Components/ModalEncarte';
import { HoursStore } from '../../../../../../Components/HoursStore';
import { CardDetailV4 } from '../../../../../../Components/CardDetailV4';
import { Wrapper } from '../../../../../../Components/Header/styles';
import { BannerEncarteWeb } from '../../../../../../Components/BannerEncarteWeb';
import { CarousselProducts } from '../../../../../../Components/CarousselProducts';

export interface ImperativePropsContentV4 {
  loading: boolean;
  moreData: boolean;
}

interface Props {
  store: StoreProps;
  hours: DaysProps;
  listCovid: CovidProps;
  listFb: FbProps;
  listWpp: WppProps;
  encarteFunction?: (data?: any) => any;
  linkBuy: (paramA: any, paramB: any) => any;
}

interface PostsProps {
  id: string;
  title?: string;
  description?: string;
  full_picture?: string;
  created_time: Date;
  link: string;
  type:
    | 'photo'
    | 'video_autoplay'
    | 'share'
    | 'album'
    | 'video'
    | 'video_direct_response_autoplay';
}

interface FbInfo {
  username: string;
  global_brand_page_name: string;
}

interface EventsProps {
  interaction: string;
}

interface BannerProps {
  photo: string;
  link?: string;
}

const SobreV4: RefForwardingComponent<ImperativePropsContentV4, Props> = (
  { store, hours, encarteFunction, linkBuy },
  ref
) => {
  const [storeBreadcrumbs, setStoreBreadcrumbs] = useState<BreadCrumbItemProps>(
    {} as BreadCrumbItemProps
  );
  const [hasDistance, setHasDistance] = useState(0);
  const [errorFb, setErrorFb] = useState(true);
  const [lastBreadcrumbs, setlastBreadcrumbs] = useState<BreadCrumbItemProps>(
    {} as BreadCrumbItemProps
  );
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const [currentBanner, setCurrentBanner] = useState(0);
  const [bannersMobile, setBannersMobile] = useState<BannerProps[]>([]);
  const [listFb, setListFb] = useState<FbProps>({} as FbProps);
  const [banners, setBanners] = useState<BannerProps[]>([]);
  const [postSelected, setPostSelected] = useState<null | number>(null);
  const isMobile = useWindowDimensions();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagingNext, setPagingNext] = useState('');
  const [moreData, setMoreData] = useState(false);
  const [posts, setPosts] = useState<PostsProps[]>([]);
  const [FbUri, setFbUri] = useState('');
  const [facebookId, setFacebookId] = useState('');
  const [fbInfo, setFbInfo] = useState<FbInfo>({} as FbInfo);

  const RefCarousel = useRef<ReactOwlCarousel>(null);
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const limit = useMemo(() => {
    return isMobile ? 6 : 9;
  }, [isMobile]);
  const lastDays = useMemo(() => {
    // sempre substrair 1 dia do número desejado. 30 dias para trás ? coloque 29.
    return 29;
  }, []);

  const MapRef = useRef<HandleMapsFunctions>(null);

  const loadFbInfo = useCallback(async (id: string) => {
    setLoading(true);
    try {
      if (id) {
        const uri = `https://graph.facebook.com/v9.0/${id}?access_token=${Config.fbToken}&fields=global_brand_page_name,%20id,%20name,%20username,%20picture`;

        const { data } = await api.get(uri);

        const { global_brand_page_name, username } = data;

        setFbInfo({ username, global_brand_page_name });
        setErrorFb(false);
      }
    } catch (e) {
      console.log('erro na api fb info: ', e);
      setErrorFb(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadFbInfo(facebookId);
  }, [facebookId, loadFbInfo]);

  useEffect(() => {
    const currentStore = UriFacebook.find(
      (i) => i.sellerStoreId === store.sellerStoreId
    );

    if (currentStore && currentStore.facebook !== 'NÃO') {
      setFbUri(currentStore.facebook);
    } else {
      setFbUri('');
    }
  }, [store]);

  const makeData = useCallback((date: Date) => {
    const now = new Date();
    const diffMinutes = differenceInMinutes(now, date);
    const diffHours = differenceInHours(now, date);
    const diffDays = differenceInDays(now, date);

    if (diffMinutes <= 60) {
      return `postado há ${diffMinutes} minutos`;
    } else if (diffHours <= 24) {
      return `postado há ${diffHours} horas`;
    } else if (diffDays <= 7) {
      return `postado há ${diffDays} ${diffDays === 1 ? 'dia' : 'dias'}`;
    } else {
      return `postado dia ${format(date, 'dd/MM/yyyy HH:mm')}`;
    }
  }, []);

  useEffect(() => {
    getApiListFb()
      .then((e) => setListFb(e))
      .catch((e) => {
        console.log('erro ao caregar listFbs: ', e);
        setErrorFb(true);
      });
  }, []);

  const loadFb = useCallback(
    async (pag: string, loadMore?: boolean) => {
      setLoading(true);

      try {
        const FbId = await FbStores({
          sellerStoreId: store.sellerStoreId,
          listFromWeb: listFb,
        });

        if (FbId && FbId.FacebookId) {
          setFacebookId(FbId.FacebookId);
          console.log('FbId.FacebookId: ', FbId.FacebookId);
          const uri =
            pag ||
            `https://graph.facebook.com/v9.0/${FbId.FacebookId}/posts?access_token=${Config.fbToken}&fields=id,created_time,message,actions,attachments,is_hidden,full_picture&status_type=added_photos,added_video&limit=${limit}`;

          const { data } = await api.get(uri);

          const { data: dados, paging } = data;

          const { next } = paging;

          const p: PostsProps[] = dados.map((i: any) => {
            const { attachments, full_picture } = i;
            const { data: dataAttach } = attachments;

            return {
              title: dataAttach[0].title,
              description: dataAttach[0].description || i.message,
              full_picture,
              id: i.id,
              created_time: parseISO(i.created_time),
              link: dataAttach[0].url,
              type: dataAttach[0].type,
            };
          });

          p.map((i: any) => {
            if (!i.description) return;

            // return (
            //   !i.description || !i.description.includes('conteúdo foi excluído')
            // );
          });

          const limitDay = subDays(new Date(), lastDays);

          const postFiltered = p.filter((i: PostsProps) => {
            const data = i.created_time;

            return (
              endOfDay(data) >= endOfDay(limitDay) &&
              (!i.description ||
                !i.description.includes('conteúdo foi excluído'))
            );
            // return endOfDay(data) >= endOfDay(limitDay);
          });

          const hasMore = postFiltered.length > 0 ? true : false;

          setPage((prev) => prev + 1);
          setPosts((prev) => [...prev, ...postFiltered]);
          setMoreData(hasMore);
          setPagingNext(next);
        }
      } catch (e) {
        console.log('erro na api fb: ', e);
      } finally {
        setLoading(false);
      }
    },
    [store, limit, lastDays, listFb]
  );

  const handleClickFb = useCallback(
    (link: string) => {
      HandleAnalyticsEvents({ store, interaction: 'storepost' });

      HandleEvents({
        isMobile,
        eventAction: 'storepost',
        eventCategory: 'nossaslojas',
        eventLabel: undefined,
        eventValue: undefined,
        store,
        cookies,
      });

      const win = window.open(link, '_blank');
      win?.focus();
    },
    [store, cookies, isMobile]
  );

  const handleVideo = useCallback((i: string) => {
    const variable = `video.*`;

    const regex = new RegExp(variable);

    const found = i.match(regex);

    if (found) {
      return (
        <ContentPhoto>
          <Play src={PlayIcon} />
        </ContentPhoto>
      );
    }
  }, []);

  useEffect(() => {
    loadFb('');
  }, [loadFb]);

  const handleChangeCarousel = useCallback(
    (action: 'prev' | 'next') => {
      // console.log('banner atual: ', currentBanner);
      // const payload = {
      //   next: currentBanner + 1,
      //   prev: currentBanner - 1,
      // };
      // console.log('new action : ', payload[action]);
      // RefCarousel.current?.next(0.2);
      RefCarousel.current?.next(0.2);
    },
    [currentBanner]
  );

  useEffect(() => {
    api
      .get(
        `https://spacey-api-v2-americanas.b2w.io/spacey-api/publication/desktop/maintop2/americanas/nossaslojas`
      )
      .then(({ data }) => {
        const bannersWeb: any = [];
        const bannersM: any = [];
        data.component.children.map((i: any, index: number) => {
          bannersWeb.push({ photo: i.large, link: i.linkUrl });
          bannersM.push({ photo: i.small, link: i.linkUrl });
          // if (index === 1) {
          //   setBanners([{ photo: i.large, link: i.linkUrl }]);
          //   setBannersMobile([{ photo: i.small, link: i.linkUrl }]);
          // }
        });

        setBanners(bannersWeb);
        setBannersMobile(bannersM);
      })
      .catch((e) => console.log('erro ao buscar do space'));
  }, []);

  const handleClickBanner = useCallback(
    (link?: string) => {
      HandleEvents({
        isMobile,
        eventAction: 'storepage',
        eventCategory: 'nossaslojas',
        eventLabel: 'banner',
        eventValue: undefined,
        cookies,
      });

      const storeLink = link?.includes(':idLoja');

      const uri = storeLink
        ? link?.replace(':idLoja', `${store.sellerStoreId}`)
        : link;

      const win = window.open(uri, '_blank');
      win?.focus();
    },
    [store, isMobile, cookies]
  );

  useEffect(() => {
    if (parsed.breadcrumb && parsed.breadcrumbLink) {
      setlastBreadcrumbs({
        label: String(parsed.breadcrumb),
        link: String(parsed.breadcrumbLink),
      });
    }
  }, [parsed.breadcrumb, parsed.breadcrumbLink]);

  useImperativeHandle(ref, () => {
    return {
      loading,
      moreData,
    };
  });

  return (
    <>
      <Container isMobile={isMobile}>
        <ContentAlignment>
          {isMobile && (
            <Content>
              <TitleSection>mais informações</TitleSection>
              <TableHoursV2 store={store} hours={hours} />

              <Accordion
                title="folheto de ofertas"
                analyticsEvent={() => {
                  HandleEvents({
                    isMobile,
                    eventAction: 'storepage',
                    eventCategory: 'nossaslojas',
                    eventLabel: 'folheto de ofertas',
                    eventValue: undefined,
                    cookies,
                  });
                }}
              >
                <EncarteBanner onClick={encarteFunction}>
                  <LeftEncarteBanner>
                    <img src={BannerEncarte} />
                    <EncarteLine />
                    <EncarteLine style={{ marginTop: 25 }} />
                  </LeftEncarteBanner>

                  <RightEncarteBanner>
                    <EncarteLine />
                    <EncarteLine style={{ marginTop: 25 }} />
                    <span>
                      eeei, vem dar uma<br></br> olhadinha também nas<br></br>{' '}
                      ofertas das nossas<br></br> lojas físicas :)
                    </span>
                    <h2>ver folheto</h2>
                  </RightEncarteBanner>
                </EncarteBanner>

                <EncarteAlert>
                  <StoreIcon width={18} height={22} />
                  <span>
                    produtos do encarte podem ser para{' '}
                    <b>
                      pegar na <br></br>loja
                    </b>{' '}
                    ou <b>receber em casa</b> ainda hoje!
                  </span>
                </EncarteAlert>
              </Accordion>

              <Accordion
                title="horário de pico"
                analyticsEvent={() => {
                  HandleEvents({
                    isMobile,
                    eventAction: 'storepage',
                    eventCategory: 'nossaslojas',
                    eventLabel: 'folheto de ofertas',
                    eventValue: undefined,
                    cookies,
                  });
                }}
              >
                <HoursStore store={store} hours={hours} />
              </Accordion>

              <TitleSection>os mais vendidos desta loja</TitleSection>

              <CarousselProducts linkBuy={linkBuy} />
            </Content>
          )}

          {isMobile ? (
            <WrapperMobile>
              <ReactOwlCarousel
                style={{ borderWidth: 0 }}
                key={`carousel_1`}
                className="owl-theme"
                nav={false}
                items={1}
                dots
              >
                {!!bannersMobile.length &&
                  bannersMobile.map((i: BannerProps, index: any) => (
                    <Banner
                      hasFb={!!FbUri}
                      key={10 * index}
                      src={i.photo}
                      onClick={() => handleClickBanner(i.link)}
                      isMobile={isMobile}
                    />
                  ))}
              </ReactOwlCarousel>
            </WrapperMobile>
          ) : (
            <CarouselContent>
              {/* <BannerEncarteWeb /> */}
              <BoxCarousel>
                <ReactOwlCarousel
                  onChanged={(e: any) => {
                    // console.log('e: ', e.item.index);
                    setCurrentBanner(e.item.index);
                  }}
                  startPosition={currentBanner}
                  ref={RefCarousel}
                  style={{
                    width: 642,
                    borderWidth: 0,
                    backgroundColor: '#e4313c',
                    borderRadius: 20,
                  }}
                  key={`carousel_2`}
                  className="owl-theme"
                  items={1}
                  dots={false}
                >
                  {!!bannersMobile.length &&
                    bannersMobile.map((i: BannerProps, index: any) => (
                      <Banner
                        hasFb={!!FbUri}
                        key={3 * index}
                        src={i.photo}
                        onClick={() => handleClickBanner(i.link)}
                        isMobile={isMobile}
                      />
                    ))}
                </ReactOwlCarousel>

                {/* {currentBanner !== 0 ? (
                  <ArrowButtonLeft
                    onClick={() => RefCarousel.current?.prev(0.2)}
                  >
                    <ArrowImgIcon />
                  </ArrowButtonLeft>
                ) : (
                  <div></div>
                )}

                {currentBanner !== banners.length - 1 ? (
                  <ArrowButtonRight
                    onClick={() => RefCarousel.current?.next(0.2)}
                  >
                    <ArrowImgIcon />
                  </ArrowButtonRight>
                ) : (
                  <div></div>
                )} */}
              </BoxCarousel>
            </CarouselContent>
          )}

          {!isMobile && <CarousselProducts linkBuy={linkBuy} />}

          {isMobile && !!posts.length && (
            <TitleSection style={{ marginTop: 34 }}>
              confira as novidades desta loja
            </TitleSection>
          )}

          {FbUri && !isMobile && !errorFb && !!posts.length && (
            <BoxTitleFeed isMobile={isMobile}>
              <TitleFeed isMobile={isMobile}>Veja as novidades</TitleFeed>
            </BoxTitleFeed>
          )}

          <BoxSocial
            isMobile={isMobile}
            error={errorFb || !FbUri || !posts.length}
          >
            {isMobile && FbUri && !errorFb && !!posts.length && (
              <HeaderFB isMobile={isMobile}>
                {facebookId && (
                  <LogoHaeder
                    isMobile={isMobile}
                    src={`https://graph.facebook.com/v9.0/${facebookId}/picture?height=110&width=110`}
                  />
                )}

                <HeaderInfo isMobile={isMobile}>
                  <TitleFbStore isMobile={isMobile}>{store.name}</TitleFbStore>

                  {fbInfo.username && (
                    <LinkFb isMobile={isMobile}>{`@${fbInfo.username}`}</LinkFb>
                  )}
                </HeaderInfo>
              </HeaderFB>
            )}

            {FbUri && !errorFb && !!posts.length && (
              <BoxFeed isMobile={isMobile}>
                <ContentFb isMobile={isMobile}>
                  {!isMobile && (
                    <HeaderFB isMobile={isMobile}>
                      {facebookId && (
                        <LogoHaeder
                          isMobile={isMobile}
                          src={`https://graph.facebook.com/v9.0/${facebookId}/picture?height=110&width=110`}
                        />
                      )}

                      <HeaderInfo isMobile={isMobile}>
                        <TitleFbStore isMobile={isMobile}>
                          {store.name}
                        </TitleFbStore>
                        {fbInfo.username && (
                          <LinkFb
                            isMobile={isMobile}
                          >{`@${fbInfo.username}`}</LinkFb>
                        )}
                      </HeaderInfo>
                    </HeaderFB>
                  )}

                  {!isMobile && (
                    <Masonry
                      style={{
                        marginTop: 17,
                        // marginLeft: -23,
                        // marginRight: 5,
                        maxHeight: isMobile ? 'auto' : 1098 * page,
                        overflow: 'hidden',
                        padding: isMobile ? 0 : 24,
                      }}
                      breakpointCols={{
                        default: 3,
                        1100: 3,
                        700: 2,
                        500: 1,
                      }}
                      className="my-masonry-grid"
                      columnClassName="my-masonry-grid_column"
                      id="masonry-box"
                    >
                      {posts.map((i: PostsProps, index: number) => (
                        <CardPost
                          whitoutImg={!!i.full_picture}
                          key={i.id}
                          isMultiplo={!!((index + 1) % 3 === 0 && index !== 0)}
                          isMobile={isMobile}
                        >
                          {i.full_picture && (
                            <ContainerPhoto
                              onClick={() => handleClickFb(i.link)}
                            >
                              <Photo
                                // onClick={() => handleClickFb(i.link)}
                                bg={i.full_picture}
                              />

                              {handleVideo(i.type)}
                            </ContainerPhoto>
                          )}

                          <Info
                            withDescription={!!i.description}
                            whitoutImg={!!i.full_picture}
                            isMobile={isMobile}
                          >
                            <HeaderInfoPost withText={!!i.description}>
                              <FbIcon src={FbIconSvg} />
                              <Data onClick={() => handleClickFb(i.link)}>
                                {makeData(i.created_time)}
                              </Data>
                            </HeaderInfoPost>

                            {i.description && (
                              <>
                                {postSelected === index ? (
                                  <Description
                                    onClick={() => handleClickFb(i.link)}
                                  >
                                    {i.description}
                                  </Description>
                                ) : (
                                  <>
                                    <Description
                                      onClick={() => handleClickFb(i.link)}
                                      className="truncate-overflow"
                                    >
                                      {i.description}
                                    </Description>
                                  </>
                                )}

                                {i.description.length > 120 && (
                                  <ButtonArrow
                                    src={ArrowDown}
                                    onClick={() =>
                                      setPostSelected(
                                        index === postSelected ? null : index
                                      )
                                    }
                                    open={postSelected === index}
                                  />
                                )}
                              </>
                            )}
                          </Info>
                        </CardPost>
                      ))}
                    </Masonry>
                  )}

                  {isMobile &&
                    posts.map((i: PostsProps, index: number) => (
                      <CardPost
                        whitoutImg={!!i.full_picture}
                        key={i.id}
                        isMultiplo={!!((index + 1) % 3 === 0 && index !== 0)}
                        isMobile={isMobile}
                      >
                        {i.full_picture && (
                          <ContainerPhoto onClick={() => handleClickFb(i.link)}>
                            <PhotoImg
                              // onClick={() => handleClickFb(i.link)}
                              src={i.full_picture}
                              isMobile={isMobile}
                            />

                            {handleVideo(i.type)}
                          </ContainerPhoto>
                        )}

                        <Info
                          withDescription={!!i.description}
                          whitoutImg={!!i.full_picture}
                          isMobile={isMobile}
                        >
                          <HeaderInfoPost withText={!!i.description}>
                            <FbIcon src={FbIconSvg} />
                            <Data onClick={() => handleClickFb(i.link)}>
                              {makeData(i.created_time)}
                            </Data>
                          </HeaderInfoPost>

                          {i.description && (
                            <>
                              {postSelected === index ? (
                                <Description
                                  onClick={() => handleClickFb(i.link)}
                                >
                                  {i.description}
                                </Description>
                              ) : (
                                <Description
                                  onClick={() => handleClickFb(i.link)}
                                  className="truncate-overflow"
                                >
                                  {i.description}
                                </Description>
                              )}

                              {i.description.length > 120 && (
                                <ButtonArrow
                                  src={ArrowDown}
                                  onClick={() =>
                                    setPostSelected(
                                      index === postSelected ? null : index
                                    )
                                  }
                                  open={postSelected === index}
                                />
                              )}
                            </>
                          )}
                        </Info>
                      </CardPost>
                    ))}
                </ContentFb>
              </BoxFeed>
            )}
          </BoxSocial>
          {loading ? (
            <LoaderBox>
              <Loader src={LoadingIcon} />
            </LoaderBox>
          ) : (
            !!moreData && (
              <MoreButton
                isMobile={isMobile}
                onClick={() => {
                  HandleAnalyticsEvents({
                    store,
                    interaction: 'storenews',
                  });
                  HandleEvents({
                    isMobile,
                    eventAction: 'storenews',
                    eventCategory: 'nossaslojas',
                    eventLabel: undefined,
                    eventValue: undefined,
                    store,
                    cookies,
                  });
                  loadFb(pagingNext, true);
                }}
              >
                <span>carregar mais posts desta loja</span>
              </MoreButton>
            )
          )}
        </ContentAlignment>
        {!isMobile && !!posts.length && <FadeBox />}
      </Container>
    </>
  );
};

export default forwardRef(SobreV4);
