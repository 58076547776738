declare const window: any;

interface Props {
  event: any;
  payload: any;
}
const DispatchHJ = ({ event, payload }: Props) => {
  window.hj(event, payload);
};

export { DispatchHJ };
