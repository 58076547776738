export interface ResponseProps {
  en: string;
  pt: string;
  numberOfWeek: number;
  shortEn: string;
  shortPt: string;
}
export default (d: number): ResponseProps => {
  let x;

  switch (d) {
    case 1:
      x = {
        en: 'monday',
        pt: 'segunda-feira',
        numberOfWeek: 1,
        shortEn: 'mon',
        shortPt: 'seg',
      };
      break;
    case 2:
      x = {
        en: 'tuesday',
        pt: 'terça-feira',
        numberOfWeek: 2,
        shortEn: 'tue',
        shortPt: 'ter',
      };
      break;
    case 3:
      x = {
        en: 'wednesday',
        pt: 'quarta-feira',
        numberOfWeek: 3,
        shortEn: 'wed',
        shortPt: 'qua',
      };
      break;
    case 4:
      x = {
        en: 'thursday',
        pt: 'quinta-feira',
        numberOfWeek: 4,
        shortEn: 'thu',
        shortPt: 'qui',
      };
      break;
    case 5:
      x = {
        en: 'friday',
        pt: 'sexta-feira',
        numberOfWeek: 5,
        shortEn: 'fri',
        shortPt: 'sex',
      };
      break;
    case 6:
      x = {
        en: 'saturday',
        pt: 'sábado',
        numberOfWeek: 6,
        shortEn: 'sat',
        shortPt: 'sab',
      };
      break;
    case 0:
      x = {
        en: 'sunday',
        pt: 'domingo',
        numberOfWeek: 0,
        shortEn: 'sun',
        shortPt: 'dom',
      };
      break;
    default:
      x = {
        en: 'monday',
        pt: 'segunda-feira',
        numberOfWeek: 1,
        shortEn: 'mon',
        shortPt: 'seg',
      };
      break;
  }

  return x;
};
