import React, { FC, useState, useRef, useCallback, useMemo } from 'react';
import {
  Container,
  Header,
  Content,
  Info,
  List,
  Item,
  BottomContent,
  FilterBox,
} from './styles';
import { GrClose } from 'react-icons/gr';
import { Accordion } from '../Accordion';
import { useFilters } from '../../Hooks/useFilters';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface Props {
  closeFunction: (data?: any) => Promise<any>;
  keysOrders: any;
  yearsOrders: any;
  monthsOrders: any;
  monthsData: any;
  filteredData: any;
}
const FilterModal: FC<Props> = ({
  closeFunction,
  keysOrders,
  yearsOrders,
  monthsOrders,
  monthsData,
  filteredData,
}) => {
  const [withFilters, setWithFilters] = useState(false);
  const [monthlyFiltersApplied, setMonthlyFiltersApplied] = useState<any[]>([]);
  const [yearlyFiltersApplied, setYearlyFiltersApplied] = useState<any[]>([]);
  const [filtersChanged, setFiltersChanged] = useState(false);

  const {
    addYearFilter,
    yearsFilter,
    removeYearFilter,
    removeMonthFilter,
    addMonthFilter,
    monthsFilter,
    cleanAllFilter,
  } = useFilters();

  const refsCheckboxYear: any = useRef<any>([]);
  const refsCheckboxMonths: any = useRef<any>([]);

  const handleApplyFilters = useCallback(() => {
    cleanAllFilter();

    yearlyFiltersApplied.forEach((i: any) => addYearFilter(i[0]));
    monthlyFiltersApplied.forEach((i: any) => addMonthFilter(i));

    closeFunction();
  }, [
    cleanAllFilter,
    addYearFilter,
    yearlyFiltersApplied,
    closeFunction,
    monthlyFiltersApplied,
    addMonthFilter,
  ]);

  const handleAddYearFilter = useCallback((e: any, i: any) => {
    const checked = e.target.checked;
    setFiltersChanged(true);

    checked
      ? setYearlyFiltersApplied((prev: any) => [...prev, i])
      : setYearlyFiltersApplied((prev: any) =>
          prev.filter((m: any) => String(i) !== String(m))
        );
  }, []);

  const filtersYear = useMemo(() => {
    return Object.entries(yearsOrders).map((i: any, key: any) => {
      const alreadyMarked = yearsFilter.includes(i[0]);
      if (alreadyMarked) setYearlyFiltersApplied((prev: any) => [...prev, i]);
      return (
        <Item key={i[0]}>
          {/* <span>{`${i[0]} (${i[1]?.length})`}</span> */}
          <span>{`${i[0]}`}</span>
          <label className="container-label">
            <input
              type="checkbox"
              onClick={(e: any) => handleAddYearFilter(e, i)}
              ref={(el) => (refsCheckboxYear.current[i[0]] = el)}
              defaultChecked={yearsFilter.includes(i[0])}
            />
            <span className="checkmark"></span>
          </label>
        </Item>
      );
    });
  }, [yearsOrders, handleAddYearFilter, yearsFilter]);

  const handleAddMonthFilter = useCallback((e: any, i: any) => {
    const checked = e.target.checked;
    setFiltersChanged(true);
    checked
      ? setMonthlyFiltersApplied((prev: any) => [...prev, i])
      : setMonthlyFiltersApplied((prev: any) =>
          prev.filter((m: any) => String(i) !== String(m))
        );
  }, []);

  const filtersMonth = useMemo(() => {
    refsCheckboxMonths.current = [];
    return monthsOrders.map((key: any, index: number) => {
      const makeDate = `${2022}-${String(Number(key)).padStart(2, '0')}-${10}`;

      const month = String(
        format(new Date(makeDate), 'MMMM', {
          locale: ptBR,
        })
      ).toLowerCase();

      const alreadyMarked = monthsFilter.includes(key);
      if (alreadyMarked)
        setMonthlyFiltersApplied((prev: any) => [...prev, key]);

      return (
        <Item key={key}>
          {/* <span>{`${month} (${monthsData[key]?.length})`}</span> */}
          <span>{`${month}`}</span>
          <label className="container-label">
            <input
              type="checkbox"
              onClick={(e) => handleAddMonthFilter(e, key)}
              ref={(el) => (refsCheckboxMonths.current[Number(key)] = el)}
              defaultChecked={monthsFilter.includes(key)}
            />
            <span className="checkmark"></span>
          </label>
        </Item>
      );
    });
  }, [monthsOrders, monthsData, handleAddMonthFilter, monthsFilter]);

  const handleDeleteAllFilters = useCallback(() => {
    refsCheckboxMonths.current.forEach((i: any) => {
      console.log('i month: ', i);
      if (i) {
        i.checked = false;
      }
    });
    refsCheckboxYear.current.forEach((i: any) => {
      console.log('i year: ', i);
      if (i) {
        i.checked = false;
      }
    });
    setFiltersChanged(true);
    setMonthlyFiltersApplied([]);
    setYearlyFiltersApplied([]);
    cleanAllFilter();
  }, [cleanAllFilter]);

  return (
    <Container>
      <Content>
        <Header>
          <FilterBox>
            <h1>filtrar por</h1>
            {(!!yearsFilter?.length || !!monthsFilter?.length) && (
              <h2 onClick={handleDeleteAllFilters}>limpar todos</h2>
            )}
          </FilterBox>
          <GrClose size={18} color="#666" onClick={closeFunction} />
        </Header>

        <Info>
          <Accordion
            containerStyle={{ padding: 0 }}
            title="ano"
            cutBottomBorderOnShow
            inverseArrows
            arrowActiveColor="#F80032"
            defaultOpen={!!yearsFilter?.length}
            // analyticsEvent={() => {
            //   HandleEvents({
            //     isMobile,
            //     eventAction: 'storepage',
            //     eventCategory: 'nossaslojas',
            //     eventLabel: 'folheto de ofertas',
            //     eventValue: undefined,
            //     cookies,
            //   });
            // }}
          >
            <List>{filtersYear}</List>
          </Accordion>

          <Accordion
            containerStyle={{ padding: 0 }}
            title="mês"
            cutBottomBorderOnShow
            inverseArrows
            arrowActiveColor="#F80032"
            defaultOpen={!!monthsFilter?.length}
            // analyticsEvent={() => {
            //   HandleEvents({
            //     isMobile,
            //     eventAction: 'storepage',
            //     eventCategory: 'nossaslojas',
            //     eventLabel: 'folheto de ofertas',
            //     eventValue: undefined,
            //     cookies,
            //   });
            // }}
          >
            <List>{filtersMonth}</List>
          </Accordion>
        </Info>
      </Content>
      <BottomContent disabled={!filtersChanged} onClick={handleApplyFilters}>
        <button>aplicar</button>
      </BottomContent>
    </Container>
  );
};

export { FilterModal };
