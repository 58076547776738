import React, { FC, useState, useRef } from 'react';
import {
  Container,
  Header,
  HeaderContentBox,
  Content,
  Footer,
  ProductCard,
  HeaderCard,
  Jumbotron,
  InfoBottom,
  Caroussel,
  RedButton,
  FooterContentBox,
  LeftArrow,
  RightArrow,
  PriceBox,
  Pill,
  FakeButtonDudeIcon,
  TooltipLocal,
} from './styles';
import { Carousel } from '@trendyol-js/react-carousel';
import useWindowDimensions from '../../../Utils/size';
import { ReactComponent as BasketIcon } from '../../../Assets/basket2.svg';
import { ReactComponent as InfoButton } from '../../../Assets/info-cinza.svg';
import { ReactComponent as ArrowDown } from '../../../Assets/arrow_down_white.svg';
import { ProductPopulated } from '../interfaces';

interface Props {
  title?: string;
  data: ProductPopulated[];
  linkBuy: any;
}

const CarousselProductsWeb: FC<Props> = ({ data, linkBuy }) => {
  const [tooltipLocalView, setTooltipLocalView] = useState(false);
  const isMobile = useWindowDimensions();
  const DudeIconRef = useRef<any>(null);
  console.log('data: ', data);
  return (
    <Container>
      <Header>
        <HeaderContentBox>
          <h1>o que você viu está aqui</h1>
        </HeaderContentBox>
      </Header>
      <Content>
        <Carousel
          swiping={false}
          dynamic={true}
          infinite={false}
          show={7.2} // quantos mostra por vez no slide
          slide={1} // quantos ele vai rolar por lado
          leftArrow={<LeftArrow />}
          rightArrow={<RightArrow windowWidth={window.innerWidth} />}
          useArrowKeys={true}
          responsive={false}
          transition={0.5}
        >
          {data?.map((product, index: number) => (
            <ProductCard key={`${index}-${Math.random()}`} onClick={linkBuy}>
              <HeaderCard>
                <img src={product?.images?.[0]?.medium} />
              </HeaderCard>

              <Jumbotron
                discount={!!product?.offer?.offers[0]?.discount?.rate}
                disabled={
                  !product?.offer.offers[0]?.nextProgressiveDiscount
                    ?.quantity &&
                  Number(
                    product?.offer?.offers[0]?.discount?.rate &&
                      product?.offer?.offers[0]?.discount?.rate
                  ) === 0
                }
              >
                <span>
                  {product?.offer.offers[0]?.nextProgressiveDiscount?.quantity
                    ? `leve ${product?.offer.offers[0]?.nextProgressiveDiscount?.quantity} pague menos`
                    : `${product?.offer?.offers[0]?.discount?.rate}% de desconto`}
                </span>
              </Jumbotron>
              <InfoBottom>
                <text className="truncate-overflow">{product?.name}</text>

                <PriceBox>
                  {/* {![3, 5, 8].includes(index) && ( */}
                  {product?.offer.offers[0].basePrice !==
                    product?.offer.offers[0].salesPrice && (
                    <div>
                      <span>R$ {product?.offer.offers[0].basePrice}</span>
                      {/* <Pill>
                        <ArrowDown />
                        <text>10%</text>
                      </Pill> */}
                    </div>
                  )}

                  <p>R$ {product?.offer.offers[0].salesPrice}</p>
                </PriceBox>
              </InfoBottom>
            </ProductCard>
          ))}
        </Carousel>
      </Content>
      <Footer>
        <FooterContentBox red>
          <BasketIcon />
          <h1>pedido mínimo: </h1>
          <h2>R$ 10</h2>
          {/* <FakeButtonDudeIcon
            onMouseOver={() => setTooltipLocalView(true)}
            onMouseOut={() => setTooltipLocalView(false)}
            ref={DudeIconRef}
          >
            <InfoButton
              width={15}
              height={15}
              onMouseOver={() => setTooltipLocalView(true)}
              onMouseOut={() => setTooltipLocalView(false)}
              ref={DudeIconRef}
            />
          </FakeButtonDudeIcon>
          <div onClick={() => setTooltipLocalView(false)}>
            <TooltipLocal
              opacity={!!tooltipLocalView}
              // opacity={true}
              mLeft={'3px'}
              isMobile={isMobile}
            >
              <h2>
                aqui podemos explicar<br></br> sobre a cesta mínima<br></br>{' '}
                esta loja :)
              </h2>
            </TooltipLocal>
          </div> */}
        </FooterContentBox>

        <RedButton onClick={linkBuy}>
          <span>ver todos os produtos</span>
        </RedButton>
      </Footer>
    </Container>
  );
};

export { CarousselProductsWeb };
