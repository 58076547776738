import React, { FC, useCallback, useRef, useState, useEffect } from 'react';
import {
  HoursTable,
  Header,
  Title,
  DaysBox,
  Arrow,
  BoxDescription,
  Description,
  Graphic,
  CurrentHourBox,
  Dots,
  BoxTile,
  HourTile,
  HourText,
  LoaderBox,
  Loader,
  EmptyLabel,
} from './styles';
import NumberToDay, { ResponseProps } from '../../Utils/NumberToDay';
import { StoreProps } from '../../Pages/Details/Sobre/Escolha/V2';
import GetHours, { DaysProps, DayProp } from '../../Utils/HourFunction';
import useWindowDimensions from '../../Utils/size';
import { getDay, startOfHour, getHours } from 'date-fns';
import LeftArrow from '../../Assets/SETA_esq.svg';
import RightArrow from '../../Assets/SETA_dir.svg';
import LoadingIcon from '../../Assets/loading.svg';

import { ReactComponent as LeftArrowSvg } from '../../Assets/arrow_change_left.svg';
import { ReactComponent as RightArrowSvg } from '../../Assets/arrow_change_right.svg';

interface Props {
  store: StoreProps;
  hours: DaysProps;
  version?: 'V2' | 'V4';
}

const HoursStore: FC<Props> = ({ store, hours, version }) => {
  const isMobile = useWindowDimensions();
  const today = new Date();
  const day = getDay(today);

  const [currentHour, setCurrentHour] = useState(() => {
    const now = startOfHour(new Date());
    return getHours(now);
  });
  const [startHour, setStartHour] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [todayHour, setTodayHour] = useState<DayProp>({} as DayProp);
  const [emptyHours, setEmptyHours] = useState(false);
  const [loadingGraphic, setLoadingGraphic] = useState(true);
  const [loading, setLoading] = useState(true);
  const [currentDotPosition, setCurrentDotPosition] = useState(0);
  const [currentPercent, setCurrentPercent] = useState(0);
  const [mBox, setMBox] = useState(0);
  const [currentDay, setCurrentDay] = useState<ResponseProps>(() =>
    NumberToDay(day)
  );

  const DotRef = useRef<HTMLDivElement>(null);

  const HoursTableRef = useRef<HTMLDivElement>(null);

  const LegendRef = useRef<HTMLDivElement>(null);

  const makeValidDay = useCallback(
    (action: 'prev' | 'next' | 'nothing') => {
      const currentAction = {
        prev: currentDay?.numberOfWeek - 1,
        next: currentDay?.numberOfWeek + 1,
        nothing: currentDay?.numberOfWeek,
      };

      let validDay = currentAction[action];

      if (validDay === 7) {
        validDay = 0;
      }

      if (validDay === -1) {
        validDay = 6;
      }

      return validDay;
    },
    [currentDay]
  );

  const updateDate = useCallback(
    (action: 'prev' | 'next' | 'nothing') => {
      const numberValid = makeValidDay(action);
      const isValid = NumberToDay(numberValid);
      setCurrentDay(isValid);
    },
    [makeValidDay]
  );

  const calcMargin = useCallback(
    (xPositionDot: number) => {
      const xPositionTable = HoursTableRef.current?.getBoundingClientRect().x;
      // const xPositionDot = DotRef.current?.getBoundingClientRect().x;
      const widthLegend = LegendRef.current?.getBoundingClientRect().width;
      const xPositionLegend = LegendRef.current?.getBoundingClientRect().x;
      const widthTable = HoursTableRef.current?.getBoundingClientRect().width;

      // console.log('-----------------');
      // console.log('xPositionDot: ', xPositionDot);
      // console.log('xPositionTable: ', xPositionTable);
      // console.log('widthLegend: ', widthLegend);
      // console.log('xPositionLegend: ', xPositionLegend);
      // console.log('widthTable: ', widthTable);
      // console.log('-----------------');

      if (
        xPositionDot &&
        (xPositionTable || xPositionTable === 0) &&
        widthLegend &&
        xPositionLegend &&
        widthTable
      ) {
        const xPositionLimitTable = xPositionTable + widthTable;
        const alinhadorDot = xPositionDot - xPositionTable;

        const halfMargin = alinhadorDot - widthLegend / 2;
        const xPositionFinalLegend = xPositionDot - (alinhadorDot - halfMargin);

        const myCalc =
          xPositionFinalLegend < xPositionTable
            ? alinhadorDot - 10
            : halfMargin;

        return xPositionDot + halfMargin > xPositionLimitTable
          ? {
              marginLeft: isMobile
                ? xPositionDot - xPositionTable - widthLegend
                : xPositionDot - xPositionTable - widthLegend + 20,
            }
          : { marginLeft: isMobile ? myCalc - 20 : myCalc };
      }
    },
    [LegendRef, HoursTableRef, isMobile]
  );

  const makeLegend = useCallback((percent: number) => {
    // Normalmente muito cheia - acima de 70%
    // Normalmente cheia - acima de 50%
    // Normalmente movimentada - acima 20%
    // Normalmente vazia - abaixo 20%
    let x;

    if (percent >= 70) {
      x = 'Normalmente muito movimentada';
    }

    if (percent >= 40 && percent <= 69) {
      x = 'Normalmente movimentada';
    }

    if (percent >= 20 && percent <= 39) {
      x = 'Normalmente pouco movimentada';
    }

    if (percent <= 20) {
      x = 'Normalmente sem movimento';
    }

    return x;
  }, []);

  const cutHours = useCallback(
    (value: string) => {
      if (!value) return;
      if (!store.schedules) return;
      const calendarHours = store.schedules[value];

      if (!calendarHours) return;
      const i = calendarHours.start.split(':');
      const e = calendarHours.end.split(':');

      const start = Number(i[0]);
      const end = Number(e[0]);
      setStartHour(start);
      setEndHour(end);
    },
    [store.schedules]
  );

  const getCurrentHour = useCallback(
    async (action: 'prev' | 'next' | 'nothing') => {
      if (!hours || !hours?.dom) {
        return;
      }
      const validDay = makeValidDay(action);

      const currentHours = await GetHours(hours, validDay);
      if (currentHours) {
        const d = NumberToDay(validDay);

        if (d) {
          cutHours(d.en);
        }

        const newArr: any = [];

        Object.entries(currentHours).filter((i: any, index: any) => {
          if (Number(i[0]) >= 6 && Number(i[0]) < 23) {
            if (Number(i[1].hour) === currentHour) {
              setCurrentPercent(i[1].percent);
            }

            newArr.push(i[1]);
          }
        });

        setTodayHour(newArr);
        setLoading(false);
        setEmptyHours(false);
      } else {
        setLoading(false);
        setEmptyHours(true);
      }
    },
    [hours, cutHours, makeValidDay, currentHour]
  );

  useEffect(() => {
    getCurrentHour('nothing');
  }, [day, getCurrentHour]);

  useEffect(() => {
    if (store && store.schedules) {
      const d = NumberToDay(day);

      if (d) {
        cutHours(d.en);
      }
    }
  }, [store, day, cutHours]);

  return (
    <HoursTable ref={HoursTableRef} isMobile={isMobile} empty={emptyHours}>
      <Header isMobile={isMobile} version={version}>
        {!isMobile && <Title version={version}>horários de pico</Title>}

        <DaysBox isMobile={isMobile} version={version}>
          {isMobile ? (
            <LeftArrowSvg
              width={18}
              height={12}
              style={{ cursor: 'pointer', marginTop: -2 }}
              onClick={() => {
                getCurrentHour('next');
                updateDate('next');
              }}
            />
          ) : (
            <Arrow
              src={RightArrow}
              style={{ marginRight: 10 }}
              onClick={() => {
                getCurrentHour('prev');
                updateDate('prev');
              }}
              isMobile={isMobile}
            />
          )}

          <Title>{currentDay?.pt}</Title>
          {isMobile ? (
            <RightArrowSvg
              width={18}
              height={12}
              style={{ cursor: 'pointer', marginTop: -2 }}
              onClick={() => {
                getCurrentHour('next');
                updateDate('next');
              }}
            />
          ) : (
            <Arrow
              src={LeftArrow}
              style={{ marginLeft: 10 }}
              onClick={() => {
                getCurrentHour('next');
                updateDate('next');
              }}
              isMobile={isMobile}
            />
          )}
        </DaysBox>
      </Header>

      <BoxDescription
        style={calcMargin(currentDotPosition) || { marginLeft: 0 }}
        opacity={!!currentDotPosition}
        ref={LegendRef}
      >
        <Description>{makeLegend(currentPercent)}</Description>
      </BoxDescription>

      <Graphic>
        {Object.values(todayHour).map((i: any, index: number) => (
          <>
            <CurrentHourBox key={i.hour}>
              {Number(i.hour) === currentHour && (
                <>
                  <Dots
                    ref={(e) =>
                      setCurrentDotPosition(e?.getBoundingClientRect().x || 0)
                    }
                    size={
                      Number(currentPercent) < 20
                        ? '70%'
                        : Number(currentPercent) > 80
                        ? '30%'
                        : '50%'
                    }
                  />
                </>
              )}

              <BoxTile>
                <HourTile
                  active={currentHour === Number(i.hour)}
                  size={String(i.percent).padStart(2, '0')}
                  isMobile={isMobile}
                  widthFatherTable={
                    HoursTableRef.current?.getBoundingClientRect().width
                  }
                />
                <HourText active={!!(index % 2 === 0)}>{i.hour}</HourText>
              </BoxTile>
            </CurrentHourBox>
          </>
        ))}
      </Graphic>

      {!currentDotPosition && loading && (
        <LoaderBox>
          <Loader src={LoadingIcon} />
        </LoaderBox>
      )}

      {emptyHours && !loading && (
        <EmptyLabel>
          Não foi possível buscar <br></br>os horários de pico.
        </EmptyLabel>
      )}
    </HoursTable>
  );
};

export { HoursStore };
