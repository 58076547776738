export default {
  colors: {
    redPrimary: '#F80032',
    grayMedium: '#666666',
    grayDark: '#333333',
    grenPrimary: '#7CD09E',
    whitePrimary: '#FFFFFF',
    pinkPrimary: '#f1d8e6',
  },
  fonts: {
    bold: 'Helvetica Neue, Bold',
    regular: 'Helvetica Neue, Regular',
  },
};
