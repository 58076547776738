import React, { FC, useEffect, useCallback, useState, useMemo } from 'react';
import HomeDataLocal from '../../Services/data/lista_homeseo.json';
import { Tabs } from 'antd';
import {
  Container,
  Title,
  TitleState,
  City,
  BoxState,
  Store,
  BoxStores,
  HeaderState,
  MoreButton,
  FooterState,
  Wrapper,
  BuscaCard,
  WrapperBuscaCard,
} from './styles';
import Header from '../../Components/Header';
import Busca from '../../Components/Busca';
import Footer from '../../Components/Footer';
import Map from '../../Components/Map';
import Config from '../../Utils/Config';
import { useHistory, useLocation } from 'react-router-dom';
import ModalHelp from '../../Components/ModalHelp';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import api from '../../Services/api';
import ModalAddressWeb from '../../Components/ModalAddressWeb';
import HandleAnalyticsEvents from '../../Utils/HandleAnalyticsEvents';
import {
  HandlePageView,
  HandleEvents,
  handleStoreID,
} from '../../Utils/AnalyticsGA';
import useWindowDimensions from '../../Utils/size';
import States from './state';
import {
  StateProps,
  SiglaProps,
  StoresProps,
  CityProps,
  UriProps,
  HomeFileProps,
} from './interfaces';
import { Breadcrumb } from '../../Components/Breadcrumb';
import PathsStateHome from '../../Services/data/PathsStateHome';

const { TabPane } = Tabs;

const Home: FC = () => {
  const [HomeData, setHomeData] = useState<HomeFileProps>({} as HomeFileProps);
  const [hasMoreStores, setHasMoreStores] = useState(false);
  const [Cities, setCities] = useState<StoresProps[]>([]);
  const [pageStore, setPageStore] = useState(1);
  const [defaultTabState, setDefaultTabState] = useState<any>('AC');
  const [cep, setCep] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const [defaultTab, setDefaultTab] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const isMobile = useWindowDimensions();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const handleCloseModalHelp = useCallback(() => {
    setVisibleModal(false);

    let current = location.search;

    const remove_after = current.indexOf('&help');
    current = current.substring(0, remove_after);

    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      location.search;

    window.history.pushState({ path: newurl }, '', newurl);
  }, [location.search]);

  const handleEventsGA = useCallback(
    (type: 'atendimento' | 'duvidasfrequentes' | 'politicadetrocas') => {
      const makeType = {
        atendimento: 'atendimento',
        duvidasfrequentes: 'faq',
        politicadetrocas: 'trocas',
      };

      HandleEvents({
        isMobile,
        eventAction: 'footer',
        eventCategory: 'nossaslojas',
        eventLabel: makeType[type],
        eventValue: undefined,
        cookies,
      });
    },
    [isMobile, cookies]
  );

  const handleChangeTab = useCallback(
    (index: number) => {
      setDefaultTab(Number(index));
      setVisibleModal(true);

      let current = location.search;

      if (parsed.help) {
        const remove_after = current.indexOf('&help');
        current = current.substring(0, remove_after);
      }

      const values: any = [
        'atendimento',
        'duvidasfrequentes',
        'politicadetrocas',
      ];

      handleEventsGA(values[index]);

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        window.location.search +
        `&help=${values[index]}`;
      window.history.pushState({ path: newurl }, '', newurl);
    },
    [parsed, location.search, handleEventsGA]
  );

  useEffect(() => {
    HandleAnalyticsEvents({
      eventCustomName: 'page:load',
      customPayload: {
        detail: {
          page: {
            type: 'americanasStores-Home',
            cep: cookies.persistentCep || null,
          },
        },
      },
    });
  }, [cookies]);

  const loadGA = useCallback(() => {
    HandlePageView({
      cookies,
      query: parsed,
      name: 'ACOM:NossasLojas:Home',
      isMobile,
    });
  }, [cookies, parsed, isMobile]);

  useEffect(() => {
    loadGA();
  }, []);

  useEffect(() => {
    if (parsed.help) {
      const values: any = {
        atendimento: 0,
        duvidasfrequentes: 1,
        politicadetrocas: 2,
      };
      setDefaultTab(Number(values[String(parsed.help)]));
      setVisibleModal(true);
    }
  }, [parsed.help]);

  useEffect(() => {
    const tempCep = '22210060';

    if (cookies.persistentCep && !parsed.help && !parsed.newUser) {
      setCep(cookies.persistentCep);
    }
    // if (22210060) {
    //   setCep('22210060');
    // }
  }, [cookies, history, parsed.help, parsed.newUser]);

  const handleSelectTab = useCallback((e: string) => {
    setPageStore(1);
    setDefaultTabState(e);
  }, []);

  const StateName = useMemo(() => {
    // console.log('States: ', States);
    return States.find((i: SiglaProps) => i.sigla === defaultTabState);
  }, [defaultTabState]);

  const loadMoreCities = useCallback(() => {
    if (!HomeData || !HomeData.Cidades || !HomeData.Cidades.length) return;

    const storesPerPage = 32;
    const filtered = HomeData.Lojas.filter(
      (i: StoresProps) => i.Estado === defaultTabState
    );

    const total = filtered.length;
    const lastStore = storesPerPage * pageStore;

    const paginated = filtered.slice(0, lastStore);

    setPageStore((prev) => prev + 1);
    setCities(paginated);

    lastStore > total ? setHasMoreStores(false) : setHasMoreStores(true);
  }, [defaultTabState, pageStore, HomeData]);

  const HandleUri = useCallback(
    ({ path, store }: UriProps) => {
      const { feather, footer, chave, epar } = parsed;

      let uri = '?';

      if (chave && chave !== 'undefined') {
        uri = uri + `chave=${chave}`;
      }

      if (epar && epar !== 'undefined') {
        uri = uri + `&epar=${epar}`;
      }

      if (feather && feather !== 'undefined') {
        uri = uri + `&feather=${feather}`;
      }

      if (footer && footer !== 'undefined') {
        uri = uri + `&footer=${footer}`;
      }

      // if (store) {
      //   path = `/loja/${store.Id}/detalhes`;
      //   uri = uri + `&path=${store.Path}`;
      // }

      if (store) {
        path = `/loja/${store.Id}`;
        uri = uri + `&path=${store.Path}`;
      }

      console.log('uri path: ', path);
      console.log('uri uri: ', uri);

      history.push({
        pathname: path,
        search: uri,
      });
    },
    [parsed, history]
  );

  const handleClickSEO = useCallback(() => {
    HandleEvents({
      isMobile,
      eventAction: 'home',
      eventCategory: 'nossaslojas',
      eventLabel: 'listaseo',
      eventValue: undefined,
      cookies: {},
    });
  }, [isMobile]);

  const handleClickCity = useCallback(
    (store: StoresProps) => {
      const city = HomeData.Cidades.find(
        (i: CityProps) => i.Cidade === store.Cidade
      );

      handleClickSEO();

      if (city) {
        HandleUri({ path: city?.Path });
      }
    },
    [handleClickSEO, HandleUri, HomeData]
  );

  const handleClickStore = useCallback(
    (store: StoresProps) => {
      handleClickSEO();
      HandleUri({ path: store.Path, store });
    },
    [handleClickSEO, HandleUri]
  );

  const handleClickState = useCallback(
    async (state: StateProps) => {
      const findLink = PathsStateHome.find(
        (i: StateProps) => i.Estado === state.Estado
      );

      handleClickSEO();
      if (findLink) {
        HandleUri({ path: findLink?.Path.toLocaleLowerCase() });
      }
    },
    [handleClickSEO, HandleUri]
  );

  useEffect(() => {
    loadMoreCities();
  }, [defaultTabState, HomeData]);

  const loadStores = useCallback(async () => {
    if (process.env.NODE_ENV !== 'development') {
      const response = await api.get(
        `https://${document.location.host}/static/json/lista_homeseo.json`
      );
      const list: any = response.data;

      setHomeData(list);
    }

    if (process.env.NODE_ENV === 'development') {
      setHomeData(HomeDataLocal);
    }
  }, []);

  useEffect(() => {
    loadStores();
  }, []);

  // useEffect(() => {
  //   const showPosition = (position: any) => {
  //     console.log('position: ', position);
  //     console.log(
  //       'Latitude: ' +
  //         position.coords.latitude +
  //         '<br>Longitude: ' +
  //         position.coords.longitude
  //     );
  //   };

  //   if (navigator.geolocation) {
  //     console.log('position entrou');
  //     navigator.geolocation.getCurrentPosition(showPosition);
  //   } else {
  //     console.log('Geolocation is not supported by this browser.');
  //   }
  // }, []);

  return (
    <>
      {/* <ModalHelp
        visible={visibleModal}
        closeFunction={handleCloseModalHelp}
        defaultTab={defaultTab}
        onSelect={handleChangeTab}
      /> */}

      {/* <Header /> */}
      <Breadcrumb home />

      <Map />
      <WrapperBuscaCard isMobile={isMobile}>
        <BuscaCard isMobile={isMobile}>
          <Busca cep={cep} fromHome />
        </BuscaCard>
      </WrapperBuscaCard>
      <Container isMobile={isMobile}>
        <Title isMobile={isMobile}>nossas lojas por estado</Title>
        <Tabs
          // renderTabBar={isMobile ? renderTabBar : undefined}
          activeKey={defaultTabState}
          onChange={(e) => handleSelectTab(e)}
          style={{ marginBottom: 10, marginTop: 26 }}
        >
          {HomeData &&
            HomeData.Estados &&
            HomeData.Estados.map((i: StateProps) => (
              <TabPane tab={i.Estado} key={i.Estado} className="tab">
                <Wrapper isMobile={isMobile}>
                  <HeaderState>
                    <TitleState onClick={() => handleClickState(i)}>
                      {StateName?.nome}
                    </TitleState>
                  </HeaderState>
                  <BoxStores>
                    {Cities.map((s: StoresProps, index: number) => (
                      <BoxState
                        key={s.Id}
                        isMultiplo={!!((index + 1) % 4 === 0 && index !== 0)}
                        isMobile={isMobile}
                      >
                        <City
                          onClick={() => handleClickCity(s)}
                        >{`${s.Cidade},`}</City>
                        <Store
                          className="truncate-overflow"
                          onClick={() => handleClickStore(s)}
                        >
                          {s.Loja}
                        </Store>
                      </BoxState>
                    ))}
                  </BoxStores>

                  {hasMoreStores && (
                    <FooterState>
                      <MoreButton onClick={loadMoreCities} isMobile={isMobile}>
                        <span>ver mais lojas</span>
                      </MoreButton>
                    </FooterState>
                  )}
                </Wrapper>
              </TabPane>
            ))}
        </Tabs>
      </Container>
      {/* <Footer
        handleMenu1={() => handleChangeTab(0)}
        handleMenu2={() => handleChangeTab(1)}
        handleMenu3={() => handleChangeTab(2)}
      /> */}
    </>
  );
};

export default Home;
