import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0 18px;
`;

export const HeaderMobile = styled.div`
  border-radius: 16px 16px 0 0;
  padding: 16px 19px;
  background-color: #fff;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface HeaderContentBoxProps {
  red?: boolean;
}

export const HeaderContentBox = styled.div<HeaderContentBoxProps>`
  display: flex;
  align-items: center;

  h1 {
    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    color: #404040;
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }

  h2 {
    margin-right: 6px;

    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    color: #404040;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
  }

  svg:nth-child(1) {
    width: 20px;
    height: 20px;
  }

  svg:nth-child(2) {
    width: 17px;
    height: 17px;
  }

  ${({ red }) =>
    red &&
    css`
      svg path {
        fill: #f80032;
      }
    `}
`;

export const Content = styled.div`
  background: #e9e9e9;
  padding: 20px 15px;
  height: 261px;
`;

export const ProductCard = styled.div`
  border: 0;
  /* width: 150px; */
  width: 40%;
  min-width: 165px;
  border-radius: 16px;
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  /* margin-left: 16px; */
  margin-left: 3%;
  position: relative;
`;

export const HeaderCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  border-radius: 8px 8px 0 0;
  padding-top: 15px;

  img {
    /* width: 100px; */
    /* width: 61%; */
    height: 88px;
    object-fit: contain;
  }
`;

interface JumbotronProps {
  discount: boolean;
  disabled: boolean;
}
export const Jumbotron = styled.div<JumbotronProps>`
  background: ${({ discount }) => (discount ? '#F2FFE3' : '#f2fbff')};
  display: flex;
  align-items: center;
  justify-content: center;

  height: 30px;

  span {
    color: ${({ discount }) => (discount ? '#008000' : '#0071e0')};

    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #fff;
      span {
        color: #fff;
      }
    `}
`;

export const InfoBottom = styled.div`
  padding: 8px;
  height: 82px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .truncate-overflow {
    max-width: 135px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  text {
    font-family: 'Helvetica Neue, Bold';

    font: -webkit-control;
    color: black;
    font-size: 12px;
    font-weight: bold;
    line-height: 14px;
  }

  p {
    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    color: black;
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
  }
`;
export const Caroussel = styled.div`
  margin-top: -317px;
  width: 100%;
  position: absolute;

  button {
    display: none;
  }

  .module_slider {
    padding-right: 18%;
  }
`;

export const Footer = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 0 0 16px 16px;
`;

export const RedButton = styled.div`
  width: 100%;
  height: 48px;
  background-color: #f80032;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: #fff;

    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
  }
`;

export const PriceBox = styled.div`
  > div {
    display: flex;
    align-items: center;

    span {
      text-decoration: line-through;
      color: #666666;

      margin-right: 5px;

      font-family: 'Helvetica Neue, Regular';
      font: -webkit-control;
      font-size: 12px;
      line-height: 14px;
    }
  }
`;

export const Pill = styled.div`
  padding: 2px 4px;
  background-color: #008000;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 14px;

    height: 11px;
  }

  text {
    color: #fff;

    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-size: 10px;
    line-height: 12px;
  }
`;
