import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  flex: 1;
  margin: 32px 12px 0px 12px;
  border-radius: 16px;
  box-shadow: 0px 2px 8px #00000019;
`;

export const Title = styled.span`
  color: #666666;
  font-family: Helvetica Neue;
  font-size: 16px;
`;

export const Data = styled.div`
  width: 120px;
  height: 20px;
  box-shadow: 0px 0px 20px #00000026;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 19px;

  span {
    color: #999999;
    font-size: 12px;
    font-family: 'Helvetica Neue, Regular';
    margin: 0;
    padding: 0;
    /* margin-top: 3px; */
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 0px 20px #00000026;
  width: 100%;
`;

export const TitleOffer = styled.span`
  font-size: 16px;
  font-family: 'Helvetica Neue, Bold';
  color: #333333;
  text-transform: capitalize;
`;

export const AddressOffer = styled.span`
  font-size: 14px;
  font-family: Helvetica Neue, Regular;
  color: #666666;
  margin-bottom: 3px;
  line-height: 17px;
  text-transform: capitalize;
`;

export const ContentHeader = styled.div`
  display: flex;
`;

interface PhotoStoreProps {
  isMobile: boolean;
  local?: boolean;
}

export const PhotoStore = styled.div<PhotoStoreProps>`
  min-width: 75px;
  width: ${(props) => (props.isMobile ? '75px' : '150px')};
  height: ${(props) => (props.isMobile ? '75px' : '120px')};
  border: 1px solid #0000000f;
  background-color: ${({ theme }) => theme.colors.redPrimary};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ local }) => (local ? '27px' : '0px')};

  img {
    width: ${(props) => (props.isMobile ? '62px' : '107px')};
  }

  > svg path {
    fill: #fff;
  }

  > svg {
    fill: #fff;
    /* width: ${({ isMobile }) => (isMobile ? '65px' : '115px')}; */
    height: auto;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0px;
    `}
`;

export const Alignment = styled.div`
  width: 100%;
  padding: 0 18px 0 15px;
  background-color: #fff;
  margin-bottom: 24px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 23px;

  /* svg path {
    fill: #4e4e4e;
  }

  svg {
    width: 13px;
    margin-right: 6px;
    transform: rotate(-90deg);
    margin-left: 10px;
  } */
`;

export const Timestamp = styled.span`
  font-size: 12px;
  color: #666666;
  font-family: 'Helvetica Neue';
`;

export const Total = styled.span`
  font-size: 13px;
  color: #666666;
  font-family: 'Helvetica Neue';
  text-decoration: underline;
`;

export const Barrinha = styled.div`
  width: 100%;
  height: 1px;
  background: #d8d8d8;
  margin: 23px 0 0 0;
  box-shadow: 0px 0px 20px #00000026;
`;

export const BarrinhaBottom = styled.div`
  height: 1px;
  background: #70707012;
  margin: 16px;
`;

export const HeaderCard = styled.div`
  display: flex;
  flex-direction: column;

  .truncate-overflow {
    /* max-width: 252px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;

export const Products = styled.div`
  /* padding: 0px 16px 0px 16px; */
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 8;
  margin-bottom: 40px;

  .truncate-overflow {
    /* max-width: 252px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 21px;

  .placeholder-icon-empty {
    width: 45px;
    height: 45px;
    transform: rotate(0deg) !important;
  }

  div + div {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const ProductImg = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  position: relative;

  span {
    font-size: 17px;
    color: #333;
    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-weight: 700;
  }

  h2 {
    margin: 0px;
    padding: 0px;
    font-size: 13px;
    color: #666666;
    font-family: Helvetica Neue, Regular;

    /* margin-top: 5px; */
  }
`;
export const Description = styled.span`
  font-size: 14px;
  color: #666666;
  font-family: 'Helvetica Neue';
  font-weight: 200;
  margin-top: 0px;
  text-transform: lowercase;
`;

export const AlignmentDescription = styled.div`
  width: 70%;
`;

export const FooterCard = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 19px 9px 13px 24px; */
  height: 70px;
  padding: 0 28px;

  box-shadow: 0px 0px 20px #00000026;
  position: fixed;
  z-index: 10;
  bottom: 0;

  > div {
    display: flex;
    align-items: flex-end;
  }

  span {
    color: #666666;
    font-family: 'Helvetica Neue';
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  h1 {
    color: #333333;
    font-family: 'Helvetica Neue, Bold';
    font-size: 24px;
    margin: 0;
    padding: 0;
  }

  h2 {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: 'Helvetica Neue';
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
`;

export const HeadBox = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 16px;
  /* justify-content: space-between;
  align-items: center; */
  width: 100%;
  padding: 13px 16px 0px 16px;
  /* position: fixed; */
  z-index: 10;

  .chevron {
    width: 13px;
    margin-right: 6px;
    transform: rotate(90deg);

    fill: #4e4e4e;
  }

  /* svg {
      width: 13px;
      margin-right: 6px;
      transform: rotate(90deg);
  } */

  /* svg path {
    fill: #4e4e4e;
  } */
`;

export const Badge = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.redPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8px;
  right: -8px;

  span {
    color: white;
    font-size: 12px;
    font-family: Helvetica Neue;
  }
`;

export const Price = styled.span`
  color: #333333;
  font-family: 'Helvetica Neue, Bold';
  font-size: 14px;
`;

export const InfoActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
  margin-top: 17px;
`;

export const AlignmentHeader = styled.div`
  position: relative;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 14px 0px 32px 0;
`;

export const MoreButton = styled.div`
  border: solid 1px #e0030e;
  border-radius: 6px;
  width: 150px;
  height: 36px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: Helvetica Neue, Regular;
    color: #df030e !important;
    font-size: 14px;
  }
`;

export const TitlePage = styled.span`
  font-size: 22px;
  color: #666666;
  line-height: 26px;
  font-family: 'Helvetica Neue, Bold';
  margin-left: 12px;
`;
