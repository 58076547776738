import React, {
  createContext,
  ReactNode,
  useContext,
  FC,
  useState,
} from 'react';

interface ProviderProps {
  children: ReactNode;
}

interface FilterContextPops {
  yearsFilter: string[];
  monthsFilter: string[];
  addYearFilter: (year: number | string) => void;
  addMonthFilter: (month: number | string) => void;
  removeYearFilter: (year: number | string) => void;
  removeMonthFilter: (month: number | string) => void;
  cleanAllFilter: () => void;
}

const FilterContext = createContext({} as FilterContextPops);

const FilterProvider = ({ children }: ProviderProps) => {
  const [yearsFilter, setYearsFilter] = useState<any>([]);
  const [monthsFilter, setMonthsFilter] = useState<any>([]);

  const addYearFilter = (year: string | number) => {
    setYearsFilter((prev: any) => [...prev, year]);
  };

  const addMonthFilter = (month: string | number) => {
    setMonthsFilter((prev: any) => [...prev, month]);
  };

  const removeYearFilter = (year: string | number) => {
    const filter = [...yearsFilter];
    const filtered = filter.filter((i: string) => String(i) !== String(year));
    setYearsFilter(filtered);
  };

  const removeMonthFilter = (month: string | number) => {
    const filter = [...monthsFilter];
    const filtered = filter.filter((i: string) => String(i) !== String(month));
    setMonthsFilter(filtered);
  };

  const cleanAllFilter = () => {
    setYearsFilter([]);
    setMonthsFilter([]);
  };

  return (
    <FilterContext.Provider
      value={{
        yearsFilter,
        monthsFilter,
        addMonthFilter,
        addYearFilter,
        removeYearFilter,
        removeMonthFilter,
        cleanAllFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

const useFilters = () => {
  const context = useContext(FilterContext);
  return context;
};

export { useFilters, FilterProvider };
