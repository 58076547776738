import React, { FC, useState, useCallback, useEffect } from 'react';
import {
  Container,
  Content,
  Header,
  Text,
  Tabela,
  Alinhador,
  Lista,
} from './styles';
import { RiCloseLine } from 'react-icons/ri';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import useWindowDimensions from '../../Utils/size';
import BlackHawkPDF from '../../Assets/Blackhawk.pdf';
import api from '../../Services/api';
import Config from '../../Utils/Config';
import { convertFromHTML, EditorState, convertFromRaw, Editor } from 'draft-js';

interface Props {
  visible: boolean;
  closeFunction?: () => void;
  defaultTab?: number;
  onSelect?: (value: number) => void;
}

const ModalHelp: FC<Props> = ({
  visible = false,
  closeFunction,
  defaultTab = 0,
  onSelect,
}) => {
  const isMobile = useWindowDimensions();
  const [attendenceContent, setAttendenceContent] = useState<any>();
  const [faqContent, setFaqContent] = useState<any>();
  const [polititionsContent, setPolititionsContent] = useState<any>();

  const loadAttendenceContent = useCallback(async () => {
    try {
      const { data } = await api.get(Config?.modalHelpAttendence);

      const content = data?.component?.contentJson;
      const convertedState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(content))
      );

      setAttendenceContent(convertedState);
    } catch (error) {
      console.log('error loadAttendenceContent: ', error);
    }
  }, []);

  const loadFAQContent = useCallback(async () => {
    try {
      const { data } = await api.get(Config?.modalFAQ);

      console.log('data faq content: ', data);

      const content = data?.component?.contentJson;
      const convertedState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(content))
      );

      setFaqContent(convertedState);
    } catch (error) {
      console.log('error loadFAQContent: ', error);
    }
  }, []);

  const loadPolititionsContent = useCallback(async () => {
    try {
      const { data } = await api.get(Config?.modalPolititions);

      const content = data?.component?.contentJson;
      const convertedState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(content))
      );

      setPolititionsContent(convertedState);
    } catch (error) {
      console.log('error loadPolititionsContent: ', error);
    }
  }, []);

  useEffect(() => {
    loadAttendenceContent();
    loadFAQContent();
    loadPolititionsContent();
  }, []);

  return (
    <>
      {visible && (
        <Container>
          <Content isMobile={isMobile}>
            <Header>
              <RiCloseLine
                size={25}
                color="#000000"
                className="icone"
                onClick={closeFunction}
              />
            </Header>
            <Tabs defaultIndex={defaultTab} onSelect={onSelect}>
              <TabList>
                <Tab>atendimento lojas</Tab>
                <Tab>dúvidas frequentes</Tab>
                <Tab>política de trocas</Tab>
              </TabList>

              <TabPanel className="table-attendent">
                <Alinhador isMobile={isMobile}>
                  <Tabela className="on-scrollbar" isMobile={isMobile}>
                    <Editor
                      editorState={attendenceContent}
                      onChange={() => null}
                      readOnly
                    />
                    {/* {attendenceContent} */}
                  </Tabela>

                  {/* <Tabela className="on-scrollbar" isMobile={isMobile}>
                    <Text bold size="18px">
                      veja os nossos contatos:
                    </Text>

                    <Text top="30px" size="16px" bold>
                      capitais e regiões metropolitanas
                    </Text>

                    <Text top="10px" size="16px" weight="200">
                      4003-8558* / 0XX 21 4042-0321 (somente WhatsApp)
                    </Text>

                    <Text top="30px" size="16px" bold>
                      outras regiões
                    </Text>

                    <Text top="10px" size="16px" weight="200">
                      0XX 21 4003-8558* / 0XX 21 4042-0321 (somente WhatsApp)
                    </Text>

                    <Text top="50px" size="16px" weight="200">
                      Atendimento das 08h00 às 18h00 de segunda à sexta e aos
                      sábados 08h00 às 16h00 (exceto feriados nacionais).
                    </Text>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Text top="30px" size="16px" weight="200" bold>
                        e-mail:
                      </Text>
                      <a
                        href="mailto:atendimento@lojasamericanas.com.br"
                        style={{
                          marginTop: -28,
                          marginLeft: 10,
                          padding: 0,
                          textDecoration: 'none',
                        }}
                      >
                        <Text top="50px" size="16px" weight="200">
                          atendimento@lojasamericanas.com.br
                        </Text>
                      </a>
                    </div>

                    <Text top="50px" size="16px" weight="200">
                      *Ligações efetuadas de capitais e regiões metropolitanas
                      têm custo de ligação local + impostos locais para
                      telefones fixos e custo de ligação + impostos para
                      celulares. Para as demais localidades o custo será de uma
                      ligação interurbana + impostos, conforme a operadora.
                      Essas tarifas serão cobradas na conta telefônica.
                    </Text>

                    <Text top="50px" size="16px" weight="200">
                      Para verificar se a cidade de onde você está falando
                      permite ligação sem código da operadora, tente discar
                      diretamente o número 4003-8558.
                    </Text>

                    <Text top="50px" size="16px" weight="200" bottom="40px">
                      Qualquer dúvida sobre a disponibilidade do serviço 4003,
                      entre em contato com a operadora Intelig.
                    </Text>
                  </Tabela>
                 */}
                </Alinhador>
              </TabPanel>

              <TabPanel className="tabela-faq">
                <Alinhador isMobile={isMobile}>
                  <Tabela className="on-scrollbar" isMobile={isMobile}>
                    {/* <Text bold>
                      1- Como faço para consultar os produtos disponíveis nas
                      lojas físicas e os preços?
                    </Text>

                    <Text top="20px" weight="300">
                      O site Americanas.com e as lojas físicas têm Política de
                      Preços e condições de pagamento próprias. Assim, a gente
                      pode oferecer aos clientes as melhores oportunidades e
                      maior variedade de produtos.<br></br>
                      <br></br>
                      Para saber a disponibilidade de um produto, preços e
                      condições de pagamento nas lojas físicas, visite uma de
                      nossas lojas.
                    </Text>

                    <Text top="50px" bold>
                      2 - Posso comprar um produto no site e retirar na loja
                      física?
                    </Text>

                    <Text top="20px" weight="300">
                      Com certeza! Você pode comprar na loja virtual
                      www.americanas.com e retirar o produto em uma loja física
                      de sua escolha. Essa é mais uma novidade pra melhor te
                      atender!
                      <br></br>
                      <br></br> Para saber detalhes,{' '}
                      <a
                        href="https://www.americanas.com.br/hotsite/entregas?chave=pm_hm_tt_acom_peguenaloja"
                        target="_blank"
                      >
                        clique aqui.
                      </a>
                    </Text>

                    <Text top="50px" bold>
                      3 - Posso comprar um produto pelo WhatsApp ?
                    </Text>

                    <Text top="20px" weight="300">
                      Pode! Pedir é muito fácil, olha só: acesse o{' '}
                      <a href="https://bit.ly/3e5T2Uo" target="_blank">
                        site
                      </a>{' '}
                      ou mande mensagem para o WhatsApp (21) 4042-0321 e
                      selecione a opção “Comprar”.
                    </Text>

                    <Text top="20px" weight="300">
                      Você será direcionado para o WhatsApp da loja mais próxima
                      ao lugar em que você está. Depois, é só escolher os
                      produtos que quer comprar.
                    </Text>

                    <Text top="20px" weight="300">
                      Gostou da novidade? Vem pedir agora!
                    </Text>

                    <Text top="50px" bold>
                      4- Onde encontro os endereços e horário de funcionamento
                      das lojas físicas?
                    </Text>

                    <Text top="20px" weight="300">
                      A lista de endereços está em nosso site.{' '}
                      <a
                        href="https://nossaslojas.americanas.com.br"
                        target="_blank"
                      >
                        Clique aqui
                      </a>
                      , selecione o seu estado e confira a loja física mais
                      perto de você.<br></br>
                      <br></br>
                      Corra pra uma de nossas lojas! Tem de tudo e tem do lado!
                    </Text>

                    <Text top="50px" bold>
                      5 - Como entro em contato com as lojas físicas?
                    </Text>

                    <Text top="20px" weight="300">
                      Você pode ir até a loja mais perto da sua casa ou recorrer
                      a um de nossos canais: SAC (21) 4003-8558, e-mail{' '}
                      <a href="mailto:atendimento@lojasamericanas.com.br">
                        atendimento@lojasamericanas.com.br
                      </a>{' '}
                      e WhatsApp (21) 4042-0321.<br></br>
                      Entre em contato com a gente! Vamos responder o mais
                      rápido possível!
                    </Text>

                    <Text top="50px" bold>
                      6 – Como funciona a Garantia Estendida?
                    </Text>

                    <Text top="20px" weight="300">
                      A Garantia Estendida, que deve ser adquirida antes de
                      finalizar a compra, dá direito a reparar o produto sem
                      custos ou a trocá-lo, desde que o problema não tenha sido
                      causado por mau uso. <br></br>
                      <br></br>A vigência da Garantia Estendida começa após o
                      término da garantia do fabricante.
                      <br></br>
                      <br></br>
                      As condições de utilização são informadas durante a compra
                      e também na cartilha do seguro que você recebe após a
                      contratação. <br></br>
                      <br></br>
                      Para usar o serviço, entre em contato com a empresa
                      seguradora Mapfre Seguros, nos telefones:<br></br>
                      <br></br>
                      Sinistro/Resgate: 0800-227-4624 (de segunda a sexta, das
                      8h às 20h, e sábado, das 8h às 17h)<br></br>
                      <br></br>
                      SAC e cancelamentos: 0800-887-4624 (24 horas)<br></br>
                      <br></br>
                      SAC e cancelamentos: pessoas com deficiência de audição ou
                      fala: 0800 775 5045 (24 horas)<br></br>
                      <br></br>
                      Ouvidoria: 0800 775 1079 (de segunda a sexta, das 8h às
                      18h)<br></br>
                      <br></br>
                      Ouvidoria: pessoas com deficiência de audição ou fala:
                      0800 962 7373 (de segunda a sexta, das 8h às 18h)<br></br>
                      <br></br>
                      Ficou com alguma dúvida?{' '}
                      <a
                        href="http://www.seguroslasa.com.br/mapfre/home/index.html"
                        target="_blank"
                      >
                        Clique aqui
                      </a>
                      .
                    </Text>

                    <Text top="50px" bold>
                      7 - Como funciona o Seguro Roubo e Furto?
                    </Text>

                    <Text top="20px" weight="300">
                      O Seguro Roubo e Furto Qualificado protege o seu
                      equipamento portátil em caso de situações adversas, tais
                      como roubo e furto qualificado. É importante que você
                      saiba o que significam essas coberturas: <br></br>- roubo:
                      é quando alguém leva um objeto seu, por exemplo, o
                      celular, com violência ou usando armas ou facas;<br></br>{' '}
                      - furto qualificado: não há violência, mas é preciso
                      romper algum obstáculo para chegar ao objeto. Por exemplo,
                      quebrar o vidro de uma janela ou arrombar um cadeado.{' '}
                      <br></br>A contratação do Seguro Roubo e Furto Qualificado
                      da Mapfre inclui o seguro de quebra acidental para bens
                      eletrônicos portáteis. A cobertura de Quebra Acidental NÃO
                      abrange imersão do equipamento em água ou derramamento de
                      líquidos. <br></br>
                      As condições de utilização do seguro são informadas
                      durante a compra e na cartilha que você recebe após a
                      contratação. Lembre-se de que esse seguro tem franquia.{' '}
                      <br></br>A vigência do seguro começa à meia-noite do dia
                      seguinte à contratação. Se você assinou o contrato às 15
                      horas de uma quinta-feira, ele começará a valer à
                      meia-noite de sexta-feira.<br></br> O contrato será
                      encerrado na data que consta na cartilha, à meia-noite.
                      <br></br>
                      Para usar o serviço, caso tenha realizado a compra antes
                      de 17/11/2021, entre em contato com a Generali Seguros,
                      nos telefones: <br></br>Sinistro/Resgate: 3004 58 58 ou
                      0800 70 70 211 (de segunda a sexta, das 8h às 18h){' '}
                      <br></br>SAC: 0800 889 0200 (24 horas);<br></br> SAC:
                      pessoas com deficiência de audição ou fala: 0800 889 0400
                      (24 horas); <br></br>Ouvidoria: 0800 880 3900 (de segunda
                      a sexta, das 8h às 18h).
                      <br></br>
                      <br></br>
                      Ficou com alguma dúvida?{' '}
                      <a
                        target="_blank"
                        href="https://www.generali.com.br/home/nossa-protecao/parcerias/lojasamericanas/"
                      >
                        Clique aqui
                      </a>
                    </Text>

                    <Text top="50px" bold>
                      8 - Como recuperar objetos/documentos perdidos dentro de
                      uma loja?
                    </Text>

                    <Text top="20px" weight="300">
                      A Lojas Americanas não se responsabiliza por objetos ou
                      documentos esquecidos em suas lojas. Mas, não se preocupe,
                      se você esquecer algo, mande uma mensagem para o nosso SAC
                      no Whatsapp (21) 4042-0321 ou envie e-mail para{' '}
                      <a href="mailto:atendimento@lojasamericanas.com.br">
                        atendimento@lojasamericanas.com.br
                      </a>
                      . A gente verifica com a loja pra você!
                    </Text>

                    <Text top="50px" bold>
                      9 - Quais os canais de atendimento do Cartão Americanas?
                    </Text>

                    <Text top="20px" weight="300">
                      Para informações sobre faturas, pagamentos, renegociação
                      de dívidas, envio de senhas e outras, ligue para o Banco
                      do Brasil.<br></br>
                      <br></br>
                      Se você ligar de uma capital, o telefone é 4004-0001. Para
                      demais regiões, o número é 0800 701 7332. O horário de
                      atendimento é 24 horas, todos os dias.<br></br>
                      <br></br>
                      Para mais informações, você também pode acessar o site:{' '}
                      <a
                        href="https://www.bb.com.br/pbb/pagina-inicial/voce/produtos-e-servicos/cartoes/cartao-de-credito-ame#/"
                        target="_blank"
                      >
                        clique aqui
                      </a>
                      .
                    </Text>

                    <Text top="50px" bold>
                      10 - Como sugerir imóveis para locação?
                    </Text>

                    <Text top="20px" weight="300">
                      Para cadastrar ofertas de imóveis,{' '}
                      <a
                        href="https://ri.lasa.com.br/expansao-oferta-de-imoveis"
                        target="_blank"
                      >
                        clique aqui
                      </a>{' '}
                      e entre em nosso site.
                    </Text>

                    <Text top="50px" bold>
                      11 - Como conseguir patrocínio e parceria com as lojas
                      físicas?
                    </Text>

                    <Text top="20px" weight="300">
                      Para que o seu pedido seja avaliado, é só enviar uma
                      descrição do projeto para o e-mail do SAC Lojas Físicas:{' '}
                      <a href="mailto:atendimento@lojasamericanas.com.br">
                        atendimento@lojasamericanas.com.br
                      </a>
                      .
                    </Text>

                    <Text top="50px" bold>
                      12 - Quais as regras para a troca ou devolução de um
                      produto?
                    </Text>

                    <Text top="20px" weight="300">
                      A Política de Trocas das Lojas Americanas está de acordo
                      com o Código de Defesa do Consumidor. <br></br>
                      <br></br>
                      Produtos comprados em lojas físicas podem ser trocados em
                      qualquer uma de nossas filiais, dentro dos critérios
                      estabelecidos pela Companhia, que variam conforme o
                      departamento do produto. É indispensável apresentar o
                      cupom fiscal. <br></br>
                      <br></br>
                      Para solicitar análise de troca, é preciso ir a uma de
                      nossas lojas. Para mais informações, acesse a aba de
                      Política de trocas neste mesmo site.
                    </Text>

                    <Text top="50px" bold>
                      13 - Como obter segunda via de documentos?
                    </Text>

                    <Text top="20px" weight="300">
                      Para obter segunda via do comprovante fiscal de compra, do
                      certificado de Garantia Estendida e do certificado do
                      Seguro Roubo e Furto, é só ir a uma loja física, informar
                      o seu CPF, data da compra, loja onde comprou e forma de
                      pagamento, entre outros dados. <br></br>
                      <br></br>
                      Para saber o endereço de nossas lojas,{' '}
                      <a href="https://nossaslojas.americanas.com.br">
                        clique aqui
                      </a>
                      .
                    </Text>

                    <Text top="50px" bold>
                      14 - O que é Ame Digital?{' '}
                    </Text>

                    <Text top="20px" weight="300">
                      Ame é um novo jeito de pagar. Você faz tudo pelo celular!
                      <br></br>
                      <br></br>
                      Com o aplicativo, você paga suas compras muito mais rápido
                      nas Lojas Americanas e na Americanas.com. E ainda ganha
                      cashback, ou seja, parte do valor de alguns produtos
                      selecionados volta para a sua conta Ame. Para mais
                      informações,{' '}
                      <a
                        href="https://www.amedigital.com/duvidas"
                        target="_blank"
                      >
                        clique aqui
                      </a>
                      .
                    </Text>

                    <Text top="50px" bold>
                      15 - Quais as formas de pagamento aceitas nas lojas
                      físicas?
                    </Text>

                    <Text top="20px" weight="300">
                      Você pode pagar suas compras com:
                    </Text>

                    <Lista>
                      <li>
                        <Text weight="300">
                          Cartão de crédito: Amex, Banese , Banescard,
                          Banricompras, Bradescard, Cabal, Credsystem (Mais),
                          Credz, Discovery, Dinners, Elo, Hipercard, Mastercard
                          (Redecard), Sicred, Sorocred
                        </Text>{' '}
                      </li>

                      <li>
                        <Text weight="300">
                          Cartão de débito: Banescard, Banrisul, Cabal, Elo,
                          Redeshop, Hipercard, Sicredi e Visa Electron.
                        </Text>{' '}
                      </li>

                      <li>
                        <Text weight="300">
                          Tíquete-refeição e tíquete-alimentação: Alelo
                          Alimentação (somente para produtos alimentícios),
                          Alelo Refeição e Sodexo Refeição (somente em lojas com
                          lanchonete).{' '}
                        </Text>{' '}
                      </li>

                      <li>
                        <Text weight="300">
                          Boletos: somente para compras na Americanas.com.
                        </Text>
                      </li>

                      <li>
                        <Text weight="300">
                          Cheque à vista: somente para compras em lojas físicas.
                        </Text>{' '}
                      </li>

                      <li>
                        <Text weight="300">Pagamento Digital: Ame Digital</Text>
                      </li>
                    </Lista>

                    <Text top="20px" weight="300">
                      Lembre-se: as formas de pagamento podem variar de uma loja
                      para outra. Informe-se na loja antes de comprar.
                    </Text>

                    <Text top="50px" bold>
                      16 - Como usar o Vale-Fralda e o Vale-Presente?
                    </Text>

                    <Text top="20px" weight="300">
                      Quer um jeito diferente de presentear? Está em dúvida
                      sobre o que dar num Chá de Fraldas? <br></br>
                      <br></br>
                      Os Cartões Vale-Fralda e Vale-Presente são a escolha
                      certa. Aceitos em todas as Lojas Americanas físicas, eles
                      têm validade de 12 meses. <br></br>
                      <br></br>
                      Cartões adquiridos por empresas podem ser usados a partir
                      da data de desbloqueio. Cartões comprados nas lojas são
                      ativados no momento da compra. <br></br>
                      <br></br>
                      Para saber mais sobre como usá-los, data de validade,
                      acesso a extrato e saldo,{' '}
                      <a
                        href="http://www.cartaolojasamericanas.com.br/"
                        target="_blank"
                      >
                        clique aqui
                      </a>
                      .
                    </Text>

                    <Text top="50px" bold>
                      17 - Como funcionam os Cartões de Conteúdo?
                    </Text>

                    <Text top="20px" weight="300">
                      Está buscando informações sobre nossos cartões Google
                      Play, Playstation ou algum outro? Temos uma parceria com a
                      empresa Blackhawk.{' '}
                      <a href={BlackHawkPDF} download>
                        Baixe o documento
                      </a>{' '}
                      para saber mais.
                    </Text>

                    <Text top="50px" bold>
                      18 - Se eu comprar um presente na loja física ele pode ser
                      trocado depois?
                    </Text>

                    <Text top="20px" weight="300">
                      Sim! É só pedir um Cupom Presente na hora da compra e
                      entregá-lo junto com o presente.<br></br>
                      <br></br>
                      Adoramos saber que fazemos parte de um momento especial da
                      sua vida!
                    </Text>

                    <Text top="50px" bold>
                      19 - Como eu posso trabalhar com vocês?
                    </Text>

                    <Text top="20px" weight="300">
                      Cadastre seu currículo em nossos canais oficiais:
                    </Text>
                    <Lista>
                      <li>
                        <a
                          href="http://www.linkedin.com/company/lojas-americanas"
                          target="_blank"
                        >
                          Linkedin
                        </a>
                      </li>
                      <li>
                        <a href="https://lasa.gupy.io/" target="_blank">
                          Gupy
                        </a>
                      </li>
                      <li>
                        <a href="https://talentos.lasa.com.br/" target="_blank">
                          Talentos Lasa
                        </a>
                      </li>
                      <li>
                        <a href="https://lasa.pandape.com.br/" target="_blank">
                          Trabalho em loja
                        </a>
                      </li>
                    </Lista>

                    <Text top="20px" weight="300">
                      Qualquer portal ou perfil diferente desses não é
                      verdadeiro! Fique atento e siga a gente no LinkedIn para
                      ficar por dentro das nossas vagas.
                    </Text>

                    <Text top="50px" bold>
                      20 - Como identifico os canais oficiais das lojas físicas
                      da Americanas?
                    </Text>

                    <Text top="20px" weight="300">
                      Todos os nossos canais têm selo de verificação. É muito
                      importante lembrar que a gente não pede dados como PIN do
                      seu celular ou senhas! Desconfie de contatos por meio de
                      canais não oficiais. Qualquer dúvida, envie mensagem para
                      o WhatsApp (21) 4042-0321.
                    </Text> */}

                    <Editor
                      editorState={faqContent}
                      onChange={() => null}
                      readOnly
                    />
                  </Tabela>
                </Alinhador>
              </TabPanel>

              <TabPanel className="table-polititions">
                <Alinhador isMobile={isMobile}>
                  <Tabela className="on-scrollbar" isMobile={isMobile}>
                    {/* <Text weight="bold" align="center">
                      POLÍTICA DE TROCAS DAS LOJAS AMERICANAS
                    </Text>

                    <Text weight="300" top="50px">
                      Para garantir a satisfação dos nossos clientes,
                      efetuaremos a troca dos produtos adquiridos em qualquer
                      uma de nossas Lojas, desde que:
                      <br></br>
                      <br></br>
                      1. Seja apresentado o Cupom Fiscal da compra;<br></br>
                      <br></br> 2. O produto faça parte do sortimento da loja
                      aonde se quer realizar a troca;<br></br>
                      <br></br> 3. Esteja dentro do prazo de 30 (trinta) dias
                      contados da data da compra,{' '}
                      <b>
                        exceto produtos dos departamentos de Eletro e
                        Eletrônicos e Celulares, cujo prazo para troca é de 3
                        (três) dias corridos por defeito
                      </b>
                      ;<br></br>
                      <br></br> 4. O produto esteja em perfeitas condições,
                      completo (manuais, acessórios, etiquetas, controles,
                      carregadores) e em sua embalagem original, incluindo CDs e
                      DVDs apenas na embalagem original e lacrada.<br></br>
                      <br></br>
                      5. Seja preenchido, integralmente, o formulário
                      “Declaração de Troca”.
                    </Text>

                    <Text weight="bold" align="center" top="50px">
                      Troca por Defeito
                    </Text>

                    <Text weight="300" top="50px">
                      Informamos que, para compras realizadas em lojas físicas,
                      a troca de produtos por defeito pode ocorrer em qualquer
                      uma de nossas filiais, mediante a apresentação do cupom
                      fiscal e dentro dos critérios estabelecidos na Política de
                      Troca, que varia de acordo com o departamento do produto.
                      Recomendamos comparecer a uma de nossas lojas e solicitar
                      análise de troca.
                      <br></br>
                      <br></br>
                      <b>Observações:</b>
                      <br></br>
                      <br></br> Trocamos celulares apenas por defeito de
                      fabricação, desde que estejam em perfeitas condições,
                      completos (manuais, acessórios e etiquetas) e em suas
                      embalagens originais.
                    </Text>

                    <Text weight="bold" align="center" top="50px">
                      Troca por Insatisfação
                    </Text>

                    <Text weight="300" top="50px">
                      Informamos que a Companhia esclarece que, conforme
                      permissão dada pelo Código de Defesa do Consumidor, não
                      realiza trocas por insatisfação pessoal.
                    </Text>

                    <Text weight="bold" align="center" top="50px">
                      Roupas Íntimas
                    </Text>

                    <Text weight="300" top="50px">
                      Por questões de higiene, não trocamos roupas íntimas. A
                      troca somente será efetuada nos casos de existência de
                      defeitos ou vícios devidamente comprovados ( CDC, arts.
                      12, 18 e 19)
                    </Text>

                    <Text weight="bold" align="center" top="50px">
                      Orientações para o Exercício da Garantia
                    </Text>

                    <Text weight="300" top="50px">
                      Em caso de defeitos em produtos durante o período da
                      garantia, solicitamos o encaminhamento para uma das
                      Assistências Técnicas indicadas pelos fabricantes, com o
                      respectivo cupom fiscal de compra.<br></br>
                      <br></br> Se o defeito ou avaria não for sanado no prazo
                      máximo de 30 (trinta) dias, e estando o produto coberto
                      pela garantia prevista no art. 26 do CDC, o cliente deverá
                      comparecer à Loja com a comprovação da impossibilidade de
                      conserto mediante laudo da Assistência Técnica, para a
                      troca do produto.
                    </Text> */}
                    <Editor
                      editorState={polititionsContent}
                      onChange={() => null}
                      readOnly
                    />
                  </Tabela>
                </Alinhador>
              </TabPanel>
            </Tabs>
          </Content>
        </Container>
      )}
    </>
  );
};

export default ModalHelp;
