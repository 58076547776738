import React from 'react';
import { CarousselProvider } from './useCaroussel';
import { FilterProvider } from './useFilters';

const AppProvider = ({ children }: any) => {
  return (
    <CarousselProvider>
      <FilterProvider>{children}</FilterProvider>
    </CarousselProvider>
  );
};

export { AppProvider };
