import listWpp from '../Services/data/lojas_wpp.json';
import api from '../Services/api';

export interface Store {
  Loja: string | number;
  Link: string;
}

export interface WppProps {
  Ativas: Store[];
}

interface Props {
  sellerStoreId: string | number;
  wppFromWeb: WppProps;
}

export const getApiListWpp = async () => {
  const response = await api.get(
    `https://${document.location.host}/static/json/lojas_wpp.json`
  );
  const list: WppProps = response.data;

  return list;
};

export const listWppElement = async ({
  sellerStoreId,
  wppFromWeb,
}: Props): Promise<Store | undefined> => {
  const customList =
    process.env.NODE_ENV === 'development' ? listWpp : wppFromWeb;
  const exists = customList.Ativas.find((i) => i.Loja === sellerStoreId);
  return exists;
};

const ListWpp = async ({
  sellerStoreId,
  wppFromWeb,
}: Props): Promise<boolean> => {
  const customList =
    process.env.NODE_ENV === 'development' ? listWpp : wppFromWeb;
  const exists = customList.Ativas.find((i) => i.Loja === sellerStoreId);
  return !!exists;
};

export default ListWpp;
