import styled, { css } from 'styled-components';

interface ContainerProps {
  isMobile?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  z-index: 10;
  height: ${(props) => (props.isMobile ? 'auto' : '168px')};
  display: flex;
  justify-content: center;
  margin-top: ${(props) => (props.isMobile ? '0px' : '0px')};

  ${({ isMobile }) =>
    !isMobile &&
    css`
      background: #f2f2f2;
    `}
`;

interface ContentProps {
  screen: number;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

interface TitleProps {
  isMobile?: boolean;
}

export const Title = styled.strong<TitleProps>`
  font-size: ${(props) => (props.isMobile ? '16px' : '20px')};
  font-weight: bold;
  color: #666666;
  font-family: Helvetica Neue;
`;

interface SubTitleProps {
  isMobile?: boolean;
}

export const SubTitle = styled.span<SubTitleProps>`
  font-size: ${(props) => (props.isMobile ? '16px' : '18px')};
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-weight: 320;
  margin-top: 5px;
`;

interface BoxProps {
  isMobile?: boolean;
}
export const Box = styled.div<BoxProps>`
  display: flex;
  align-items: center;
  border-radius: 16px;
  height: ${({ isMobile }) => (isMobile ? '49px' : '88px')};
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 8px #0000001a;
  flex: 1;
  max-width: 1280px;
  margin-top: 23px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 10px 10px 12px 10px;
    `}
`;

interface InfoProps {
  isMobile?: boolean;
}

export const Info = styled.div<InfoProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;

  padding: 0 30px 0px 24px;

  .truncate-overflow {
    /* max-width: 252px; */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0px;
    `}

  h2 {
    color: #999999;
    font-size: ${({ isMobile }) => (isMobile ? '12px' : '16px')};
    font-family: Helvetica Neue, Regular;
  }

  h3 {
    color: #666666;
    font-size: ${({ isMobile }) => (isMobile ? '12px' : '16px')};
    font-family: Helvetica Neue, Regular;
    margin-top: ${({ isMobile }) => (isMobile ? '2px' : '6px')};
    font-weight: 300;
    margin-right: 64px;
  }
`;

interface ActionProps {
  isMobile?: boolean;
}

export const Action = styled.div<ActionProps>`
  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-size: ${({ isMobile }) => (isMobile ? '12px' : '16px')};
    font-family: Helvetica Neue, Regular;

    cursor: pointer;
  }
`;

interface ContentBoxProps {
  isMobile?: boolean;
}

export const ContentBox = styled.div<ContentBoxProps>`
  margin: 0 30px;
  flex: 1;
  display: flex;
  align-items: center;

  svg {
    fill: #666666;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 0 12px;
    `}
`;
