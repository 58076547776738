import listNotaFiscal from '../Services/data/nota_fiscal.json';
import api from '../Services/api';

export interface Nota {
  uf: string;
  url?: string;
  working: boolean;
  directLink: boolean;
  replaceString: boolean;
}

interface SearchProps {
  uf: string;
  listFromWeb: Nota[];
}

export const getApiListNote = async () => {
  const response = await api.get(
    `https://${document.location.host}/static/json/nota_fiscal.json`
  );
  const list: Nota[] = response.data;

  return list;
};

export const listNoteElement = async ({
  uf,
  listFromWeb,
}: SearchProps): Promise<Nota | undefined> => {
  const customList =
    process.env.NODE_ENV === 'development' ? listNotaFiscal : listFromWeb;
  const exists = customList.find((i: Nota) => i.uf === uf);
  return exists;
};

const listNote = async ({ uf, listFromWeb }: SearchProps): Promise<boolean> => {
  const customList =
    process.env.NODE_ENV === 'development' ? listNotaFiscal : listFromWeb;
  const exists = customList.find((i: Nota) => i.uf === uf);
  return !!exists;
};

interface HandleProps {
  chaveNfe: string;
  uf: string;
  list: Nota[];
}
export const handleNfeLink = async ({
  chaveNfe,
  uf,
  list,
}: HandleProps): Promise<any> => {
  const nfe = chaveNfe.replace(/([^0-9]+)/g, '');

  const findUF = await listNoteElement({
    uf: uf,
    listFromWeb: list,
  });

  if (findUF) {
    const { working, directLink, replaceString, url } = findUF;

    if (!working) {
      console.log('NFE desativada para este estado.');
      return;
    }

    if (directLink) {
      const win = window.open(url, '_blank');
      win?.focus();
      return;
    }

    if (replaceString) {
      const customUrl = url?.replace('%codigo%', nfe);

      const win = window.open(customUrl, '_blank');
      win?.focus();
      return;
    }
  }
};

export default listNote;
