import { addHours, format, isAfter } from 'date-fns';
import { addDays, isBefore } from 'date-fns/esm';
import { ptBR } from 'date-fns/locale';
import queryString from 'query-string';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ReactComponent as Aamericanas } from '../../Assets/a-americanas.svg';
import { ReactComponent as Clock } from '../../Assets/clock.svg';
import { ReactComponent as BasketIcon } from '../../Assets/ICONE_CESTA.svg';
import { ReactComponent as InfoIcon } from '../../Assets/info.svg';
import { ReactComponent as LocalIcon } from '../../Assets/local_branco.svg';
import RouteIcon from '../../Assets/route_icon.png';
import { ReactComponent as WhatsImg } from '../../Assets/WHATS_ICONE.svg';
import { useCaroussel } from '../../Hooks/useCaroussel';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import Config from '../../Utils/Config';
import ListCovid, { CovidProps, getApiListCovid } from '../../Utils/Covid';
import getDay from '../../Utils/getday';
import ListWpp, { getApiListWpp, WppProps } from '../../Utils/ListWpp';
import ListLocal, { getApiListLocal, LocalProps } from '../../Utils/Local';
import useWindowDimensions from '../../Utils/size';
import GoogleMaps from '../GoogleMaps';
import {
  ActionFakeBox,
  BoxLayer,
  ButtonOtherStores,
  Buttons,
  BuyButton,
  Container,
  Content,
  FakeBg,
  FooterCard,
  Header,
  HeaderActions,
  HeaderInfo,
  Info,
  LogoStore,
  MiddleContent,
  ProductButton,
  Tag,
  TagsContent,
  WhatsButton,
  WhatsSolidButton,
  WithoutServiceAlert,
  Wrapper,
} from './styles';

interface CardProps {
  title: string;
  address: string;
  distance?: number;
  isPar?: boolean;
  index: number;
  schedules?: any;
  canPickUpInStore?: boolean;
  canShipFromStore?: boolean;
  sellerStoreId: number | string;
  carousel?: boolean;
  selected?: boolean;
  onClickProducts?: (item: any) => void;
  onClickWpp?: (item: any) => void;
  onClickBuy?: (item: any) => void;
  item?: any;
  onClickCard?: (item: any) => void;
  containerStyle?: object;
  isMap: 'lista' | 'mapa' | undefined;
  variant: any;
  currentLegions?: any[];
}

const CardStoreV4Details: FC<CardProps> = ({
  title,
  address,
  distance,
  isPar,
  index,
  schedules,
  canPickUpInStore,
  canShipFromStore,
  sellerStoreId,
  carousel = false,
  selected = false,
  onClickProducts,
  onClickWpp,
  item,
  onClickBuy,
  onClickCard,
  containerStyle,
  isMap,
  variant,
  currentLegions,
}) => {
  const [validatePNLAtag, setValidatePNLAtag] = useState(false);
  const [validateSFStag, setValidateSFStag] = useState(false);
  const [validateSFStagName, setValidateSFStagName] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);

  const [listLocal, setListLocal] = useState<LocalProps>({} as LocalProps);
  const [listWpp, setListWpp] = useState<WppProps>({} as WppProps);
  const [listCovid, setListCovid] = useState<CovidProps>({} as CovidProps);
  const [covidStore, setCovidStore] = useState(false);
  const [wppStore, setWppStore] = useState(false);
  const [localStore, setLocalStore] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const history = useHistory();
  const isMobile = useWindowDimensions();
  const { storeSelected } = useCaroussel();
  const { colors } = useTheme();
  // console.log('storeSelected: ', storeSelected);
  const mapRef = useRef<any>();
  const distanceParsed = useMemo(() => {
    let d = '0 m';

    if (distance) {
      d = distance > 1 ? `${distance.toFixed(1)} km ` : `${distance * 1000} m `;
    }

    return d;
  }, [distance]);

  const multiploTres = useMemo(() => {
    const multiplo = (index + 1) % 3 === 0 && index !== 0;
    return multiplo;
  }, [index]);

  const day = useMemo(() => {
    if (!schedules) return null;
    const d = getDay(new Date());

    if (d) {
      const hours = schedules[d];

      if (hours && hours.start && hours.end) {
        const [hourStart, minutesStart] = hours.start.split(':');
        const [hourEnd, minutesEnd] = hours.end.split(':');

        const minutesS = minutesStart === '00' ? '' : `:${minutesStart}`;
        const minutesE = minutesEnd === '00' ? '' : `:${minutesEnd}`;
        return `${hourStart}${minutesS}h às ${hourEnd}${minutesE}h`;
      } else {
        return null;
      }
    }
  }, [schedules]);

  const linkStore = useMemo(() => {
    const link = canShipFromStore
      ? `https://www.americanas.com.br/lojas-proximas/33014556000196/${sellerStoreId}/ship`
      : `https://www.americanas.com.br/lojas-proximas/33014556000196/${sellerStoreId}/pick-up`;

    return link;
  }, [canShipFromStore, sellerStoreId]);

  const handleDetails = useCallback(
    (id: string) => {
      const { lat, lng, name, pag, feather, footer, near } = parsed;

      let uri = `?lat=${lat}&lng=${lng}&name=${name}&pag=${pag}`;

      if (feather !== 'undefined') {
        uri = uri + `&feather=${feather}`;
      }

      if (footer !== 'undefined') {
        uri = uri + `&footer=${footer}`;
      }

      if (near !== 'undefined') {
        uri = uri + `&near=${near}`;
      }

      // history.push({
      //   pathname: `/loja/${id}/detalhes`,
      //   search: uri,
      // });

      history.push({
        pathname: `/loja/${id}`,
        search: uri,
      });
    },
    [history, parsed]
  );

  const handleOpenCard = useCallback(() => {
    setCollapsed((prev) => {
      const newToggleValue = !prev;

      if (newToggleValue) {
        HandleEvents({
          isMobile,
          eventAction: 'storecard',
          eventCategory: 'nossaslojas',
          eventLabel: 'expandir card',
          eventValue: undefined,
          cookies,
        });
      }

      return newToggleValue;
    });
  }, [cookies, isMobile]);

  useEffect(() => {
    ListWpp({ sellerStoreId: sellerStoreId, wppFromWeb: listWpp })
      .then((e: boolean) => setWppStore(e))
      .catch((e: any) => console.log('erro2: ', e));
  }, [listWpp, sellerStoreId]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListWpp()
        .then((e) => setListWpp(e))
        .catch((e) => console.log('erro ao caregar listaWpp: ', e));
    }
  }, []);

  useEffect(() => {
    ListCovid({ sellerStoreId, wppFromWeb: listCovid })
      .then((e: boolean) => setCovidStore(e))
      .catch((e: any) => console.log('erro2 listaCovid: ', e));
  }, [listCovid, sellerStoreId]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListCovid()
        .then((e) => setListCovid(e))
        .catch((e) => console.log('erro ao caregar listaCovid: ', e));
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListLocal()
        .then((e) => setListLocal(e))
        .catch((e) => console.log('erro ao carregar listaLocal: ', e));
    }
  }, []);

  useEffect(() => {
    ListLocal({ sellerStoreId: sellerStoreId, wppFromWeb: listLocal })
      .then((e: boolean) => setLocalStore(e))
      .catch((e: any) => console.log('erro2: ', e));
  }, [listLocal, sellerStoreId]);

  const validateButtons = useMemo(() => {
    if (!isMobile) {
      return true;
    }

    if (isMobile && collapsed) {
      return true;
    }

    if (isMobile && isMap === 'lista') {
      return true;
    }

    return false;
  }, [isMobile, collapsed, isMap]);

  const validateStartAndEndHours = useCallback((hoursScheduled: any) => {
    // console.log('entrou no validate');
    let startHour;
    let endHour;
    const d = getDay(new Date());

    if (d) {
      const hours = hoursScheduled[d];
      if (hours && hours.start && hours.end) {
        startHour = hours.start;
        endHour = hours.end;
      } else {
        return null;
      }
    }
    // console.log('entrou no validate2');
    const today = new Date();
    const todayDay = today.getDate();
    const todayMonth = today.getMonth() + 1;
    const todayYeay = today.getFullYear();

    startHour = new Date(
      `${todayYeay}-${todayMonth}-${todayDay} ${startHour}:00`
    );
    endHour = new Date(`${todayYeay}-${todayMonth}-${todayDay} ${endHour}:00`);
    // console.log('entrou no validate 3');
    return {
      startHour,
      endHour,
    };
  }, []);

  useEffect(() => {
    const load = async () => {
      if (!schedules) return null;
      // console.log('item: ', item);
      // console.log('parsed: ', parsed);
      const resp: any = validateStartAndEndHours(schedules);

      // console.log('currentLegions: ', currentLegions);
      if (!resp?.startHour || !resp?.endHour) return '';
      const { startHour, endHour } = resp;
      const dayIncreased = addHours(new Date(), 2);
      const storeClosedAfterTwoHoursFromNow = isAfter(dayIncreased, endHour);

      const {
        canShipFromStore,
        active,
        distance,
        deliveryDaysSFS,
        address,
        legions,
      } = item.allStore;

      if (!canShipFromStore || !active) {
        setValidateSFStag(false);
        return;
      }

      if (
        address &&
        address.city &&
        address.city !== 'Rio De Janeiro' &&
        distance > 7
      ) {
        setValidateSFStag(false);
        return;
      }

      if (
        address &&
        address.city &&
        address.city === 'Rio De Janeiro' &&
        distance <= 7
      ) {
        let verifyLegion = false;

        legions.forEach((i: any) => {
          if (currentLegions?.includes(i)) {
            verifyLegion = true;
          }
        });

        if (verifyLegion) {
          setValidateSFStag(true);
          setValidateSFStagName('receba em 3 horas');
          return;
        } else {
          setValidateSFStag(false);
          return;
        }
      }

      if (deliveryDaysSFS === 0 && !storeClosedAfterTwoHoursFromNow) {
        setValidateSFStag(true);
        setValidateSFStagName('receba em 3 horas');
        return;
      }

      if (deliveryDaysSFS === 0 && storeClosedAfterTwoHoursFromNow) {
        setValidateSFStag(true);
        setValidateSFStagName('receba amanhã');
        return;
      }

      if (deliveryDaysSFS === 1) {
        setValidateSFStagName('receba amanhã');
        setValidateSFStag(true);
      }

      if (deliveryDaysSFS > 1) {
        const makeDay = addDays(new Date(), deliveryDaysSFS);
        const dayFormatted = format(makeDay, 'EEEE', {
          locale: ptBR,
        });
        setValidateSFStagName(`receba ${dayFormatted}`);
        setValidateSFStag(true);
      }

      const storeOpendNow = isBefore(new Date(), startHour);

      if (!storeOpendNow && deliveryDaysSFS === 0) {
        const deliveryHour = addHours(startHour, 2);
        const deliveryHourFormatted = format(deliveryHour, 'HH:mm');
        setValidateSFStagName(`receba até às ${deliveryHourFormatted}`);
        setValidateSFStag(true);
        return;
      }
    };

    load();
  }, [schedules, item, parsed, currentLegions]);

  useEffect(() => {
    const load = async () => {
      if (!item?.allStore) return;

      const {
        canPickUpInStore,
        canPickUpInStoreScheduled,
        distance,
        active,
        deliveryDaysPNLA,
      } = item.allStore;

      if ((!canPickUpInStore && !canPickUpInStoreScheduled) || !active) {
        setValidatePNLAtag(false);
        return;
      }

      if (distance > 35) {
        setValidatePNLAtag(false);
        return;
      }

      if (deliveryDaysPNLA !== null && deliveryDaysPNLA >= 0) {
        setValidatePNLAtag(true);
        return;
      }
    };

    load();
  }, [item]);

  const handleGeoLocation = useCallback(
    (type: 'maps' | 'browser' | 'waze' | 'mapsBrowser') => {
      // setShowNavigationOptions(false);
      console.log('type: ', type);
      if (type === 'mapsBrowser') {
        const showPosition = (position: any) => {
          console.log('position: ', position);
          console.log(
            'Latitude: ' +
              position.coords.latitude +
              '<br>Longitude: ' +
              position.coords.longitudes
          );

          const win = window.open(
            `https://maps.google.com/?daddr=${item.geolocation.lat},${item.geolocation.lng}&saddr=${position.coords.latitude},${position.coords.longitude}&directionsmode=driving`,
            '_blank'
          );
          win?.focus();
        };
        if (navigator.geolocation) {
          console.log('position entrou');
          navigator.geolocation.getCurrentPosition(showPosition);
        } else {
          console.log('Geolocation is not supported by this browser.');
        }
      }

      // if (type === 'browser') {
      //   const showPosition = (position: any) => {
      //     console.log('position: ', position);
      //     console.log(
      //       'Latitude: ' +
      //         position.coords.latitude +
      //         '<br>Longitude: ' +
      //         position.coords.longitude
      //     );

      //     const storePosition = coordsCenter;

      //     const request = {
      //       origin: { lat: coordsCenter.lat, lng: coordsCenter.lng },
      //       destination: {
      //         lat: position.coords.latitude,
      //         lng: position.coords.longitude,
      //       },
      //       // waypoints: markerLocations,
      //       // optimizeWaypoints: true,
      //       travelMode: 'BICYCLING',
      //     };

      //     MapRef?.current?.directionsService.route(
      //       request,
      //       (result: any, status: any) => {
      //         if (status === 'OK') {
      //           // Session.set('directionsService', result);
      //           MapRef?.current?.directionsDisplay.setDirections(result);

      //           // setTimeout(() => {
      //           //   MapRef?.current?.setOptions({ preserveViewport: true });
      //           // }, 1000)
      //         }
      //       }
      //     );
      //   };
      //   if (navigator.geolocation) {
      //     console.log('position entrou');
      //     navigator.geolocation.getCurrentPosition(showPosition);
      //   } else {
      //     console.log('Geolocation is not supported by this browser.');
      //   }
      // }

      // if (type === 'waze') {
      //   const win = window.open(
      //     `https://waze.com/?ll=${coordsCenter.lat},${coordsCenter.lng}&navigate=yes`,
      //     '_blank'
      //   );
      //   // const win = window.open(
      //   //   `waze://?ll=${coordsCenter.lat},${coordsCenter.lng}&navigate=yes`,
      //   //   '_blank'
      //   // );
      //   win?.focus();
      // }

      if (type === 'maps') {
        const win = window.open(
          `https://maps.google.com/?daddr=${item.geolocation.lat},${item.geolocation.lng}&directionsmode=driving`,
          '_blank'
        );
        win?.focus();
      }
    },
    [item]
  );

  const handleBack = useCallback(() => {
    const {
      lat,
      lng,
      name,
      pag,
      feather,
      footer,
      chave,
      epar,
      path,
      local,
      withoutLocal,
    } = parsed;
    // HandleAnalyticsEvents({ store,  console.log('local: ', local);
    const normalizeLat = typeof lat === 'string' ? lat : String(lat?.[0]);
    const normalizeLng = typeof lng === 'string' ? lng : String(lng?.[0]);

    // const uri = lat
    //   ? `?lat=${normalizeLat}&lng=${normalizeLng}&name=${name}&pag=${pag}&feather=${feather}&footer=${footer}&chave=${Config.key}&local=${local}&withoutLocal=${withoutLocal}`
    //   : `?lat=${item.geolocation.lat}&lng=${item.geolocation.lng}&name=${
    //       item.name
    //     }&pag=${1}&feather=${feather}&footer=${footer}&epar=${epar}&local=${local}&withoutLocal=${withoutLocal}`;
    let uri = lat
      ? `?lat=${typeof lat === 'object' ? lat[0] : lat}&lng=${
          lng && typeof lng === 'object' ? lng[0] : lng
        }&name=${
          name && typeof name === 'object' ? name[0] : name
        }&pag=${pag}&feather=${feather}&footer=${footer}&chave=${Config.key}`
      : `?lat=${item.geolocation.lat}&lng=${item.geolocation.lng}&name=${
          item.name
        }&pag=${1}&feather=${feather}&footer=${footer}&epar=${epar}&local=${local}&withoutLocal=${withoutLocal}`;

    uri = uri + `&from=${parsed.from}`;

    history.push({
      pathname: `/${path}`,
      search: uri,
    });
  }, [history, parsed, item]);

  const makeBackButton = useMemo(() => {
    return (
      <WhatsSolidButton
        style={{ height: 28, width: 114 }}
        isMobile={isMobile}
        onClick={() => {
          // setShowNavigationOptions(true);
          handleBack();

          HandleEvents({
            isMobile,
            eventAction: 'storepage',
            eventCategory: 'nossaslojas',
            eventLabel: parsed.from
              ? parsed.from === 'lista'
                ? 'voltar pra lista'
                : 'voltar pro mapa'
              : 'voltar pra lista',
            eventValue: undefined,
            cookies,
          });
        }}
        // isMapView={activeButton === 'mapa'}
      >
        <FiChevronLeft
          size={20}
          color={colors.redPrimary}
          style={{ marginTop: 1 }}
        />
        <span style={{ marginTop: 1 }}>
          {parsed.from
            ? parsed.from === 'lista'
              ? 'voltar pra lista'
              : 'voltar pro mapa'
            : 'voltar pra lista'}
        </span>
      </WhatsSolidButton>
    );
  }, [isMobile, colors, handleBack, parsed.from, cookies]);

  const handleOtherStore = useCallback(() => {
    HandleEvents({
      isMobile,
      eventAction: 'storepage',
      eventCategory: 'nossaslojas',
      eventLabel: 'comprar em outra loja',
      eventValue: undefined,
      cookies,
    });

    const win = window.open(
      `https://www.americanas.com.br/lojas-proximas?type=americanas&chave=o2o_compraremoutraloja_00_0_0_nossaslojas`,
      '_blank'
    );
    win?.focus();
  }, [cookies, isMobile]);

  return (
    <Wrapper withTags={validateSFStag || validatePNLAtag}>
      <Container
        isPar={isPar}
        multiploTres={multiploTres}
        isMobile={isMobile}
        carousel={carousel}
        index={index}
        selected={selected}
        style={containerStyle}
        isMap={isMap === 'mapa'}
        // onClick={isMobile && isMap === 'mapa' ? handleOpenCard : onClickCard}
        collapsed={!collapsed && isMap === 'mapa'}
      >
        {/* {makeBackButton} */}
        <Content>
          <Info
            isMobile={isMobile}
            open={!!(item.open || covidStore)}
            small={variant === 'NEW'}
          >
            {/* <h1>{Truncate(title, 21)}</h1> */}
            <h1
              onClick={
                isMobile && isMap === 'mapa' ? handleOpenCard : onClickCard
              }
            >
              {title}
            </h1>
            {/* <BoxLayer isMobile={isMobile} open={!!(item.open || covidStore)}>
              <Clock width={16} height={16} style={{ marginRight: 8 }} />
              {item.open ? (
                covidStore ? (
                  <h2>aberta para pedidos online</h2>
                ) : (
                  <h2>
                    aberta
                    <strong>{day || 'Horário Indisponível'}</strong>
                  </h2>
                )
              ) : covidStore ? (
                <h2>aberta para pedidos online</h2>
              ) : (
                <h2>
                  fechada
                  <strong>{day || 'Horário Indisponível'}</strong>
                </h2>
              )}
            </BoxLayer> */}

            {/* <div>
            <p className="truncate-overflow">
              <strong>{distanceParsed}</strong>
              {address}
            </p>
          </div> */}
          </Info>
        </Content>
        <FooterCard
          isMobile={isMobile}
          open={!!(item.open || covidStore)}
          onClick={isMobile && isMap === 'mapa' ? handleOpenCard : onClickCard}
        >
          <MiddleContent>
            {/* <Distance>
            <span>{distanceParsed}</span>
          </Distance> */}
            <p>
              <strong>{distanceParsed}</strong>
              {address}
            </p>
          </MiddleContent>

          {!!validateButtons && (
            <div>
              {(validateSFStag || validatePNLAtag) && (
                <TagsContent>
                  {!!validateSFStag && (
                    <Tag style={{ display: 'none' }}>
                      <span>receba em 2h</span>
                    </Tag>
                  )}

                  {validatePNLAtag && (
                    <Tag blue>
                      <span>agende para retirar na loja</span>
                    </Tag>
                  )}
                </TagsContent>
              )}

              {(validateSFStag || validatePNLAtag) && (
                <Buttons
                  wppStore={wppStore}
                  variant={variant}
                  isMobile={isMobile}
                >
                  <div onClick={onClickBuy}>
                    <BuyButton
                      isMobile={isMobile}
                      variant={variant}
                      big={!wppStore}
                    >
                      <BasketIcon />
                      <span>comprar</span>
                    </BuyButton>
                  </div>

                  {variant === 'NEW' && wppStore && (
                    <div onClick={onClickWpp}>
                      <ProductButton isMobile={isMobile} variant={variant}>
                        <WhatsImg />
                        <span>whatsapp</span>
                      </ProductButton>
                    </div>
                  )}
                </Buttons>
              )}
            </div>
          )}
        </FooterCard>

        <ActionFakeBox
          onClick={isMobile && isMap === 'mapa' ? handleOpenCard : onClickCard}
        />
      </Container>

      {!validateSFStag && !validatePNLAtag && (
        <>
          <WithoutServiceAlert>
            <InfoIcon width={20} height={20} />
            <p>
              A loja está com a entrega e retirada<br></br> indisponível no
              momento.
            </p>
          </WithoutServiceAlert>

          <div style={{ position: 'absolute', bottom: 0 }}>
            <ButtonOtherStores onClick={handleOtherStore}>
              <span>ver outras lojas</span>
            </ButtonOtherStores>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default CardStoreV4Details;
