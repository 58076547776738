import React, { FC } from 'react';
import { Container, TitleLocalBox, Title, Placeholder } from './styles';
import Empty from '../../Assets/empty.png';
import { ReactComponent as LocalIcon } from '../../Assets/local_cinza.svg';

interface Props {
  local?: boolean;
  isMobile?: boolean;
  containerStyle?: object;
  map?: boolean;
}

const PlaceholderStores: FC<Props> = ({
  local,
  isMobile,
  containerStyle = {},
  map,
}) => {
  return (
    <Container style={containerStyle}>
      {local ? (
        <TitleLocalBox isMobile={isMobile}>
          {isMobile ? (
            <>
              <div>
                <Title
                  style={{ marginTop: -4, color: '#858585' }}
                  weight="300"
                  size="14px"
                >
                  Não encontramos nenhuma
                </Title>
                <LocalIcon width={49} height={24} />
              </div>
              <div>
                <Title style={{ marginTop: 4 }} weight="300" size="14px">
                  {`${!map && 'próxima a você. '} Tente um novo endereço`}
                </Title>
              </div>
            </>
          ) : (
            <>
              <div>
                <Title style={{ marginTop: -4, color: '#858585' }} weight="300">
                  Não encontramos nenhuma
                </Title>
                <LocalIcon />
                {!map && (
                  <Title style={{ marginTop: -4 }} weight="300">
                    próxima a você
                  </Title>
                )}
              </div>
              <Title style={{ marginTop: 4 }} weight="300">
                {`${map && 'próxima a você.'} Tente um novo endereço.`}
              </Title>
            </>
          )}
        </TitleLocalBox>
      ) : (
        <Title size="16px" top="100px" bottom="0px" align="center" weight="300">
          Não encontramos nenhuma loja próxima <br></br>a você. Tente um novo
          endereço
        </Title>
      )}

      {/* <Placeholder src={Empty} isMobile={isMobile} /> */}
    </Container>
  );
};

export default PlaceholderStores;
