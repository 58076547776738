import { getDay } from 'date-fns';

const today = new Date();
const day = getDay(today);

const getDayOfWeek = (d: number) => {
  let x;
  switch (d) {
    case 0:
      x = 'dom';
      break;
    case 1:
      x = 'seg';
      break;
    case 2:
      x = 'ter';
      break;
    case 3:
      x = 'qua';
      break;
    case 4:
      x = 'qui';
      break;
    case 5:
      x = 'sex';
      break;
    case 6:
      x = 'sab';
      break;
  }

  return x;
};

export interface DayProp {
  [key: string]: {
    hour: string;
    times: number;
    qtd: number;
    average: string;
    percent: string;
  };
}

export interface DaysProps {
  [key: string]: {
    hours: DayProp;
  };
}

export default (days: DaysProps, manualDay: number): any => {
  const currentDay =
    manualDay === 0 || manualDay ? getDayOfWeek(manualDay) : getDayOfWeek(day);

  if (!currentDay || !days[currentDay]) return;

  const myDays = days[currentDay].hours;

  return myDays;
};
