interface StoreProps {
  name: string;
  address: string;
  open: boolean;
  schedules: any;
  canShipFromStore: boolean;
  canPickUpInStore: boolean;
  sellerStoreId: string;
  geolocation: {
    lat: number;
    lng: number;
  };
}

interface Props {
  store?: StoreProps;
  eventCustomName?: string;
  interaction?: string;
  customPayload?: any;
}

const eventHandled = (e: any) => {
  console.log('disparou o evento: ', e);
};

const cleanEvent = (e: any) => {
  console.log('removeu o evento: ', e);
};

const HandleAnalyticsEvents = ({
  store,
  eventCustomName,
  interaction,
  customPayload,
}: Props): void => {
  const nameEvent = 'americanasStores:storeInteraction';

  const payload = {
    detail: {
      store: {
        name: store?.name,
        id: store?.sellerStoreId,
      },
      interaction,
    },
  };
  const eventClick = new CustomEvent(
    eventCustomName || nameEvent,
    customPayload || payload
  );

  window.addEventListener(eventCustomName || nameEvent, eventHandled);
  document.addEventListener(eventCustomName || nameEvent, eventHandled);

  window.dispatchEvent(eventClick);
  document.dispatchEvent(eventClick);

  window.removeEventListener(eventCustomName || nameEvent, cleanEvent);
  document.removeEventListener(eventCustomName || nameEvent, cleanEvent);

  return;
};

export default HandleAnalyticsEvents;
