import listHome from '../Services/data/lojas_mapahome.json';
import api from '../Services/api';

export interface listProps {
  Loja: string;
  Nome: string;
  Longitude: string;
  Latitude: string;
}

interface Props {
  sellerStoreId: string | number;
  listFromWeb: listProps[];
}

export const getApiListHome = async () => {
  let list: listProps[] = [];

  if (process.env.NODE_ENV === 'development') {
    list = listHome;
  } else {
    const response = await api.get(
      `https://${document.location.host}/static/json/lojas_mapahome.json`
    );

    list = response.data;
  }

  return list;
};

export const listHomeElement = async ({
  sellerStoreId,
  listFromWeb,
}: Props): Promise<listProps | undefined> => {
  const customList =
    process.env.NODE_ENV === 'development' ? listHome : listFromWeb;
  const exists = customList.find((i: listProps) => i.Loja === sellerStoreId);
  return exists;
};

const ListWpp = async ({
  sellerStoreId,
  listFromWeb,
}: Props): Promise<boolean> => {
  const customList =
    process.env.NODE_ENV === 'development' ? listHome : listFromWeb;
  const exists = customList.find((i: listProps) => i.Loja === sellerStoreId);
  return !!exists;
};

export default ListWpp;
