import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  height: 100vh;
`;

export const Box = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.redPrimary};

  position: absolute;
  margin-top: 74px;
  border-radius: 16px 16px 0 0;
  z-index: 99999999999;

  span {
    color: ${({ theme }) => theme.colors.whitePrimary};
    font-size: 16px;
    font-family: Helvetica Neue, Regular;
    font: -webkit-control;
  }
`;

export const Header = styled.div`
  display: flex;

  justify-content: space-between;

  align-items: center;

  width: 100%;
  height: 59px;
  padding: 0 23px;
`;

export const ButtonClose = styled.button`
  border: 0px;

  background: transparent;
`;
