import styled, { css } from 'styled-components';

interface ContainerProps {
  isPar?: boolean;
  multiploTres?: boolean;
  isMobile?: boolean;
  carousel?: boolean;
  index?: number;
  selected?: boolean;
  isMap?: boolean;
}

interface BoxLayerProps {
  isMobile: boolean;
  open?: boolean;
}
export const BoxLayer = styled.div<BoxLayerProps>`
  margin-top: ${({ isMobile }) => (isMobile ? '-1px' : '-1px')};

  position: relative;

  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: ${(props) => (props.open ? '#eff9ea' : '#F7E6E8')}; */

  h2 {
    color: ${(props) => (props.open ? '#38B62B' : '#F40033')};
    font-family: 'Helvetica Neue, Bold';

    font: -webkit-control;
    font-size: 13px;
    font-weight: bold;
  }

  strong {
    color: #333333 !important;

    font-weight: 200 !important;

    :before {
      content: '•';
      font-size: 12px;
      color: #666666;
      margin: 0 10px;
    }
  }
`;

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.isMobile ? '16px' : '16px')};
  background: #ffffff;
  margin: ${(props) => (props.isMobile ? 'auto' : '')};
  z-index: 10;
  min-width: 347px;
  width: ${(props) => (props.isMobile ? '100%' : '413px')};
  box-shadow: 0px 2px 8px #00000019;
  /*
  @media only screen and (max-width: 414px) {
    width: ${(props) => (props.isMobile ? '100%' : '413px')};
  } */

  /* @media only screen and (max-width: 400px) {
    width: ${(props) => (props.isMobile ? '80%' : '413px')};
  } */

  @media only screen and (max-width: 374.9px) {
    min-width: 329px !important;
    width: 329px !important;
  }

  height: ${(props) => (props.isMobile ? '199px' : '199px')};
  border: ${(props) =>
    props.selected ? 'solid 1px #5bd49a' : 'solid 1px #e5e5e5'};
  border-radius: 16px;
  margin-bottom: 20px;

  margin-right: ${(props) =>
    (props.multiploTres && !props.isMobile) || props.carousel ? null : '20px'};

  ${({ isMobile, isMap }) =>
    isMobile &&
    css`
      margin-right: 0 !important;
      margin-left: 0 !important;
      margin: auto !important;
      margin-bottom: 20px !important;

      ${!isMap &&
      css`
        margin: 8px 12px !important;
      `}
    `}
`;

export const Content = styled.div`
  display: flex;
`;

interface LogoStoreProps {
  isMobile?: boolean;
  isLocal?: boolean;
}
export const LogoStore = styled.div<LogoStoreProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 80px;
  cursor: pointer;

  width: ${(props) => (props.isMobile ? '75px' : '75px')};

  @media only screen and (max-width: 374px) {
    min-width: 60px !important;
    width: 60px !important;
  }
  height: ${(props) => (props.isMobile ? '75px' : '75px')};
  background: ${({ theme }) => theme.colors.redPrimary};
  border: solid 1px #0000000f;
  border-radius: 8px;

  > img {
    width: ${(props) => (props.isMobile ? '61px' : '76px')};
  }

  > svg path {
    fill: #fff;
  }

  /* > svg {
    ${({ isLocal, isMobile }) =>
    isLocal &&
    css`
      width: ${isMobile ? '20px' : '20px'} !important;
    `}
  } */

  #Rectangle-2,
  #Rectangle,
  rect {
    fill: #fff;
  }
`;

interface InfoProps {
  isMobile?: boolean;
  open?: boolean;
  small?: boolean;
}

export const Info = styled.div<InfoProps>`
  display: flex;
  flex: 1;

  flex-direction: column;
  margin-left: ${(props) => (props.isMobile ? '15px' : '20px')};
  position: relative;
  /* justify-content: space-between; */
  height: auto;

  .truncate-overflow {
    max-width: 252px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  h1 {
    width: ${({ small }) => (small ? '90%' : '100%')};
    color: #333333;
    font-family: 'Helvetica Neue, Bold';
    font-size: 14px;
    /* margin-bottom: ${(props) => (props.isMobile ? '0px' : '0px')}; */

    cursor: pointer;
  }

  p {
    font-family: Helvetica Neue, Regular;
    font-weight: 300;
    color: #999999;
    font-size: ${(props) => (props.isMobile ? '14px' : '14px')};
    margin-top: 8px;

    /* margin-bottom: ${(props) => (props.isMobile ? '5px' : '0px')}; */
    font: -webkit-control;
    line-height: 20px;
  }

  h2 {
    color: ${({ theme, open }) =>
      open ? theme.colors.grenPrimary : theme.colors.redPrimary};
    font-weight: bold;
    font-family: Helvetica Neue, Bold;
    font-size: ${(props) => (props.isMobile ? '14px' : '14px')};
  }

  strong {
    color: #000;
    font-family: Helvetica Neue, Regular;
    font-size: 14px;
    font-weight: 200;

    font: -webkit-control;

    :after {
      content: '•';
      font-size: 12px;
      color: #000000;
      margin: 0 3px;
    }
  }

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }

  /* span {
    color: #000000;
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.regular};

    :after {
      content: '•';
      font-size: 12px;
      color: #000000;
      margin: 0 10px;
    }
  } */
`;

interface ButtonsProps {
  wppStore?: boolean;
  variant: string;
  isMobile?: boolean;
}

export const Buttons = styled.div<ButtonsProps>`
  display: flex;
  align-items: center;
  margin: 0;
  z-index: 9999999;
  width: 100%;
  position: relative;
  justify-content: ${({ wppStore }) =>
    wppStore ? 'space-between' : 'flex-start'};

  a {
    text-decoration: none;
  }

  ${({ variant }) =>
    variant === 'NEW' &&
    css`
      justify-content: space-between;
    `}

  ${({ variant, wppStore, isMobile }) =>
    !wppStore &&
    (variant === 'OLD' || variant === 'CONTROL') &&
    isMobile &&
    css`
      justify-content: space-between !important;
    `}
`;

interface ProductButtonProps {
  isMobile?: boolean;
  variant: string;
  big?: boolean;
}

export const ProductButton = styled.div<ProductButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: ${(props) => (props.isMobile ? '125px' : '160px')}; */
  height: 40px;
  border: solid 1.5px;
  color: ${({ theme }) => theme.colors.redPrimary};
  padding: 10px 0px;
  border-radius: 5px;
  z-index: 11;
  cursor: pointer;
  margin-left: 5px;
  width: 98%;

  ${({ variant, isMobile }) =>
    variant === 'NEW' &&
    css`
      /* width: ${isMobile ? '152px' : '182px'} !important;

      @media only screen and (min-width: 376px) {
        width: 152px !important;
      }

      @media only screen and (max-width: 360px) {
        width: 141px !important;
      } */
    `}
  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 10px 7px 10px 5px;
    `}
    ${({ big, variant, isMobile }) =>
    !big &&
    (variant === 'OLD' || variant === 'CONTROL') &&
    css`
      /* width: 152px !important;

      @media only screen and (max-width: 360px) {
        width: 125px !important;
      }

      @media only screen and (min-width: 376px) {
        width: 152px !important;
      } */
    `}
    ${({ big, variant, isMobile }) =>
    big &&
    (variant === 'OLD' || variant === 'CONTROL') &&
    isMobile &&
    css`
      /* width: 125px !important;

      @media only screen and (min-width: 376px) {
        width: 152px !important;
      } */
    `}
    > svg {
    width: 17px;
    height: 15px;
    margin-right: 7px;

    ${({ isMobile }) =>
      isMobile &&
      css`
        width: 15px;
        height: 13px;
      `}
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.redPrimary};
  }

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: 'Helvetica Neue, Bold';
    font-size: 14px;
    font: -webkit-control;
    font-weight: bold;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.redPrimary};

    span {
      color: ${({ theme }) => theme.colors.redPrimary};
    }

    > svg path {
      fill: ${({ theme }) => theme.colors.redPrimary};
    }
  }
`;

interface BuyButtonProps {
  isMobile?: boolean;
  variant: string;
  big?: boolean;
}
export const BuyButton = styled.div<BuyButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: ${(props) => (props.isMobile ? '125px' : '160px')}; */
  height: 40px;
  border: solid 1.5px;
  border-color: ${({ theme }) => theme.colors.redPrimary};
  padding: 10px 0px;
  border-radius: 5px;
  z-index: 11;
  cursor: pointer;
  width: ${({ variant, big }) => (variant === 'NEW' && big ? '100%' : '98%')};

  ${({ variant, isMobile }) =>
    variant === 'NEW' &&
    css`
      /* width: ${isMobile ? '152px' : '182px'} !important; */

      /* @media only screen and (max-width: 360px) {
        width: 141px !important;
      }

      @media only screen and (min-width: 376px) {
        width: 152px !important;
      } */
    `}
  /* ${({ isMobile }) =>
    isMobile &&
    css`
      padding-right: 10px;
      padding-left: 7px;
    `} */
    ${({ big, variant }) =>
    !big &&
    (variant === 'OLD' || variant === 'CONTROL') &&
    css`
      /* width: 152px !important;

      @media only screen and (max-width: 360px) {
        width: 125px !important;
      }

      @media only screen and (min-width: 376px) {
        width: 152px !important;
      } */
    `}
    ${({ big, variant, isMobile }) =>
    big &&
    variant === 'NEW' &&
    css`
      /* width: ${isMobile ? '317px' : '379px'} !important;

      @media only screen and (max-width: 360px) {
        width: 295px !important;
      }

      @media only screen and (min-width: 376px) {
        width: 152px !important;
      } */
    `}
    ${({ big, variant, isMobile }) =>
    !big &&
    (variant === 'OLD' || variant === 'CONTROL') &&
    isMobile &&
    css`
      /* width: 125px !important; */
    `}
    > svg {
    width: 17px;
    height: 15px;
    margin-right: 7px;
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.redPrimary};
  }

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: 'Helvetica Neue, Bold';
    font-size: 14px;
    font: -webkit-control;
    font-weight: bold;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.redPrimary};

    span {
      color: ${({ theme }) => theme.colors.redPrimary};
    }

    > svg path {
      fill: ${({ theme }) => theme.colors.redPrimary};
    }
  }
`;

interface WhatsButtonProps {
  isMobile?: boolean;
  withProducts?: boolean;
  variant: string;
}

export const WhatsButton = styled.div<WhatsButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ variant }) =>
    variant === 'OLD' || variant === 'CONTROL' ? '44px' : '150px'};
  height: 40px;
  border: solid 1.5px;
  border-color: ${({ theme }) => theme.colors.redPrimary};
  padding: 12px;
  border-radius: 5px;
  margin-left: ${(props) => (props.withProducts ? '10px' : '0px')};
  z-index: 11;
  margin-left: 5px;

  > svg {
    width: 25px;
    height: 25px;
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.redPrimary};
  }

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: 'Helvetica Neue, Bold';
    margin-left: 4px;
    font-size: ${(props) => (props.isMobile ? '14px' : '14px')};
    font-weight: bold;

    margin-top: -2px;
  }

  cursor: pointer;

  &:hover {
    border-color: ${({ theme }) => theme.colors.redPrimary};

    span {
      color: ${({ theme }) => theme.colors.redPrimary};
    }

    > svg path {
      fill: ${({ theme }) => theme.colors.redPrimary};
    }
  }
`;

export const Distance = styled.div`
  position: relative;
  width: 80px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1.5px #d5d5d5;
  margin-right: 16px;

  span {
    color: #333333;
    font-family: 'Helvetica Neue, Bold';
    font-size: 13px;
    font-weight: bold;
    font: -webkit-control;
    font-weight: bold;
  }
`;

export const ActionFakeBox = styled.div`
  position: absolute;
  z-index: 11;
  cursor: pointer;
  top: 0;
  left: 0;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
`;

interface FootercardProps {
  isMobile?: boolean;
  open?: boolean;
}

export const FooterCard = styled.div<FootercardProps>`
  width: 100%;

  .truncate-overflow {
    max-width: 252px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;

export const MiddleContent = styled.div`
  display: flex;
  padding: 16px 0px;
`;

export const DudeBox = styled.div`
  position: absolute;
  right: 16px;

  > svg {
    fill: #cccccc;
  }

  > svg path {
    fill: #cccccc;
  }
`;
