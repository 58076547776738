import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

interface TitleProps {
  isMobile?: boolean;
  fromModalChange?: boolean;
}

export const Title = styled.strong<TitleProps>`
  font-size: 18px;

  color: #666666;
  font-family: 'Helvetica Neue, Bold';

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-left: 5px;
      line-height: 22px;

      @media only screen and (max-width: 320px) {
        line-height: 17px;
        font-size: 14px;
      }
    `}

  ${({ fromModalChange }) =>
    fromModalChange &&
    css`
      font-size: 21px;
      margin: auto;
    `}
`;

interface SubTitleProps {
  isMobile?: boolean;
  fromModalChange?: boolean;
}

export const SubTitle = styled.span<SubTitleProps>`
  font-size: 16px;
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-weight: 400;
  margin-top: 3px;
  margin-bottom: 16px !important;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-left: 5px;
      margin-top: 12px;
    `}

  ${({ fromModalChange }) =>
    fromModalChange &&
    css`
      margin: auto;
      font-size: 18px;
      margin-top: 5px;
    `}

  @media only screen and (max-width: 361px) {
    font-size: 15px;
  }
`;
