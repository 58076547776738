export default [
  {
    Estado: 'AC',
    Path: '/AC/Rio-Branco',
  },
  {
    Estado: 'AL',
    Path: '/AL/Maceio',
  },
  {
    Estado: 'AM',
    Path: '/AM/Manaus',
  },
  {
    Estado: 'AP',
    Path: '/AP/Macapa',
  },
  {
    Estado: 'BA',
    Path: '/BA/Salvador',
  },
  {
    Estado: 'CE',
    Path: '/CE/Fortaleza',
  },
  {
    Estado: 'DF',
    Path: '/DF',
  },
  {
    Estado: 'ES',
    Path: '/ES/Vitoria',
  },
  {
    Estado: 'GO',
    Path: '/GO/Goiania',
  },
  {
    Estado: 'MA',
    Path: '/MA/Sao-Luis',
  },
  {
    Estado: 'MG',
    Path: '/MG/Belo-Horizonte',
  },
  {
    Estado: 'MS',
    Path: '/MS/Campo-Grande',
  },
  {
    Estado: 'MT',
    Path: '/MT/Cuiaba',
  },
  {
    Estado: 'PA',
    Path: '/PA/Belem',
  },
  {
    Estado: 'PB',
    Path: '/PB/Joao-Pessoa',
  },
  {
    Estado: 'PE',
    Path: '/PE/Recife',
  },
  {
    Estado: 'PI',
    Path: '/PI/Teresina',
  },
  {
    Estado: 'PR',
    Path: '/PR/Curitiba',
  },
  {
    Estado: 'RJ',
    Path: '/RJ/Rio-De-Janeiro',
  },
  {
    Estado: 'RN',
    Path: '/RN/Natal',
  },
  {
    Estado: 'RO',
    Path: '/RO/Porto-Velho',
  },
  {
    Estado: 'RR',
    Path: '/RR/Boa-Vista',
  },
  {
    Estado: 'RS',
    Path: '/RS/Porto-Alegre',
  },
  {
    Estado: 'SC',
    Path: '/SC/Florianopolis',
  },
  {
    Estado: 'SE',
    Path: '/SE/Aracaju',
  },
  {
    Estado: 'SP',
    Path: '/SP/Sao-Paulo',
  },
  {
    Estado: 'TO',
    Path: '/TO/Palmas',
  },
];
