import styled, { css } from 'styled-components';

export const Container = styled.div`
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 42px 0px 70px 0px;
`;

interface TitleLocalBoxProps {
  isMobile?: boolean;
}

export const TitleLocalBox = styled.div<TitleLocalBoxProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  svg {
    margin: 0 10px;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0 46px;
    `}
`;

interface TitleProps {
  size?: string;
  top?: string;
  bottom?: string;
  align?: string;
  weight?: string;
  isMobile?: boolean;
}
export const Title = styled.span<TitleProps>`
  font-size: ${(props) => props.size || '18px'};
  font-weight: ${(props) => props.weight || 'bold'};
  color: #858585;
  font-family: Helvetica Neue;
  text-align: ${(props) => props.align || 'left'};
  margin-right: 0;

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 12px;
      margin-left: 7px;
    `}
`;

interface PlaceholderProps {
  isMobile?: boolean;
  width?: string;
}
export const Placeholder = styled.img<PlaceholderProps>`
  width: ${(props) => (props.isMobile ? '348px' : '433px')} !important;
  margin: ${(props) => (props.isMobile ? '14px' : '0px')} !important;
`;
