import React, {
  FC,
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { MdClose } from 'react-icons/md';
import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import { IoMdSearch } from 'react-icons/io';
import {
  Container,
  Title,
  Modal,
  Close,
  ArrowButtonLeft,
  ArrowButtonRight,
  ZoommButton,
} from './styles';
import { WppProps, getApiListWpp } from '../../Utils/ListWpp';
import { useCookies } from 'react-cookie';
import { useLocation, useHistory } from 'react-router-dom';
import useWindowDimensions from '../../Utils/size';
import queryString from 'query-string';
import { Document, Page, pdfjs, PageProps } from 'react-pdf';
import ClickCardStore from '../../Utils/ClickCardStore';
import samplePDF from './pdfexample.pdf';
import OwlCarousel from 'react-owl-carousel';
import { CatalogProps } from '../../Utils/GetCatalog';
import { ReactComponent as ArrowImgIcon } from '../../Assets/icon-arrow.svg';
import Magnifier from 'react-magnifier';
import ReactOwlCarousel from 'react-owl-carousel';
import { HandleEvents } from '../../Utils/AnalyticsGA';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
  catalog: CatalogProps | undefined;
  closeFunction?: () => void;
}

let bannerIndex = 2;
let controlMagnify = false;

const EncarteWeb: FC<Props> = ({ catalog, closeFunction }) => {
  const [showPrevButton, setShowPrevButton] = useState(false);
  // const [pageRefs, setPageRefs] = useState([])
  const [activePage, setActivePage] = useState(null);
  const [pagesPDF, setPagesPDF] = useState<any[]>([]);
  const [listWpp, setListWpp] = useState<WppProps>({} as WppProps);
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfLink, setPdfLink] = useState('');
  const [activeShare, setActiveShare] = useState(false);
  // const [bannerImg, setBannerImg] = useState('');
  // const [catalog, setCatalog] = useState('');
  const [catalogView, setCatalogView] = useState(false);
  const location = useLocation();
  const imgRef = useRef<HTMLImageElement>(null);
  const isMobile = useWindowDimensions();
  const history = useHistory();
  const { v3, storeId } = queryString.parse(location.search);
  const [currentBanner, setCurrentBanner] = useState(0);
  const RefCarousel = useRef<ReactOwlCarousel>(null);
  const itemsOff = [0, 1, 2];

  const refPage = useRef<any>(null);

  // const handleShare = useCallback(async () => {
  //   const current = location.search;
  //   const shareData = {
  //     title: 'Encarte',
  //     text: `Encarte da ${store.name}`,
  //     url: window.location.href,
  //   };

  //   setActiveShare(true);

  //   if (navigator.share) {
  //     navigator
  //       .share(shareData)
  //       .then(() => {
  //         ClickCardStore({
  //           item: store,
  //           type: 'share',
  //           wppFromWeb: listWpp,
  //           isMobile,
  //           cookies,
  //         });
  //       })
  //       .catch((error: any) => console.log('Error sharing', error));
  //   } else {
  //     alert('Não é possível a ação de compartilhar neste dispositivo.');
  //   }
  // }, [location.search, store, listWpp, isMobile]);

  // const makePages = useMemo(() => {
  //   console.log('chamou: ', numPages);
  //   if (!numPages) return [];
  //   const p: any = Array.from({ length: numPages }, (curr, index) => index + 1);
  //   console.log('tamanho: ', p);
  //   const final = [0, 1, 2, ...p];

  //   setPagesPDF(final);
  // }, [numPages]);

  useEffect(() => {
    const p: any = Array.from({ length: numPages }, (curr, index) => index + 1);

    const final = [0, 1, 2];

    p.forEach((i: number) => {
      final.push(i + 2);
    });

    setPagesPDF(final || []);
  }, [numPages]);

  // useEffect(() => {
  //   console.log('chamou: ', numPages);
  //   if (!numPages) return [];
  //   const p: any = Array.from({ length: numPages }, (curr, index) => index + 1);
  //   console.log('tamanho: ', p);
  //   const final = [0, 1, 2, ...p];

  //   setPagesPDF(final || []);
  // }, [numPages]);

  const onDocumentLoadSuccess = useCallback((dados: any) => {
    const { numPages } = dados;
    console.log('dados: ', dados);
    setNumPages(numPages);
  }, []);

  useEffect(() => {
    getApiListWpp()
      .then((e) => setListWpp(e))
      .catch((e) => console.log('erro ao caregar listaWpp: ', e));
  }, []);

  const eventChangeCarousel = useCallback((e: any) => {
    // setActivePage(e.item.index);
  }, []);

  const pageSize = useMemo(() => {
    // const size = window.innerWidth / 4 + window.innerWidth * 0.02;
    // const size = window.innerWidth / 3.7;
    const size = window.innerHeight / 2;

    return size;
  }, []);

  const MarginSize = useMemo(() => {
    // const size = window.innerWidth / 4 + window.innerWidth * 0.02;
    const size = window.innerWidth / 3.7;

    return size;
  }, []);

  const handleChangeFolder = useCallback(
    (direction: 'prev' | 'next') => {
      if (direction === 'next') {
        RefCarousel.current?.to(bannerIndex + 1, 0.2);

        bannerIndex = bannerIndex + 1;
      }

      if (direction === 'prev') {
        RefCarousel.current?.to(bannerIndex - 1, 0.2);

        bannerIndex = bannerIndex - 1;

        if (bannerIndex <= 2) {
          bannerIndex = 2;
        }
      }
    },
    [RefCarousel]
  );

  const getCursorPos = (e: any, img: any) => {
    let x = 0;
    let y = 0;
    e = e || window.event;
    /* Get the x and y positions of the image: */
    const a = img.getBoundingClientRect();
    /* Calculate the cursor's x and y coordinates, relative to the image: */
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /* Consider any page scrolling: */
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x: x, y: y };
  };

  function moveMagnifier(
    e: any,
    img: any,
    bw: any,
    w: any,
    h: any,
    zoom: any,
    glass: any
  ) {
    let x: any;
    let y: any;
    /* Prevent any other actions that may occur when moving over the image */
    e.preventDefault();
    /* Get the cursor's x and y positions: */
    const pos = getCursorPos(e, img);
    x = pos?.x;
    y = pos?.y;
    /* Prevent the magnifier glass from being positioned outside the image: */
    if (x > img.width - w / zoom) {
      x = img.width - w / zoom;
    }
    if (x < w / zoom) {
      x = w / zoom;
    }
    if (y > img.height - h / zoom) {
      y = img.height - h / zoom;
    }
    if (y < h / zoom) {
      y = h / zoom;
    }
    /* Set the position of the magnifier glass: */
    glass.style.left = x - w + 'px';
    glass.style.top = y - h + 'px';
    /* Display what the magnifier glass "sees": */
    glass.style.backgroundPosition =
      '-' + (x * zoom - w + bw) + 'px -' + (y * zoom - h + bw) + 'px';
  }

  const magnify = (imgID: any, zoom: any, index: number) => {
    const paras = document.getElementsByClassName(`img-magnifier-glass`);

    while (paras[1]) {
      paras[1]?.parentNode?.removeChild(paras[0]);
    }
    const img: any = document.getElementById(imgID);
    const finalIndex = index + 1;
    console.log('index: ', finalIndex);

    const customElement: any = document.querySelector(
      `#root > div.sc-hrcaRA.bJOkOh > div > div > div.owl-carousel.owl-theme.owl-loaded.owl-drag > div.owl-stage-outer > div > div:nth-child(${finalIndex}) > div > div > div > canvas`
    );

    if (!customElement) return;

    const zoomImage = new Image();
    // zoomImage.id = `test-${index}`;
    zoomImage.width = pageSize;
    zoomImage.height = 839;
    zoomImage.src = customElement.toDataURL();

    console.log('url: ', customElement.toDataURL());

    if (!zoomImage) return;
    controlMagnify = true;
    // document?.getElementById(`test-${index + 1}`)?.appendChild(zoomImage);

    /* Create magnifier glass: */
    const glass = document.createElement('DIV');
    glass.setAttribute('class', 'img-magnifier-glass');

    /* Insert magnifier glass: */
    img?.parentElement?.insertBefore(glass, img);

    /* Set background properties for the magnifier glass: */
    glass.style.backgroundImage = customElement.toDataURL();
    glass.style.backgroundRepeat = 'no-repeat';
    glass.style.backgroundSize =
      img.width * zoom + 'px ' + img.height * zoom + 'px';
    const bw = 3;
    const w = glass.offsetWidth / 2;
    const h = glass.offsetHeight / 2;

    /* Execute a function when someone moves the magnifier glass over the image: */
    glass.addEventListener('mousemove', (e: any) =>
      moveMagnifier(e, img, bw, w, h, zoom, glass)
    );
    img.addEventListener('mousemove', (e: any) =>
      moveMagnifier(e, img, bw, w, h, zoom, glass)
    );

    /*and also for touch screens:*/
    glass.addEventListener('touchmove', (e: any) =>
      moveMagnifier(e, img, bw, w, h, zoom, glass)
    );
    img.addEventListener('touchmove', (e: any) =>
      moveMagnifier(e, img, bw, w, h, zoom, glass)
    );
  };

  const handleZoom = useCallback(() => {
    if (!catalog) return;

    HandleEvents({
      isMobile,
      eventAction: 'storepage',
      eventCategory: 'nossaslojas',
      eventLabel: 'zoom encarte',
      eventValue: undefined,
      cookies,
    });

    const win = window.open(catalog.catalog, '_blank');
    win?.focus();
  }, [catalog, cookies, isMobile]);

  return (
    <Modal>
      <Document
        file={{ url: catalog?.catalog }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Container>
          <ZoommButton onClick={handleZoom} style={{ cursor: 'pointer' }}>
            <IoMdSearch size={32} color="#ef1324" />
            <span>baixe o folheto</span>
          </ZoommButton>

          <Close onClick={closeFunction}>
            <MdClose size={39} color="#ef1324" />
          </Close>
          <OwlCarousel
            style={{
              position: 'absolute',
              height: '100vh',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'flex-end',
              bottom: 15,
              left: 0,
            }}
            key={`carousel_1`}
            className="owl-theme"
            center
            ref={RefCarousel}
            dots={false}
            onDrag={(e: any) => eventChangeCarousel(e)}
            autoWidth={true}
          >
            {pagesPDF.map((i: any, index: number) => (
              <>
                {itemsOff.includes(i) ? (
                  <div></div>
                ) : (
                  <>
                    <div
                      style={{
                        width: pageSize,
                        marginRight: index + 1 === pagesPDF.length ? 20 : 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Page width={pageSize} pageNumber={i - 2} ref={refPage} />
                    </div>
                  </>
                )}

                {index + 1 === pagesPDF.length && (
                  <div style={{ width: MarginSize }}></div>
                )}
              </>
            ))}
          </OwlCarousel>

          {/* {showPrevButton ? (
            <ArrowButtonLeft onClick={() => handleChangeFolder('prev')}>
              <BsChevronLeft color="#ef1324" size={44} />
            </ArrowButtonLeft>
          ) : (
            <div></div>
          )} */}
          <ArrowButtonLeft
            onClick={() => handleChangeFolder('prev')}
            // style={{ display: showPrevButton ? 'block' : 'none' }}
          >
            <BsChevronLeft color="#ef1324" size={44} />
          </ArrowButtonLeft>

          <ArrowButtonRight onClick={() => handleChangeFolder('next')}>
            <BsChevronRight color="#ef1324" size={44} />
          </ArrowButtonRight>

          {/* {currentBanner !== pagesPDF.length - 1 ? (
            <ArrowButtonRight onClick={() => handleChangeFolder('next')}>
              <BsChevronRight color="#ef1324" size={44} />
            </ArrowButtonRight>
          ) : (
            <div></div>
          )} */}
        </Container>
      </Document>
    </Modal>
  );
};

export { EncarteWeb };
