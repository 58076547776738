import styled, { css } from 'styled-components';

interface ContainerProps {
  isMobile: boolean;
  dados?: boolean;
}

export const Container = styled.button<ContainerProps>`
  text-align: center;

  ${({ isMobile, dados }) =>
    isMobile &&
    css`
      margin-top: auto;
      /* margin-bottom: ${(props: ContainerProps) =>
        props.dados ? '78px' : '15px'}; */
      bottom: 15px;
      top: 0;
      font-size: 15px;
      background: rgba(0, 0, 0, 0.3) !important;
      border-radius: 100px !important;

      ${dados &&
      css`
        position: relative;
        /* margin-bottom: calc(100vh / 4); */
      `}
    `}

  background: #ffffff;
  box-shadow: 0px 5px 20px #00000029;
  width: ${(props) => (props.isMobile ? '147px' : '214px')};
  height: ${(props) => (props.isMobile ? '30px' : '47px')};
  border-radius: 5px;
  z-index: 999;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: 'Helvetica Neue, Bold';
    font-size: ${(props) => (props.isMobile ? '14px' : '18px')};
    color: #666666;
    margin-top: 5px;

    ${({ isMobile }) =>
      isMobile &&
      css`
        color: #fff;
        font-family: 'Helvetica Neue';
        font-weight: 400 !important;
        margin-top: 0;
      `}
  }
`;
