import React, {
  useContext,
  ReactNode,
  createContext,
  useState,
  useCallback,
} from 'react';

interface carouselHookProps {
  storeSelected: string;
  changeStoreSelected: (list: any, event: any) => void;
}

interface Props {
  children: ReactNode;
}
const CarousselContext = createContext({} as carouselHookProps);

const CarousselProvider = ({ children }: Props) => {
  const [storeSelected, setStoreSelected] = useState('');

  const changeStoreSelected = useCallback((list, event) => {
    const { property } = event;
    const { value } = property;
    console.log('event change: ', event);
    const card = list[value];

    if (!card) return;

    setStoreSelected(card.id);
  }, []);

  return (
    <CarousselContext.Provider value={{ storeSelected, changeStoreSelected }}>
      {children}
    </CarousselContext.Provider>
  );
};

const useCaroussel = () => {
  const context = useContext(CarousselContext);

  return context;
};

export { useCaroussel, CarousselProvider };
