import React, { FC, ReactNode } from 'react';
import {
  Container,
  ButtonsBox,
  Buttons,
  Button,
  LineButtons,
  Content,
} from '../styles';
import useWindowDimensions from '../../../Utils/size';
import { MapMode } from './MapMode';
import { ListMode } from './ListMode';
import { WppProps } from '../../../Utils/ListWpp';
import { CoordsInterface } from '..';

interface WebContentProps {
  activeButton: 'mapa' | 'lista';
  handleChangeList: (data: 'mapa' | 'lista') => void;
  TypeList: boolean;
  existsCovid: boolean;
  currentLegions: any[];
  makeAddress: (data?: any) => any;
  storeSelected: any;
  selectedStore: any;
  isPar: boolean;
  handleDetails: (id: string, item: any, interaction?: string) => void;
  listWpp: WppProps;
  cookies: any;
  handleBuy: (id: string, item: any) => void;
  load: (data: any) => Promise<any>;
  loadForMap: (data: any) => Promise<any>;
  currentPag: number;
  totalPag: number;
  filter: 'all' | 'local' | 'normal';
  comparativeList: any[];
  pagAppend: number;
  parsed: any;
  dadosLocal: any[];
  dados: any[];
  makeCardStore: (data: any, i: any) => ReactNode;
  loadingFirst: boolean;
  myList: any;
  makeFilter: (type?: 'mapaMobile') => ReactNode;
  makeWppButton: ReactNode;
  totalElements: number;
  handleGeoLocation: (
    type: 'maps' | 'browser' | 'waze' | 'mapsBrowser'
  ) => void;
  visibleButtonMap: boolean;
  handleAnalytics: () => void;
  coordsTmpCenter: CoordsInterface;
  coordsCenter: CoordsInterface;
  pinsList: any[];
  handleDrag: (data?: any) => void;
  handleChangeMap: (data?: any) => void;
  handlePinClick: (data?: any) => void;
  handleZoom: (data?: any) => void;
  MapRef: any;
  changeState: (value: boolean) => void;
  hasMore: boolean;
}

const WebContent: FC<WebContentProps> = ({
  myList,
  makeFilter,
  makeWppButton,
  activeButton,
  handleChangeList,
  TypeList,
  existsCovid,
  comparativeList,
  cookies,
  dados,
  parsed,
  filter,
  totalPag,
  currentPag,
  currentLegions,
  dadosLocal,
  handleBuy,
  handleDetails,
  isPar,
  listWpp,
  load,
  makeAddress,
  makeCardStore,
  pagAppend,
  selectedStore,
  storeSelected,
  loadingFirst,
  totalElements,
  handleGeoLocation,
  visibleButtonMap,
  handleAnalytics,
  coordsTmpCenter,
  pinsList,
  coordsCenter,
  handleDrag,
  handleChangeMap,
  handlePinClick,
  handleZoom,
  MapRef,
  changeState,
  hasMore,
  loadForMap,
}) => {
  const isMobile = useWindowDimensions();

  return (
    <Container>
      <ButtonsBox isMobile={isMobile}>
        <Buttons isMobile={isMobile}>
          <Button
            isMobile={isMobile}
            type="button"
            active={activeButton === 'lista'}
            onClick={() => handleChangeList('lista')}
          >
            lista
          </Button>
          <Button
            isMobile={isMobile}
            type="button"
            active={activeButton === 'mapa'}
            onClick={() => handleChangeList('mapa')}
          >
            mapa
          </Button>
        </Buttons>
        <LineButtons isMobile={isMobile} />
      </ButtonsBox>

      <Content isMap={TypeList}>
        {activeButton === 'lista' && (
          <ListMode
            totalElements={totalElements}
            selectedStore={selectedStore}
            storeSelected={storeSelected}
            activeButton={activeButton}
            comparativeList={comparativeList}
            cookies={cookies}
            currentLegions={currentLegions}
            currentPag={currentPag}
            dados={dados}
            dadosLocal={dadosLocal}
            existsCovid={existsCovid}
            filter={filter}
            handleBuy={handleBuy}
            handleDetails={handleDetails}
            isPar={isPar}
            listWpp={listWpp}
            load={load}
            loadingFirst={loadingFirst}
            makeAddress={makeAddress}
            makeCardStore={makeCardStore}
            makeFilter={makeFilter}
            makeWppButton={makeWppButton}
            myList={myList}
            parsed={parsed}
            totalPag={totalPag}
            pagAppend={pagAppend}
          />
        )}

        {activeButton === 'mapa' && (
          <MapMode
            totalElements={totalElements}
            selectedStore={selectedStore}
            storeSelected={storeSelected}
            activeButton={activeButton}
            cookies={cookies}
            currentLegions={currentLegions}
            currentPag={currentPag}
            dados={dados}
            dadosLocal={dadosLocal}
            filter={filter}
            handleBuy={handleBuy}
            handleDetails={handleDetails}
            isPar={isPar}
            listWpp={listWpp}
            load={loadForMap}
            loadingFirst={loadingFirst}
            makeAddress={makeAddress}
            makeCardStore={makeCardStore}
            makeFilter={makeFilter}
            makeWppButton={makeWppButton}
            myList={myList}
            parsed={parsed}
            TypeList={TypeList}
            changeState={changeState}
            MapRef={MapRef}
            handleGeoLocation={handleGeoLocation}
            visibleButtonMap={visibleButtonMap}
            handleAnalytics={handleAnalytics}
            coordsTmpCenter={coordsTmpCenter}
            pinsList={pinsList}
            coordsCenter={coordsCenter}
            handleDrag={handleDrag}
            handleChangeMap={handleChangeMap}
            handlePinClick={handlePinClick}
            handleZoom={handleZoom}
            hasMore={hasMore}
          />
        )}
      </Content>
    </Container>
  );
};

export { WebContent };
