export interface TitleGeneratorProps {
  uf?: string;
  city?: string;
  neighborhood?: string;
  link?: string;
}

const PutNewTitle = (unit: string) => {
  document.title = `Americanas: Encontre lojas em ${unit}`;
};

const TitleGenerator = ({
  uf,
  city,
  neighborhood,
}: TitleGeneratorProps): void => {
  let x;

  if (neighborhood) {
    PutNewTitle(neighborhood);
    return;
  }

  if (city) {
    PutNewTitle(city);
    return;
  }

  if (uf) {
    PutNewTitle(uf);
    return;
  }
};

export { TitleGenerator };
