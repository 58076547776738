import React, { FC, useCallback, useState } from 'react';
import { Container, Content, Box, TooltipWhatsapp } from './styles';
import ModalTooltip from '../ModalTooltip';
import useWindowDimensions from '../../Utils/size';
import { ActionsProps } from '../Legends';
import { useHistory } from 'react-router-dom';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import { useCookies } from 'react-cookie';
import { HandleClickMenus } from '../../Utils/HandleClickMenu';
import handleClickCardStore from '../../Utils/ClickCardStore';

interface TooltipProps {
  className?: string;
  sizeFakeBox?: string;
  listWpp: any;
  cbFunction?: (data?: any) => void;
}

const Tooltip: FC<TooltipProps> = ({
  className = '',
  sizeFakeBox,
  listWpp,
  cbFunction,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);

  const [modalVisible, setModalVisible] = useState(false);
  const isMobile = useWindowDimensions();
  const history = useHistory();

  const handleClick = useCallback(
    (
      uri: string,
      type: 'logo' | 'minhaconta' | 'favoritos' | 'cesta' | undefined
    ) => {
      if (type) {
        HandleClickMenus({ uri, type, isMobile, cookies });
      }

      return;
      window.location.replace(uri);
    },
    [isMobile, cookies]
  );

  const handleClickAction = useCallback(
    (type: 'route' | 'open' | 'function', linkBtn: string) => {
      if (type === 'open') {
        window.location.href = linkBtn;
      } else if (type === 'route') {
        history.push(linkBtn);
      }
    },
    [history]
  );

  const makeItem = useCallback(
    (i: any, index: number) => {
      if (i.renderItem) {
        return (
          <Box
            // style={{ marginTop: index > 0 ? 30 * index : 0 }}
            onClick={() => handleClickAction(i.linkType, i.link)}
          >
            {i.renderItem()}
          </Box>
        );
      } else if (i.type) {
        return (
          <Box onClick={() => handleClick(i.link, i.type)}>
            <span>{i.label}</span>
          </Box>
        );
      } else {
        return (
          <Box>
            <a href={i.link || '#'}>{i.label}</a>
          </Box>
        );
      }
    },
    [handleClickAction, handleClick]
  );

  return (
    <div style={{ position: 'relative' }}>
      {/* <FakeArea
      // onMouseOver={() => setModalVisible(true)}
      // onMouseLeave={() => setModalVisible(false)}
      /> */}

      <Container
        sizeFakeBox={sizeFakeBox}
        className={className}
        isMobile={isMobile}
        onMouseOver={() => {
          setModalVisible(true);
          // HandleEvents({
          //   isMobile,
          //   eventAction: 'whatsapp',
          //   eventCategory: 'nossaslojas',
          //   eventLabel: 'whatsapp hover',
          //   eventValue: undefined,
          //   cookies,
          // });
        }}
        onMouseLeave={() => {
          setModalVisible(false);
          if (cbFunction) {
            console.log('chamou o cb');
            cbFunction();
          }
        }}
      >
        <div className="popover__wrapper">
          <div className="popover__content">
            {modalVisible && (
              <TooltipWhatsapp
                opacity={true}
                mLeft={isMobile ? `0px` : '5px'}
                isMobile={isMobile}
              >
                <h1>compre no Whatsapp</h1>
                <h2>
                  Monte sua cesta no Whatsapp e ainda tire suas dúvidas ;)
                </h2>

                <button
                  onClick={() => {
                    handleClickCardStore({
                      type: 'whatsapp',
                      wppFromWeb: listWpp,
                      isMobile,
                      cookies,
                      eventAction: 'whatsapp',
                    });
                  }}
                >
                  comprar
                </button>
              </TooltipWhatsapp>
            )}
          </div>
        </div>
      </Container>

      <ModalTooltip visible={modalVisible} />
    </div>
  );
};

export default Tooltip;
