import React, { FC, useCallback, useMemo } from 'react';

import { Container } from './syles';
import { useHistory } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import ChevronRight from '../../Assets/chevron-right.svg';
import useWindowDimensions from '../../Utils/size';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import { useCookies } from 'react-cookie';

export interface BreadCrumbItemProps {
  label: string;
  link: string;
  type?: string;
}

interface Props {
  items?: BreadCrumbItemProps[];
  home?: boolean;
  containerStyle?: object;
  labelStyle?: object;
}

const Breadcrumb: FC<Props> = ({ items, home, containerStyle, labelStyle }) => {
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const history = useHistory();
  const isMobile = useWindowDimensions();

  const breadcrumbs = useMemo(() => {
    const group = [
      {
        label: 'página inicial',
        link: `https://www.americanas.com.br`,
        type: 'externalLink',
      },
      { label: 'nossas lojas', link: `/?newUser=${true}` },
    ];

    return items?.length ? [...group, ...items] : [...group];
  }, [items]);

  const DispatchEvents = useCallback(
    (i: BreadCrumbItemProps, index: number) => {
      const getType: any = {
        '0': 'paginainicial',
        '1': 'homenossaslojas',
        '2': 'outros',
      };

      HandleEvents({
        isMobile,
        eventAction: 'breadcrumb',
        eventCategory: 'nossaslojas',
        eventLabel: getType[String(index)] || 'outros',
        eventValue: undefined,
        cookies,
      });
    },
    [cookies, isMobile]
  );

  const handleClick = useCallback(
    (i: BreadCrumbItemProps, index: number) => {
      DispatchEvents(i, index);

      if (i.type) {
        window.location.href = i.link;
      } else {
        history.push(i.link);
      }
    },
    [history, DispatchEvents]
  );

  return (
    <Container isMobile={isMobile} home={home} style={containerStyle}>
      {breadcrumbs.map((i: BreadCrumbItemProps, index: number) => (
        <>
          <span onClick={() => handleClick(i, index)} style={labelStyle}>
            {i.label}
          </span>

          {index + 1 < breadcrumbs.length && breadcrumbs[index + 1].label && (
            <FaChevronRight
              size={10}
              color="#CCCCCC"
              style={{
                marginRight: 12,
                marginLeft: 12,
              }}
            />
          )}
        </>
      ))}
    </Container>
  );
};

export { Breadcrumb };
