import styled, { css } from 'styled-components';
import Carousel from '@brainhubeu/react-carousel';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

  align-items: center;
  justify-content: center;

  .on-scrollbar {
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  .on-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }

  .on-scrollbar::-webkit-scrollbar {
    width: 6px !important;
    background-color: transparent;
  }

  .on-scrollbar::-webkit-scrollbar-thumb {
    background-color: #acacac;
  }

  .blue {
    width: 320px;
    height: 100px;
    background: blue;
  }
`;

interface ContentProps {
  isMap?: boolean;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: ${(props) => (props.isMap ? 'row' : 'column')};
  width: 100%;
  margin: auto;
  justify-content: space-between;
`;

interface StoresProps {
  isMap?: boolean;
  isPar?: boolean;
}

export const ListaBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 1280px;
  margin-left: calc((100vw - 1280px) / 2);
`;

export const Cards = styled.div`
  display: flex;
  width: 1280px;
  flex-wrap: wrap;
`;

export const Stores = styled.div<StoresProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) =>
    props.isPar ? 'flex-start' : 'justify-center'};

  overflow: ${(props) => (props.isMap ? 'scroll' : 'hidden')};
  height: ${(props) => (props.isMap ? '796px' : 'auto')};
  width: ${(props) => (props.isMap ? '100%' : '100%')};
  margin: ${(props) => (props.isMap ? '0' : 'auto')};
  overflow-y: hidden;
  /* ${(props) => props.isMap && `margin-left: calc((100vw - 1280px) / 2);`}; */

  .on-scrollbar {
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  .on-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }

  .on-scrollbar::-webkit-scrollbar {
    width: 6px !important;
    background-color: transparent;
  }

  .on-scrollbar::-webkit-scrollbar-thumb {
    background-color: #acacac;
  }
`;

interface TitleProps {
  size?: string;
  top?: string;
  bottom?: string;
  align?: string;
  weight?: string;
  isMobile?: boolean;
}
export const Title = styled.span<TitleProps>`
  font-size: ${(props) => props.size || '18px'};
  font-weight: ${(props) => props.weight || 'bold'};
  color: #666666;
  font-family: 'Helvetica Neue, Bold';
  text-align: ${(props) => props.align || 'left'};
  margin-right: 0;
  display: block;
  /* height: 22px; */

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: 28px;
      margin-bottom: 16px;
      font-size: 13px;
      margin-left: 7px;
    `}
`;

interface ButtonsMobile {
  isMobile?: boolean;
}

export const ButtonsBox = styled.div<ButtonsMobile>`
  width: 100%;
  max-width: 1280px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: auto;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0 12px;
      justify-content: space-between;
      flex-direction: row;
    `}
`;

export const Buttons = styled.div<ButtonsMobile>`
  display: flex;
  width: ${(props) => (props.isMobile ? '221px' : 'auto')};
  height: ${(props) => (props.isMobile ? '49px' : '72px')};
  margin: auto;
  border: solid 1px #e0e0e0;
  align-items: center;
  justify-content: space-between;
  border-radius: 37px;
  padding: ${(props) => (props.isMobile ? '4px' : '8px')};
  margin-top: ${(props) => (props.isMobile ? '-22px' : '-32px')};
  background: #ffffff;
  position: relative;
  z-index: 10;

  margin-bottom: ${(props) => (props.isMobile ? '30px' : '0')};
  > button {
    width: ${(props) => (props.isMobile ? '106px' : '150px')};
    /* padding: ${(props) => (props.isMobile ? '14px 43px' : '')}; */
  }
`;

interface LineButtonsProps {
  isMobile?: boolean;
}
export const LineButtons = styled.div<LineButtonsProps>`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  position: relative;
  margin-top: -32px;
  margin-bottom: 31px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-top: -54px;
      margin-bottom: 51px;
    `}
`;

interface ButtonProps {
  active?: boolean;
  isMobile?: boolean;
}

export const Button = styled.button<ButtonProps>`
  color: ${(props) => (props.active ? '#FFFFFF' : '#707070')};
  padding: ${(props) => (props.isMobile ? '10px 44px' : '16px 44px')};
  background: ${(props) => (props.active ? '#e60115' : '#FFFFFF')};
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue;
  font-size: ${(props) => (props.isMobile ? '17px' : '20px')};
  border-radius: 37px;
  cursor: pointer;
`;

interface LeftProps {
  isMap?: boolean;
}

export const Left = styled.div<LeftProps>`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  margin-left: ${(props) =>
    props.isMap ? '0px' : css`calc((100vw - 1280px)/2)`};
  margin-right: 20px;
`;

interface MapContainerProps {
  isMobile?: boolean;
}

export const MapContainer = styled.div<MapContainerProps>`
  width: ${(props) => (props.isMobile ? '100%' : '100%')};
  margin-top: ${(props) => (props.isMobile ? '-57px' : '0px')};
  position: ${(props) => (props.isMobile ? 'absolute' : 'relative')};
  z-index: 9;
  margin-left: auto;

  ${({ isMobile }) =>
    isMobile &&
    css`
      height: 100%;
      margin-top: -192px;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
    `}
`;

export const RouteButton = styled.button`
  border: 0px;
  background-color: ${({ theme }) => theme.colors.redPrimary};
  border-radius: 12px;
  width: 150px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 99;

  span {
    color: white;
    font-size: 20px;
  }

  img {
    width: 8px;
    height: 10px;
  }
`;

interface FakeBoxShadowProps {
  type: 'up' | 'down';
}

export const FakeBoxShadow = styled.div<FakeBoxShadowProps>`
  width: 100%;
  height: ${({ type }) => (type === 'up' ? '127px' : '260px')};
  position: absolute;

  left: 0;
  z-index: ${({ type }) => (type === 'down' ? 99999999 : 9)};
  margin-top: 109px;

  ${({ type }) =>
    type === 'up' &&
    css`
      top: 0;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 23.62%,
        rgba(0, 0, 0, 0) 100%
      );
    `}

  ${({ type }) =>
    type === 'down' &&
    css`
      bottom: 0px;
      /* margin-top: calc(100vh - 260px - 279px); */
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      transform: rotate(-180deg);
    `}
`;

interface ToggleButtonViewProps {
  isMap?: boolean;
}

export const ToggleButtonView = styled.button<ToggleButtonViewProps>`
  position: relative;
  z-index: 10;
  width: 70px;
  height: 30px;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  border: 0px;

  ${({ isMap }) =>
    isMap &&
    css`
      margin-left: 0;
    `}

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: ${({ theme }) => theme.fonts.regular};
    font-size: 13px;
    margin-left: 5px;
  }
`;

export const AddressMobile = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  background: #f2f2f2;
  /* margin-top: 9px; */
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
  margin-top: -9px;

  span {
    font-family: Helvetica Neue;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
  }

  button {
    margin-bottom: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: solid 2px ${({ theme }) => theme.colors.redPrimary};
    border-radius: 10px;
    padding: 10px;
    margin: 0;
    font-family: Helvetica Neue;

    color: ${({ theme }) => theme.colors.redPrimary};
    font-weight: bold;
  }
`;

export const ContentMapMobile = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  .owl-carousel .owl-item {
    padding: 0px 13px !important;
    /* margin-left: -17px; */
  }

  .owl-carousel .owl-item .active {
    /* margin-left: 0px !important; */
  }

  .owl-stage {
    display: flex;
    align-items: flex-end;
  }

  .active {
    border: 0px !important;
    margin-left: 0px;
    /* margin-left: -5px !important; */
  }

  /* .owl-stage {
    left: -12px;
  } */

  /* @media only screen and (min-width: 400px) {
    .owl-stage {
      left: -29px;
    }
  } */

  .owl-carousel {
    z-index: 10;
  }

  .BrainhubCarousel__container {
    margin-top: -78px;
    position: relative;
    z-index: 99999999;
    margin-bottom: 50px;
    z-index: 10;
  }
`;

export const CarouselContent = styled(Carousel)`
  .BrainhubCarouselItem--active {
    margin-left: -26px !important;

    > div {
      margin-left: 36px !important;
    }
  }
`;

interface PlaceholderProps {
  isMobile?: boolean;
  width?: string;
}
export const Placeholder = styled.img<PlaceholderProps>`
  width: ${(props) => (props.isMobile ? '300px' : '433px')};
  margin: ${(props) => (props.isMobile ? '22px 0px' : '20px 0px')};
`;

export const BoxEmptyMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

interface BoxProps {
  isMap?: boolean;
}
export const Box = styled.div<BoxProps>`
  display: block;
  text-align: left;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: ${(props) =>
    props.isMap ? css`calc((100vw - 1280px)/2)` : '0px'};
`;

export const MoreButton = styled.div`
  border: solid 2px #e0030e;
  border-radius: 6px;
  padding: 15px 30px;
  margin: auto;
  margin-bottom: 50px;
  margin-top: 10px;

  span {
    font-family: Helvetica Neue;
    color: #df030e;
    font-size: 14px;
    font-weight: bold;
  }
`;

interface LoaderProps {
  size?: string;
  bottom?: string | null;
}

export const Loader = styled.img<LoaderProps>`
  width: ${(props) => props.size || '80px'};
  height: ${(props) => props.size || '80px'};
  margin: auto;
  margin-bottom: ${(props) =>
    props.bottom === null ? null : props.bottom || '20px'};
`;

export const BoxPlaceholder = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 42px;
`;

interface BoxHeaderProps {
  isMobile?: boolean;
  disabled?: boolean;
  all?: boolean;
  map?: boolean;
}

export const BoxHeader = styled.div<BoxHeaderProps>`
  /* width: 100%; */
  display: flex;
  flex: 1;
  max-width: 1280px;
  justify-content: space-between;
  margin: 21px 0px 0px 0px;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: -7px 13px 0 13px;
    `}

  ${({ map }) =>
    map &&
    css`
      margin-top: 0px;
    `}

  ul {
    display: flex;
    list-style: none;
    margin-left: 7px;

    ${({ isMobile }) =>
      isMobile &&
      css`
        margin-top: 12px;
        margin-left: 0;
        margin-bottom: 10px;
        justify-content: flex-start
        width: 100%;
      `}

    li {
      width: 105px;
      height: 38px;
      margin-right: 10px;
      border: 1px solid black;
      border-radius: 20px;
      color: #9d9d9c;
      font-size: 13px;
      font-family: Helvetica Neue, Regular;

      display: flex;
      align-items: center;
      justify-content: center;

      ${({ isMobile }) =>
        isMobile &&
        css`
          margin-right: 8px;
        `}
    }
  }
`;

interface ItemListProps {
  active: boolean;
  isMobile?: boolean;
  disabled?: boolean;
  all?: boolean;
}
export const ItemList = styled.li<ItemListProps>`
  background-color: #fff;
  color: ${({ active }) => (active ? '#e60115' : '#9d9d9c')} !important;
  border-color: ${({ active }) => (active ? '#e60115' : '#e0e0e0')} !important;
  font-family: 'Helvetica Neue, Bold' !important;
  cursor: ${({ disabled, all }) => (disabled ? 'not-allowed' : 'pointer')};

  span {
    margin: 0;
    padding: 0;
    font-size: 13px;
    font: -webkit-control;
    font-weight: bold;
  }

  ${({ active }) =>
    active &&
    css`
      > svg path {
        fill: #e60115 !important;
      }

      #Rectangle-2,
      #Rectangle,
      rect {
        fill: #e60115 !important;
      }
    `};

  ${({ disabled }) =>
    !disabled &&
    css`
      :hover {
        > svg path {
          fill: #e60115;
        }

        #Rectangle-2,
        #Rectangle,
        rect {
          fill: #e60115;
        }

        border-color: #e60115 !important;
        color: #e60115 !important;
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      > svg path {
        fill: #e8e8e8;
      }

      #Rectangle-2,
      #Rectangle,
      rect {
        fill: #e8e8e8;
      }

      border-color: #e8e8e8 !important;
      color: #e8e8e8 !important;
    `}
`;

interface FilterProps {
  isMobile?: boolean;
  disabled?: boolean;
}

export const Filter = styled.div<FilterProps>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  position: relative;

  h2 {
    font-size: ${({ isMobile }) => (isMobile ? '13px' : '18px')};
    color: ${({ disabled }) => (disabled ? '#e8e8e8' : '#666')};
    font-family: Helvetica Neue, Regular;
  }

  ul {
    align-items: center;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
    `}
`;

interface WarningProps {
  isMobile?: boolean;
}

export const Warning = styled.div<WarningProps>`
  display: flex;
  align-items: center;
  max-width: 1280px;
  width: 100%;
  height: 63px;
  background: #fbedc1 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 0 15px;
  margin-bottom: 21px;

  span {
    margin-left: 10px;
    font-family: Helvetica Neue, Regular;
    font-size: ${({ isMobile }) => (isMobile ? '12px' : '17.5px')};
    color: #333333;

    ${({ isMobile }) =>
      isMobile &&
      css`
        line-height: 16px;
      `}
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 0px 10px 0 10px;
      width: auto;

      svg path {
        width: 32px;
        height: 32px;
      }
    `}
`;

export const BarrinhaStoresMobile = styled.div`
  width: 38px;
  height: 4px;
  background: #aaaaaa;
  border-radius: 30px;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 15px;
`;

interface WrapperStoresMobileProps {
  isIOS: boolean;
  isChrome: boolean;
  value?: any;
}
export const WrapperStoresMobile = styled.div<WrapperStoresMobileProps>`
  width: 100%;
  position: absolute;
  border-radius: 20px 20px 0 0;
  z-index: 99999999;

  bottom: -${(props) => props.value}px;

  transition: all 2s linear;

  ${({ isIOS, value }) =>
    isIOS &&
    css`
      /* bottom: calc((100vh / 2) - 279px - 44px - 44px); */
      bottom: -calc(100vh - 537px);
      /* top: calc(100vh - 248px - 279px - 44px - 44px); */
    `};
`;

interface TooltipLocalProps {
  opacity?: boolean;
  mLeft: string;
  isMobile?: boolean;
}

export const TooltipLocal = styled.span<TooltipLocalProps>`
  background: white;
  padding: 32px 29px;
  opacity: ${({ opacity }) => (opacity ? 1 : 0)};
  cursor: pointer !important;

  /* opacity: 1; */

  transition: 0.2s;

  position: absolute;
  z-index: 9999999999;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 2px 8px #00000027;

  width: 351px;
  height: 233px;

  /* top: calc(100vh - 61%);
  left: calc(100vw - 55%); */
  top: ${({ isMobile }) => (isMobile ? '74px' : '52px')};
  left: ${({ isMobile }) => (isMobile ? '-1px' : '130px')};

  display: ${({ opacity }) => (opacity ? 'flex' : 'none')};
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100% !important;
    `}

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: ${({ mLeft }) => mLeft};

    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid white;
    margin-top: -43px;

    position: absolute;

    ${({ isMobile }) =>
      isMobile &&
      css`
        @media only screen and (min-width: 413px) {
          margin-left: calc(100vw - 85%) !important;
        }
        /* margin-left: calc(100vw - 85%) px !important; */

        @media only screen and (max-width: 412px) {
          left: calc(223px + 2px);
        }

        @media only screen and (max-width: 320px) {
          left: calc(223px + 5px);
        }

        @media only screen and (max-width: 375px) {
          left: calc(223px + 2px);
        }

        @media only screen and (max-width: 360px) {
          left: calc(223px + 5px);
        }
      `}
  }

  h1 {
    margin: 0;
    font-size: 18px;
    color: #333333;
    line-height: 22px;
    font-family: Helvetica Neue, Regular;
    /* margin-bottom: 16px; */
  }

  h2 {
    margin: 0;
    font-size: 14px;
    color: #666666;
    font-family: Helvetica Neue, Regular;
    line-height: 18px;
  }

  @media only screen and (max-width: 414px) {
    width: 386px;
  }

  @media only screen and (max-width: 375px) {
    width: 351px;
  }

  @media only screen and (max-width: 360px) {
    width: 339px;
    height: 243px;
    left: -3px;

    h1 {
      font-size: 17;
    }

    h2 {
      font-size: 13px;
    }
  }

  @media only screen and (max-width: 320px) {
    width: 301px;
    height: 243px;
    left: -3px;

    h1 {
      font-size: 17;
    }

    span {
      font-size: 13px;
    }
  }
`;

export const FakeButtonDudeIcon = styled.div`
  border: 0;
  background: transparent;

  z-index: 999;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: center;
`;

export const StoresNear = styled.div`
  width: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  padding: 7px 12px;

  background: rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;

  position: relative;
  /* bottom: calc(100vh - 567px); */
  margin-left: 10px;

  span {
    color: #fff;
    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-weight: bold;
    font-size: 14px;
  }
`;

interface WhatsSolidButtonProps {
  isMobile?: boolean;
  isMapView?: boolean;
}

export const WhatsSolidButton = styled.div<WhatsSolidButtonProps>`
  cursor: pointer;
  width: ${({ isMobile }) => (isMobile ? '98px' : '186px')};
  height: ${({ isMobile }) => (isMobile ? '26px' : '39px')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.redPrimary};

  z-index: 1;
  position: relative;

  background-color: #fff;
  border-color: #e60115 !important;
  border-radius: 54px;
  margin-left: 13px;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.05));

  span {
    margin: 0px;
    padding: 0px;
    font: -webkit-control;
    font-weight: bold;
    margin-left: ${({ isMobile }) => (isMobile ? '6px' : '10.5px')};
    font-size: 12px;
    line-height: 13.8px;
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.redPrimary} !important;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      svg {
        width: 15px;
        height: 15px;
      }
    `}

  ${({ isMobile, isMapView }) =>
    isMobile &&
    isMapView &&
    css`
      width: 91px;
      height: 25px;
      margin-right: 13px;

      svg {
        width: 13px;
        height: 13px;
      }
    `}

    img {
    width: 8px;
    height: 10px;
`;

interface TooltipProps {
  opacity?: boolean;
  mLeft: string;
  isMobile?: boolean;
  isViewMap?: boolean;
}

export const TooltipWhatsappMobile = styled.span<TooltipProps>`
  background: white;
  padding: 27px 31px;

  opacity: ${({ opacity }) => (opacity ? 1 : 0)};
  flex: 1;
  margin-right: 16px;
  margin-left: 16px;

  /* opacity: 1; */

  transition: 0.2s;

  position: absolute;
  z-index: 9999999999999;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 2px 8px #00000027;

  height: 175px;

  /* top: calc(100vh - 61%);
  left: calc(100vw - 55%); */
  top: 384px;
  left: ${({ isMobile }) => (isMobile ? '-1px' : '-80px')};

  display: ${({ opacity }) => (opacity ? 'flex' : 'none')};
  /* display: flex; */
  flex-direction: column;

  text-align: center;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: ${({ mLeft }) => mLeft};

    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid white;
    margin-top: -38px;

    position: absolute;

    ${({ isMobile }) =>
      isMobile &&
      css`
        @media only screen and (min-width: 413px) {
          left: calc(100vw - 21%);
        }
        /* margin-left: calc(100vw - 85%) px !important; */

        @media only screen and (max-width: 412px) {
          left: calc(100vw - 21%);
        }

        @media only screen and (max-width: 320px) {
          left: calc(100vw - 21%);
        }

        @media only screen and (max-width: 375px) {
          left: calc(100vw - 21%);
        }

        @media only screen and (max-width: 360px) {
          left: calc(100vw - 21%);
        }
      `}
  }

  h1 {
    margin: 0;
    font-size: 16px;
    color: #333333;
    line-height: 20px;
    font-family: 'Helvetica Neue, Bold';
    /* margin-bottom: 16px; */
  }

  h2 {
    margin: 0;
    font-size: 14px;
    color: #666666;
    font-family: Helvetica Neue, Regular;
    line-height: 16px;
    margin-top: 8px;
  }

  /* @media only screen and (max-width: 414px) {
    width: 386px;
  } */

  /* @media only screen and (max-width: 375px) {
    width: 351px;
  } */

  @media only screen and (max-width: 360px) {
    /* width: 339px;
    height: 243px; */
    left: -3px;

    h1 {
      font-size: 17;
    }

    h2 {
      font-size: 13px;
    }
  }

  @media only screen and (max-width: 320px) {
    /* width: 301px;
    height: 243px; */
    left: -3px;

    h1 {
      font-size: 17;
    }

    span {
      font-size: 13px;
    }
  }

  button {
    background-color: ${({ theme }) => theme.colors.redPrimary};
    font-size: 14px;
    font-family: 'Helvetica Neue, Bold';
    font-weight: bold;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    width: 289px;
    height: 36px;
    margin-top: 24px;
  }

  @media only screen and (max-width: 361px) {
    padding: 27px 16px;
  }

  ${({ isViewMap }) =>
    isViewMap &&
    css`
      top: calc(100vh / 1.42);
    `}
`;
