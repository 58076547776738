export default {
  key_google_maps: 'AI  -  zaSy  -  ACga  -  DiO87M - _VSvd  -  YME  -  m3fcH  -  wzd3s  -  vp - 4',
  // fbToken:
  //   'EAAGZBmTDYRfsBAKoe1St916MLVrzd0kvZAGVJRTywTDQSDZABwBpaS1sFwoTcsB87ILgliZAHmlkphEXClr02SYvswQTSWQQypeN1E6jvWJyABhZA7DyWJN32taOTzWFe2pvVZBfLMz01ZADPTwi3iDEZCok0Q7TMgnZB03PKgwpOaUZBcbW9N1yUU',
  fbToken:
    'EAAGcSis1cLMBAH4ZCUP45GtZCpt7ZBKRhs5mUyxHJuMyZCOlpgLHqNkc7J9C74UDRjOS5WKfZBz35aDNmqq7KWoQOYFjOUmtGz3CnPwYp4ZBCeJ9EXNwoPOQDFxI2Y9lhcB5FnSXMdW76AOYorb2nFsCiZBycSzIOgQ0QJYkuaFM63dfpmmguN3',
  gaId: 'GTM-PDFX6WC',
  key: 'o2o_alterar_00_0_0_nossaslojas',
  // loadPinsResume:
  //   'https://restql-api-v2-itg.b2w.io/acom/run-query/yourdev/store-category-navigation-resume/4',
  // loadPinsResumeKey: 'store-v2-category-navigation',
  loadPinsResume:
    'https://restql-api-v2-itg.b2w.io/acom/run-query/yourdev/store-nearby-stores-resume/1',
  loadPinsResumeKey: 'store-nearby-stores',
  // loadPins:
  //   'https://restql-api-v2-itg.b2w.io/acom/run-query/yourdev/store-category-navigation/8',
  // loadPinsKey: 'store-v2-category-navigation',
  loadPins:
    'https://restql-api-v2-itg.b2w.io/acom/run-query/yourdev/store-nearby-stores/1',
  loadPinsKey: 'store-nearby-stores',
  catalogSpacey:
    'https://spacey-api-v2-americanas.b2w.io/spacey-api/publication/app/maintop1/americanas/nossaslojas',
  legionsApi:
    'https://restql-api-v2-itg.b2w.io/acom/run-query/yourdev/region-from-lat-long/1',
  modalHelpAttendence:
    'https://spacey-api-v2-americanas.b2w.io/spacey-api/publication/mobile/contenttop1/americanas/nossaslojas',
  modalFAQ:
    'https://spacey-api-v2-americanas.b2w.io/spacey-api/publication/mobile/contenttop2/americanas/nossaslojas',
  modalPolititions:
    'https://spacey-api-v2-americanas.b2w.io/spacey-api/publication/mobile/contenttop3/americanas/nossaslojas',
  suggestionProductsStoreAPI:
    'https://mars-v1-americanas-npf.b2w.io/rrserver/api/rrPlatform/recsForPlacements',
  suggestionProductsPopulateAPI:
    // 'http://restql-api-v2-itg.b2w.io/acom/run-query/yourdev/product-offer/1',
    'http://restql-api-v2-itg.b2w.io/acom/run-query/yourdev/product-offer/3',
};
