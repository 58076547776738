/* eslint-disable */
/* eslint-disable react/prop-types */
import React, {
  FC,
  useState,
  useCallback,
  useRef,
  useEffect,
  forwardRef,
  RefForwardingComponent,
} from 'react';
import Input from '../../Components/Input_old';
import { Container, Box, Barrinha } from './styles';
import useWindowDimensions from '../../Utils/size';
import { loadProps, LoadFunctionProps } from '../../Pages/List';

interface OtherProps {
  defaultValueInput?: any;
  visible: boolean;
  onClose: () => void;
  onClickHandle?: (data: LoadFunctionProps) => void;
  cbFunction?: () => void;
}

const ModalAddress: RefForwardingComponent<any, OtherProps> = (
  {
    cbFunction,
    onClickHandle,
    defaultValueInput = '',
    visible = false,
    onClose,
  },
  ref
) => {
  const [activeInput, setActiveInput] = useState(false);
  const [isError, setIsError] = useState('');
  const isMobile = useWindowDimensions();
  // const InputRef = useRef<HTMLInputElement>(null);
  const handleSearch = useCallback((e: string) => {
    if (e && e.length >= 3) {
      setActiveInput(true);

      if (e.length > 6) {
        setActiveInput(false);
        setIsError('CEP inválido, tente novamente');
      }
    } else {
      setActiveInput(false);
      setIsError('');
    }
  }, []);
  return (
    <>
      {visible && (
        <>
          <Container onClick={onClose} />
          <Box>
            <Barrinha />
            <span>use um endereço</span>
            {/*
  // @ts-ignore */}
            <Input
              onClickHandle={onClickHandle}
              styleContainer={{
                margin: 'auto',
                marginTop: '0px',
                border: 'solid 1px #CCCCCC',
              }}
              isActive={activeInput}
              handleOnChange={handleSearch}
              isError={isError}
              isMobile={isMobile}
              defaultValue={defaultValueInput}
              isList={true}
              cbFunction={cbFunction}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default forwardRef(ModalAddress);
