/* eslint-disable react/prop-types */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, {
  FC,
  useCallback,
  useState,
  useImperativeHandle,
  RefForwardingComponent,
  forwardRef,
  useEffect,
} from 'react';
import { Mapa, MapBox } from './styles';
import AmericanasPin from '../../Assets/PIN.svg';
import useWindowDimensions from '../../Utils/size';
import Config from '../../Utils/Config';
import { FakeBoxShadow } from '../../Pages/List/styles';
import GoogleMapReact from 'google-map-react';

// interface ListProps {
//   Latitude: string;
//   Longitude: string;
//   Loja: string;
//   'Nome Loja': string;
// }
interface ListProps {
  geolocation: {
    latitude: number;
    longitude: number;
  };
  sellerStoreId?: string;
}

interface Props {
  list: ListProps[];
  loading?: boolean;
  width?: string;
  height?: string;
  rounded?: boolean;
  zoom?: number;
  coordsCenter?: {
    lat: number;
    lng: number;
  };
  handleDrag?: (value: any) => void;
  handleZoom?: (value: any) => void;
  handleChangeMap?: (value: any) => void;
  handlePinClick?: (item: any) => void;
  initialPage?: boolean;
  selectedStore?: any;
  changeState?: (value: boolean) => void;
  detail?: boolean;
  styleContainer?: object;
  pinClicked?: boolean;
}

interface CoordsProps {
  lat: number;
  lng: number;
}

export interface HandleMapsFunctions {
  changeCenterMap: (data: CoordsProps) => void;
  radius: number | undefined;
  markers: any;
  clearPins: () => void;
  getCenter: () => void;
  mapsProps: any;
  animationPin: any;
  directionsService?: any;
  directionsDisplay?: any;
}

let trava = false;

const GoogleMaps: RefForwardingComponent<HandleMapsFunctions, Props> = (
  {
    loading,
    list,
    detail,
    width,
    height,
    rounded = false,
    coordsCenter = {
      lat: -10.3333333,
      lng: -53.2,
    },
    zoom = 5,
    handleDrag,
    handleZoom,
    handleChangeMap,
    handlePinClick,
    initialPage,
    selectedStore,
    changeState,
    styleContainer,
    pinClicked,
  },
  ref
) => {
  // const [trava, setTrava] = useState(false);
  const [coordsInside, setCoordsInside] = useState<CoordsProps>(
    {} as CoordsProps
  );
  const [mapsProps, setMapsProps] = useState<any>(null);
  const [mapComponent, setMapComponent] = useState<any>(null);
  const [markersControl, setMarkersControl] = useState<any>([]);
  const [markers, setMarkers] = useState<any>([]);
  const [radius, setRadius] = useState<any>(null);
  const [selectedPin, setSelectedPin] = useState('');
  const [animationPin, setAnimationPin] = useState<any>(null);
  const isMobile = useWindowDimensions();
  const [directionsService, setDirectionsService] = useState();
  const [directionsDisplay, setDirectionsDisplay] = useState();

  const clearPins = useCallback(async () => {
    for (let i = 0; i < markers.length; i++) {
      // console.log('limpando i: ', i);
      markers[i].setMap(null);
      // markers[i].animation = false;
      // markers[i].setAnimation(0);
    }

    setMarkers((prev: any) => {
      return [];
      return (prev.length = 0);
    });

    // console.log('pins cleaned');
  }, [markers]);

  const toggleBounce = useCallback(
    (marker: any, animation: any, item: any) => {
      if (handlePinClick) {
        trava = true;

        setTimeout(() => {
          trava = false;
        }, 8000);
        handlePinClick(item);
      }

      // const savedPin = localStorage.getItem('@ACOM:pin');

      // for (let i = 0; i < markersControl.length; i++) {
      //   console.log('i: ', i);
      //   if (markersControl[i].id !== selectedStore) {
      //     markersControl[i].animation = false;
      //     markersControl[i].setAnimation(0);
      //   }
      // }

      // if (marker.id !== savedPin) {
      //   marker.setAnimation(animation);
      //   localStorage.setItem('@ACOM:pin', marker.id);
      // }
    },
    [handlePinClick]
  );

  const getCenter = useCallback(() => {
    if (mapComponent) {
      const bounds = mapComponent.getBounds();
      const center = bounds.getCenter();
      const lat = center.lat();
      const lng = center.lng();

      return { lat, lng };
    }
  }, [mapComponent]);

  const makePin = useCallback(async () => {
    // console.log('makePin ref: ', getCenter());
    // console.log('makePin selectedStore: ', selectedStore);
    // console.log('makePin: ', loading);

    if (loading) return;
    // console.log('makePin coordsCenter: ', coordsCenter);
    // console.log('makePin coordsInside: ', coordsInside);
    // console.log('makePin sem loading');

    // if (coordsCenter && coordsInside === coordsCenter && !loading && trava) {

    // if(trava){
    //   return
    // }
    // if (coordsCenter && coordsInside === coordsCenter && !loading) {
    //   // console.log('makePin: travou o re-render');
    //   return;
    // }

    // console.log('makePin pinClicked: ', pinClicked);
    if (pinClicked) return;

    trava = true;
    // console.log('makePin: criar pin');
    await clearPins().then(() => {
      const newPins: any = [];

      list.map((i: any) => {
        if (mapsProps && mapComponent) {
          if (initialPage) {
            console.log('makePin lat', i.geolocation.latitude);
            console.log('makePin lat', i.geolocation.longitude);
            new mapsProps.Marker({
              map: mapComponent,
              position: {
                lat: Number(i.geolocation.latitude),
                lng: Number(i.geolocation.longitude),
              },
              icon: AmericanasPin,
            });
          }

          if (!i.geolocation) return;

          if (!markersControl.includes(i.sellerStoreId) && !initialPage) {
            const marker = new mapsProps.Marker({
              id: i.sellerStoreId,
              map: mapComponent,
              position: {
                lat: Number(i.geolocation.latitude),
                lng: Number(i.geolocation.longitude),
              },
              icon: AmericanasPin,
            });

            const animation = mapsProps.Animation.BOUNCE;

            marker.addListener('click', () =>
              toggleBounce(marker, animation, i)
            );

            if (
              i.sellerStoreId === selectedStore ||
              i.sellerStoreId === `L${selectedStore}`
            ) {
              marker.setAnimation(animation);
            }

            if (i.sellerStoreId) {
              setMarkersControl((prev: any) => {
                return [...prev, ...i.sellerStoreId];
              });
              newPins.push(marker);
            }
          }
        } else {
          return false;
        }
      });

      setMarkers(newPins);

      setTimeout(() => {
        // console.log('makePin: destravou o pin');
        trava = false;
      }, 4000);
    });
  }, [
    mapsProps,
    mapComponent,
    toggleBounce,
    initialPage,
    selectedStore,
    list,
    clearPins,
    markersControl,
    loading,

    pinClicked,
  ]);

  useEffect(() => {
    // makePin();
    // console.log('alterou mapsProps: ', mapsProps);
    // console.log('alterou mapComponent: ', mapComponent);

    if (mapsProps && mapComponent && !loading) {
      makePin();
    }
  }, [mapsProps, mapComponent, loading, list]);

  const changeCenterMap = useCallback(
    ({ lat, lng }: CoordsProps) => {
      if (mapComponent) {
        mapComponent.panTo({ lat, lng });
      }
    },
    [mapComponent]
  );

  useImperativeHandle(ref, () => {
    return {
      changeCenterMap,
      markers,
      radius,
      clearPins,
      getCenter,
      mapsProps,
      animationPin,
      directionsService,
      directionsDisplay,
    };
  });

  useEffect(() => {
    // if (coordsInside !== coordsCenter) {
    //   setTrava(false);
    // }
    setCoordsInside(coordsCenter);
  }, [coordsCenter, coordsInside]);

  return (
    <>
      <MapBox
        width={width}
        height={height}
        style={styleContainer}
        isMobile={isMobile}
        detail={detail}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: Config.key_google_maps }}
          center={coordsCenter}
          defaultZoom={zoom}
          options={
            detail
              ? {
                  disableDefaultUI: true,
                  gestureHandling: 'greedy',
                  draggable: false,
                  panControl: false,
                }
              : { disableDefaultUI: true, gestureHandling: 'greedy' }
          }
          onZoomAnimationStart={changeState}
          onDrag={handleDrag}
          onDragEnd={handleChangeMap}
          onZoomAnimationEnd={handleZoom}
          // onChange={handleChangeMap}
          onChildClick={handlePinClick}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            console.log('onGoogleApiLoaded map: ', map);
            console.log('onGoogleApiLoaded maps: ', maps);
            setMapsProps(maps);
            setMapComponent(map);
            setAnimationPin(maps.Animation.BOUNCE);

            const DirectionsService = new maps.DirectionsService();

            const DirectionsDisplay = new maps.DirectionsRenderer({
              suppressMarkers: true,
              suppressBicyclingLayer: true,
            });

            DirectionsDisplay.setOptions({
              polylineOptions: {
                strokeColor: '#ff85a2',
                strokeWeight: '4',
                strokeOpacity: '0.7',
              },
              // draggable: true,
            });

            DirectionsDisplay.setMap(map);

            setDirectionsDisplay(DirectionsDisplay);
            setDirectionsService(DirectionsService);

            const bounds = map.getBounds();
            const center = bounds.getCenter();
            const ne = bounds.getNorthEast();
            const r = 3963.0;
            const lat1 = center.lat() / 57.2958;
            const lon1 = center.lng() / 57.2958;
            const lat2 = ne.lat() / 57.2958;
            const lon2 = ne.lng() / 57.2958;
            const dis =
              r *
              Math.acos(
                Math.sin(lat1) * Math.sin(lat2) +
                  Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)
              );
            // console.log('dis: ', dis);
            return dis;
          }}
        >
          {/* {list.map((i) => {
            return i.Latitude !== '-' && i.Longitude !== '-'
              ? makePin(i)
              : null;
          })} */}
        </GoogleMapReact>

        {!isMobile && rounded && (
          <>
            <i className="top"></i>
            <i className="right"></i>
            <i className="bottom"></i>
            <i className="left"></i>
            <i className="top left"></i>
            <i className="top right"></i>
            <i className="bottom left"></i>
            <i className="bottom right"></i>
          </>
        )}
        {!detail && isMobile && <FakeBoxShadow type="down" />}
      </MapBox>
    </>
  );
};

export default forwardRef(GoogleMaps);
