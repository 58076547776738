import React, { FC, useEffect, useState, useCallback, useMemo } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import LogoAmericanas from '../../Assets/LOGO_AMERICANAS.svg';
import UserIcon from '../../Assets/AVATAR.svg';
import FavoriteIcon from '../../Assets/FAVORITE_ICON.svg';
import BasketIcon from '../../Assets/ICONE_CESTA.svg';
import useWindowDimensions from '../../Utils/size';
import { useCookies } from 'react-cookie';
import Truncate from '../../Utils/truncate';
import Legends from '../Legends';
import queryString from 'query-string';

import {
  Container,
  Logo,
  Content,
  Menus,
  ActionsRight,
  ActionsRightItem,
  TooltipContainer,
  Info,
  MenuItemMobile,
  Wrapper,
  WrapperMenuMobile,
  InfoLogin,
  Scroll,
  Description,
  PopoverContainer,
  TooltipContainerMobile,
  TopContent,
  NewBtn,
} from './styles';
import SearchBar from '../SearchBar_old';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import { HandleClickMenus } from '../../Utils/HandleClickMenu';
import { BreadCrumbItemProps } from '../Breadcrumb';

interface HeaderProps {
  handleMenu1?: () => void;
  handleMenu2?: () => void;
  handleMenu3?: () => void;
}

const Header: FC<HeaderProps> = ({ handleMenu1, handleMenu2, handleMenu3 }) => {
  const isMobile = useWindowDimensions();
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);

  const [loginTooltip, setLoginTooltip] = useState(false);
  const [linkLoginT, setLinkLoginT] = useState(false);
  const [cestaTooltip, setCestaTooltip] = useState(false);
  const [linkCestaT, setLinkCestaT] = useState(false);
  const [favoritosTooltip, setFavoritosTooltip] = useState(false);
  const [linkFavoritosT, setLinkFavoritosT] = useState(false);
  const [name, setName] = useState('');
  const [feather, setFeather] = useState(true);
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const linkHome = cookies.acomNick
    ? 'https://minhaconta.americanas.com.br/v2/'
    : `https://cliente.americanas.com.br/simple-login/?guest=true&next=${window.location.href}`;

  // const linkHome = `https://cliente.americanas.com.br/simple-login/?guest=true`;

  const linkFavoritos = 'https://www.americanas.com.br/listas/favoritos';

  const linkCesta = 'https://sacola.americanas.com.br/simple-basket/#/basket';

  // console.log('cookies: ', cookies);

  useEffect(() => {
    const { feather } = parsed;

    if (feather === 'false') {
      setFeather(false);
    }
  }, [parsed]);

  const handleSEO = useCallback(
    (type: 'logo' | 'minhaconta' | 'favoritos' | 'cesta') => {
      HandleEvents({
        isMobile,
        eventAction: 'header',
        eventCategory: 'nossaslojas',
        eventLabel: type,
        eventValue: undefined,
        cookies,
      });
    },
    [cookies, isMobile]
  );

  const handleClick = useCallback(
    (uri: string, type: 'logo' | 'minhaconta' | 'favoritos' | 'cesta') => {
      HandleClickMenus({ uri, type, isMobile, cookies });
    },
    [isMobile, cookies]
  );

  const handleLoginClick = useCallback(() => {
    // linkLoginT ? (window.location.href = linkHome) : setLinkLoginT(true);
    // handleClick(linkHome, 'minhaconta')
    linkLoginT ? (window.location.href = linkHome) : setLinkLoginT(true);
    setLoginTooltip(true);
  }, [linkLoginT, linkHome]);

  const handleFavoritosClick = useCallback(() => {
    // link do href => linkFavoritos
    linkCestaT ? (window.location.href = linkFavoritos) : setLinkCestaT(true);
    setFavoritosTooltip(true);
  }, [linkCestaT, linkFavoritos]);

  const handleCestaClick = useCallback(() => {
    linkFavoritosT
      ? (window.location.href = linkCesta)
      : setLinkFavoritosT(true);
    // link do href => linkCesta
    setCestaTooltip(true);
  }, [linkFavoritosT, linkCesta]);

  useEffect(() => {
    if (cookies.acomNick) {
      const { acomNick } = cookies;
      setName(acomNick);
    }
  }, [cookies]);

  // useEffect(() => {
  //   if (history) {
  //     history.listen(() => {
  //       console.log('history: ', history);
  //       console.log('entrou na funcao history');

  //       if (history.location.pathname !== pathName) {
  //         console.log('history pathname antes: ', history.location.pathname);
  //         console.log('history pathname depois: ', pathName);
  //         setPathName(history.location.pathname);
  //         window.scrollTo(0, 0);
  //       }
  //     });
  //   }
  // }, [history, pathName]);

  const makeBreadCrumb = useMemo(() => {
    const path = history.location.pathname;
    const query = history.location.search;

    let x;
    let xValue;
    const isStoreDetail = !!path.includes('loja');

    if (isStoreDetail) {
      x = 'store';
      xValue = parsed.storeName;
    } else if (path === '/') {
      x = 'home';
      xValue = '';
    } else {
      x = 'list';
      xValue = parsed.name;
    }

    const link = `&breadcrumbType=${x}&breadcrumb=${xValue}&breadcrumbLink=${path}${query}`;

    return link;
  }, [
    history.location.search,
    history.location.pathname,
    parsed.storeName,
    parsed.name,
  ]);

  return (
    <>
      {isMobile && feather && (
        <>
          <Legends
            actions={[
              {
                label: 'acessar minha conta',
                link: linkHome,
                linkType: 'open',
                type: 'minhaconta',
              },
              {
                label: 'minhas compras na loja',
                link: '/pedidos?chave=o2o_pedidos_00_0_0_nossaslojas',
                linkType: 'route',
                renderItem: () => (
                  <>
                    <span>minhas compras na loja</span>
                    <NewBtn>novo</NewBtn>
                  </>
                ),
              },
            ]}
            title="minha conta"
            bottomCalc="83%"
            mRight="-181px"
            mLeft="52px"
            visible={loginTooltip}
            onClickBody={() => {
              setLoginTooltip(false);
              setLinkLoginT(false);
            }}
            link={linkHome}
            multiOptions
          />

          <Legends
            title="acessar meus favoritos"
            bottomCalc="83%"
            mRight="-179px"
            mLeft="15px"
            visible={favoritosTooltip}
            onClickBody={() => {
              setFavoritosTooltip(false);
              setLinkFavoritosT(false);
            }}
            link={linkFavoritos}
            actions={[
              {
                label: 'acessar meus favoritos',
                link: linkFavoritos,
                linkType: 'open',
                type: 'favoritos',
              },
            ]}
          />

          <Legends
            lastContent
            title="acessar minha Cesta"
            bottomCalc="83%"
            mRight="-214px"
            mLeft="15px"
            visible={cestaTooltip}
            onClickBody={() => {
              setCestaTooltip(false);
              setLinkCestaT(false);
            }}
            link={linkCesta}
            actions={[
              {
                label: 'acessar minha cesta',
                link: linkCesta,
                linkType: 'open',
                type: 'cesta',
              },
            ]}
          />
        </>
      )}

      {feather && (
        <Wrapper isMobile={isMobile}>
          <Container isMobile={isMobile}>
            <Content isMobile={isMobile}>
              <TopContent isMobile={isMobile}>
                {/* <a href="https://www.americanas.com.br/"> */}
                <Logo
                  src={LogoAmericanas}
                  w={isMobile ? '110px' : '150px'}
                  h={isMobile ? '21px' : '29px'}
                  onClick={() =>
                    handleClick('https://www.americanas.com.br/', 'logo')
                  }
                />
                {/* </a> */}

                {!isMobile && <SearchBar />}

                <ActionsRight isMobile={isMobile}>
                  <ActionsRightItem isMobile={isMobile} upper={!!loginTooltip}>
                    {isMobile ? (
                      <Info>
                        {/* <a href={linkHome}> */}
                        {/* <a href="#" onClick={handleLoginClick}> */}
                        <Logo
                          src={UserIcon}
                          w={isMobile ? '18px' : '38px'}
                          h={isMobile ? '18px' : '38px'}
                          Mr="9px"
                          isMobile={isMobile}
                          onClick={handleLoginClick}
                        />
                        {/* </a> */}

                        {!isMobile && (
                          // <a href={linkHome}>
                          <InfoLogin
                            onClick={() => handleClick(linkHome, 'minhaconta')}
                          >
                            {name ? (
                              <Description>
                                Olá, {Truncate(name, 10)}
                                <br></br> minha conta
                              </Description>
                            ) : (
                              <Description>
                                Olá, faça seu login<br></br> ou cadastre-se
                              </Description>
                            )}

                            <FiChevronDown size="20" color="#FFFFFF" />
                          </InfoLogin>
                          // </a>
                        )}
                      </Info>
                    ) : (
                      <TooltipContainer
                        actions={[
                          {
                            label: 'acessar minha conta',
                            link: linkHome,
                            linkType: 'open',
                            type: 'minhaconta',
                          },
                          {
                            label: 'minhas compras na loja',
                            link: `/pedidos?chave=o2o_pedidos_00_0_0_nossaslojas${makeBreadCrumb}`,
                            linkType: 'route',
                            renderItem: () => (
                              <>
                                <span>minhas compras na loja</span>
                                <NewBtn>novo</NewBtn>
                              </>
                            ),
                          },
                        ]}
                        title="acessar minha conta"
                        bottomCalc="-162%"
                        // bottomCalc="-85%"
                        mNegativeLeft="51.3%"
                        sizeFakeBox="250px"
                        mLeft="71%"
                        link={linkHome}
                      >
                        <Info>
                          <Logo
                            src={UserIcon}
                            w={isMobile ? '30px' : '24px'}
                            h={isMobile ? '30px' : '24px'}
                            Mr="9px"
                            isMobile={isMobile}
                          />
                          {!isMobile && (
                            // <a href={linkHome}>
                            <InfoLogin
                            // onClick={() =>
                            //   handleClick(linkHome, 'minhaconta')
                            // }
                            >
                              {name ? (
                                <Description>
                                  Olá, {Truncate(name, 10)}
                                  <br></br> minha conta
                                </Description>
                              ) : (
                                <Description>
                                  Olá, faça seu login<br></br> ou cadastre-se
                                </Description>
                              )}

                              <FiChevronDown size="20" color="#FFFFFF" />
                            </InfoLogin>
                            // </a>
                          )}
                        </Info>
                      </TooltipContainer>
                    )}
                  </ActionsRightItem>

                  <ActionsRightItem
                    isMobile={isMobile}
                    upper={!!favoritosTooltip}
                  >
                    {isMobile ? (
                      <Logo
                        src={FavoriteIcon}
                        w={isMobile ? '16.37px' : '24px'}
                        h={isMobile ? '16px' : '24px'}
                        onClick={handleFavoritosClick}
                      />
                    ) : (
                      <TooltipContainer
                        title="acessar meus favoritos"
                        bottomCalc="-85%"
                        mNegativeLeft="-4.5%"
                        mLeft="68%"
                        link={linkFavoritos}
                        sizeFakeBox="110px"
                        actions={[
                          {
                            label: 'acessar meus favoritos',
                            link: linkFavoritos,
                            linkType: 'open',
                            type: 'favoritos',
                          },
                        ]}
                      >
                        <Logo
                          src={FavoriteIcon}
                          w={isMobile ? '22px' : '24px'}
                          h={isMobile ? '16px' : '24px'}
                        />
                      </TooltipContainer>
                    )}
                  </ActionsRightItem>

                  <ActionsRightItem isMobile={isMobile} upper={!!cestaTooltip}>
                    {isMobile ? (
                      <Logo
                        src={BasketIcon}
                        w={isMobile ? '18px' : '24px'}
                        h={isMobile ? '16px' : '24px'}
                        onClick={handleCestaClick}
                      />
                    ) : (
                      <TooltipContainer
                        title="acessar minha cesta"
                        bottomCalc="-85%"
                        mNegativeLeft="-30.5%"
                        mLeft="79%"
                        link={linkCesta}
                        sizeFakeBox="110px"
                        actions={[
                          {
                            label: 'acessar minha cesta',
                            link: linkCesta,
                            linkType: 'open',
                            type: 'cesta',
                          },
                        ]}
                      >
                        <Logo
                          src={BasketIcon}
                          w={isMobile ? '28px' : '24px'}
                          h={isMobile ? '28px' : '24px'}
                        />
                      </TooltipContainer>
                    )}
                  </ActionsRightItem>
                </ActionsRight>
              </TopContent>

              {isMobile && (
                <SearchBar
                  contentStyle={{
                    marginTop: 10,
                    width: 'calc(100vw - 32px)',
                  }}
                  isMobile={isMobile}
                />
              )}
            </Content>
          </Container>
        </Wrapper>
      )}
    </>
  );
};

export default Header;
