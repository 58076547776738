import React, { FC, useMemo, useCallback, useState, useEffect } from 'react';

import {
  Container,
  LogoStore,
  Info,
  Buttons,
  ProductButton,
  WhatsButton,
  Distance,
  BuyButton,
  BoxLayer,
  ActionFakeBox,
  FooterCard,
  Content,
  MiddleContent,
  DudeBox,
} from './styles';
import Truncate from '../../Utils/truncate';
import AmericanasLogo from '../../Assets/logo_menor.png';
import { ReactComponent as Clock } from '../../Assets/clock.svg';
import { ReactComponent as WhatsImg } from '../../Assets/WHATS_ICONE.svg';
import { ReactComponent as HouseIcon } from '../../Assets/ICONE-LOJA.svg';
import { ReactComponent as BasketIcon } from '../../Assets/ICONE_CESTA.svg';
import { ReactComponent as LocalIcon } from '../../Assets/local_branco.svg';
import { ReactComponent as LogoBranco } from '../../Assets/logo_branco.svg';
import { ReactComponent as Aamericanas } from '../../Assets/a-americanas.svg';
import { ReactComponent as StoreIcon } from '../../Assets/store.svg';
import { ReactComponent as DudeIcon } from '../../Assets/info-cinza.svg';
import getDay from '../../Utils/getday';
import useWindowDimensions from '../../Utils/size';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import ListWpp, { getApiListWpp, WppProps } from '../../Utils/ListWpp';
import ListCovid, { CovidProps, getApiListCovid } from '../../Utils/Covid';
import ListLocal, { LocalProps, getApiListLocal } from '../../Utils/Local';
import { TestAB } from '../../Utils/PlanOut';
import { useCookies } from 'react-cookie';

interface CardProps {
  title: string;
  address: string;
  distance?: number;
  isPar?: boolean;
  index: number;
  schedules?: any;
  canPickUpInStore?: boolean;
  canShipFromStore?: boolean;
  sellerStoreId: number | string;
  carousel?: boolean;
  selected?: boolean;
  onClickProducts?: (item: any) => void;
  onClickWpp?: (item: any) => void;
  onClickBuy?: (item: any) => void;
  item?: any;
  onClickCard?: (item: any) => void;
  containerStyle?: object;
  isMap: 'lista' | 'mapa' | undefined;
  variant: any;
}

const CardStore: FC<CardProps> = ({
  title,
  address,
  distance,
  isPar,
  index,
  schedules,
  canPickUpInStore,
  canShipFromStore,
  sellerStoreId,
  carousel = false,
  selected = false,
  onClickProducts,
  onClickWpp,
  item,
  onClickBuy,
  onClickCard,
  containerStyle,
  isMap,
  variant,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);

  const [listLocal, setListLocal] = useState<LocalProps>({} as LocalProps);
  const [listWpp, setListWpp] = useState<WppProps>({} as WppProps);
  const [listCovid, setListCovid] = useState<CovidProps>({} as CovidProps);
  const [covidStore, setCovidStore] = useState(false);
  const [wppStore, setWppStore] = useState(false);
  const [localStore, setLocalStore] = useState(false);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const history = useHistory();
  const isMobile = useWindowDimensions();

  const distanceParsed = useMemo(() => {
    let d = '0 m';

    if (distance) {
      d = distance > 1 ? `${distance.toFixed(1)} km ` : `${distance * 1000} m `;
    }

    return d;
  }, [distance]);

  const multiploTres = useMemo(() => {
    const multiplo = (index + 1) % 3 === 0 && index !== 0;
    return multiplo;
  }, [index]);

  const day = useMemo(() => {
    if (!schedules) return null;
    const d = getDay(new Date());

    if (d) {
      const hours = schedules[d];

      if (hours && hours.start && hours.end) {
        const [hourStart, minutesStart] = hours.start.split(':');
        const [hourEnd, minutesEnd] = hours.end.split(':');

        const minutesS = minutesStart === '00' ? '' : `:${minutesStart}`;
        const minutesE = minutesEnd === '00' ? '' : `:${minutesEnd}`;
        return `${hourStart}${minutesS}h às ${hourEnd}${minutesE}h`;
      } else {
        return null;
      }
    }
  }, [schedules]);

  const linkStore = useMemo(() => {
    const link = canShipFromStore
      ? `https://www.americanas.com.br/lojas-proximas/33014556000196/${sellerStoreId}/ship`
      : `https://www.americanas.com.br/lojas-proximas/33014556000196/${sellerStoreId}/pick-up`;

    return link;
  }, [canShipFromStore, sellerStoreId]);

  const handleDetails = useCallback(
    (id: string) => {
      const { lat, lng, name, pag, feather, footer, near } = parsed;

      let uri = `?lat=${lat}&lng=${lng}&name=${name}&pag=${pag}`;

      if (feather !== 'undefined') {
        uri = uri + `&feather=${feather}`;
      }

      if (footer !== 'undefined') {
        uri = uri + `&footer=${footer}`;
      }

      if (near !== 'undefined') {
        uri = uri + `&near=${near}`;
      }

      // history.push({
      //   pathname: `/loja/${id}/detalhes`,
      //   search: uri,
      // });

      history.push({
        pathname: `/loja/${id}`,
        search: uri,
      });
    },
    [history, parsed]
  );

  useEffect(() => {
    ListWpp({ sellerStoreId: sellerStoreId, wppFromWeb: listWpp })
      .then((e: boolean) => setWppStore(e))
      .catch((e: any) => console.log('erro2: ', e));
  }, [listWpp, sellerStoreId]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListWpp()
        .then((e) => setListWpp(e))
        .catch((e) => console.log('erro ao caregar listaWpp: ', e));
    }
  }, []);

  useEffect(() => {
    ListCovid({ sellerStoreId, wppFromWeb: listCovid })
      .then((e: boolean) => setCovidStore(e))
      .catch((e: any) => console.log('erro2 listaCovid: ', e));
  }, [listCovid, sellerStoreId]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListCovid()
        .then((e) => setListCovid(e))
        .catch((e) => console.log('erro ao caregar listaCovid: ', e));
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListLocal()
        .then((e) => setListLocal(e))
        .catch((e) => console.log('erro ao carregar listaLocal: ', e));
    }
  }, []);

  useEffect(() => {
    ListLocal({ sellerStoreId: sellerStoreId, wppFromWeb: listLocal })
      .then((e: boolean) => setLocalStore(e))
      .catch((e: any) => console.log('erro2: ', e));
  }, [listLocal, sellerStoreId]);

  return (
    <Container
      isPar={isPar}
      multiploTres={multiploTres}
      isMobile={isMobile}
      carousel={carousel}
      index={index}
      selected={selected}
      style={containerStyle}
      isMap={isMap === 'mapa'}
      // onClick={() => (isMobile ? onClickCard : {})}
    >
      <Content>
        <LogoStore
          isMobile={isMobile}
          onClick={onClickCard}
          isLocal={localStore}
        >
          {localStore ? <LocalIcon /> : <Aamericanas width={20} height={75} />}
        </LogoStore>

        {variant === 'NEW' && (
          <DudeBox>
            <DudeIcon width="18px" height="18px" />
          </DudeBox>
        )}

        <Info
          isMobile={isMobile}
          open={!!(item.open || covidStore)}
          small={variant === 'NEW'}
        >
          {/* <h1>{Truncate(title, 21)}</h1> */}
          <h1 onClick={onClickCard}>{title}</h1>

          <div>
            <p className="truncate-overflow">
              <strong>{distanceParsed}</strong>
              {address}
            </p>
          </div>
        </Info>
      </Content>
      <FooterCard isMobile={isMobile} open={!!(item.open || covidStore)}>
        <MiddleContent>
          {/* <Distance>
            <span>{distanceParsed}</span>
          </Distance> */}
          <BoxLayer isMobile={isMobile} open={!!(item.open || covidStore)}>
            <Clock width={18} height={18} style={{ marginRight: 8 }} />
            {item.open ? (
              covidStore ? (
                <h2>aberta para pedidos online</h2>
              ) : (
                <h2>
                  aberta&nbsp;
                  <strong>{day || 'Horário Indisponível'}</strong>
                </h2>
              )
            ) : covidStore ? (
              <h2>aberta para pedidos online</h2>
            ) : (
              <h2>
                fechada&nbsp; 
                <strong>{day || 'Horário Indisponível'}</strong>
              </h2>
            )}
          </BoxLayer>
        </MiddleContent>

        <Buttons wppStore={wppStore} variant={variant} isMobile={isMobile}>
          <div
            onClick={onClickBuy}
            style={{ width: variant === 'NEW' && !wppStore ? '100%' : '98%' }}
          >
            <BuyButton isMobile={isMobile} variant={variant} big={!wppStore}>
              <BasketIcon />
              <span>comprar</span>
            </BuyButton>
          </div>

          {(variant === 'OLD' || variant === 'CONTROL') && (
            <div onClick={onClickProducts} style={{ width: '98%' }}>
              <ProductButton
                isMobile={isMobile}
                variant={variant}
                big={wppStore}
              >
                <StoreIcon />
                <span>visitar loja</span>
              </ProductButton>
            </div>
          )}

          {variant === 'NEW' && wppStore && (
            <div onClick={onClickWpp} style={{ width: '98%' }}>
              <ProductButton isMobile={isMobile} variant={variant}>
                <WhatsImg />
                <span>whatsapp</span>
              </ProductButton>
            </div>
          )}

          {(variant === 'OLD' || variant === 'CONTROL') && wppStore && (
            <div onClick={onClickWpp} style={{ marginLeft: 5 }}>
              <WhatsButton isMobile={isMobile} variant={variant}>
                <WhatsImg />
              </WhatsButton>
            </div>
          )}
        </Buttons>
      </FooterCard>

      <ActionFakeBox onClick={onClickCard} />
    </Container>
  );
};

export default CardStore;
