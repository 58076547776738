import styled, { css } from 'styled-components';

interface ContainerProps {
  isMobile?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  margin-top: ${(props) => (props.isMobile ? '82px' : '32px')};
  border-radius: ${(props) => (props.isMobile ? '0px' : '10px')};
  margin-bottom: ${(props) => (props.isMobile ? '0px' : '35px')};

  ${({ isMobile }) =>
    isMobile &&
    css`
      @media only screen and (max-width: 320px) {
        margin-top: -12px;
      }
    `}
`;
