import React, { FC, useCallback } from 'react';
import { Container, Content, Barrinha } from './styles';
import ModalTooltip from '../ModalTooltip';
import useWindowDimensions from '../../Utils/size';
import { useHistory } from 'react-router-dom';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import { useCookies } from 'react-cookie';
import { HandleClickMenus } from '../../Utils/HandleClickMenu';

export interface ActionsProps {
  label: string;
  link: any;
  linkType: 'open' | 'route';
  renderItem?: () => React.ReactNode;
  type?: 'logo' | 'minhaconta' | 'favoritos' | 'cesta' | undefined;
}

interface Props {
  bottomCalc: string;
  mNegativeLeft?: string;
  mLeft?: string;
  mRight: string;
  link?: string;
  title: string;
  visible: boolean;
  onClickBody?: () => void;
  actions?: ActionsProps[];
  lastContent?: boolean;
  multiOptions?: boolean;
}

const Legends: FC<Props> = ({
  bottomCalc,
  mNegativeLeft,
  mLeft,
  link,
  title,
  mRight,
  onClickBody,
  visible,
  actions,
  lastContent,
  multiOptions,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);

  const isMobile = useWindowDimensions();
  const history = useHistory();

  const handleClick = useCallback(
    (
      uri: string,
      type: 'logo' | 'minhaconta' | 'favoritos' | 'cesta' | undefined
    ) => {
      console.log('uri: ', uri);
      console.log('type: ', type);
      if (type) {
        HandleClickMenus({ type, uri, isMobile, cookies });
      }

      if (uri && !type) {
        // rota interna da aplicacao
        history.push(uri);
      }
    },
    [isMobile, cookies, history]
  );

  // const handleClick = useCallback(() => {
  //   if (link) {
  //     window.location.href = link;
  //   }
  // }, [link]);

  const handleClickAction = useCallback(
    (type: 'route' | 'open', linkBtn: string) => {
      if (type === 'open') {
        window.location.href = linkBtn;
      } else {
        history.push(linkBtn);
      }
    },
    [history]
  );

  return (
    <>
      {visible && (
        <Container>
          {actions ? (
            actions.map((i: ActionsProps, index: number) => (
              <Content
                isMobile={isMobile}
                key={i.label}
                bottomCalc={bottomCalc}
                mNegativeLeft={mNegativeLeft}
                mLeft={mLeft}
                mRight={mRight}
                style={{ marginTop: index > 0 ? 55 * index : 0 }}
                onClick={() => handleClick(i.link, i.type)}
                lastContent={lastContent}
                multiOptions={multiOptions}
              >
                {i.renderItem ? i.renderItem() : <span>{i.label}</span>}

                {/* {index % 2 == 0 && <Barrinha />} */}
              </Content>
            ))
          ) : (
            <Content
              isMobile={isMobile}
              bottomCalc={bottomCalc}
              mNegativeLeft={mNegativeLeft}
              mLeft={mLeft}
              mRight={mRight}
              // onClick={handleClick}
            >
              <span>{title}</span>
            </Content>
          )}

          <ModalTooltip visible={visible} onClick={onClickBody} />
        </Container>
      )}
    </>
  );
};
export default Legends;
