import React, { FC } from 'react';
import { FiChevronDown, FiChevronLeft, FiChevronUp } from 'react-icons/fi';
import { Container, Box, Info, Action, ContentBox } from './styles';

import useWindowDimensions from '../../Utils/size';
import { ReactComponent as PinIcon } from '../../Assets/icon-map-reference.svg';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import PinWhite from '../../Assets/pin-white.png';

interface BuscaProps {
  openAction: () => void;
}

const BuscaBoxV2: FC<BuscaProps> = ({ openAction }) => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const isMobile = useWindowDimensions();

  return (
    <Container isMobile={isMobile}>
      <Box isMobile={isMobile}>
        <ContentBox isMobile={isMobile}>
          <img src={PinWhite} width={14} height={19} />

          <Info isMobile={isMobile}>
            <h3 className="truncate-overflow">{parsed.name}</h3>
          </Info>

          <Action isMobile={isMobile}>
            <span onClick={() => openAction()}>
              <FiChevronDown size={20} color={'#FFF'} />
            </span>
          </Action>
        </ContentBox>
      </Box>
    </Container>
  );
};

export { BuscaBoxV2 };
