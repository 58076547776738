import React, { FC, useCallback, ReactNode } from 'react';
import useWindowDimensions from '../../../Utils/size';
import { ListMode } from './ListMode';
import BuscaBox from '../../../Components/BuscaBox';
import { BuscaBoxV2 } from '../../../Components/BuscaBoxV2';
import { ButtonsBox, ToggleButtonView } from '../styles';
import ViewStoreButton from '../../../Components/ViewStoreButton';
import { CoordsInterface } from '..';

import { ReactComponent as MapIcon } from '../../../Assets/map.svg';
import { ReactComponent as BurgerIcon } from '../../../Assets/burguer.svg';
import { WppProps } from '../../../Utils/ListWpp';
import { MapMode } from '../MobileContent/MapMode';

interface Props {
  handleChangeAddress: () => void;
  activeButton: 'mapa' | 'lista';
  visibleButtonMap: boolean;
  myList: any[];
  coordsTmpCenter: CoordsInterface;
  coordsCenter: CoordsInterface;
  filter: 'all' | 'local' | 'normal';
  handleAnalytics: () => void;
  load: (data?: any) => Promise<any>;
  loadForMap: (data?: any) => Promise<any>;
  handleChangeList: (data: any) => void;
  makeFilter: () => ReactNode;
  parsed: any;
  dadosLocal: any[];
  dados: any[];
  makeCardStore: (a: any, b: any, c?: any) => ReactNode;
  loadingFirst: boolean;
  existsCovid: boolean;
  totalElements: number;
  makeWppButton: ReactNode;
  tooltipWhatsView: boolean;
  listWpp: WppProps;
  changeState: (data: boolean) => void;
  loading: boolean;
  hasMore: boolean;
  TypeList: boolean;
  isPar: boolean;
  currentLegions: any;
  storeSelected: any;
  selectedStore: any;
  makeAddress: (data: any) => string;
  handleDetails: (a: any, b: any, c?: any) => void;
  cookies: any;
  handleBuy: (data?: any, b?: any) => void;
  totalPag: number;
  MapRef: any;
  currentPag: number;
  changeStateForMap: () => void;

  pinsList: any[];
  handleDrag: (data?: any) => void;
  handleChangeMap: (data?: any) => void;
  handlePinClick: (data?: any) => void;
  handleZoom: (data?: any) => void;
  handleGeoLocation: (
    type: 'maps' | 'browser' | 'waze' | 'mapsBrowser'
  ) => void;
  showNavigationOptions: boolean;
  makeRouteButton: ReactNode;
  startPositionCarousel: number;
  eventChangeCarousel: (data: any) => void;
  makeWppButtonMobile: ReactNode;
}
const MobileContent: FC<Props> = ({
  handleChangeAddress,
  activeButton,
  visibleButtonMap,
  myList,
  coordsCenter,
  coordsTmpCenter,
  filter,
  handleAnalytics,
  load,
  loadForMap,
  handleChangeList,
  makeFilter,
  makeCardStore,
  parsed,
  dados,
  dadosLocal,
  loadingFirst,
  existsCovid,
  totalElements,
  makeWppButton,
  tooltipWhatsView,
  listWpp,
  changeState,
  loading,
  hasMore,
  TypeList,
  isPar,
  currentLegions,
  storeSelected,
  selectedStore,
  makeAddress,
  handleDetails,
  cookies,
  handleBuy,
  totalPag,
  MapRef,
  currentPag,
  changeStateForMap,
  handleChangeMap,
  handleDrag,
  handlePinClick,
  handleZoom,
  handleGeoLocation,
  showNavigationOptions,
  makeRouteButton,
  startPositionCarousel,
  eventChangeCarousel,
  pinsList,
  makeWppButtonMobile,
}) => {
  const isMobile = useWindowDimensions();

  const handleMapLoad = useCallback(() => {
    loadForMap({
      append: false,
      pag: 1,
      lat: String(coordsTmpCenter.lat),
      lng: String(coordsTmpCenter.lng),
      loadFromMap: true,
      local: filter === 'local',
    });

    setTimeout(() => {
      handleAnalytics();
    }, 4000);
  }, [loadForMap, coordsTmpCenter, filter, handleAnalytics]);
  return (
    <>
      {isMobile && tooltipWhatsView && makeWppButtonMobile};
      {activeButton === 'lista' ? (
        <BuscaBox openAction={handleChangeAddress} />
      ) : (
        <BuscaBoxV2 openAction={handleChangeAddress} />
      )}
      <ButtonsBox isMobile={isMobile}>
        <div style={{ width: 70 }}></div>

        <div>
          {visibleButtonMap && (
            <ViewStoreButton
              isMobile={isMobile}
              dados={!!myList.length}
              onClick={handleMapLoad}
            />
          )}
        </div>

        <ToggleButtonView
          isMap={activeButton === 'mapa'}
          onClick={() =>
            handleChangeList(activeButton === 'lista' ? 'mapa' : 'lista')
          }
        >
          {activeButton === 'lista' ? (
            <MapIcon />
          ) : (
            <BurgerIcon width={16} height={16} />
          )}
          <span>{activeButton === 'lista' ? 'mapa' : 'lista'}</span>
        </ToggleButtonView>
      </ButtonsBox>
      {activeButton === 'lista' ? (
        <ListMode
          activeButton={activeButton}
          dados={dados}
          dadosLocal={dadosLocal}
          loadingFirst={loadingFirst}
          makeCardStore={makeCardStore}
          makeFilter={makeFilter}
          myList={myList}
          parsed={parsed}
          TypeList={TypeList}
          changeState={changeState}
          cookies={cookies}
          currentLegions={currentLegions}
          existsCovid={existsCovid}
          handleBuy={handleBuy}
          handleDetails={handleDetails}
          hasMore={hasMore}
          isPar={isPar}
          listWpp={listWpp}
          loading={loading}
          makeAddress={makeAddress}
          makeWppButton={makeWppButton}
          selectedStore={selectedStore}
          storeSelected={storeSelected}
          tooltipWhatsView={tooltipWhatsView}
          totalElements={totalElements}
          totalPag={totalPag}
          load={load}
          currentPag={currentPag}
          filter={filter}
        />
      ) : (
        <MapMode
          activeButton={activeButton}
          dados={dados}
          dadosLocal={dadosLocal}
          loadingFirst={loadingFirst}
          makeCardStore={makeCardStore}
          makeFilter={makeFilter}
          myList={myList}
          parsed={parsed}
          TypeList={TypeList}
          changeState={changeState}
          cookies={cookies}
          currentLegions={currentLegions}
          existsCovid={existsCovid}
          handleBuy={handleBuy}
          handleDetails={handleDetails}
          hasMore={hasMore}
          isPar={isPar}
          listWpp={listWpp}
          loading={loading}
          makeAddress={makeAddress}
          makeWppButton={makeWppButton}
          selectedStore={selectedStore}
          storeSelected={storeSelected}
          tooltipWhatsView={tooltipWhatsView}
          totalElements={totalElements}
          totalPag={totalPag}
          load={loadForMap}
          currentPag={currentPag}
          filter={filter}
          MapRef={MapRef}
          coordsCenter={coordsCenter}
          eventChangeCarousel={eventChangeCarousel}
          handleChangeMap={handleChangeMap}
          handleDrag={handleDrag}
          handleGeoLocation={handleGeoLocation}
          handlePinClick={handlePinClick}
          handleZoom={handleZoom}
          makeRouteButton={makeRouteButton}
          pinsList={pinsList}
          showNavigationOptions={showNavigationOptions}
          startPositionCarousel={startPositionCarousel}
        />
      )}
    </>
  );
};

export { MobileContent };
