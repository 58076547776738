import React, { FC } from 'react';

import { Container } from './styles';
import useWindowDimensions from '../../Utils/size';
import GoogleMapsHome from '../GoogleMapsHome_old';

import Lojas from '../../Assets/lojas.json';

const Map: FC = () => {
  const isMobile = useWindowDimensions();

  return (
    <Container isMobile={isMobile}>
      <GoogleMapsHome />
    </Container>
  );
};

export default Map;
