import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.span`
  color: #333333;
  font-family: 'Helvetica Neue, Bold';
  font-size: 20px;
  line-height: 24px;
`;

export const Data = styled.div`
  /* width: 120px;
  height: 20px; */
  padding: 6px 11px;
  background-color: #fff;
  box-shadow: 0px 0px 20px #00000026;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 19px;

  span {
    color: #666;
    font-size: 14px;
    font-family: 'Helvetica Neue, Regular';
    margin: 0;
    padding: 0;
    font: -webkit-control;
    line-height: 16px;
    /* margin-top: 3px; */
  }
`;

// export const Content = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

interface CardProps {
  empty?: boolean;
}

export const Card = styled.div<CardProps>`
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 0px 20px #00000026;
  width: 100%;
  margin-bottom: 16px;
  /* height: 107px; */

  &:last-child {
    margin-bottom: 0px;
  }

  ${({ empty }) =>
    empty &&
    css`
      justify-content: space-between;
    `}

  h3 {
    text-align: center;
    font-size: 14px;
    color: #666666;
    font-family: 'Helvetica Neue';
  }
`;

export const TitleOffer = styled.span`
  font-size: 16px;
  font-family: 'Helvetica Neue, Bold';
  color: #333333;
  line-height: 19px;
  text-transform: capitalize;
`;

export const AddressOffer = styled.span`
  font-size: 14px;
  font-family: Helvetica Neue, Regular;
  color: #666;
  margin-top: 4px;
  line-height: 17px;
  text-transform: capitalize;
`;

export const Alignment = styled.div`
  width: 100%;
  .on-scrollbar {
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  .on-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }

  .on-scrollbar::-webkit-scrollbar {
    width: 4px !important;
    background-color: transparent;
    height: 40px;
  }

  .on-scrollbar::-webkit-scrollbar-thumb {
    background-color: #acacac;
  }
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 27px;
`;

export const Timestamp = styled.span`
  font-size: 14px;
  color: #333;
  font-family: 'Helvetica Neue, Regular';
  font: -webkit-control;
  line-height: 16px;
  font-weight: bold;
`;

export const Total = styled.span`
  font-size: 14px;
  color: #333;
  font-family: 'Helvetica Neue, Regular';
  font: -webkit-control;
  line-height: 16px;

  b {
    font-weight: bold;
  }
`;

export const Barrinha = styled.div`
  width: 1px;
  height: 100%;
  background: #d8d8d8;
`;

export const BarrinhaBottom = styled.div`
  height: 1px;
  background: #70707012;
  margin: 16px;
`;

export const HeaderCard = styled.div`
  /* width: 366px; */
  padding: 16px;
  /* border-right: solid 1px #d8d8d8; */
  display: flex;
  flex: 1;

  .truncate-overflow {
    /* max-width: 252px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  h2 {
    margin: 0;
    color: ${({ theme }) => theme.colors.redPrimary};
    font-size: 14px;
    font-family: Helvetica Neue;
    cursor: pointer;
  }
`;

export const Products = styled.div`
  /* padding: 0px 16px; */
  display: flex;
  flex: 1;
  justify-content: space-between;

  .truncate-overflow {
    /* max-width: 252px; */
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 14px;

  .placeholder-icon-empty {
    width: 80px;
    height: 80px;
    transform: rotate(0deg) !important;
  }

  svg {
    margin-right: 10px;
  }
`;

export const ItemDetail = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;

  .placeholder-icon-empty {
    width: 80px;
    height: 80px;
    transform: rotate(0deg) !important;
  }

  svg {
    margin-right: 10px;
  }
`;

interface ProductImgProps {
  marginRight: string;
}

export const ProductImg = styled.div<ProductImgProps>`
  width: 80px;
  height: 80px;
  border-radius: 9px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.marginRight};
  position: relative;

  img {
    width: 100%;
  }
`;
export const Description = styled.span`
  font-size: 14px;
  color: #666666 !important;
  font-family: 'Helvetica Neue';
  font-weight: 200;
  line-height: 17px;
`;

export const DescriptionDetail = styled.span`
  font-size: 14px;
  color: #666666 !important;
  font-family: 'Helvetica Neue';
  font-weight: 200;
  line-height: 17px;
  margin-top: 5px;
`;

interface AlignmentDescriptionProps {
  size: string;
  unique: boolean;
}

export const AlignmentDescription = styled.div<AlignmentDescriptionProps>`
  /* flex: 1; */
  width: 100%;
  max-width: ${(props) => props.size};

  ${({ unique }) =>
    unique &&
    css`
      max-width: 100%;
      flex: 1;
    `}
`;

export const FooterCardDetail = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: solid 1px #cccccc;
  padding-top: 29px;
  padding-bottom: 9px;

  > div {
    display: flex;
    align-items: flex-end;
  }

  span {
    color: #666666 !important;
    font-family: Helvetica Neue, Regular !important;
    line-height: 15px !important;
    font-size: 14px !important;
    margin: 0;
    padding: 0;
  }

  h1 {
    color: #333333 !important;
    font-family: 'Helvetica Neue, Bold' !important;
    font-size: 20px !important;
    margin: 0;
    padding: 0;
    margin-bottom: -7px !important;
    margin-left: 4px !important;
  }

  h2 {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: Helvetica Neue;
    font-size: 14px;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
`;

export const FooterCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 16px;
  /* border-top: solid 1px #70707012; */
  padding-top: 16px;

  > div {
    display: flex;
    align-items: flex-end;
  }

  span {
    color: #666666;
    font-family: 'Helvetica Neue';
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  h1 {
    color: #333333;
    font-family: 'Helvetica Neue, Bold';
    font-size: 24px;
    margin: 0;
    padding: 0;
    margin-bottom: -7px;
    margin-left: 4px;
  }

  h2 {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: Helvetica Neue;
    font-size: 14px;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
`;

export const HeadBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  svg {
    width: 13px;
    margin-right: 6px;
    transform: rotate(90deg);
  }

  svg path {
    fill: #4e4e4e;
  }
`;

export const Badge = styled.div`
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.redPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8px;
  right: -8px;

  span {
    color: white;
    font-size: 12px;
    font-family: Helvetica Neue;
  }
`;

export const LoaderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 158px;
`;

interface LoaderProps {
  size?: string;
  bottom?: string | null;
}

export const Loader = styled.img<LoaderProps>`
  width: ${(props) => props.size || '80px'};
  height: ${(props) => props.size || '80px'};
  margin: auto;
`;

export const BoxNfe = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: #666666;
    font-size: 16px;
    margin: 0;
    font-family: Helvetica Neue, Regular;
    text-align: center;
    line-height: 21px;
    font-weight: 100;
  }

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: Helvetica Neue, Regular;
    font-size: 14px;
  }
`;

export const AlignmentNfe = styled.div`
  position: absolute;
  z-index: 99;

  width: 100%;
  max-width: 500px;
  height: calc(100vh - 204px);

  .on-scrollbar {
    scrollbar-width: thin; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }

  .on-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }

  .on-scrollbar::-webkit-scrollbar {
    width: 6px !important;
    background-color: transparent;
  }

  .on-scrollbar::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
  }
`;

export const ActionsNfe = styled.div`
  display: flex;
  margin-top: 22px;
  align-items: center;
`;

export const MoreButton = styled.div`
  border: solid 2px #e0030e;
  border-radius: 8px;
  width: 160px;
  height: 36px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  cursor: pointer;

  span {
    font-family: Helvetica Neue, Regular;
    color: #df030e;
    font-size: 14px;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 24px;
  right: 20px;
  width: 32px;
  cursor: pointer;

  height: 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000026;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  padding-top: 17px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 41px;

  &:first-child {
    margin-top: 0px;
  }
`;

export const RightContent = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: space-between;
  padding: 16px;
`;

export const WrapperProduct = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: color-interpolation-filters;

  > div {
    display: flex;
  }

  h2 {
    color: #e81c2d;
    font-family: Helvetica Neue;
    font-size: 14px;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
`;

export const Values = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
  font-family: 'Helvetica Neue, Bold';
  font: -webkit-control;
  font-weight: bold;

  span {
    font-size: 14px;
    line-height: 16px;
    color: #333;
    margin-right: 3px;
    margin-bottom: 2px;
  }

  h1 {
    margin: 0;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
  }
`;

export const Separator = styled.div`
  border-bottom: solid 1px #e8e8e8;
  width: 100%;
  display: flex;
  flex-direction: column;

  h1 {
    margin: 0;
    color: #666666;
    font-family: 'Helvetica Neue, Bold';
    font-size: 22px;
    text-align: center;
    margin-bottom: 33px;
  }

  h2 {
    color: #333333;
    font-family: 'Helvetica Neue, Bold';
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    margin-bottom: 6px;
    margin-top: 16px;
  }

  h3 {
    margin: 0;
    color: #666666;
    font-family: Helvetica Neue, Regular;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 8px;
  }

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: Helvetica Neue, Regular;
    font-size: 14px;
    line-height: 18px;
    text-align: center !important;
    margin-bottom: 15.8px;
    cursor: pointer;
  }
`;

export const ProductImgDetail = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 8px;

  background: #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  position: relative;

  span {
    font-family: 'Helvetica Neue, Bold';
    font-size: 14px;
    color: #333333;
  }
`;

export const AlignmentDescriptionDetail = styled.div`
  flex: 1;
  padding-right: 16px;
`;

export const Price = styled.span`
  color: #333333 !important;
  font-family: 'Helvetica Neue, Bold' !important;
  font-size: 14px;
  margin-top: 5px;
`;

export const InfoDetail = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 23px;
  width: 100%;
  padding-bottom: 24px;
  border-bottom: solid 1px #d8d8d8;

  svg path {
    fill: #4e4e4e;
  }

  svg {
    width: 13px;
    margin-right: 6px;
    transform: rotate(-90deg);
    margin-left: 10px;
  }

  h2 {
    margin: 0;
    color: #666666;
    font-family: Helvetica Neue, Regular;
    font-size: 14px;
    text-align: right;
  }
`;

export const TimestampDetail = styled.span`
  font-size: 13px;
  color: #666666 !important;
  font-family: Helvetica Neue;
  margin-bottom: 4px;
`;

export const TotalDetail = styled.span`
  font-size: 13px;
  color: #333333 !important;
  font-family: Helvetica Neue, Regular;
`;

export const ProductsDetail = styled.div`
  /* padding: 0px 16px 0px 16px; */
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  z-index: 8;
  margin-top: 16px;
  margin-bottom: 17px;

  .truncate-overflow {
    /* max-width: 252px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  svg {
    margin-right: 0px !important;
  }

  p {
    color: #666666;
    font-size: 13px;
    font-family: Helvetica Neue, Regular;
    line-height: 16px;
    margin: 0px;
    margin-left: 12px;
    margin-bottom: 9px;
  }
`;

export const AlignPrice = styled.div`
  display: flex;
  /* width: 90px; */

  margin-right: 12px;
`;

export const PathBox = styled.div`
  height: 100% !important;
  margin: 0 16px;
`;

export const Path = styled.div`
  width: 1px;
  height: 100% !important;
  background: #d8d8d8;
`;

export const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 10px;
    margin-right: 6px;
    transform: rotate(90deg);
  }

  svg path {
    fill: #4e4e4e;
  }

  h2 {
    margin-left: 6px;
    font-size: 12px;

    color: #666666;
    font-family: Helvetica Neue;
  }
`;

interface TabelaProps {
  isMobile?: boolean;
}
export const Tabela = styled.div<TabelaProps>`
  background: white;
  overflow: scroll;
  /* height: calc(100vh - 204px - 85px - 57px - 144px); */
  height: 245px;

  overflow-x: hidden;
  padding: 0px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      height: calc(100vh - 85px - 57px - 44px);
    `}
`;

interface PhotoStoreProps {
  isMobile: boolean;
  local?: boolean;
}

export const PhotoStore = styled.div<PhotoStoreProps>`
  width: ${(props) => (props.isMobile ? '55px' : '71px')};
  height: ${(props) => (props.isMobile ? '55px' : '71px')};
  border: 1px solid #0000000f;
  background-color: ${({ theme }) => theme.colors.redPrimary};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ local }) => (local ? '7px' : '7px')};
  margin-right: 24px;

  img {
    width: ${(props) => (props.isMobile ? '62px' : '107px')};
  }

  > svg path {
    fill: #fff;
  }

  > svg {
    fill: #fff;
    width: ${({ isMobile }) => (isMobile ? '65px' : '115px')};
    height: auto;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0px;
    `}
`;

export const BoxInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InfoStore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .truncate-overflow-small {
    /* max-width: 252px; */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  .truncate-overflow {
    /* max-width: 252px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  h2 {
    color: #333333;
    font-family: 'Helvetica Neue, Bold';
    font-size: 16px;
    line-height: 19px;
    margin: 0px;
    margin-top: 10px;
    text-transform: uppercase;
  }

  h3 {
    color: #333333;
    font-family: Helvetica Neue, Regular;
    font-size: 14px;
    line-height: 17px;
    margin-top: 5px;
    text-align: left;
  }
`;

export const BoxEntenda = styled.div`
  display: flex;
  cursor: pointer;

  h2 {
    margin-right: 8px;
  }

  svg {
    transform: rotate(0) !important;
    margin: 0px !important;
  }
`;

interface TooltipDiffProps {
  opacity: boolean;
}
export const TooltipDiff = styled.div<TooltipDiffProps>`
  width: 351px;
  height: 221px;
  opacity: ${({ opacity }) => (opacity ? 1 : 0)};
  /* opacity: 1; */

  transition: 0.2s;

  position: absolute;
  z-index: 9999999999;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 2px 8px #00000027;
  padding: 24px 28px;
  top: 240px;
  left: 155px;

  display: ${({ opacity }) => (opacity ? 'flex' : 'none')};
  /* display: flex; */
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;

  /* &::before {
    content: '';
    border-style: solid;
    border-color: #ffffff;
    border-width: 0px 11px 11px 11px;
    bottom: 100%;
    position: absolute;
    left: 85%;
  } */

  .triangle-with-shadow {
    width: 25px;
    height: 25px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 11px 0px -30px rgb(0 0 0 / 50%);
    margin-top: -49px;
    margin-left: 99%;
  }
  .triangle-with-shadow:after {
    content: '';
    position: absolute;
    width: 12.5px;
    height: 12.5px;
    background: white;
    transform: rotate(45deg); /* Prefixes... */
    top: 18px;
    left: 6px;
    box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
  }

  /* &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 80%;

    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid red;
    margin-top: -35px;
    box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
    position: absolute;
  } */

  h1 {
    margin: 0;
    color: #333333;

    font-family: 'Helvetica Neue, Bold';
    font-size: 16px;
  }

  span {
    margin: 0;
    color: #666666;

    font-family: Helvetica Neue, Regular;
    font-size: 14px;
  }

  b {
    color: #333;
    font-family: 'Helvetica Neue, Bold';
  }
`;

export const Lingueta = styled.div``;

export const WrapperCupom = styled.div`
  margin: auto;
  width: 100%;
  max-width: 600px;
  margin: -15px auto 50px auto;
  z-index: 9999999999999;
  position: relative;
`;

export const WrapperLoadingCupom = styled.div`
  display: block;
  visibility: visible;
  opacity: 1;
  position: fixed; /* Stay in place */
  z-index: 9999999999;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(222, 222, 222); /* Fallback color */
  background-color: rgba(222, 222, 222, 1); /* Black w/ opacity */
  -webkit-transition: opacity 0.2s, visibility 0.2s;
  transition: opacity 0.2s, visibility 0.2s;
`;

export const Logo = styled.img`
  width: 200px;
  height: auto;
`;

export const WrapperContent = styled.div`
  display: flex;
`;

export const Filters = styled.div`
  min-width: 312px;

  h1 {
    margin: 0;
    font-size: 16px !important;
    color: #333;
    line-height: 20px;
    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-weight: bold;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 90px;

  .shopping-quantity {
    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-size: 14px;
    color: #666;
    position: absolute;
    margin-top: -54px;
  }
`;

export const SeasonFilter = styled.div`
  margin-top: 21px;

  p {
    margin: 0;
    font-size: 14px;
    color: #333;
    line-height: 16px;
    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-weight: bold;
  }
`;

export const ItemFilter = styled.div`
  display: flex;
  align-items: center;
  height: 28px;

  h2 {
    margin: 0;
    font-size: 14px;
    color: #666;
    line-height: 16px;
    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    margin-left: 16px;
    margin-top: 29px;
  }

  .container-label {
    display: block;
    position: relative;
    padding-left: 20px;
    font-size: 12px;
    color: #424242;
    font-weight: 400;
  }

  .container-label input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 5px;
    left: 4px;
    height: 18px;
    width: 18px;
    border-radius: 6px;
    background-color: white;
    border: 2px solid #999;
    border-radius: 6px;
  }
  .container-label input:checked ~ .checkmark {
    background-color: #f80032;
    border: none;
  }

  .checkmark::after {
    display: none;
    content: '';
    position: absolute;
  }

  .container-label .checkmark::after {
    content: '';
    left: 6px;
    top: 2px;
    width: 4px;
    height: 7px;
    transform: rotate(45deg);
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
  }

  .container-label input:checked ~ .checkmark::after {
    display: block;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
  height: 40px;
  width: 100%;
  border-radius: 16px 16px 0 0;
  padding: 0 25px 0 16px;

  p {
    margin: 0;
    font-size: 14px;
    color: #008000;
    line-height: 16px;
    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-weight: bold;
  }
`;

interface CardProps {
  empty?: boolean;
}

export const CardBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  h3 {
    text-align: center;
    font-size: 14px;
    color: #666666;
    font-family: 'Helvetica Neue';
  }
`;

export const FilterResultBox = styled.div`
  width: 100%;
  padding: 16px 18px;
  background: #e8e8e8;
  border-radius: 8px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
`;

interface FilterItemProps {
  filter?: boolean;
}

export const FilterItem = styled.div<FilterItemProps>`
  padding: 6px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: solid 1px #666;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.25);
  background: #fff;
  cursor: pointer;

  span {
    margin-right: 7px;
    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-size: 13px;
    line-height: 14px;
    color: #666;
  }

  h2 {
    color: #f80032;
    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-size: 14px;
    line-height: 16px !important;
  }

  ${({ filter }) =>
    filter &&
    css`
      border: 0px;
      box-shadow: none;
      padding: 6px 8px;
      background: none;
    `}
`;
