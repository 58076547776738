import styled from 'styled-components';

export const Container = styled.div`
  /* background-color: red;
  display: flex;
  flex: 1;
  flex-direction: column; */
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: auto;
  background-color: #f3f3f3;
  z-index: 9999999999;
  position: relative;
  border-radius: 16px 16px 0 0;
  margin-top: 50px;
  padding-bottom: 30px;
`;

export const MoreButton = styled.button`
  background: transparent;
  border: solid 2px #e0030e;
  border-radius: 8px;
  width: 160px;
  height: 36px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
  cursor: pointer;

  margin-right: 20px;

  span {
    font-family: Helvetica Neue, Regular;
    color: #df030e;
    font-size: 14px;
  }
`;

export const WrapperCupom = styled.div`
  margin: auto;
  width: 100%;
  max-width: 600px;
  margin: -15px auto 50px auto;
  z-index: 9999999999999;
  position: relative;
`;
