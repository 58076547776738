import React, { FC } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import {
  Container,
  Background,
  Name,
  Address,
  Barrinha,
  InfoHeader,
  Info,
  ItemList,
  ItemData,
  Title,
  Footer,
  ActionButton,
  Content,
  Card,
  CardHeader,
  NameProduct,
  CardInfo,
  Value,
  Qtd,
  Ean,
  FooterContent,
  Wrapper,
  MoreButton,
} from './styles';
import { ReactComponent as Logo } from '../../Assets/logo_dark.svg';

interface Props {
  onClose: () => void;
}
const ModalDiferencas: FC<Props> = ({ onClose }) => {
  return (
    <>
      <Background>
        <Container>
          <RiCloseLine
            size={30}
            color="#666666"
            className="icone"
            onClick={onClose}
            style={{ marginLeft: 'auto', marginBottom: 32 }}
          />
          <Content>
            <h1>o que é cada um desses documentos?</h1>

            <span>
              <b>Cupom fiscal: </b>é um comprovante de venda e pode ser
              utilizado para realizar trocas dentro das lojas, ele é auxiliar da
              nota fiscal.
            </span>

            <span>
              <b>Nota fiscal: </b>é um documento oficial que pode ser utilizado
              para trocas com os fornecedores, fazer seguros e usar para
              assistência técnica.
            </span>
          </Content>
          <MoreButton onClick={onClose}>
            <span>ok, entendi</span>
          </MoreButton>
        </Container>
      </Background>
    </>
  );
};

export { ModalDiferencas };
