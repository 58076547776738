import styled, { css } from 'styled-components';

interface ContainerProps {
  isMobile?: boolean;
}

export const Container = styled.div<ContainerProps>`
  height: ${(props) => (props.isMobile ? '180px' : 'auto')};
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
`;

interface ContentProps {
  screen: number;
}

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;
