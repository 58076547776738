import styled from 'styled-components';

interface ContainerProps {
  isMobile: boolean;
  sizeFakeBox?: string;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  height: ${(props) => (props.isMobile ? 'auto' : '80px')};
  /* background: yellow; */
  z-index: 999999;
  display: flex;
  align-items: center;
  border-radius: 16px;

  a {
    color: #666666;
    border: 0px;
  }

  &:hover {
    z-index: 100;
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }

  .popover__content {
    width: ${({ sizeFakeBox }) => sizeFakeBox || '100px'};
    height: 80px;
    position: absolute;

    top: 0px;
    margin-left: -40px;
    z-index: 9999999;
  }

  .popover__content:hover {
    opacity: 1;
    visibility: visible;

    display: block;
    /* background: green; */
  }

  .active {
    z-index: 9999999999;
  }
`;

export const FakeArea = styled.div`
  width: 200px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0px;
  background: blue;
  z-index: 9;

  &:hover {
    .container-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
`;

interface PropsContent {
  bottomCalc: string;
  mNegativeLeft: string;
  mLeft: string;
  actions?: boolean;
}

export const Content = styled.span<PropsContent>`
  width: 259px;
  /* background: #ffffff;
  padding: 20px 21px; */
  border-radius: 16px;

  font-size: 14px;
  font-weight: bold;
  opacity: 0;
  visibility: hidden;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s;

  box-shadow: 0px 0px 5px #0000001a;

  position: absolute;
  bottom: calc(${(props) => props.bottomCalc} + 12px);
  left: ${(props) => props.mNegativeLeft};
  transform: translateX(-50%);
  color: #666666;
  font-family: Helvetica Neue;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.actions ? 'flex-start' : 'flex-start')};

  &::before {
    content: '';
    border-style: solid;
    border-color: #ffffff;
    border-width: 11px 11px 0 11px;
    bottom: 100%;
    position: absolute;
    left: ${(props) => props.mLeft};
    transform: translateX(-50%);
  }

  span {
    font-size: 14px !important;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export const Box = styled.div`
  background: #fff;
  width: 259px;
  border-radius: 16px;
  padding: 20px 21px;
`;
