/* eslint-disable */
import React, {
  FC,
  useCallback,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  Container,
  Select,
  SearchBox,
  Input,
  Icon,
  SelectBox,
  IconArrow,
  BoxArrow,
} from './styles';
import { useParams, useHistory } from 'react-router-dom';
import LupaIcon from '../../Assets/ICONE_BUSCA.svg';
import ArrowDown from '../../Assets/arrow-down.svg';
import api from '../../Services/api';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import useWindowDimensions from '../../Utils/size';
import { useCookies } from 'react-cookie';

interface Props {
  contentStyle?: object;
  isMobile?: boolean;
}

const SearchBar: FC<Props> = ({ contentStyle, isMobile }) => {
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const [nameStore, setNameStore] = useState('');
  const RefSelect = useRef<HTMLSelectElement>(null);
  const RefInput = useRef<HTMLInputElement>(null);
  const TestRef = useRef<HTMLOptionElement>(null);

  const { id } = useParams();

  const handleClickArrow = useCallback(() => {
    TestRef.current?.click();
  }, []);

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();

      // const SelectValue = RefSelect.current?.value;
      const SelectValue = id ? '0' : '1';
      const InputValue = RefInput.current?.value;

      // 1 - pegue na loja
      // 2 - receba em casa

      let label = SelectValue === String(1) ? 'buscaacom' : 'buscanpl';

      if (id) {
        label = 'buscaloja';
      }

      HandleEvents({
        isMobile: !!isMobile,
        eventAction: 'header',
        eventCategory: 'nossaslojas',
        eventLabel: label,
        eventValue: InputValue,
        cookies,
      });

      let uri;

      if (SelectValue === String(1)) {
        uri = `https://www.americanas.com.br/busca/${InputValue}?chave=o2o_busca_00_0_0_nossaslojas`;
      } else {
        if (id) {
          uri = `https://www.americanas.com.br/lojas-proximas/33014556000196/${id}/pick-up?ordenacao=relevance&conteudo=${InputValue}&chave=o2o_busca-loja-npl_00_0_0_nossaslojas`;
        } else {
          uri = `https://www.americanas.com.br/lojas-proximas/busca?ordenacao=relevance&conteudo=${InputValue}&chave=o2o_busca-npl_00_0_0_nossaslojas`;
        }
      }

      // console.log('uri: ', uri);
      // // history.push({
      // //   pathname: uri,
      // // });

      window.location.href = uri;
    },
    [id, isMobile, cookies]
  );

  useEffect(() => {
    let uri = `https://restql-api-v2-itg.b2w.io/acom/run-query/yourdev/store-details/2`;

    if (id) {
      uri = `${uri}?sellerStoreId=${id}`;
    }
    api
      .get(uri)
      .then(({ data }) => {
        const store = data['store-v2-capacity'].result.stores[0];

        if (store) {
          setNameStore(store.name);
        }
      })
      .catch((e) => console.log('e: ', e));
  }, [id]);

  const StoreNameSplitted = useMemo(() => {
    const name = nameStore;
    return name.substr(0, 17);
  }, [nameStore]);

  return (
    <form onSubmit={handleSubmit}>
      <Container style={contentStyle}>
        {/* <SelectBox isMobile={isMobile}>
          <span>{id ? StoreNameSplitted : 'Americanas'}</span>


          {isMobile && (
            <IconArrow
              isMobile={isMobile}
              src={ArrowDown}
              onClick={handleClickArrow}
            />
          )}
        </SelectBox> */}
        {/*
  // @ts-ignore */}
        <Input
          ref={RefInput}
          placeholder="busque aqui o produto pra pegar ou receber hoje :)"
          isMobile={isMobile}
        />
        <SearchBox isMobile={isMobile}>
          <Icon isMobile={isMobile} src={LupaIcon} onClick={handleSubmit} />
        </SearchBox>
      </Container>
    </form>
  );
};

export default SearchBar;
