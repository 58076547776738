import React, { FC, useRef } from 'react';
import { Container, MoreButton, Actions, WrapperCupom } from './styles';
import CupomFiscal, {
  ImperativeCupomFiscalProps,
} from '../../Components/CupomFiscal';
import { exportComponentAsPNG } from 'react-component-export-image';
import ModalTooltip from '../../Components/ModalTooltip';

const CupomFiscalPrint: FC = () => {
  const ref = useRef<any>(null);
  const CupomRef = useRef<ImperativeCupomFiscalProps>(null);

  return (
    <Container>
      <Actions>
        <MoreButton
          onClick={() =>
            exportComponentAsPNG(ref, { fileName: 'cupom-fiscal' })
          }
          style={{ width: 200 }}
        >
          <span>Salvar</span>
        </MoreButton>
      </Actions>
      <WrapperCupom
        style={{
          width: CupomRef.current?.getBoxSize().width,
          height: CupomRef.current?.getBoxSize().height,
          paddingTop: 30,
          backgroundColor: '#f3f3f3',
        }}
        ref={ref}
      >
        <CupomFiscal onClose={() => null} ref={CupomRef} forPrint order="" />
      </WrapperCupom>
      <ModalTooltip visible />
    </Container>
  );
};

export { CupomFiscalPrint };
