import styled, { css } from 'styled-components';

interface ContainerProps {
  isMobile: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  margin: ${(props) => (props.isMobile ? '0 16px' : '0px 0px 18px 0px')};
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
`;

interface PhotoStoreProps {
  isMobile: boolean;
  local?: boolean;
}

export const PhotoStore = styled.div<PhotoStoreProps>`
  min-width: ${(props) => (props.isMobile ? '90px' : '150px')};
  width: ${(props) => (props.isMobile ? '90px' : '150px')};
  height: ${(props) => (props.isMobile ? '90px' : '120px')};
  border: 1px solid #0000000f;
  background-color: ${({ theme }) => theme.colors.redPrimary};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ local }) => (local ? '27px' : '0px')};

  img {
    width: ${(props) => (props.isMobile ? '62px' : '107px')};
  }

  > svg path {
    fill: #fff;
  }

  > svg {
    fill: #fff;
    /* width: ${({ isMobile }) => (isMobile ? '65px' : '37px')}; */
    height: auto;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 8px;
    `}

  ${({ isMobile, local }) =>
    local &&
    !isMobile &&
    css`
      padding: 0px !important;

      svg {
        width: 150px;
        height: 50px !important;
      }
    `}

    ${({ isMobile, local }) =>
    local &&
    isMobile &&
    css`
      padding: 0px !important;

      svg {
        width: 150px;
        height: 31px !important;
      }
    `}
`;

interface InfoProps {
  isMobile: boolean;
}

export const Info = styled.div<InfoProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: ${(props) => (props.isMobile ? '15px' : '20px')};
  height: 120px;

  .truncate-overflow {
    max-width: 286px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;

interface TitleProps {
  isMobile: boolean;
}

export const Title = styled.span<TitleProps>`
  font-size: ${(props) => (props.isMobile ? '14px' : '18px')};
  font-family: 'Helvetica Neue, Bold';
  font-weight: bold;
  margin-top: ${(props) => (props.isMobile ? '8px' : '9px')};
  color: #333333;

  ${({ isMobile }) =>
    isMobile &&
    css`
      line-height: 17px;
    `}
`;

interface AddressProps {
  isMobile: boolean;
}

export const Address = styled.span<AddressProps>`
  font-size: ${(props) => (props.isMobile ? '13px' : '16px')};
  font-family: 'Helvetica Neue, Regular';
  font-weight: 100;
  color: #666666;
  /* margin-top: 10px; */

  ${({ isMobile }) =>
    isMobile &&
    css`
      line-height: 16px;
    `}
`;

export const List = styled.ul`
  display: flex;
  align-items: center;
  /* margin-top: 10px; */
  list-style: none;
  margin-bottom: 0px !important;

  li + li {
    :before {
      content: '•';
      font-size: 12px;
      color: #666666;
      margin: 0 10px;
    }
  }
`;

interface ItemProps {
  color?: string;
  isMobile: boolean;
  manyKm?: boolean;
}
export const Item = styled.li<ItemProps>`
  font-family: 'Helvetica Neue, Bold';
  font: -webkit-control;
  font-weight: bold;
  color: ${(props) => props.color || '#666666'};
  font-size: ${(props) => (props.isMobile ? '14px' : '16px')};

  span {
    font-family: 'Helvetica Neue, Regular';
    color: ${(props) => props.color || '#666666'};
    font-size: ${(props) => (props.isMobile ? '14px' : '16px')};

    strong {
      font-family: Helvetica Neue, Regular;
      font-weight: 200 !important;
    }
  }

  @media only screen and (max-width: 360px) {
    ${({ manyKm, isMobile }) =>
      manyKm &&
      isMobile &&
      css`
        font-size: 11px;
      `}
  }

  ${({ manyKm, isMobile }) =>
    manyKm &&
    isMobile &&
    css`
      font-size: 12px;
    `}
`;

interface BackButtonProps {
  isMobile: boolean;
}

export const BackButton = styled.div<BackButtonProps>`
  display: flex;
  align-items: center;
  margin-top: ${(props) => (props.isMobile ? '0px' : '0px')};

  ${(props) =>
    props.isMobile &&
    css`
      margin-bottom: 4px;
    `}

  span {
    font-family: 'Helvetica Neue';
    color: ${({ theme }) => theme.colors.redPrimary};
    font-weight: 100;
    font-size: 14px;
    text-decoration: underline;
    margin-left: 10px;
    cursor: pointer;
  }
`;

interface SetaProps {
  isMobile: boolean;
}

export const Seta = styled.img<SetaProps>`
  width: ${(props) => (props.isMobile ? '16px' : '16px')};
  height: ${(props) => (props.isMobile ? '16px' : '16px')};

  > svg {
    width: 17px;
    height: 15px;
    margin-right: 7px;
    fill: ${({ theme }) => theme.colors.redPrimary};
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.redPrimary};
  }

  cursor: pointer;
`;

interface ActionsProps {
  isMobile: boolean;
  withWpp: boolean;
  withTags: boolean;
}
export const Actions = styled.div<ActionsProps>`
  width: 100%;
  display: flex;
  align-items: center;

  ${(props) =>
    props.withWpp &&
    css`
      margin-top: 9px;
    `}

  ${(props) =>
    props.isMobile &&
    css`
      justify-content: space-between;
      align-items: center;
      margin-top: 19px;
      margin-bottom: 16px;
    `}

    ${({ isMobile, withTags }) =>
    !isMobile &&
    css`
      margin-top: ${withTags ? '24px' : '14px'};
    `}
`;

interface WhatsButtonProps {
  isMobile?: boolean;
  withProducts?: boolean;
}

export const WhatsButton = styled.div<WhatsButtonProps>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.isMobile ? 'auto' : '165px')};
  height: 40px;
  border: solid 1.6px;
  border-color: ${({ theme }) => theme.colors.redPrimary};
  padding: 10px 0px;
  border-radius: 5px;
  z-index: 11;
  cursor: pointer;
  /* padding-right: ${(props) => (props.isMobile ? '11px' : '16px')}; */

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding-right: 10px;
      padding-left: 7px;
    `}

  > svg {
    width: 17px;
    height: 15px;
    margin-right: 7px;
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.redPrimary};
  }

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: 'Helvetica Neue, Bold';
    font-size: 14px;
    font: -webkit-control;
    font-weight: bold;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.redPrimary};

    span {
      color: ${({ theme }) => theme.colors.redPrimary};
    }

    > svg path {
      fill: ${({ theme }) => theme.colors.redPrimary};
    }
  }
`;

interface BuyButtonProps {
  isMobile?: boolean;
}

export const BuyButton = styled.div<BuyButtonProps>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.isMobile ? 'auto' : '165px')};
  height: 40px;
  border: solid 1.6px;
  border-color: ${({ theme }) => theme.colors.redPrimary};
  padding: 10px 0px;
  border-radius: 5px;
  z-index: 11;
  cursor: pointer;
  /* padding-right: ${(props) => (props.isMobile ? '11px' : '16px')}; */

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding-right: 10px;
      padding-left: 7px;
    `}

  > svg {
    width: 17px;
    height: 15px;
    margin-right: 7px;
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.redPrimary};
  }

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: 'Helvetica Neue, Bold';

    font-size: 14px;
    font: -webkit-control;
    font-weight: bold;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.redPrimary};

    span {
      color: ${({ theme }) => theme.colors.redPrimary};
    }

    > svg path {
      fill: ${({ theme }) => theme.colors.redPrimary};
    }
  }
`;

export const FolderButton = styled.div<BuyButtonProps>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.isMobile ? 'auto' : '188px')};
  height: 41px;
  border: solid 1.6px;
  border-color: ${({ theme }) => theme.colors.redPrimary};
  padding: 10px 0px;
  border-radius: 5px;
  z-index: 11;
  cursor: pointer;
  /* padding-right: ${(props) => (props.isMobile ? '11px' : '16px')}; */

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding-right: 10px;
      padding-left: 7px;
    `}

  > svg {
    width: 17px;
    height: 15px;
    margin-right: 7px;
  }

  > svg path {
    fill: ${({ theme }) => theme.colors.redPrimary};
  }

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: 'Helvetica Neue, Bold';

    font-size: 14px;
    font: -webkit-control;
    font-weight: bold;
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.redPrimary};

    span {
      color: ${({ theme }) => theme.colors.redPrimary};
    }

    > svg path {
      fill: ${({ theme }) => theme.colors.redPrimary};
    }
  }
`;

interface WrapperButtonProps {
  isMobile?: boolean;
  big?: boolean;
}
export const WrapperButton = styled.div<WrapperButtonProps>`
  display: flex;
  flex: 1;

  margin-right: 8px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      &:last-child {
        margin-right: 0px;
      }
    `}

  ${({ isMobile, big }) =>
    !isMobile &&
    css`
      max-width: ${big ? '188px' : '165px'};
    `}
`;

export const ButtonOtherStores = styled.button`
  border: 0px;
  background: #f80032;
  height: 44px;
  width: 100%;
  max-width: 188px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  span {
    line-height: 20px;
    color: #fff;
    font-family: 'Helvetica Neue, Bold';
    font: -webkit-control;
    font-size: 16px;
    font-weight: bold;
  }
`;

export const WithoutServiceAlert = styled.div`
  margin-top: 16px;
  width: 100%;
  max-width: 500px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2fbff;
  border-radius: 8px;

  svg {
    fill: #666;
  }

  svg path {
    fill: #666;
  }

  p {
    margin-left: 16px;
    line-height: 16px;
    color: #333333;
    font-family: Helvetica Neue, Regular;
    font: -webkit-control;
    font-size: 14px;
  }
`;
