import React, { FC, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ReactPaginate from 'react-paginate';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Container } from './styles';
import './style.css';
import { LoadFunctionProps, LoadAllStoresProps } from '../../Pages/List';

interface PaginateProps {
  loadFunction: (data: LoadAllStoresProps) => void;
  total: number;
  currentPag: number;
  append?: boolean;
  local?: boolean;
  all?: boolean;
  comparativeList?: any[];
  pagAppend: any;
  filter?: 'all' | 'local' | 'normal';
}

const Paginate: FC<PaginateProps> = ({
  loadFunction,
  total,
  currentPag,
  append,
  local,
  all,
  comparativeList,
  pagAppend,
  filter,
}) => {
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  // const handleChanged = useCallback(
  //   (value: any) => {
  //     const { selected } = value;

  //     if (loadFunction) {
  //       loadFunction({
  //         append: !!append,
  //         pag: selected + 1,
  //         lat: Number(parsed.lat),
  //         lng: Number(parsed.lng),
  //         name: String(parsed.name),
  //         feather: String(parsed.feather),
  //         footer: String(parsed.footer),
  //         chave: String(parsed.chave),
  //         epar: String(parsed.epar),
  //         local,
  //         all,
  //         // newPag: true,
  //         clickedPag: selected + 1,
  //         // list: comparativeList,
  //         // pagAppendLocal: (local || filter === 'all') ? pagAppend : undefined,
  //         // pagAppendLocal: pagAppend,
  //       });
  //     }
  //   },
  //   [loadFunction, append, parsed, local, all, pagAppend]
  // );

  const handleChanged = useCallback(
    (value: any) => {
      const { selected } = value;

      if (loadFunction) {
        loadFunction({
          pag: selected + 1,
          lat: String(parsed.lat),
          lng: String(parsed.lng),
        });
      }
    },
    [loadFunction, parsed]
  );

  return (
    <Container>
      <ReactPaginate
        activeClassName="item active "
        breakClassName="item break-me "
        breakLabel="..."
        containerClassName="pagination"
        disabledClassName="disabled-page"
        marginPagesDisplayed={2}
        nextClassName="item next "
        forcePage={currentPag - 1}
        nextLabel={
          <>
            <div className="arrows">
              <BsChevronRight size={13} />
            </div>
          </>
        }
        onPageChange={(e) => handleChanged(e)}
        pageCount={total}
        pageClassName="item pagination-page "
        pageRangeDisplayed={2}
        previousClassName="item previous"
        previousLabel={
          <>
            <div className="arrows">
              <BsChevronLeft size={13} />
            </div>
          </>
        }
      />
    </Container>
  );
};

export default Paginate;
