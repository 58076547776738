import styled, { css } from 'styled-components';

interface ContainerProps {
  isMobile: boolean;
  sizeFakeBox?: string;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  height: ${(props) => (props.isMobile ? 'auto' : '39px')};
  /* background: yellow; */
  z-index: 999999999;
  display: flex;
  align-items: center;
  background-color: green;

  a {
    color: #666666;
    border: 0px;
  }

  &:hover {
    z-index: 100;
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }

  .popover__content {
    width: ${({ sizeFakeBox }) => sizeFakeBox || '100px'};
    height: 81px;
    position: absolute;

    top: 0px;
    margin-left: -190px;
    z-index: 9999999;
  }

  .popover__content:hover {
    opacity: 1;
    visibility: visible;

    display: block;
    /* background: green; */
  }

  .active {
    z-index: 9999999999;
  }
`;

export const FakeArea = styled.div`
  width: 200px;
  height: 80px;
  position: absolute;
  top: 0;
  left: 0px;
  background: blue;
  z-index: 9;

  &:hover {
    .container-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }
`;

interface PropsContent {
  mTop: string;
  mLeft: string;
  actions?: boolean;
}

export const Content = styled.span<PropsContent>`
  width: 259px;
  /* background: #ffffff;
  padding: 20px 21px; */

  font-size: 14px;
  font-weight: bold;
  /* opacity: 0;
  visibility: hidden; */
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s;

  box-shadow: 0px 0px 5px #0000001a;

  position: absolute;

  transform: translateX(-50%);
  color: #666666;
  font-family: Helvetica Neue;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.actions ? 'flex-start' : 'flex-start')};
  /*
  &::before {
    content: '';
    border-style: solid;
    border-color: #ffffff;
    border-width: 11px 11px 0 11px;
    bottom: 100%;
    position: absolute;
    left: ${(props) => props.mLeft};
    transform: translateX(-50%);
  } */

  span {
    font-size: 14px !important;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export const Box = styled.div`
  background: #fff;
  width: 259px;

  padding: 20px 21px;
`;

interface TooltipProps {
  opacity?: boolean;
  mLeft: string;
  isMobile?: boolean;
}
export const TooltipWhatsapp = styled.span<TooltipProps>`
  background: white;
  padding: 27px 31px;
  opacity: ${({ opacity }) => (opacity ? 1 : 0)};

  /* opacity: 1; */

  transition: 0.2s;

  position: absolute;
  z-index: 9999999999999;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 2px 8px #00000027;

  width: 351px;
  height: 175px;

  /* top: calc(100vh - 61%);
  left: calc(100vw - 55%); */
  top: ${({ isMobile }) => (isMobile ? '74px' : '54px')};
  left: ${({ isMobile }) => (isMobile ? '-1px' : '-80px')};

  display: ${({ opacity }) => (opacity ? 'flex' : 'none')};
  /* display: flex; */
  flex-direction: column;

  text-align: center;
  align-items: center;

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100% !important;
    `}

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: ${({ mLeft }) => mLeft};

    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid white;
    margin-top: -38px;

    position: absolute;

    ${({ isMobile }) =>
      isMobile &&
      css`
        @media only screen and (min-width: 413px) {
          margin-left: calc(100vw - 85%) !important;
        }
        /* margin-left: calc(100vw - 85%) px !important; */

        @media only screen and (max-width: 412px) {
          left: calc(223px + 2px);
        }

        @media only screen and (max-width: 320px) {
          left: calc(223px + 5px);
        }

        @media only screen and (max-width: 375px) {
          left: calc(223px + 2px);
        }

        @media only screen and (max-width: 360px) {
          left: calc(223px + 5px);
        }
      `}
  }

  h1 {
    margin: 0;
    font-size: 16px;
    color: #333333;
    line-height: 20px;
    font-family: 'Helvetica Neue, Bold';
    /* margin-bottom: 16px; */
  }

  h2 {
    margin: 0;
    font-size: 14px;
    color: #666666;
    font-family: Helvetica Neue, Regular;
    line-height: 16px;
    margin-top: 8px;
  }

  @media only screen and (max-width: 414px) {
    width: 386px;
  }

  @media only screen and (max-width: 375px) {
    width: 351px;
  }

  @media only screen and (max-width: 360px) {
    width: 339px;
    height: 243px;
    left: -3px;

    h1 {
      font-size: 17;
    }

    h2 {
      font-size: 13px;
    }
  }

  @media only screen and (max-width: 320px) {
    width: 301px;
    height: 243px;
    left: -3px;

    h1 {
      font-size: 17;
    }

    span {
      font-size: 13px;
    }
  }

  button {
    background-color: ${({ theme }) => theme.colors.redPrimary};
    font-size: 14px;
    font-family: 'Helvetica Neue, Bold';
    font-weight: bold;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    width: 289px;
    height: 36px;
    margin-top: 24px;
  }
`;
