import React, { FC, useState, useCallback, useEffect } from 'react';
import {
  Container,
  Content,
  Logo,
  Menus,
  MenuItem,
  FooterAddress,
  Separator,
  LogoBox,
} from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import useWindowDimensions from '../../Utils/size';
import AmericanasLogo from '../../Assets/logo_menor.png';
import LogoVermelhoVertical from '../../Assets/LOGO_AMERICANAS_VERTICAL.svg';
import YoutubeIcon from '../../Assets/YOUTUBE_ICON.svg';
import FacebookIcon from '../../Assets/FACEBOOK_ICON.svg';
import InstagramIcon from '../../Assets/INSTAGRAM_ICON.svg';
import TwitterIcon from '../../Assets/TWITTER_ICON.svg';
import queryString from 'query-string';

interface FooterProps {
  handleMenu1?: () => void;
  handleMenu2?: () => void;
  handleMenu3?: () => void;
  containerStyle?: object;
  isMap?: boolean;
}

const Footer: FC<FooterProps> = ({
  handleMenu1,
  handleMenu2,
  handleMenu3,
  containerStyle,
  isMap,
}) => {
  const isMobile = useWindowDimensions();
  const [footer, setFooter] = useState(true);

  const location = useLocation();
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    const { footer } = parsed;

    if (footer === 'false') {
      setFooter(false);
    }
  }, [parsed]);

  return (
    <>
      {footer && (
        <Container isMobile={isMobile} style={containerStyle} isMap={isMap}>
          <Content isMobile={isMobile}>
            <Separator isMobile={isMobile}>
              {!isMobile && (
                <LogoBox>
                  <Logo src={AmericanasLogo} w="auto" h="27px" />
                  <span>Tudo. A toda hora. Em qualquer lugar.</span>
                </LogoBox>
              )}

              <Menus isMobile={isMobile}>
                <div className="menus-textos">
                  <div>
                    <div onClick={handleMenu1} className="link-help">
                      <MenuItem isMobile={isMobile}>
                        <p>atendimento lojas</p>
                      </MenuItem>
                    </div>

                    <div onClick={handleMenu2} className="link-help">
                      <MenuItem isMobile={isMobile}>
                        <p>dúvidas frequentes</p>
                      </MenuItem>
                    </div>

                    <div onClick={handleMenu3} className="link-help">
                      <MenuItem isMobile={isMobile}>
                        <p>política de trocas</p>
                      </MenuItem>
                    </div>
                  </div>

                  <div className="no-help">
                    <a
                      href="https://carreiras.b2w.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MenuItem isMobile={isMobile}>
                        <p>trabalhe na americanas</p>
                      </MenuItem>
                    </a>
                    <a
                      href="https://ri.americanas.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MenuItem isMobile={isMobile}>
                        <p>americanas s.a.</p>
                      </MenuItem>
                    </a>
                  </div>
                </div>

                <div>
                  {!isMobile && <MenuItem isMobile={isMobile}>|</MenuItem>}

                  <MenuItem isMobile={isMobile}>
                    <a
                      href="https://www.youtube.com/user/CanalAmericanas"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Logo
                        src={YoutubeIcon}
                        w={isMobile ? '16px' : '22px'}
                        h={isMobile ? '11px' : '15px'}
                      />
                    </a>
                  </MenuItem>
                  <MenuItem isMobile={isMobile}>
                    <a
                      href="https://www.facebook.com/lojasamericanas"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Logo
                        src={FacebookIcon}
                        w={isMobile ? '16px' : '8px'}
                        h={isMobile ? '12px' : '17px'}
                      />
                    </a>
                  </MenuItem>
                  <MenuItem isMobile={isMobile}>
                    <a
                      href="https://www.instagram.com/lojasamericanas/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Logo
                        src={InstagramIcon}
                        w={isMobile ? '12px' : '16px'}
                        h={isMobile ? '12px' : '16px'}
                      />
                    </a>
                  </MenuItem>
                  <MenuItem isMobile={isMobile}>
                    <a
                      href="https://twitter.com/americanas"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Logo
                        src={TwitterIcon}
                        w={isMobile ? '14px' : '18px'}
                        h={isMobile ? '11px' : '14px'}
                      />
                    </a>
                  </MenuItem>
                </div>
              </Menus>

              {isMobile && (
                <LogoBox isMobile>
                  <Logo src={AmericanasLogo} w="auto" h="21px" />
                  <span>Tudo. A toda hora. Em qualquer lugar.</span>
                </LogoBox>
              )}
            </Separator>
            <FooterAddress isMobile={isMobile}>
              americanas s.a. / CNPJ: 00.776.574/0006-60 / Inscrição Estadual:
              85.687.08-5 / Endereço Rua Sacadura Cabral, 102 - Rio de Janeiro,
              RS - 20081-902 /{' '}
              <a
                href="https://www.americanas.com.br/hotsite/atendimento"
                target="_blank"
                rel="noreferrer"
              >
                fale com a gente
              </a>
            </FooterAddress>
          </Content>
        </Container>
      )}
    </>
  );
};

export default Footer;
