import { LojasWppWithNumber } from '../Services/interfaces/lojas_wpp';
import path from 'path';
import { listWppElement, WppProps } from '../Utils/ListWpp';
import HandleAnalyticsEvents from './HandleAnalyticsEvents';
import { HandleEvents } from './AnalyticsGA';
import useWindowDimensions from './size';
import { useCookies } from 'react-cookie';

interface Props {
  item?: any;
  type: string;
  wppFromWeb: WppProps;
  isMobile: boolean;
  cookies?: any;
  eventAction?: string;
}
export default async ({
  item,
  type,
  wppFromWeb,
  isMobile,
  cookies,
  eventAction,
}: Props): Promise<void> => {
  const link = item?.canShipFromStore
    ? `https://www.americanas.com.br/lojas-proximas/33014556000196/${item?.sellerStoreId}/ship?chave=o2o_lp_00_0_0_nossaslojas`
    : `https://www.americanas.com.br/lojas-proximas/33014556000196/${item?.sellerStoreId}/pick-up?chave=o2o_lp_00_0_0_nossaslojas`;

  const findWppNumber = await listWppElement({
    sellerStoreId: item?.sellerStoreId,
    wppFromWeb,
  });
  let numberWpp;

  if (findWppNumber) {
    let { Link } = findWppNumber;

    const remove_after = Link.indexOf('&text');
    Link = Link.substring(0, remove_after);
    numberWpp = `${Link}&text=Ol%C3%A1%2C%20quero%20fazer%20um%20pedido`;
  }

  const wppLink = findWppNumber
    ? findWppNumber.Link
    : `https://api.whatsapp.com/send?phone=552140420321&text=Ol%C3%A1%2C%20quero%20fazer%20um%20pedido`;

  HandleEvents({
    isMobile,
    eventAction: eventAction || 'storecard',
    eventCategory: 'nossaslojas',
    eventLabel: type,
    eventValue: undefined,
    store: item || undefined,
    cookies,
  });

  // HandleAnalyticsEvents({
  //   customPayload: {
  //     detail: {
  //       store: {
  //         name: item?.name,
  //         id: item?.sellerStoreId,
  //       },
  //       interaction: type,
  //     },
  //   },
  // });

  if (type !== 'share') {
    const win = window.open(type === 'products' ? link : wppLink, '_blank');
    win?.focus();
  }

  // window.location.replace(type === 'products' ? link : wppLink);
};
