import React, {
  FC,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { ReactComponent as Aamericanas } from '../../Assets/a-americanas.svg';
import { ReactComponent as LocalIcon } from '../../Assets/local_branco.svg';
import { ReactComponent as MapIcon } from '../../Assets/map.svg';
import ListLocal, { getApiListLocal, LocalProps } from '../../Utils/Local';
import useWindowDimensions from '../../Utils/size';
import handleClickCardStore from '../../Utils/ClickCardStore';
import RouteIcon from '../../Assets/route_icon.png';
import { ReactComponent as Tag } from '../../Assets/tag_red.svg';
import GoogleMaps from '../GoogleMaps';
import {
  Container,
  HeaderMap,
  FakeBg,
  LogoStore,
  Header,
  Content,
  LeftInfo,
  MiddleInfo,
  RightInfo,
  StoreButton,
  RightActions,
} from './styles';
import CardStoreV4Details from '../CardStoreV4Details';
import { WppProps } from '../../Utils/ListWpp';
import { useCookies } from 'react-cookie';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { WhatsSolidButton } from '../CardStoreV4Details/styles';
import { FiChevronLeft } from 'react-icons/fi';
import { useTheme } from 'styled-components';
import Config from '../../Utils/Config';
import { DaysProps } from '../../Utils/HourFunction';
import TableHoursV2 from '../TableHoursV2';
import TableHoursV4 from '../TableHoursV4';
import { HoursStore } from '../HoursStore';

interface Props {
  store: any;
  listWpp: WppProps;
  currentLegions: any[];
  hasDistance: number;
  hours: DaysProps;
  encarteFunction: (data?: any) => any;
}

const CardDetailV4: FC<Props> = ({
  store,
  listWpp,
  currentLegions,
  hasDistance,
  hours,
  encarteFunction,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const [localStore, setLocalStore] = useState(false);
  const [listLocal, setListLocal] = useState<LocalProps>({} as LocalProps);
  const location = useLocation();
  const isMobile = useWindowDimensions();
  const mapRef = useRef<any>();
  const parsed = queryString.parse(location.search);
  const history = useHistory();
  const { colors } = useTheme();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListLocal()
        .then((e) => setListLocal(e))
        .catch((e) => console.log('erro ao carregar listaLocal: ', e));
    }
  }, []);

  useEffect(() => {
    ListLocal({ sellerStoreId: store.sellerStoreId, wppFromWeb: listLocal })
      .then((e: boolean) => setLocalStore(e))
      .catch((e: any) => console.log('erro2: ', e));
  }, [listLocal, store]);

  // useEffect(() => {
  //   ListWpp({ sellerStoreId: store.sellerStoreId, wppFromWeb: listWpp })
  //     .then((e: boolean) => setWppStore(e))
  //     .catch((e: any) => console.log('erro2: ', e));
  // }, [listWpp, store]);

  const handleBuy = useCallback(
    (id: string, item: any) => {
      const { lat, lng, name, pag, feather, footer, chave, epar } = parsed;
      const { name: nameStore } = item;

      const uri = `https://www.americanas.com.br/lojas-proximas/33014556000196/${id}?chave=o2o_comprar_00_0_0_nossaslojas`;

      // HandleEvents({
      //   isMobile,
      //   eventAction: 'storecard',
      //   eventCategory: 'nossaslojas',
      //   eventLabel: 'ver mais',
      //   eventValue: 'lista',
      //   store: item,
      //   cookies,
      // });

      const win = window.open(uri, '_blank');
      win?.focus();
    },
    [parsed, isMobile, cookies]
  );

  const handleBack = useCallback(() => {
    const {
      lat,
      lng,
      name,
      pag,
      feather,
      footer,
      chave,
      epar,
      path,
      local,
      withoutLocal,
    } = parsed;
    // HandleAnalyticsEvents({ store,  console.log('local: ', local);
    const normalizeLat = typeof lat === 'string' ? lat : String(lat?.[0]);
    const normalizeLng = typeof lng === 'string' ? lng : String(lng?.[0]);

    // const uri = lat
    //   ? `?lat=${normalizeLat}&lng=${normalizeLng}&name=${name}&pag=${pag}&feather=${feather}&footer=${footer}&chave=${Config.key}&local=${local}&withoutLocal=${withoutLocal}`
    //   : `?lat=${store.geolocation.lat}&lng=${store.geolocation.lng}&name=${
    //       store.name
    //     }&pag=${1}&feather=${feather}&footer=${footer}&epar=${epar}&local=${local}&withoutLocal=${withoutLocal}`;
    let uri = lat
      ? `?lat=${typeof lat === 'object' ? lat[0] : lat}&lng=${
          lng && typeof lng === 'object' ? lng[0] : lng
        }&name=${
          name && typeof name === 'object' ? name[0] : name
        }&pag=${pag}&feather=${feather}&footer=${footer}&chave=${Config.key}`
      : `?lat=${store.geolocation.lat}&lng=${store.geolocation.lng}&name=${
          store.name
        }&pag=${1}&feather=${feather}&footer=${footer}&epar=${epar}`;

    // &local=${local}&withoutLocal=${withoutLocal

    uri = uri + `&from=${parsed.from || 'lista'}`;
    console.log('uri: ', uri);
    console.log('path: ', path);

    const firstChar = path?.slice(0, 1);

    console.log('firstChar: ', firstChar);

    let normalizePath = path;

    if (firstChar === '/') {
      normalizePath = String(path)?.substring(1);
    } else {
      // uri = uri + `&local=${local}&withoutLocal=${withoutLocal}`;
    }

    console.log('normalizePath: ', String(normalizePath).toLowerCase());

    history.push({
      pathname: `/${String(normalizePath).toLowerCase()}`,
      search: uri,
    });
  }, [history, parsed, store]);

  const handleGeoLocation = useCallback(
    (type: 'maps' | 'browser' | 'waze' | 'mapsBrowser') => {
      // setShowNavigationOptions(false);
      console.log('type 2: ', type);
      if (type === 'mapsBrowser') {
        const showPosition = (position: any) => {
          console.log('position: ', position);
          console.log(
            'Latitude: ' +
              position.coords.latitude +
              '<br>Longitude: ' +
              position.coords.longitudes
          );

          const win = window.open(
            `https://maps.google.com/?daddr=${store.geolocation.lat},${store.geolocation.lng}&saddr=${position.coords.latitude},${position.coords.longitude}&directionsmode=driving`,
            '_blank'
          );
          win?.focus();
        };
        if (navigator.geolocation) {
          console.log('position entrou');
          navigator.geolocation.getCurrentPosition(showPosition);
        } else {
          console.log('Geolocation is not supported by this browser.');
        }
      }

      // if (type === 'browser') {
      //   const showPosition = (position: any) => {
      //     console.log('position: ', position);
      //     console.log(
      //       'Latitude: ' +
      //         position.coords.latitude +
      //         '<br>Longitude: ' +
      //         position.coords.longitude
      //     );

      //     const storePosition = coordsCenter;

      //     const request = {
      //       origin: { lat: coordsCenter.lat, lng: coordsCenter.lng },
      //       destination: {
      //         lat: position.coords.latitude,
      //         lng: position.coords.longitude,
      //       },
      //       // waypoints: markerLocations,
      //       // optimizeWaypoints: true,
      //       travelMode: 'BICYCLING',
      //     };

      //     MapRef?.current?.directionsService.route(
      //       request,
      //       (result: any, status: any) => {
      //         if (status === 'OK') {
      //           // Session.set('directionsService', result);
      //           MapRef?.current?.directionsDisplay.setDirections(result);

      //           // setTimeout(() => {
      //           //   MapRef?.current?.setOptions({ preserveViewport: true });
      //           // }, 1000)
      //         }
      //       }
      //     );
      //   };
      //   if (navigator.geolocation) {
      //     console.log('position entrou');
      //     navigator.geolocation.getCurrentPosition(showPosition);
      //   } else {
      //     console.log('Geolocation is not supported by this browser.');
      //   }
      // }

      // if (type === 'waze') {
      //   const win = window.open(
      //     `https://waze.com/?ll=${coordsCenter.lat},${coordsCenter.lng}&navigate=yes`,
      //     '_blank'
      //   );
      //   // const win = window.open(
      //   //   `waze://?ll=${coordsCenter.lat},${coordsCenter.lng}&navigate=yes`,
      //   //   '_blank'
      //   // );
      //   win?.focus();
      // }

      if (type === 'maps') {
        const win = window.open(
          `https://maps.google.com/?daddr=${store.geolocation.lat},${store.geolocation.lng}&directionsmode=driving`,
          '_blank'
        );
        win?.focus();
      }
    },
    [store]
  );

  const makeFolderButton = useMemo(() => {
    return (
      <StoreButton
        isMobile={isMobile}
        onClick={encarteFunction}
        style={{ marginRight: 12 }}
      >
        {/* <img src={RouteIcon} /> */}
        <Tag />
        <span style={{ marginTop: 1 }}>folheto de ofertas</span>
      </StoreButton>
    );
  }, [isMobile, encarteFunction]);

  const makeRouteButton = useMemo(() => {
    return (
      <StoreButton
        isMobile={isMobile}
        onClick={() => {
          // setShowNavigationOptions(true);
          handleGeoLocation('mapsBrowser');
          HandleEvents({
            isMobile,
            eventAction: 'storepage',
            eventCategory: 'nossaslojas',
            eventLabel: 'rota',
            eventValue: undefined,
            cookies,
          });
        }}

        // isMapView={activeButton === 'mapa'}
      >
        <img src={RouteIcon} />
        {/* <FiSend
          size={20}
          color={colors.redPrimary}
          style={{ marginTop: 1 }}
        /> */}
        <span style={{ marginTop: 1 }}>traçar rota</span>
      </StoreButton>
    );
  }, [isMobile, handleGeoLocation, cookies]);

  const handleMapView = useCallback(() => {
    console.log('store: ', store);
    const { lat, lng } = parsed;

    if (!lat || !lng) return;
    const uri = `lat=${typeof lat === 'object' ? lat[0] : lat}&lng=${
      lng && typeof lng === 'object' ? lng[0] : lng
    }&store=${store?.sellerStoreId}&pag=1&byPin=${true}`;

    history.push({
      pathname: `/${parsed?.path}`,
      search: uri,
    });
  }, [parsed, history, store]);

  const makeMapButton = useMemo(() => {
    return parsed.from === 'lista' ? (
      <StoreButton
        isMobile={isMobile}
        style={{ marginRight: 12 }}
        onClick={() => {
          // setShowNavigationOptions(true);
          // handleGeoLocation('maps');
          handleMapView();
          //   HandleEvents({
          //     isMobile,
          //     eventAction: 'storepage',
          //     eventCategory: 'nossaslojas',
          //     eventLabel: 'rota',
          //     eventValue: undefined,
          //     cookies,
          //   });
          // }}
        }}
      >
        <MapIcon />
        <span style={{ marginTop: 1 }}>ver mapa</span>
      </StoreButton>
    ) : (
      <div></div>
    );
  }, [isMobile, handleGeoLocation, cookies, parsed, handleMapView]);

  const makeBackButton = useMemo(() => {
    return (
      <div style={{ width: 428 }}>
        <StoreButton
          width={parsed.from === 'mapa' ? '161px' : '150px'}
          isMobile={isMobile}
          onClick={() => {
            // setShowNavigationOptions(true);
            handleBack();

            HandleEvents({
              isMobile,
              eventAction: 'storepage',
              eventCategory: 'nossaslojas',
              eventLabel: parsed.from
                ? parsed.from === 'lista'
                  ? 'voltar pra lista'
                  : 'voltar pro mapa'
                : 'voltar pra lista',
              eventValue: undefined,
              cookies,
            });
          }}
          // isMapView={activeButton === 'mapa'}
        >
          <FiChevronLeft
            size={20}
            color={colors.redPrimary}
            style={{ marginTop: 1 }}
          />
          <span style={{ marginTop: 1 }}>
            {parsed.from
              ? parsed.from === 'lista'
                ? 'voltar pra lista'
                : 'voltar pro mapa'
              : 'voltar pra lista'}
          </span>
        </StoreButton>
      </div>
    );
  }, [isMobile, colors, handleBack, parsed.from, cookies]);

  return (
    <Container>
      <Header>
        {makeBackButton}
        <LogoStore isMobile={isMobile} local={localStore}>
          {localStore ? (
            <LocalIcon width={isMobile ? 120 : 150} />
          ) : (
            <Aamericanas width={isMobile ? 20 : 37} />
          )}
        </LogoStore>
        <RightActions>
          {makeFolderButton}
          {makeMapButton}
          {makeRouteButton}
        </RightActions>
      </Header>
      <HeaderMap>
        <GoogleMaps
          detail
          width="100%"
          height="438px"
          list={[
            {
              geolocation: {
                latitude: 0,
                longitude: 0,
              },
              sellerStoreId: '',
            },
          ]}
          coordsCenter={{
            lat: store.geolocation ? store.geolocation.lat : 0,
            lng: store.geolocation ? store.geolocation.lng : 0,
          }}
          zoom={15}
          handleDrag={() => null}
          handleChangeMap={() => null}
          handlePinClick={() => null}
          handleZoom={() => null}
          ref={mapRef}
          styleContainer={{
            borderBottom: '1px solid #f0f0f0',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 100,
            borderRadius: 16,
          }}
        />
        <FakeBg withTags={false} />
      </HeaderMap>

      <Content>
        <LeftInfo>
          <CardStoreV4Details
            currentLegions={currentLegions}
            variant="NEW"
            isMap="lista"
            containerStyle={{}}
            title={store.name}
            address={store?.address}
            distance={hasDistance}
            isPar={false}
            index={0}
            schedules={store.schedules}
            sellerStoreId={store.sellerStoreId}
            canPickUpInStore={store.canPickUpInStore}
            canShipFromStore={store.canShipFromStore}
            selected={false}
            onClickCard={() => null}
            onClickProducts={() => null}
            onClickWpp={() =>
              handleClickCardStore({
                item: store,
                type: 'whatsapp',
                wppFromWeb: listWpp,
                isMobile,
                cookies,
              })
            }
            item={store}
            onClickBuy={() => handleBuy(store.sellerStoreId, store)}
          />
        </LeftInfo>
        <MiddleInfo>
          <TableHoursV4 store={store} hours={hours} />
        </MiddleInfo>
        <RightInfo>
          <HoursStore store={store} hours={hours} version="V4" />
        </RightInfo>
      </Content>
    </Container>
  );
};

export { CardDetailV4 };
