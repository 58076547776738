/* eslint-disable no-constant-condition */
import React, { FC, useEffect, useCallback, useState, useRef } from 'react';
import Header from '../../Components/Header';
import { useHistory, useLocation } from 'react-router-dom';
import ModalHelp from '../../Components/ModalHelp';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import { ReactComponent as LocalIcon } from '../../Assets/local_branco.svg';
import { ReactComponent as Aamericanas } from '../../Assets/a-americanas.svg';
import LogoVermelho from '../../Assets/logo_menor.png';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import LoadingIcon from '../../Assets/loading.svg';
import {
  Container,
  Title,
  Data,
  Card,
  TitleOffer,
  AddressOffer,
  Alignment,
  Info,
  Timestamp,
  Total,
  Barrinha,
  HeaderCard,
  Products,
  Item,
  Description,
  ProductImg,
  AlignmentDescription,
  BarrinhaBottom,
  FooterCard,
  HeadBox,
  Badge,
  Price,
  AlignmentHeader,
  ContentHeader,
  PhotoStore,
  InfoActions,
  MoreButton,
  TitlePage,
} from './styles';
import OrderPlaceholder from '../../Components/OrderPlaceholder';
import ModalNFE from '../../Components/ModalNFE';
import {
  AlignmentNfe,
  BoxNfe,
  CloseButton,
  ActionsNfe,
  LoaderBox,
  Loader,
} from '../Order/styles';
import { RiCloseLine } from 'react-icons/ri';
import { Nota, getApiListNote, handleNfeLink } from '../../Utils/NotaFiscal';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import useWindowDimensions from '../../Utils/size';
import CupomFiscal, {
  ImperativeCupomFiscalProps,
} from '../../Components/CupomFiscal';
import { Breadcrumb } from '../../Components/Breadcrumb';
import { ModalDiferencas } from '../../Components/ModalDiferencas';
import { exportComponentAsPNG } from 'react-component-export-image';
import {
  WrapperCupom,
  WrapperLoadingCupom,
  Logo,
} from '../Order/OrderWeb/styles';

const Home: FC = () => {
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [viewDiferencas, setViewDiferencas] = useState(false);
  const [viewCupomFiscal, setViewCupomFiscal] = useState(false);
  const [listNote, setListNote] = useState<Nota[]>([]);
  const [nfe, setNfe] = useState('');
  const [visibleModalNFE, setVisibleModalNFE] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [defaultTab, setDefaultTab] = useState(0);
  const [data, setData] = useState<any>();

  const [cookies, setCookie, removeCookie] = useCookies<any>([
    'customer.id',
    'customer.api.token',
  ]);

  const isMobile = useWindowDimensions();
  const ref = useRef<any>(null);
  const CupomRef = useRef<ImperativeCupomFiscalProps>(null);
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const handleCloseModalHelp = useCallback(() => {
    setVisibleModal(false);

    let current = location.search;

    const remove_after = current.indexOf('&help');
    current = current.substring(0, remove_after);

    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      location.search;

    window.history.pushState({ path: newurl }, '', newurl);
  }, [location.search]);

  const handleEventsGA = useCallback(
    (type: 'atendimento' | 'duvidasfrequentes' | 'politicadetrocas') => {
      const makeType = {
        atendimento: 'atendimento',
        duvidasfrequentes: 'faq',
        politicadetrocas: 'trocas',
      };

      HandleEvents({
        isMobile,
        eventAction: 'footer',
        eventCategory: 'nossaslojas',
        eventLabel: makeType[type],
        eventValue: undefined,
        cookies,
      });
    },
    [isMobile, cookies]
  );

  const handleChangeTab = useCallback(
    (index: number) => {
      setDefaultTab(Number(index));
      setVisibleModal(true);

      let current = location.search;

      if (parsed.help) {
        const remove_after = current.indexOf('&help');
        current = current.substring(0, remove_after);
      }

      const values: any = [
        'atendimento',
        'duvidasfrequentes',
        'politicadetrocas',
      ];

      handleEventsGA(values[index]);

      history.push({
        pathname: '/',
        search: `${current}&help=${values[index]}`,
      });
    },
    [history, parsed, location.search, handleEventsGA]
  );

  const handleViewNfe = useCallback(() => {
    HandleEvents({
      isMobile,
      eventAction: 'minhas compras',
      eventCategory: 'nossaslojas',
      eventLabel: 'ver nota fiscal',
      eventValue: undefined,
      cookies,
    });
    console.log('data: ', data);
    console.log('chave : ', nfe);

    handleNfeLink({ uf: data.uf, chaveNfe: data.chaveNfe, list: listNote });
  }, [nfe, listNote, data, isMobile, cookies]);

  const handleNfe = useCallback((item: any) => {
    setNfe(item.chaveNfe);
    setVisibleModalNFE(true);
  }, []);

  const loadData = useCallback(async () => {
    const dados = localStorage.getItem('@b2w:order-offline');

    if (dados) {
      const parsed = JSON.parse(dados);

      setData(parsed);
    } else {
      history.push('/pedidos');
    }
  }, [history]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (parsed.help) {
      const values: any = {
        atendimento: 0,
        duvidasfrequentes: 1,
        politicadetrocas: 2,
      };
      setDefaultTab(Number(values[String(parsed.help)]));
      setVisibleModal(true);
    }
  }, [parsed.help]);

  const handleBack = useCallback(() => {
    localStorage.removeItem('@b2w:order-offline');
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListNote()
        .then((e) => setListNote(e))
        .catch((e) => console.log('erro ao caregar listNote mobile: ', e));
    }
  }, []);

  const handleShare = useCallback(
    async (type: 'salvar' | 'compartilhar') => {
      // history.push('/pedidos/cupom-fiscal');
      setLoadingPrint(true);

      HandleEvents({
        isMobile,
        eventAction: 'minhas compras',
        eventCategory: 'nossaslojas',
        eventLabel: type === 'salvar' ? 'baixar cupom' : 'compartilhar cupom',
        eventValue: undefined,
        cookies,
      });

      HandleEvents({
        isMobile,
        eventAction: 'minhas compras',
        eventCategory: 'nossaslojas',
        eventLabel: 'ver cupom fiscal',
        eventValue: undefined,
        cookies,
      });

      setTimeout(() => {
        exportComponentAsPNG(ref, { fileName: 'cupom-fiscal' }).then(() => {
          setTimeout(() => {
            setLoadingPrint(false);
          }, 2000);
        });
      }, 2500);
    },
    [isMobile, cookies]
  );

  return (
    <>
      {viewDiferencas && (
        <ModalDiferencas onClose={() => setViewDiferencas(false)} />
      )}

      {loadingPrint && (
        <div>
          <WrapperCupom
            style={{
              width: CupomRef.current?.getBoxSize().width,
              height: CupomRef.current?.getBoxSize().height,
              // marginTop: 50,
              backgroundColor: '#f3f3f3',
            }}
            ref={ref}
          >
            <CupomFiscal
              onClose={() => null}
              ref={CupomRef}
              forPrint
              order={data}
            />
          </WrapperCupom>
          <WrapperLoadingCupom>
            <LoaderBox>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Logo src={LogoVermelho} />
                <Loader src={LoadingIcon} />
              </div>
            </LoaderBox>
          </WrapperLoadingCupom>
        </div>
      )}

      {/* <div
        style={{
          width: CupomRef.current?.getBoxSize().width,
          height: CupomRef.current?.getBoxSize().height,
          backgroundColor: '#f3f3f3',
        }}
        ref={ref}
      >
        <CupomFiscal
          onClose={() => {}}
          ref={CupomRef}
          forPrint
          printFunction={() =>
            exportComponentAsPNG(ref, { fileName: 'cupom-fiscal' })
          }
        />
      </div> */}

      {/* <button
        ref={ref}
        onClick={() => exportComponentAsPNG(ref, { fileName: 'cupom-fiscal' })}
      >
        SHAREEEEE
      </button>

      <div
        ref={ref}
        style={{ width: 200, height: 200, backgroundColor: 'blue' }}
      >
        <span>aaa</span>
      </div> */}

      {viewCupomFiscal && (
        <CupomFiscal
          onClose={() => setViewCupomFiscal(false)}
          printFunction={() => handleShare('salvar')}
          order={data}
        />
      )}

      <ModalHelp
        visible={visibleModal}
        closeFunction={handleCloseModalHelp}
        defaultTab={defaultTab}
        onSelect={handleChangeTab}
      />

      {visibleModalNFE && (
        <>
          <ModalNFE
            visible={visibleModalNFE}
            onClick={() => setVisibleModalNFE(false)}
          />
          <AlignmentNfe>
            <BoxNfe>
              <CloseButton>
                <RiCloseLine
                  size={25}
                  color="#707070"
                  className="icone"
                  onClick={() => setVisibleModalNFE(false)}
                />
              </CloseButton>
              <h1>
                Acesse e confira através do site<br></br>da nota fiscal
                eletrônica.
              </h1>

              <ActionsNfe>
                <span onClick={() => setVisibleModalNFE(false)}>
                  depois vejo
                </span>
                <MoreButton onClick={handleViewNfe}>
                  <span>ver nota fiscal</span>
                </MoreButton>
              </ActionsNfe>
            </BoxNfe>
          </AlignmentNfe>
        </>
      )}

      <Header />

      <Breadcrumb
        containerStyle={{ marginBottom: 17, paddingLeft: 12 }}
        items={[
          { label: 'minhas compras na loja', link: '/pedidos' },
          { label: 'detalhes da compra', link: '/pedidos/detalhes' },
        ]}
      />

      <TitlePage>detalhes da compra</TitlePage>

      {data && (
        <Container>
          <HeadBox>
            <HeaderCard>
              <ContentHeader>
                <PhotoStore isMobile={isMobile} local={false}>
                  {false ? (
                    <LocalIcon width={65} height={65} />
                  ) : (
                    <Aamericanas width={20} height={75} />
                  )}
                </PhotoStore>
                <div style={{ marginLeft: 16 }}>
                  <TitleOffer>
                    {String(data.nomeLoja).toLocaleLowerCase()}
                  </TitleOffer>
                  <AddressOffer className="truncate-overflow">
                    {String(data.logradouro).toLocaleLowerCase()}
                  </AddressOffer>
                </div>
              </ContentHeader>
              <Barrinha style={{ marginTop: 20 }} />

              <Info>
                <Timestamp>{data.data}</Timestamp>
                <div
                  style={{ display: 'flex' }}
                  onClick={() => setViewDiferencas(true)}
                >
                  <Total style={{ marginRight: 5, textDecoration: 'none' }}>
                    entenda as diferenças
                  </Total>
                  <AiOutlineInfoCircle size={16} color="#666666" />
                </div>
                {/* <div>
                  <Total>total de produtos: {data.itens.length}</Total>
                </div> */}
              </Info>
              <InfoActions>
                <MoreButton onClick={() => setViewCupomFiscal(true)}>
                  <span>ver cupom fiscal</span>
                </MoreButton>

                <MoreButton onClick={handleViewNfe}>
                  <span>ver nota fiscal</span>
                </MoreButton>
              </InfoActions>
              <Barrinha />
            </HeaderCard>
          </HeadBox>

          <Alignment>
            <Products>
              <Item style={{ marginBottom: 0 }}>
                <ProductImg style={{ backgroundColor: '#fff' }}>
                  <h2>qtd</h2>
                </ProductImg>
              </Item>

              {[...data.itensFinal].map((i: any) => (
                <Item key={Math.random()}>
                  <ProductImg>
                    <span>{i.quantidade}</span>
                  </ProductImg>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                    }}
                  >
                    <AlignmentDescription>
                      <Description className="truncate-overflow">
                        {i.nome}
                      </Description>
                    </AlignmentDescription>
                    <Price>R$ {i.valor}</Price>
                  </div>
                </Item>
              ))}
            </Products>
          </Alignment>
          <FooterCard>
            <Total style={{ textDecoration: 'none' }}>
              total de produtos: {data.itens.length}
            </Total>
            <div>
              <h1> R$ {data.valorTotal}</h1>
            </div>
          </FooterCard>
        </Container>
      )}
    </>
  );
};

export default Home;
