import styled, { css } from 'styled-components';

interface ContainersProps {
  isMobile: boolean;
  home?: boolean;
}

export const Container = styled.div<ContainersProps>`
  position: relative;
  z-index: 10;
  margin: auto;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin-top: 15px;

  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scroll-padding-top: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }

  span {
    cursor: pointer;
    color: #666666;
    font-family: Helvetica Neue;
    font-size: 13px;

    display: inline-block;
  }

  img {
    width: 7px;
    height: 7.39px;
  }

  ${({ home, isMobile }) =>
    home &&
    isMobile &&
    css`
      margin-top: 15px;
      margin-bottom: 78px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 15px 20px;
      margin-top: 0px;

      svg {
        display: block;
        overflow: initial;
      }
    `}
`;
