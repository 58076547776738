import styled, { css } from 'styled-components';

interface ContainerProps {
  isActive?: boolean;
  isError?: string;
  isMobile?: boolean;
}

export const Content = styled.div`
  width: 100%;
`;

export const Error = styled.span`
  height: 20px;
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.redPrimary};
  font-family: Helvetica Neue, Regular;
  font-size: 14px;
  display: block;
`;

export const Container = styled.div<ContainerProps>`
  background: #ffffff;
  align-items: center;
  display: flex;
  width: ${(props) => (props.isMobile ? '100%' : '100%')};
  height: 56px;
  border-radius: ${(props) => (props.isActive ? '5px 5px 0px 0px' : '8px')};
  padding: 13px 20px 13px 15px;

  border: ${({ isError, theme }) =>
    isError ? `solid 1px ${theme.colors.redPrimary}` : null};
  margin-bottom: ${({ isMobile }) => (isMobile ? '0px' : '5px')};

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 13px 17px 13px 15px;
      max-width: 334px !important;

      @media only screen and (max-width: 414px) {
        max-width: 90% !important;
      }

      @media only screen and (max-width: 375px) {
        max-width: 100% !important;
      }
    `};

  @media only screen and (max-width: 361px) {
    width: 100%;
    padding: 13px 13px 13px 15px;
  }

  input {
    border: 0;
    background: transparent;
    height: 39px;
    width: 100%;
    margin-right: 15px;
    font-family: Helvetica Neue, Regular;
    font-size: 14px;
    font-weight: 200;

    &::placeholder {
      font-family: Helvetica Neue;
      font-size: 16px;
      color: #999;
    }

    ${({ isMobile }) =>
      isMobile &&
      css`
        ::-webkit-input-placeholder {
          font-size: 13px;
          line-height: 3;
        }
        margin-right: 12px;

        @media only screen and (max-width: 320px) {
          font-size: 14px !important;
        }
      `}

    @media only screen and (max-width: 361px) {
      margin-right: 0px;
    }
  }
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;

  @media only screen and (max-width: 361px) {
    width: 23px;
    height: 23px;
  }

  @media only screen and (max-width: 320px) {
    width: 18px;
    height: 18px;
  }
`;

interface CardResult {
  isList?: boolean;
}

export const CardResult = styled.div<CardResult>`
  width: 100%;
  position: ${(props) => (props.isList ? 'relative' : 'absolute')};
  z-index: 999999;
  margin-top: ${(props) => (props.isList ? '0' : '0px')};
`;

interface CardItemProps {
  isMobile?: boolean;
  isList?: boolean;
  fromModalChange?: boolean;
}
export const CardItem = styled.div<CardItemProps>`
  width: ${(props) => (!props.isList && props.isMobile ? '100%' : '100%')};
  min-height: 40px;
  background: #ffffff;
  padding: ${(props) =>
    props.isList ? '3px 0px 14px 4px' : '14px 0px 14px 20px'};
  border-top: ${(props) => (props.isList ? '0px' : 'solid 1px')};
  border-bottom: ${(props) => (props.isList ? 'solid 1px' : '0px')};
  border-color: #efefef;
  cursor: pointer;
  position: relative;
  z-index: 9999999999;

  &:hover {
    background: ${({ theme }) => theme.colors.redPrimary};

    span {
      color: white;
    }
  }

  span {
    font-family: Helvetica Neue, Regular;
    font-size: 16px;
    font-weight: 300;
    color: #666666;
  }

  ${({ fromModalChange }) =>
    fromModalChange &&
    css`
      margin: auto;
    `}
`;
