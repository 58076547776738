import React, {
  FC,
  useEffect,
  useCallback,
  useState,
  useMemo,
  useRef,
} from 'react';
import Header from '../../../../../Components/Header';
import Busca from '../../../../../Components/Busca';
import Footer from '../../../../../Components/Footer';
import { Content, Barrinha, Wrapper } from './styles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ModalHelp from '../../../../../Components/ModalHelp';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import api from '../../../../../Services/api';
import LoadingIcon from '../../../../../Assets/loading.svg';
import CardDetailV2 from '../../../../../Components/CardDetailV2';
import useWindowDimensions from '../../../../../Utils/size';
import Tab from '../../../../../Components/Tab';
import handleClickCardStore from '../../../../../Utils/ClickCardStore';
import Config from '../../../../../Utils/Config';
import GetHours from '../../../../../Utils/HourFunction';
import { DaysProps } from '../../../../../Utils/HourFunction';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { getDistance } from 'geolib';
import ListWpp, { WppProps, getApiListWpp } from '../../../../../Utils/ListWpp';
import { CovidProps } from '../../../../../Utils/Covid';
import { FbProps } from '../../../../../Utils/Fb';
import HandleAnalyticsEvents from '../../../../../Utils/HandleAnalyticsEvents';
import { HandlePageView, HandleEvents } from '../../../../../Utils/AnalyticsGA';
import {
  BreadCrumbItemProps,
  Breadcrumb,
} from '../../../../../Components/Breadcrumb';
import { NormalizeBreadCrumb } from '../../../../../Utils/NormalizeBreadCrumb';
import { EncarteWeb } from '../../../../../Components/EncarteWeb';
import { getCatalog, CatalogProps } from '../../../../../Utils/GetCatalog';
import CardStoreV2Details from '../../../../../Components/CardStoreV2Details';
import SobreV4, { ImperativePropsContentV4 } from './ContentV4';
import { ModalEncarte } from '../../../../../Components/ModalEncarte';
import { CardDetailV4 } from '../../../../../Components/CardDetailV4';
import { FadeBox, MoreButton, LoaderBox, Loader } from './ContentV4/styles';

export interface StoreProps {
  name: string;
  address: string;
  open: boolean;
  schedules: any;
  canShipFromStore: boolean;
  canPickUpInStore: boolean;
  sellerStoreId: string;
  geolocation: {
    lat: number;
    lng: number;
  };
}

interface ComponentProps {
  store: StoreProps;
  hours: DaysProps;
  listCovid: CovidProps;
  listFb: FbProps;
  listWpp: WppProps;
  tmpPath?: string;
}

interface Props {
  id: string;
}

const V4: FC<ComponentProps> = ({
  store,
  hours,
  listCovid,
  listFb,
  listWpp,
  tmpPath,
}) => {
  const [currentLegions, setCurrentLegions] = useState([]);
  const [catalog, setCatalog] = useState<CatalogProps | undefined>(
    {} as CatalogProps
  );
  const [encarteWebView, setEncarteWebView] = useState(false);
  const [lastBreadcrumbs, setlastBreadcrumbs] = useState<BreadCrumbItemProps>(
    {} as BreadCrumbItemProps
  );
  const [storeBreadcrumbs, setStoreBreadcrumbs] = useState<BreadCrumbItemProps>(
    {} as BreadCrumbItemProps
  );
  // const [listWpp, setListWpp] = useState<WppProps>({} as WppProps);
  const [wppStore, setWppStore] = useState(false);
  // const [hours, setHours] = useState<DaysProps>({} as DaysProps);
  const [hasDistance, setHasDistance] = useState(0);
  const [cep, setCep] = useState('');
  const [visibleModal, setVisibleModal] = useState(false);
  const [defaultTab, setDefaultTab] = useState(0);
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  // const [store, setStore] = useState<StoreProps>({} as StoreProps);
  const isMobile = useWindowDimensions();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams() as Props;
  const parsed = queryString.parse(location.search);
  const [visibleModalEncarte, setVisibleModalEncarte] = useState(false);

  const V4Ref = useRef<ImperativePropsContentV4>(null);

  const handleCloseModalHelp = useCallback(() => {
    setVisibleModal(false);

    let current = location.search;
    console.log('current remove antes: ', current);

    const remove_after = current.indexOf('&help');
    current = current.substring(0, remove_after);
    console.log('current remove: ', current);

    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      location.search;

    window.history.pushState({ path: newurl }, '', newurl);

    // history.push({
    //   pathname: '/',
    //   search: current,
    // });
  }, [location.search]);

  const handleEventsGA = useCallback(
    (type: 'atendimento' | 'duvidasfrequentes' | 'politicadetrocas') => {
      const makeType = {
        atendimento: 'atendimento',
        duvidasfrequentes: 'faq',
        politicadetrocas: 'trocas',
      };

      HandleEvents({
        isMobile,
        eventAction: 'footer',
        eventCategory: 'nossaslojas',
        eventLabel: makeType[type],
        eventValue: undefined,
        cookies,
      });
    },
    [isMobile, cookies]
  );

  const handleChangeTab = useCallback(
    (index: number) => {
      setDefaultTab(Number(index));
      setVisibleModal(true);

      let current = location.search;

      if (parsed.help) {
        const remove_after = current.indexOf('&help');
        current = current.substring(0, remove_after);
      }

      const values: any = [
        'atendimento',
        'duvidasfrequentes',
        'politicadetrocas',
      ];

      handleEventsGA(values[index]);

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        window.location.search +
        `&help=${values[index]}`;
      window.history.pushState({ path: newurl }, '', newurl);
    },
    [parsed, location.search, handleEventsGA]
  );

  const handleAnalytics = useCallback(() => {
    if (!store.sellerStoreId) return;

    HandlePageView({
      cookies,
      query: parsed,
      name: `ACOM:NossasLojas:loja:${id}`,
      isMobile,
      store,
    });

    HandleAnalyticsEvents({
      eventCustomName: 'page:load',
      customPayload: {
        detail: {
          page: {
            type: 'americanasStores-V2',
            cep: cookies.persistentCep || null,
          },
        },
      },
    });
  }, [cookies, isMobile, store, parsed, id]);

  useEffect(() => {
    handleAnalytics();
  }, [store]);

  useEffect(() => {
    if (parsed.help) {
      const values: any = {
        atendimento: 0,
        duvidasfrequentes: 1,
        politicadetrocas: 2,
      };
      setDefaultTab(Number(values[String(parsed.help)]));
      setVisibleModal(true);
    }
  }, [parsed.help]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    ListWpp({ sellerStoreId: store.sellerStoreId, wppFromWeb: listWpp })
      .then((e: boolean) => setWppStore(e))
      .catch((e: any) => console.log('erro2: ', e));
  }, [listWpp, store]);

  // useEffect(() => {
  //   getApiListWpp()
  //     .then((e) => setListWpp(e))
  //     .catch((e) => console.log('erro ao caregar listaWpp: ', e));
  // }, []);

  useEffect(() => {
    if (parsed.breadcrumb && parsed.breadcrumbLink) {
      setlastBreadcrumbs({
        label:
          NormalizeBreadCrumb({
            name: String(parsed.breadcrumb),
            replace: true,
          }) || '',
        link: String(parsed.breadcrumbLink),
      });
    }
  }, [parsed.breadcrumb, parsed.breadcrumbLink]);

  useEffect(() => {
    setStoreBreadcrumbs({
      label: NormalizeBreadCrumb({ name: String(store.name) }) || '',
      link: `${location.pathname}${location.search}`,
    });
  }, [store.name, location]);

  const onClickFolder = useCallback(async () => {
    HandleEvents({
      isMobile,
      eventAction: 'storepage',
      eventCategory: 'nossaslojas',
      eventLabel: 'encarte',
      eventValue: undefined,
      cookies,
    });
    setEncarteWebView(true);
  }, [cookies, isMobile]);

  useEffect(() => {
    const load = async () => {
      const catalogLink = await getCatalog();
      setCatalog(catalogLink?.catalog ? catalogLink : undefined);
    };

    load();
  }, []);

  const findLegion = useCallback(async () => {
    try {
      const { lat, lng } = parsed;

      if (!lat || !lng) return;

      const { data } = await api.get(
        `${Config.legionsApi}?longitude=${lat[0]}&latitude=${lng[0]}`
      );

      const resp = data.region.result;
      // console.log('legion: ', resp);

      if (resp.length) {
        const legions = resp.map((i: any) => i.legionRegion);
        // console.log('legions: ', legions);
        setCurrentLegions(legions);
      }
    } catch (error) {
      console.log('erro legions: ', error);
    }
  }, [parsed]);

  useEffect(() => {
    findLegion();
  }, []);

  const handleBuy = useCallback(
    (id: string, item: any) => {
      const { lat, lng, name, pag, feather, footer, chave, epar } = parsed;
      const { name: nameStore } = item;

      const uri = `https://www.americanas.com.br/lojas-proximas/33014556000196/${id}?chave=o2o_comprar_00_0_0_nossaslojas`;

      HandleEvents({
        isMobile,
        eventAction: 'storecard',
        eventCategory: 'nossaslojas',
        eventLabel: 'ver mais',
        eventValue: 'lista',
        store: item,
        cookies,
      });

      const win = window.open(uri, '_blank');
      win?.focus();
    },
    [parsed, isMobile, cookies]
  );

  const calcDistance = useCallback(
    async ({ latitude, longitude }) => {
      // flamengo - -22.936822
      // -43.1757021

      // laranjeira -22.9322349
      // -43.1810349
      // cookies.persistentCep
      // 22210-060

      if (parsed.distance) {
        setHasDistance(Number(parsed.distance));
      } else {
        if (cookies.persistentCep) {
          const results = await geocodeByAddress(cookies.persistentCep);
          const { lat: latUser, lng: lngUser } = await getLatLng(results[0]);

          const d = getDistance(
            { latitude: latUser, longitude: lngUser },
            { latitude, longitude }
          );

          setHasDistance(d);
        } else {
          setHasDistance(0);
        }
      }
      // if ('22210-060') {
      //   const results = await geocodeByAddress('22210-060');
      //   const { lat: latUser, lng: lngUser } = await getLatLng(results[0]);

      //   const d = getDistance(
      //     { latitude: latUser, longitude: lngUser },
      //     { latitude, longitude }
      //   );

      //   setHasDistance(d);
      // } else {
      //   setHasDistance(Number(parsed.distance));
      // }
    },
    [parsed.distance, cookies]
  );

  useEffect(() => {
    if (!store || !store.geolocation) return;

    calcDistance({
      latitude: store.geolocation.lat,
      longitude: store.geolocation.lng,
    });
  }, [calcDistance, store]);

  const makeMoreButton = useMemo(() => {
    console.log('current: ', V4Ref);
    return V4Ref?.current?.loading ? (
      <LoaderBox>
        <Loader src={LoadingIcon} />
      </LoaderBox>
    ) : (
      !!V4Ref?.current?.moreData && (
        <MoreButton
          isMobile={isMobile}
          onClick={() => {
            // HandleAnalyticsEvents({
            //   store,
            //   interaction: 'storenews',
            // });
            // HandleEvents({
            //   isMobile,
            //   eventAction: 'storenews',
            //   eventCategory: 'nossaslojas',
            //   eventLabel: undefined,
            //   eventValue: undefined,
            //   store,
            //   cookies,
            // });
            // loadFb(pagingNext, true);
          }}
        >
          <span>carregar mais posts da loja</span>
        </MoreButton>
      )
    );
  }, [V4Ref, isMobile]);

  const encarteFunction = useCallback(() => {
    HandleEvents({
      isMobile,
      eventAction: 'storepage',
      eventCategory: 'nossaslojas',
      eventLabel: 'encarte',
      eventValue: undefined,
      cookies,
    });
    setVisibleModalEncarte(true);
  }, [cookies, isMobile]);

  return (
    <>
      <ModalEncarte
        store={store}
        visible={visibleModalEncarte}
        onClose={() => setVisibleModalEncarte(false)}
      />
      <ModalHelp
        visible={visibleModal}
        closeFunction={handleCloseModalHelp}
        defaultTab={defaultTab}
        onSelect={handleChangeTab}
      />
      {encarteWebView && catalog?.catalog && (
        <EncarteWeb
          catalog={catalog}
          closeFunction={() => setEncarteWebView(false)}
        />
      )}
      <Header />
      <Wrapper isMobile={isMobile}>
        {store && store.name && (
          <Breadcrumb items={[lastBreadcrumbs, storeBreadcrumbs]} />
        )}
      </Wrapper>
      <Content isMobile={isMobile}>
        {isMobile ? (
          <div style={{ paddingRight: 18, paddingLeft: 18, width: '100%' }}>
            <CardStoreV2Details
              currentLegions={currentLegions}
              variant="NEW"
              isMap="lista"
              containerStyle={{}}
              title={store.name}
              address={store?.address}
              distance={hasDistance}
              isPar={false}
              index={0}
              schedules={store.schedules}
              sellerStoreId={store.sellerStoreId}
              canPickUpInStore={store.canPickUpInStore}
              canShipFromStore={store.canShipFromStore}
              selected={false}
              onClickCard={() => null}
              onClickProducts={() => null}
              onClickWpp={() =>
                handleClickCardStore({
                  item: store,
                  type: 'whatsapp',
                  wppFromWeb: listWpp,
                  isMobile,
                  cookies,
                })
              }
              item={store}
              onClickBuy={() => handleBuy(store.sellerStoreId, store)}
            />
          </div>
        ) : (
          <CardDetailV4
            encarteFunction={encarteFunction}
            store={store}
            listWpp={listWpp}
            hasDistance={hasDistance}
            currentLegions={currentLegions}
            hours={hours}
          />
        )}

        <SobreV4
          ref={V4Ref}
          store={store}
          hours={hours}
          listCovid={listCovid}
          listFb={listFb}
          listWpp={listWpp}
          encarteFunction={() => encarteFunction()}
          linkBuy={() => handleBuy(store.sellerStoreId, store)}
        />
      </Content>
      {/* {makeMoreButton} */}
      {/* {!isMobile && <FadeBox />} */}

      <Footer
        containerStyle={{ zIndex: 9999, position: 'relative' }}
        handleMenu1={() => handleChangeTab(0)}
        handleMenu2={() => handleChangeTab(1)}
        handleMenu3={() => handleChangeTab(2)}
      />
    </>
  );
};

export default V4;
