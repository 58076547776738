import styled from 'styled-components';

interface ContainerProps {
  isMobile: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  padding: 0 17px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;

  svg {
    width: ${(props) => (props.isMobile ? '60px' : '130px')};
    height: ${(props) => (props.isMobile ? '74px' : '161px')};
    transform: rotate(0deg) !important;
  }

  span {
    color: #666666;
    font-size: 16px;
    font-family: 'Helvetica Neue';
    text-align: center;
    line-height: 20px;
  }
`;

export const ActionBtn = styled.button`
  background: transparent;
  width: 160px;
  height: 38px;
  border: solid 2px ${({ theme }) => theme.colors.redPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 24px;

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-size: 14px;
    font-family: 'Helvetica Neue';
  }
`;
