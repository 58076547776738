import React, { FC, useMemo, useCallback, useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import {
  Container,
  LogoStore,
  Info,
  Buttons,
  ProductButton,
  WhatsButton,
  Distance,
  BuyButton,
  BoxLayer,
  ActionFakeBox,
  FooterCard,
  Content,
  MiddleContent,
  DudeBox,
  TagsContent,
  Tag,
} from './styles';

import Truncate from '../../Utils/truncate';
import AmericanasLogo from '../../Assets/logo_menor.png';
import { ReactComponent as Clock } from '../../Assets/clock.svg';
import { ReactComponent as WhatsImg } from '../../Assets/WHATS_ICONE.svg';
import { ReactComponent as HouseIcon } from '../../Assets/ICONE-LOJA.svg';
import { ReactComponent as BasketIcon } from '../../Assets/ICONE_CESTA.svg';
import { ReactComponent as LocalIcon } from '../../Assets/local_branco.svg';
import { ReactComponent as LogoBranco } from '../../Assets/logo_branco.svg';
import { ReactComponent as Aamericanas } from '../../Assets/a-americanas.svg';
import { ReactComponent as StoreIcon } from '../../Assets/store.svg';
import { ReactComponent as DudeIcon } from '../../Assets/info-cinza.svg';
import getDay from '../../Utils/getday';
import useWindowDimensions from '../../Utils/size';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import ListWpp, { getApiListWpp, WppProps } from '../../Utils/ListWpp';
import ListCovid, { CovidProps, getApiListCovid } from '../../Utils/Covid';
import ListLocal, { LocalProps, getApiListLocal } from '../../Utils/Local';
import { TestAB } from '../../Utils/PlanOut';
import { useCookies } from 'react-cookie';
import { parseISO, addHours, isAfter, format } from 'date-fns';
import {
  differenceInHours,
  differenceInMinutes,
  addDays,
  isBefore,
} from 'date-fns/esm';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import { useCaroussel } from '../../Hooks/useCaroussel';
import { OwlCarouselProps } from 'react-owl-carousel';
import { ptBR } from 'date-fns/locale';
import api from '../../Services/api';
import Config from '../../Utils/Config';

interface CardProps {
  title: string;
  address: string;
  distance?: number;
  isPar?: boolean;
  index: number;
  schedules?: any;
  canPickUpInStore?: boolean;
  canShipFromStore?: boolean;
  sellerStoreId: number | string;
  carousel?: boolean;
  selected?: boolean;
  onClickProducts?: (item: any) => void;
  onClickWpp?: (item: any) => void;
  onClickBuy?: (item: any) => void;
  item?: any;
  onClickCard?: (item: any) => void;
  containerStyle?: object;
  isMap: 'lista' | 'mapa' | undefined;
  variant: any;
  currentLegions?: any[];
}

const CardStoreV2: FC<CardProps> = ({
  title,
  address,
  distance,
  isPar,
  index,
  schedules,
  canPickUpInStore,
  canShipFromStore,
  sellerStoreId,
  carousel = false,
  selected = false,
  onClickProducts,
  onClickWpp,
  item,
  onClickBuy,
  onClickCard,
  containerStyle,
  isMap,
  variant,
  currentLegions,
}) => {
  const [validatePNLAtag, setValidatePNLAtag] = useState(false);
  const [validateSFStag, setValidateSFStag] = useState(false);
  const [validateSFStagName, setValidateSFStagName] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);

  const [listLocal, setListLocal] = useState<LocalProps>({} as LocalProps);
  const [listWpp, setListWpp] = useState<WppProps>({} as WppProps);
  const [listCovid, setListCovid] = useState<CovidProps>({} as CovidProps);
  const [covidStore, setCovidStore] = useState(false);
  const [wppStore, setWppStore] = useState(false);
  const [localStore, setLocalStore] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const history = useHistory();
  const isMobile = useWindowDimensions();
  const { storeSelected } = useCaroussel();
  // console.log('storeSelected: ', storeSelected);
  const distanceParsed = useMemo(() => {
    let d = '0 m';

    if (distance) {
      d = distance > 1 ? `${distance.toFixed(1)} km ` : `${distance * 1000} m `;
    }

    return d;
  }, [distance]);

  const multiploTres = useMemo(() => {
    const multiplo = (index + 1) % 3 === 0 && index !== 0;
    return multiplo;
  }, [index]);

  const day = useMemo(() => {
    if (!schedules) return null;
    const d = getDay(new Date());

    if (d) {
      const hours = schedules[d];

      if (hours && hours.start && hours.end) {
        const [hourStart, minutesStart] = hours.start.split(':');
        const [hourEnd, minutesEnd] = hours.end.split(':');

        const minutesS = minutesStart === '00' ? '' : `:${minutesStart}`;
        const minutesE = minutesEnd === '00' ? '' : `:${minutesEnd}`;
        return `${hourStart}${minutesS}h às ${hourEnd}${minutesE}h`;
      } else {
        return null;
      }
    }
  }, [schedules]);

  const linkStore = useMemo(() => {
    const link = canShipFromStore
      ? `https://www.americanas.com.br/lojas-proximas/33014556000196/${sellerStoreId}/ship`
      : `https://www.americanas.com.br/lojas-proximas/33014556000196/${sellerStoreId}/pick-up`;

    return link;
  }, [canShipFromStore, sellerStoreId]);

  const handleDetails = useCallback(
    (id: string) => {
      const { lat, lng, name, pag, feather, footer, near } = parsed;

      let uri = `?lat=${lat}&lng=${lng}&name=${name}&pag=${pag}`;

      if (feather !== 'undefined') {
        uri = uri + `&feather=${feather}`;
      }

      if (footer !== 'undefined') {
        uri = uri + `&footer=${footer}`;
      }

      if (near !== 'undefined') {
        uri = uri + `&near=${near}`;
      }

      // history.push({
      //   pathname: `/loja/${id}/detalhes`,
      //   search: uri,
      // });

      history.push({
        pathname: `/loja/${id}`,
        search: uri,
      });
    },
    [history, parsed]
  );

  const handleOpenCard = useCallback(() => {
    console.log('apertou no toggle');
    setCollapsed((prev) => {
      const newToggleValue = !prev;

      if (newToggleValue) {
        HandleEvents({
          isMobile,
          eventAction: 'storecard',
          eventCategory: 'nossaslojas',
          eventLabel: 'expandir card',
          eventValue: undefined,
          cookies,
        });
      }

      return newToggleValue;
    });
  }, [cookies, isMobile]);

  useEffect(() => {
    ListWpp({ sellerStoreId: sellerStoreId, wppFromWeb: listWpp })
      .then((e: boolean) => setWppStore(e))
      .catch((e: any) => console.log('erro2: ', e));
  }, [listWpp, sellerStoreId]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListWpp()
        .then((e) => setListWpp(e))
        .catch((e) => console.log('erro ao caregar listaWpp: ', e));
    }
  }, []);

  useEffect(() => {
    ListCovid({ sellerStoreId, wppFromWeb: listCovid })
      .then((e: boolean) => setCovidStore(e))
      .catch((e: any) => console.log('erro2 listaCovid: ', e));
  }, [listCovid, sellerStoreId]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListCovid()
        .then((e) => setListCovid(e))
        .catch((e) => console.log('erro ao caregar listaCovid: ', e));
    }
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListLocal()
        .then((e) => setListLocal(e))
        .catch((e) => console.log('erro ao carregar listaLocal: ', e));
    }
  }, []);

  useEffect(() => {
    ListLocal({ sellerStoreId: sellerStoreId, wppFromWeb: listLocal })
      .then((e: boolean) => setLocalStore(e))
      .catch((e: any) => console.log('erro2: ', e));
  }, [listLocal, sellerStoreId]);

  const validateButtons = useMemo(() => {
    if (!isMobile) {
      return true;
    }

    if (isMobile && collapsed) {
      return true;
    }

    if (isMobile && isMap === 'lista') {
      return true;
    }

    return false;
  }, [isMobile, collapsed, isMap]);

  const validateStartAndEndHours = useCallback((hoursScheduled: any) => {
    // console.log('entrou no validate');
    let startHour;
    let endHour;
    const d = getDay(new Date());

    if (d) {
      const hours = hoursScheduled[d];
      if (hours && hours.start && hours.end) {
        startHour = hours.start;
        endHour = hours.end;
      } else {
        return null;
      }
    }
    // console.log('entrou no validate2');
    const today = new Date();
    const todayDay = today.getDate();
    const todayMonth = today.getMonth() + 1;
    const todayYeay = today.getFullYear();

    startHour = new Date(
      `${todayYeay}-${todayMonth}-${todayDay} ${startHour}:00`
    );
    endHour = new Date(`${todayYeay}-${todayMonth}-${todayDay} ${endHour}:00`);
    // console.log('entrou no validate 3');
    return {
      startHour,
      endHour,
    };
  }, []);

  useEffect(() => {
    const load = async () => {
      if (!schedules) return null;
      // console.log('item: ', item);
      // console.log('parsed: ', parsed);
      const resp: any = validateStartAndEndHours(schedules);

      // console.log('currentLegions: ', currentLegions);
      if (!resp?.startHour || !resp?.endHour) return '';
      const { startHour, endHour } = resp;
      const dayIncreased = addHours(new Date(), 2);
      const storeClosedAfterTwoHoursFromNow = isAfter(dayIncreased, endHour);

      const {
        canShipFromStore,
        active,
        distance,
        deliveryDaysSFS,
        address,
        legions,
      } = item;

      if (!canShipFromStore || !active) {
        setValidateSFStag(false);
        return;
      }

      if (
        address &&
        address.city &&
        address.city !== 'Rio De Janeiro' &&
        distance > 7
      ) {
        setValidateSFStag(false);
        return;
      }

      if (
        address &&
        address.city &&
        address.city === 'Rio De Janeiro' &&
        distance <= 7
      ) {
        let verifyLegion = false;

        legions.forEach((i: any) => {
          if (currentLegions?.includes(i)) {
            verifyLegion = true;
          }
        });

        if (verifyLegion) {
          setValidateSFStag(true);
          setValidateSFStagName('receba em 3 horas');
          return;
        } else {
          setValidateSFStag(false);
          return;
        }
      }

      if (deliveryDaysSFS === 0 && !storeClosedAfterTwoHoursFromNow) {
        setValidateSFStag(true);
        setValidateSFStagName('receba em 3 horas');
        return;
      }

      if (deliveryDaysSFS === 0 && storeClosedAfterTwoHoursFromNow) {
        setValidateSFStag(true);
        setValidateSFStagName('receba amanhã');
        return;
      }

      if (deliveryDaysSFS === 1) {
        setValidateSFStagName('receba amanhã');
        setValidateSFStag(true);
      }

      if (deliveryDaysSFS > 1) {
        const makeDay = addDays(new Date(), deliveryDaysSFS);
        const dayFormatted = format(makeDay, 'EEEE', {
          locale: ptBR,
        });
        setValidateSFStagName(`receba ${dayFormatted}`);
        setValidateSFStag(true);
      }

      const storeOpendNow = isBefore(new Date(), startHour);

      if (!storeOpendNow && deliveryDaysSFS === 0) {
        const deliveryHour = addHours(startHour, 2);
        const deliveryHourFormatted = format(deliveryHour, 'HH:mm');
        setValidateSFStagName(`receba até às ${deliveryHourFormatted}`);
        setValidateSFStag(true);
        return;
      }
    };

    load();
  }, [schedules, item, parsed, currentLegions]);

  useEffect(() => {
    const load = async () => {
      const {
        canPickUpInStore,
        canPickUpInStoreScheduled,
        distance,
        active,
        deliveryDaysPNLA,
      } = item;

      if ((!canPickUpInStore && !canPickUpInStoreScheduled) || !active) {
        setValidatePNLAtag(false);
        return;
      }

      if (distance > 35) {
        setValidatePNLAtag(false);
        return;
      }

      if (deliveryDaysPNLA !== null && deliveryDaysPNLA >= 0) {
        setValidatePNLAtag(true);
        return;
      }
    };

    load();
  }, [item]);

  return (
    <Container
      isPar={isPar}
      multiploTres={multiploTres}
      isMobile={isMobile}
      carousel={carousel}
      index={index}
      selected={selected}
      style={containerStyle}
      isMap={isMap === 'mapa'}
      // onClick={isMobile && isMap === 'mapa' ? handleOpenCard : onClickCard}
      collapsed={!collapsed && isMap === 'mapa'}
      emptyTags={!validateSFStag && !validatePNLAtag}
    >
      <Content>
        <LogoStore
          isMobile={isMobile}
          onClick={isMobile && isMap === 'mapa' ? handleOpenCard : onClickCard}
          isLocal={localStore}
        >
          {localStore ? (
            <LocalIcon width={38} height={31} />
          ) : (
            <Aamericanas width={14} height={54} />
          )}
        </LogoStore>

        {/* {variant === 'NEW' && (
          <DudeBox>
            <DudeIcon width="18px" height="18px" />
          </DudeBox>
        )} */}

        <Info
          isMobile={isMobile}
          open={!!(item.open || covidStore)}
          small={variant === 'NEW'}
        >
          {/* <h1>{Truncate(title, 21)}</h1> */}
          <h1
            onClick={
              isMobile && isMap === 'mapa' ? handleOpenCard : onClickCard
            }
          >
            {title}
          </h1>
          <BoxLayer isMobile={isMobile} open={!!(item.open || covidStore)}>
            <Clock width={16} height={16} style={{ marginRight: 8 }} />
            {item.open ? (
              covidStore ? (
                <h2>aberta para pedidos online</h2>
              ) : (
                <h2>
                  aberta&nbsp;
                  <strong>{day || 'Horário Indisponível'}</strong>
                </h2>
              )
            ) : covidStore ? (
              <h2>aberta para pedidos online</h2>
            ) : (
              <h2>
                fechada&nbsp; 
                <strong>{day || 'Horário Indisponível'}</strong>
              </h2>
            )}
          </BoxLayer>

          {/* <div>
            <p className="truncate-overflow">
              <strong>{distanceParsed}</strong>
              {address}
            </p>
          </div> */}
        </Info>
      </Content>
      <FooterCard
        isMobile={isMobile}
        open={!!(item.open || covidStore)}
        onClick={isMobile && isMap === 'mapa' ? handleOpenCard : onClickCard}
        emptyTags={!validateSFStag && !validatePNLAtag}
      >
        <MiddleContent>
          {/* <Distance>
            <span>{distanceParsed}</span>
          </Distance> */}
          <p className="truncate-overflow-middle-content">
            <strong>{distanceParsed}</strong>
            {address}
          </p>
        </MiddleContent>

        {!!validateButtons && (
          <div style={{ marginTop: 'auto' }}>
            <TagsContent>
              {!!validateSFStag && (
                <Tag style={{ marginRight: 5, display: 'none' }}>
                  <span>receba em 2h</span>
                </Tag>
              )}

              {/* <Tag style={{ marginRight: 5 }}>
                <span>receba em 3h</span>
              </Tag> */}

              {validatePNLAtag && (
                <Tag blue>
                  <span>agende para retirar na loja</span>
                </Tag>
              )}
            </TagsContent>
            <Buttons
              wppStore={wppStore}
              variant={variant}
              isMobile={isMobile}
              emptyTags={!validateSFStag && !validatePNLAtag}
            >
              <div
                onClick={onClickProducts}
                style={{
                  width: validateSFStag || validatePNLAtag ? '98%' : '100%',
                }}
              >
                <ProductButton
                  isMobile={isMobile}
                  variant={variant}
                  big={wppStore}
                  withTags={validateSFStag || validatePNLAtag}
                >
                  <StoreIcon />
                  <span>ver mais</span>
                </ProductButton>
              </div>

              {(validateSFStag || validatePNLAtag) && (
                <div
                  onClick={onClickBuy}
                  style={{
                    width: variant === 'NEW' && !wppStore ? '100%' : '98%',
                    display: 'flex',
                    justifyContent: variant === 'NEW' ? 'flex-end' : 'center',
                  }}
                >
                  <BuyButton
                    isMobile={isMobile}
                    variant={variant}
                    big={!wppStore}
                  >
                    <BasketIcon />
                    <span>comprar</span>
                  </BuyButton>
                </div>
              )}
            </Buttons>
          </div>
        )}
      </FooterCard>

      <ActionFakeBox
        onClick={isMobile && isMap === 'mapa' ? handleOpenCard : onClickCard}
      />
    </Container>
  );
};

export default CardStoreV2;
