import {
  LoginLasaProps,
  ResponseProps,
  PopulateProductsProps,
} from './interfaces';
import { AxiosError, AxiosResponse } from 'axios';
import api from '../api';

export const populateProducts = async ({
  store,
  ean,
}: PopulateProductsProps): Promise<ResponseProps> => {
  const payload = {
    store,
    ean,
  };

  // ean=789259734891

  let uri = `https://restql-server-api-v2-americanas.b2w.io/run-query/app/lasa-and-b2w-product-by-ean/9?store=${store}`;

  ean.forEach((i: string | number) => (uri = uri + `&ean=${i}`));

  const response = await api
    .post(uri, payload)
    .then((resp: AxiosResponse) => {
      console.log('resp: ', resp);
      return {
        status: resp.status,
        data: resp.data['product-b2w'].result,
      };
    })
    .catch((e: AxiosError) => {
      console.log('error populateProducts: ', JSON.stringify(e.response));
      console.log('error populateProducts status: ', e.response?.status);

      return {
        status: e.response?.status,
        data: e.response?.statusText,
      };
    });

  return response.data;
};

export const getOrders = async (token: string): Promise<any> => {
  try {
    // https://cliunico.azure-api.net/portalcliente/cliente/cupons/lista?page_number=1&limite_page=12
    const isHml = window.location.hostname.includes('hml');
    const headers: any = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (isHml) {
      headers.headers['Ocp-Apim-Subscription-Key'] =
        '0562e0d225874818971b5352fe0aa85f';
    }

    const response = api
      .get(
        `https://itg-nossaslojas.americanas.com.br/portalcliente/cliente/cupons/lista?page_number=1&limite_page=12`,
        headers
      )
      .then((resp: AxiosResponse) => {
        console.log('resp: ', resp);
        return {
          status: resp.status,
          data: resp.data,
        };
      })
      .catch((e: AxiosError) => {
        console.log('error getOrders: ', e);
        console.log('error getOrders: ', JSON.stringify(e.response));
        console.log('error getOrders status: ', e.response?.status);

        return {
          status: e.response?.status,
          data: e.response?.statusText,
        };
      });

    return response;
  } catch (e) {
    console.log('error inside getOrders: ', e);
    return e;
  }
};

export const loginLasa = async ({
  id_cliente,
  access_code_cliente,
}: LoginLasaProps): Promise<ResponseProps> => {
  const payload = {
    id_cliente,
    access_code_cliente,
  };

  const isHml = window.location.hostname.includes('hml');
  const uri = isHml
    ? 'https://horus.azure-api.net/cliunicoapp/login/'
    : `https://itg-nossaslojas.americanas.com.br/portalcliente/login`;
  
  const headers = isHml
    ? {
        headers: {
          // 'Ocp-Apim-Subscription-Key': '05  -  62  -  e0  -  d2  -  25  -  87  -  48  -  18  -  97  -  1b  -  53  -  52  -  fe  -  0a  -  a8  -  5f',
        },
      }
    : undefined;

  const response = api
    .post(uri, payload, headers)
    .then((resp: AxiosResponse) => {
      console.log('resp: ', resp);
      return {
        status: resp.status,
        data: resp.data,
      };
    })
    .catch((e: AxiosError) => {
      console.log('error loginLasa: ', JSON.stringify(e.response));
      console.log('error loginLasa status: ', e.response?.status);

      return {
        status: e.response?.status,
        data: e.response?.statusText,
      };
    });

  return response;
};
