import React, { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from './Styles/global';
import { CookiesProvider } from 'react-cookie';
import Routes from './Routes';
import './Styles/fonts.css';
import { ThemeProvider } from 'styled-components';
import theme from './Styles/theme';
import { CarousselProvider } from './Hooks/useCaroussel';
import { AppProvider } from './Hooks';

const App: FC = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <CookiesProvider>
          <ThemeProvider theme={theme}>
            <AppProvider>
              <Routes />
            </AppProvider>
          </ThemeProvider>
        </CookiesProvider>
      </BrowserRouter>
      <GlobalStyle />
    </>
  );
};

export default App;
