/* eslint-disable no-constant-condition */
import React, {
  FC,
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';

import queryString from 'query-string';
import { ReactComponent as LocalIcon } from '../../../Assets/local_branco.svg';
import { ReactComponent as LogoBranco } from '../../../Assets/logo_branco.svg';
import { ReactComponent as IconPlaceholderEmpty } from '../../../Assets/IconPlaceholderEmpty.svg';
import { ReactComponent as InfoCinza } from '../../../Assets/info-cinza.svg';
import { RiCloseLine } from 'react-icons/ri';
import LogoVermelho from '../../../Assets/logo_menor.png';

import {
  Data,
  Alignment,
  Card,
  HeaderCard,
  TitleOffer,
  AddressOffer,
  Info,
  Timestamp,
  Total,
  Barrinha,
  Products,
  Item,
  ProductImg,
  Badge,
  AlignmentDescription,
  Description,
  BarrinhaBottom,
  FooterCard,
  ContentWrapper,
  Title,
  Box,
  RightContent,
  WrapperProduct,
  Values,
  ContentInfo,
  AlignmentNfe,
  BoxNfe,
  CloseButton,
  ActionsNfe,
  MoreButton,
  Separator,
  ProductImgDetail,
  AlignmentDescriptionDetail,
  Price,
  InfoDetail,
  TimestampDetail,
  TotalDetail,
  ProductsDetail,
  DescriptionDetail,
  ItemDetail,
  FooterCardDetail,
  AlignPrice,
  Path,
  PathBox,
  BackButton,
  Tabela,
  PhotoStore,
  BoxInfo,
  InfoStore,
  BoxEntenda,
  TooltipDiff,
  Lingueta,
  WrapperCupom,
  WrapperLoadingCupom,
  LoaderBox,
  Loader,
  Logo,
  WrapperContent,
  Filters,
  Content,
  SeasonFilter,
  ItemFilter,
  Header,
  CardBottom,
  FilterItem,
  FilterResultBox,
} from './styles';
import ModalOrderDetail from '../../../Components/ModalOrderDetail';
import { HandleEvents, handleStoreID } from '../../../Utils/AnalyticsGA';
import useWindowDimensions from '../../../Utils/size';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getApiListNote,
  Nota,
  listNoteElement,
  handleNfeLink,
} from '../../../Utils/NotaFiscal';
import { useCookies } from 'react-cookie';
import {
  BreadCrumbItemProps,
  Breadcrumb,
} from '../../../Components/Breadcrumb';
import CupomFiscal, {
  ImperativeCupomFiscalProps,
} from '../../../Components/CupomFiscal';
import ModalTooltip from '../../../Components/ModalTooltip';
import LoadingIcon from '../../../Assets/loading.svg';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useFilters } from '../../../Hooks/useFilters';

interface Props {
  data: any;
  error: any;
  keysOrders: any;
  yearsOrders: any;
  monthsOrders: any;
  monthsData: any;
  filteredData: any;
}

const OrderWeb: FC<Props> = ({
  data,
  error,
  keysOrders,
  yearsOrders,
  monthsOrders,
  monthsData,
  filteredData,
}) => {
  console.log('filteredData: ', filteredData);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [tooltipDiffView, setTooltipDiffView] = useState(false);
  const [orderBreadcrumbs, setOrderBreadcrumbs] = useState<BreadCrumbItemProps>(
    {} as BreadCrumbItemProps
  );
  const [lastBreadcrumbs, setlastBreadcrumbs] = useState<BreadCrumbItemProps>(
    {} as BreadCrumbItemProps
  );
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);
  const [listNote, setListNote] = useState<Nota[]>([]);
  const [selectedStore, setSelectedStore] = useState<any>({});
  const [visibleModalDetails, setVisibleModalDetails] = useState(false);
  const [order, setOrder] = useState<any>();
  const [withFilters, setWithFilters] = useState(false);
  const isMobile = useWindowDimensions();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const {
    addYearFilter,
    yearsFilter,
    removeYearFilter,
    removeMonthFilter,
    addMonthFilter,
    monthsFilter,
    cleanAllFilter,
  } = useFilters();

  const refsCheckboxYear: any = useRef<any>([]);
  const refsCheckboxMonths: any = useRef<any>([]);

  const ref = useRef<any>(null);
  const CupomRef = useRef<ImperativeCupomFiscalProps>(null);

  useEffect(() => {
    const isObj = typeof parsed.breadcrumb === 'object';

    if (parsed.breadcrumbType === 'home') return;
    if (parsed.breadcrumb && parsed.breadcrumbLink) {
      const obj = {
        label: isObj ? String(parsed.breadcrumb[0]) : String(parsed.breadcrumb),
        link: String(parsed.breadcrumbLink),
      };

      // console.log('obj: ', obj);
      setlastBreadcrumbs(obj);
    }
  }, []);

  const handleNfe = useCallback(async () => {
    console.log('item nfe: ', order);
    HandleEvents({
      isMobile,
      eventAction: 'minhas compras',
      eventCategory: 'nossaslojas',
      eventLabel: 'nota fiscal',
      eventValue: undefined,
      cookies,
      store: { name: order.nomeLoja, sellerStoreId: order.codigoLoja },
    });
    console.log('chave: ', order.chaveNfe);

    handleNfeLink({ uf: order.uf, chaveNfe: order.chaveNfe, list: listNote });
  }, [isMobile, listNote, cookies, order]);

  const handleDetails = useCallback(
    (item: any) => {
      setOrder(item);

      setVisibleModalDetails(true);
      setSelectedStore(item);
      HandleEvents({
        isMobile,
        eventAction: 'minhas compras',
        eventCategory: 'nossaslojas',
        eventLabel: 'total de produtos',
        eventValue: undefined,
        cookies,
        store: {
          name: item?.nomeLoja,
          sellerStoreId: item?.codigoLoja,
        },
      });
    },
    [isMobile, cookies]
  );

  const handleVisitStore = useCallback(
    (item?: any) => {
      HandleEvents({
        isMobile,
        eventAction: 'minhas compras',
        eventCategory: 'nossaslojas',
        eventLabel: 'visitar loja',
        eventValue: undefined,
        cookies,
        store: {
          name: item.nomeLoja || selectedStore.nomeLoja,
          sellerStoreId: item.codigoLoja || selectedStore.codigoLoja,
        },
      });

      const newID = handleStoreID(item.codigoLoja || selectedStore.codigoLoja);

      // history.push(`/loja/${newID}/detalhes&chave=o2o_lp_00_0_0_nossaslojas`);
      history.push(`/loja/${newID}&chave=o2o_lp_00_0_0_nossaslojas`);
    },
    [isMobile, selectedStore, history, cookies]
  );

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      getApiListNote()
        .then((e) => setListNote(e))
        .catch((e) => console.log('erro ao caregar listNote: ', e));
    }
  }, []);

  useEffect(() => {
    setOrderBreadcrumbs({
      label: 'meus pedidos',
      link: `${location.pathname}${location.search}`,
    });
  }, [location]);

  const handleCupomFiscal = useCallback(() => {
    // history.push('/pedidos/cupom-fiscal');
    setLoadingPrint(true);

    HandleEvents({
      isMobile,
      eventAction: 'minhas compras',
      eventCategory: 'nossaslojas',
      eventLabel: 'ver cupom fiscal',
      eventValue: undefined,
      cookies,
    });

    setTimeout(() => {
      exportComponentAsPNG(ref, { fileName: 'cupom-fiscal' }).then(() => {
        setTimeout(() => {
          setLoadingPrint(false);
        }, 2000);
      });
    }, 2500);
  }, [isMobile, cookies]);

  const handleAddYearFilter = useCallback(
    (e: any, i: any) => {
      console.log('event checbox: ', e.target.checked);
      const checked = e.target.checked;

      checked ? addYearFilter(i[0]) : removeYearFilter(i[0]);
    },
    [addYearFilter, removeYearFilter]
  );

  const handleRemoveYearFilter = useCallback(
    (year: string) => {
      removeYearFilter(year);
      refsCheckboxYear.current[year].checked = false;
    },
    [removeYearFilter, refsCheckboxYear]
  );

  const handleRemoveMonthFilter = useCallback(
    (month: string) => {
      removeMonthFilter(month);
      refsCheckboxMonths.current[Number(month)].checked = false;
    },
    [removeMonthFilter, refsCheckboxMonths]
  );

  const filtersYear = useMemo(() => {
    return Object.entries(yearsOrders).map((i: any, key: any) => {
      return (
        <ItemFilter key={i[0]}>
          <label className="container-label">
            <input
              type="checkbox"
              onClick={(e: any) => handleAddYearFilter(e, i)}
              ref={(el) => (refsCheckboxYear.current[i[0]] = el)}
            />
            <span className="checkmark"></span>
          </label>
          <h2>{`${i[0]}`}</h2>
        </ItemFilter>
      );
    });
  }, [yearsOrders, handleAddYearFilter]);

  const handleAddMonthFilter = useCallback(
    (e: any, i: any) => {
      const checked = e.target.checked;

      checked ? addMonthFilter(i) : removeMonthFilter(i);
    },
    [addMonthFilter, removeMonthFilter]
  );

  const filtersMonth = useMemo(() => {
    refsCheckboxMonths.current = [];
    console.log('monthsOrders: ', monthsOrders);
    const validArray = Array.isArray(monthsOrders);

    if (!validArray) return '';

    return monthsOrders.map((key: any, index: number) => {
      const makeDate = `${2022}-${String(Number(key)).padStart(2, '0')}-${10}`;

      const month = String(
        format(new Date(makeDate), 'MMMM', {
          locale: ptBR,
        })
      ).toLowerCase();
      return (
        <ItemFilter key={key}>
          <label className="container-label">
            <input
              type="checkbox"
              onClick={(e) => handleAddMonthFilter(e, key)}
              ref={(el) => (refsCheckboxMonths.current[Number(key)] = el)}
            />
            <span className="checkmark"></span>
          </label>
          <h2>{`${month} `}</h2>
        </ItemFilter>
      );
    });
  }, [monthsOrders, monthsData, handleAddMonthFilter]);

  const handleDeleteAllFilters = useCallback(() => {
    refsCheckboxMonths.current.forEach((i: any) => {
      i.checked = false;
    });
    refsCheckboxYear.current.forEach((i: any) => (i.checked = false));

    cleanAllFilter();
  }, [cleanAllFilter]);

  const itemsFound = useMemo(() => {
    let items = 0;

    keysOrders.map((key: any, index: number) => {
      const filteredIsEmpty = Object.keys(filteredData).length === 0;
      const validData = filteredIsEmpty ? data : filteredData;

      if (!validData[key]?.length) return;
      validData[key].forEach((i: any) => {
        items += 1;
      });
    });

    return items;
  }, [keysOrders, data, filteredData]);

  return (
    <>
      {visibleModalDetails && order && (
        <>
          <ModalOrderDetail
            visible={visibleModalDetails}
            onClick={() => setVisibleModalDetails(false)}
          />
          <AlignmentNfe>
            <div style={{ position: 'relative' }}>
              <TooltipDiff className="tooltipdiff" opacity={!!tooltipDiffView}>
                <div className="triangle-with-shadow"></div>
                <h1>o que é cada um desses documentos?</h1>

                <span>
                  <b>Cupom fiscal: </b>é um comprovante de venda e pode ser
                  utilizado para realizar trocas dentro das lojas, ele é
                  auxiliar da nota fiscal.
                </span>

                <span>
                  <b>Nota fiscal: </b>é um documento oficial que pode ser
                  utilizado para trocas com os fornecedores, fazer seguros e
                  usar para assistência técnica.
                </span>
              </TooltipDiff>
            </div>

            <BoxNfe>
              <RiCloseLine
                size={40}
                color="#666666"
                className="icone"
                onClick={() => setVisibleModalDetails(false)}
                style={{ marginLeft: 'auto' }}
              />

              <Separator style={{ border: 0 }}>
                <h1>detalhes da compra</h1>
              </Separator>

              <Separator
                style={{ display: 'flex', flexDirection: 'row', border: 0 }}
              >
                <PhotoStore
                  style={{ marginRight: 16 }}
                  isMobile={isMobile}
                  local={false}
                >
                  {false ? <LocalIcon width={65} /> : <LogoBranco width={65} />}
                </PhotoStore>
                <InfoStore>
                  <h2 className="truncate-overflow-small">
                    {String(order.nomeLoja).toLocaleLowerCase()}
                  </h2>
                  <h3 className="truncate-overflow">
                    {String(order.logradouro).toLocaleLowerCase()}
                  </h3>
                </InfoStore>
              </Separator>

              <InfoDetail>
                <div>
                  <TimestampDetail>{order.data}</TimestampDetail>
                  <MoreButton onClick={handleCupomFiscal}>
                    <span>ver cupom fiscal</span>
                  </MoreButton>
                </div>

                <div>
                  <BoxEntenda
                    onMouseOver={() => setTooltipDiffView(true)}
                    onMouseOut={() => setTooltipDiffView(false)}
                  >
                    <h2>entenda as diferenças</h2>
                    <InfoCinza width={17} height={17} />
                  </BoxEntenda>
                  <MoreButton onClick={handleNfe}>
                    <span>ver nota fiscal</span>
                  </MoreButton>
                </div>
              </InfoDetail>

              <Alignment>
                <Tabela isMobile={isMobile} className="on-scrollbar">
                  <ProductsDetail>
                    <p>qtd</p>
                    {order.itensFinal.map((i: any) => (
                      <ItemDetail key={Math.random()}>
                        <ProductImgDetail>
                          <span>{i.quantidade}</span>
                        </ProductImgDetail>
                        <div style={{ flex: 1 }}>
                          <AlignmentDescriptionDetail>
                            <DescriptionDetail className="truncate-overflow">
                              {String(i.nome).toLocaleLowerCase()}
                            </DescriptionDetail>
                          </AlignmentDescriptionDetail>
                        </div>
                        <AlignPrice>
                          <Price>R$ {i.valor}</Price>
                        </AlignPrice>
                      </ItemDetail>
                    ))}
                  </ProductsDetail>
                </Tabela>
              </Alignment>
              <FooterCardDetail>
                <Total>total de produtos: {order.itensFinal.length}</Total>

                <div>
                  <h1> R$ {order.valorTotal}</h1>
                </div>
              </FooterCardDetail>
            </BoxNfe>
          </AlignmentNfe>
        </>
      )}

      {loadingPrint && (
        <div>
          <WrapperCupom
            style={{
              width: CupomRef.current?.getBoxSize().width,
              height: CupomRef.current?.getBoxSize().height,
              marginTop: 50,
              backgroundColor: '#f3f3f3',
            }}
            ref={ref}
          >
            <CupomFiscal
              onClose={() => null}
              ref={CupomRef}
              forPrint
              order={order}
            />
          </WrapperCupom>
          <WrapperLoadingCupom>
            <LoaderBox>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Logo src={LogoVermelho} />
                <Loader src={LoadingIcon} />
              </div>
            </LoaderBox>
          </WrapperLoadingCupom>
        </div>
      )}

      <ContentWrapper>
        <Breadcrumb items={[lastBreadcrumbs, orderBreadcrumbs]} />
        {/*
        <BackButton onClick={() => history.goBack()}>
          <ChevronLeft onClick={() => history.goBack()} />
          <h2>voltar</h2>
        </BackButton> */}
        <div style={{ marginBottom: 34, marginTop: 28 }}>
          <Title>minhas compras nas lojas</Title>
        </div>

        {error ? (
          error
        ) : (
          <WrapperContent>
            <Filters>
              <h1>Filtrar por</h1>

              <SeasonFilter>
                <p>ano</p>

                {filtersYear}
              </SeasonFilter>
              <SeasonFilter style={{ marginTop: 38 }}>
                <p>mês</p>

                {filtersMonth}
              </SeasonFilter>
            </Filters>

            <Content>
              <p className="shopping-quantity">
                {itemsFound}{' '}
                {Number(itemsFound) === 1
                  ? 'compra encontrada'
                  : 'compras encontradas'}
              </p>

              {(!!yearsFilter?.length || !!monthsFilter?.length) && (
                <FilterResultBox>
                  {yearsFilter.map((i: string) => (
                    <FilterItem
                      key={i}
                      onClick={() => handleRemoveYearFilter(i)}
                    >
                      <span>{i}</span>
                      <RiCloseLine color="#666" size={18} />
                    </FilterItem>
                  ))}

                  {monthsFilter.map((i: string) => (
                    <FilterItem
                      key={i}
                      onClick={() => handleRemoveMonthFilter(i)}
                    >
                      <span>
                        {String(
                          format(
                            new Date(
                              `${2022}-${String(Number(i)).padStart(
                                2,
                                '0'
                              )}-${10}`
                            ),
                            'MMMM',
                            {
                              locale: ptBR,
                            }
                          )
                        ).toLowerCase()}
                      </span>
                      <RiCloseLine color="#666" size={18} />
                    </FilterItem>
                  ))}

                  <FilterItem filter onClick={handleDeleteAllFilters}>
                    <h2>limpar todos</h2>
                  </FilterItem>
                </FilterResultBox>
              )}

              {keysOrders.map((key: any, index: number) => {
                const filteredIsEmpty = Object.keys(filteredData).length === 0;
                const validData = filteredIsEmpty ? data : filteredData;

                if (!validData[key]?.length) return;
                return validData[key].map((i: any, indexInterno: number) =>
                  i ? (
                    <Box
                      key={`${i}_box_validData`}
                      style={{
                        marginTop: index === 0 ? 20 : 41,
                      }}
                    >
                      {indexInterno === 0 && (
                        <Data>
                          <span>{i.dataDescription}</span>
                        </Data>
                      )}

                      <Alignment>
                        <Card>
                          <Header>
                            <Timestamp>{i.data}</Timestamp>
                            <p>compra concluída</p>
                          </Header>
                          <CardBottom>
                            <HeaderCard>
                              <PhotoStore isMobile={isMobile} local={false}>
                                {false ? (
                                  <LocalIcon width={65} height={71} />
                                ) : (
                                  <LogoBranco width={65} height={71} />
                                )}
                              </PhotoStore>

                              <BoxInfo>
                                <TitleOffer className="truncate-overflow">
                                  {String(i.nomeLoja).toLocaleLowerCase()}
                                </TitleOffer>
                                <AddressOffer className="truncate-overflow">
                                  {String(i.logradouro).toLocaleLowerCase()}
                                </AddressOffer>
                              </BoxInfo>
                            </HeaderCard>

                            <RightContent>
                              <WrapperProduct>
                                <Info>
                                  <ContentInfo>
                                    <div onClick={() => handleDetails(i)}>
                                      <Total>
                                        total de produtos:{' '}
                                        <b>
                                          {String(i.itens.length).padStart(
                                            2,
                                            '0'
                                          )}
                                        </b>
                                      </Total>
                                    </div>
                                  </ContentInfo>

                                  <Values>
                                    <span>valor total: </span>
                                    <h1>R${i.valorTotal}</h1>
                                  </Values>
                                </Info>

                                <HiOutlineChevronRight
                                  size={16}
                                  color="#000"
                                  onClick={() => handleDetails(i)}
                                  style={{ cursor: 'pointer' }}
                                />
                              </WrapperProduct>
                            </RightContent>
                          </CardBottom>
                        </Card>
                      </Alignment>
                    </Box>
                  ) : (
                    <Card key={`${i}_Card_validData`} empty>
                      <div />
                      <h3>Ops! Falha ao carregar.</h3>
                      <FooterCard>
                        <div />

                        <h2>atualizar</h2>
                      </FooterCard>
                    </Card>
                  )
                );
              })}
            </Content>
          </WrapperContent>
        )}
      </ContentWrapper>
    </>
  );
};

export default OrderWeb;
