import { getDay } from 'date-fns';
import React, { FC, useState } from 'react';
import { useCookies } from 'react-cookie';
import { StoreProps } from '../../Pages/Details/Sobre';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import { DaysProps } from '../../Utils/HourFunction';
import makeDay from '../../Utils/makeDay';
import NumberToDay, { ResponseProps } from '../../Utils/NumberToDay';
import useWindowDimensions from '../../Utils/size';
import { Accordion } from '../Accordion';
import { HoursStore } from '../HoursStore';
import {
  Bar,
  BoxHours,
  BoxHoursAccordion,
  Container,
  Day,
  Hours,
  Item,
  Table,
  Title,
} from './styles';

interface Props {
  store: StoreProps;
  hours: DaysProps;
}

interface HourProps {
  hour: string;
  percent: string;
  times: number;
  qtd: number;
  avegrage: string;
}

const TableHoursV2: FC<Props> = ({ store, hours }) => {
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);

  const isMobile = useWindowDimensions();
  const today = new Date();
  const day = getDay(today);
  const [currentDay, setCurrentDay] = useState<ResponseProps>(() =>
    NumberToDay(day)
  );

  return (
    <Container isMobile={isMobile}>
      {isMobile && (
        <Accordion
          title="horários de funcionamento"
          defaultOpen
          analyticsEvent={() => {
            HandleEvents({
              isMobile,
              eventAction: 'storepage',
              eventCategory: 'nossaslojas',
              eventLabel: 'horario de funcionamento',
              eventValue: undefined,
              cookies,
            });
          }}
        >
          <BoxHoursAccordion>
            {store.schedules &&
              Object.entries(store.schedules).map((value: any, _: any) => {
                const [dia, horarios] = value;

                return (
                  <Item key={value}>
                    <Day>{makeDay(dia).name}</Day>
                    <Bar />
                    {horarios.start && horarios.end ? (
                      <Hours>
                        {`${horarios.start}h`} às {`${horarios.end}h`}
                      </Hours>
                    ) : (
                      <Hours>fechada</Hours>
                    )}
                  </Item>
                );
              })}
          </BoxHoursAccordion>
        </Accordion>
      )}

      {!isMobile && (
        <>
          <Table isMobile={isMobile}>
            <li style={{ marginBottom: 15 }}>
              <Title>horários de funcionamento</Title>
            </li>
            <BoxHours>
              {store.schedules &&
                Object.entries(store.schedules).map((value: any, _: any) => {
                  const [dia, horarios] = value;

                  return (
                    <Item key={value}>
                      <Day>{makeDay(dia).name}</Day>
                      <Bar />
                      {horarios.start && horarios.end ? (
                        <Hours>
                          {`${horarios.start}h`} às {`${horarios.end}h`}
                        </Hours>
                      ) : (
                        <Hours>fechada</Hours>
                      )}
                    </Item>
                  );
                })}
            </BoxHours>
          </Table>

          <HoursStore store={store} hours={hours} />
        </>
      )}
    </Container>
  );
};

export default TableHoursV2;
