import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #fff; */
`;

export const Title = styled.span`
  color: #666666;
  margin: 20px 0px;
  font-family: Helvetica Neue;
  font-size: 16px;
`;

export const Data = styled.div`
  width: 120px;
  height: 20px;
  box-shadow: 0px 0px 20px #00000026;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 19px;

  span {
    color: #999999;
    font-size: 12px;
    font-family: 'Helvetica Neue, Regular';
    margin: 0;
    padding: 0;
    /* margin-top: 3px; */
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface CardProps {
  empty?: boolean;
}

export const Card = styled.div<CardProps>`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 0px 20px #00000026;
  width: 100%;
  margin-bottom: 16px;
  min-height: 235px;

  &:last-child {
    margin-bottom: 0px;
  }

  ${({ empty }) =>
    empty &&
    css`
      justify-content: space-between;
    `}

  h3 {
    text-align: center;
    font-size: 14px;
    color: #666666;
    font-family: 'Helvetica Neue';
  }
`;

export const TitleOffer = styled.span`
  font-size: 16px;
  font-family: 'Helvetica Neue, Bold';
  color: #666666;
`;

export const AddressOffer = styled.span`
  font-size: 14px;
  font-family: 'Helvetica Neue';
  color: #666666;
  margin-bottom: 3px;
`;

export const Alignment = styled.div`
  width: 100%;
  padding: 0 18px 0 15px;
  margin-bottom: 24px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 21px;

  svg path {
    fill: #4e4e4e;
  }

  svg {
    width: 13px;
    margin-right: 6px;
    transform: rotate(-90deg);
    margin-left: 10px;
  }
`;

export const Timestamp = styled.span`
  font-size: 13px;
  color: #666666;
  font-family: 'Helvetica Neue';
`;

export const Total = styled.span`
  font-size: 13px;
  color: #666666;
  font-family: 'Helvetica Neue';
`;

export const Barrinha = styled.div`
  width: 100%;
  height: 1px;
  background: #d8d8d8;
  margin: 16px 0;
`;

export const BarrinhaBottom = styled.div`
  height: 1px;
  background: #70707012;
  margin: 16px;
`;

export const HeaderCard = styled.div`
  width: 100%;
  padding: 16px 16px 0 16px;

  .truncate-overflow {
    /* max-width: 252px; */
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;

export const Products = styled.div`
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;

  .truncate-overflow {
    /* max-width: 252px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .placeholder-icon-empty {
    width: 45px;
    height: 45px;
    transform: rotate(0deg) !important;
  }

  svg {
    margin-right: 10px;
  }
`;

interface ProductImgProps {
  marginRight: string;
}

export const ProductImg = styled.div<ProductImgProps>`
  width: 45px;
  height: 45px;
  border-radius: 9px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => props.marginRight};
  position: relative;

  img {
    width: 100%;
  }
`;
export const Description = styled.span`
  font-size: 14px;
  color: #666666;
  font-family: 'Helvetica Neue';
  font-weight: 200;
`;

interface AlignmentDescriptionProps {
  size: string;
  unique: boolean;
}

export const AlignmentDescription = styled.div<AlignmentDescriptionProps>`
  /* flex: 1; */
  width: 100%;
  max-width: ${(props) => props.size};

  ${({ unique }) =>
    unique &&
    css`
      max-width: 100%;
      flex: 1;
    `}
`;

export const FooterCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 16px 14px 16px;

  > div {
    display: flex;
    align-items: flex-end;
  }

  span {
    color: #666666;
    font-family: 'Helvetica Neue';
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  h1 {
    color: #333333;
    font-family: 'Helvetica Neue, Bold';
    font-size: 24px;
    margin: 0;
    padding: 0;
    margin-bottom: -7px;
    margin-left: 4px;
  }

  h2 {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: 'Helvetica Neue';
    font-size: 14px;
    margin: 0;
    padding: 0;
  }
`;

export const HeadBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  svg {
    width: 13px;
    margin-right: 6px;
    transform: rotate(90deg);
  }

  svg path {
    fill: #4e4e4e;
  }
`;

export const Badge = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.redPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8px;
  right: -8px;

  span {
    color: white;
    font-size: 12px;
    font-family: Helvetica Neue;
  }
`;

export const LoaderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 158px;
`;

interface LoaderProps {
  size?: string;
  bottom?: string | null;
}

export const Loader = styled.img<LoaderProps>`
  width: ${(props) => props.size || '80px'};
  height: ${(props) => props.size || '80px'};
  margin: auto;
`;

export const BoxNfe = styled.div`
  width: 100%;
  height: 147px;
  background: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: #666666;
    font-size: 16px;
    margin: 0;
    font-family: Helvetica Neue, Regular;
    text-align: center;
    line-height: 21px;
    font-weight: 100;
  }

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: Helvetica Neue, Regular;
    font-size: 14px;
  }
`;

export const AlignmentNfe = styled.div`
  position: absolute;
  z-index: 99;
  margin-top: 272px;
  padding: 0 5px;
  width: 100%;
`;

export const ActionsNfe = styled.div`
  display: flex;
  margin-top: 22px;
  align-items: center;
`;

export const MoreButton = styled.div`
  border: solid 2px #e0030e;
  cursor: pointer;
  border-radius: 8px;
  width: 109px;
  height: 37px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;

  span {
    font-family: Helvetica Neue, Regular;
    color: #df030e !important;
    font-size: 14px;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 6px;
  right: 10px;
`;

export const AlignmentError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .fontBold {
    font-family: 'Helvetica Neue, Regular' !important;
  }
`;

export const BoxAlignmentTitle = styled.div`
  width: 100%;
  /* margin-bottom: 32px; */

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 16px;
`;

interface WrapperHeaderProps {
  hasFilter: boolean;
}

export const WrapperHeader = styled.div<WrapperHeaderProps>`
  width: 100%;

  margin-bottom: 28px;
  padding-bottom: 16px;

  p {
    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-size: 12px;
    color: #666;
    margin-top: 16px;
    margin-left: 16px;
  }

  ${({ hasFilter }) =>
    hasFilter &&
    css`
      min-height: 176px;
      background: #fff;
      box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.25);
    `}
`;

interface FilterBoxProps {
  hasFilter?: boolean;
}

export const FilterBox = styled.div<FilterBoxProps>`
  display: flex;
  align-items: center;
  padding: 0 6px 0 16px;

  ${({ hasFilter }) =>
    !hasFilter &&
    css`
      padding-right: 6px;
    `}

  span {
    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-size: 14px;
    color: #f80032;
    margin-left: 10px;
  }
`;

export const BoxActionsFilter = styled.div`
  display: flex;
  margin-left: 16px;
  margin-top: 16px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

interface FilterItemProps {
  filter?: boolean;
}

export const FilterItem = styled.div<FilterItemProps>`
  padding: 6px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: solid 1px #666;
  box-shadow: 0px 3px 10px -5px rgba(0, 0, 0, 0.25);

  span {
    margin-right: 7px;
    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-size: 13px;
    line-height: 14px;
    color: #666;
  }

  h2 {
    color: #f80032;
    font-family: 'Helvetica Neue, Regular';
    font: -webkit-control;
    font-size: 14px;
    line-height: 16px !important;
  }

  ${({ filter }) =>
    filter &&
    css`
      border: 0px;
      box-shadow: none;
      padding: 6px 8px;
    `}
`;
