import styled, { css } from 'styled-components';

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  height: 100vh;
  padding: 12px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 351px;
  flex-direction: column;
  border-radius: 16px;
  background-color: #f3f3f3;
  margin-top: 45%;
  align-items: center;
  padding: 16px;
`;

export const Name = styled.span`
  color: #666666;
  font-size: 16px;
  font-family: 'Helvetica Neue, Bold';
  line-height: 19px;
  text-align: center;
  margin: 0 51px;
  margin-top: 11px;
`;

export const Address = styled.span`
  color: #666666;
  font-size: 14px;
  font-family: Helvetica Neue, Regular;
  line-height: 17px;
  margin: 0 66px;
  margin-top: 9px;
  text-align: center;
`;

export const Barrinha = styled.div`
  width: 100%;
  height: 1.5px;
  background: #e5e5e5;
  margin-top: 16.7px;
`;

export const InfoHeader = styled.div`
  margin-top: 13.3px;
  width: 100%;
  padding: 0 21px 0px 16px;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
`;

export const ItemList = styled.span`
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-size: 14px;
  line-height: 17px;
`;

export const ItemData = styled.span`
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-size: 14px;
  line-height: 17px;
`;

export const Title = styled.span`
  color: #666666;
  font-family: 'Helvetica Neue, Bold';
  font-size: 16px;
  line-height: 17px;
  margin-top: 19px;
  margin-bottom: 16px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const ActionButton = styled.button`
  border: 0px;
  width: 170px;
  height: 44px;
  background: #ffffff;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: ${({ theme }) => theme.colors.redPrimary};
    font-family: Helvetica Neue, Regular;
    font-size: 16px;
    line-height: 18px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 234px;
  justify-content: space-between;

  h1 {
    margin: 0;
    color: #333333;

    font-family: Helvetica Neue, Regular;
    font-size: 18px;
  }

  span {
    margin: 0;
    color: #666666;

    font-family: Helvetica Neue, Regular;
    font-size: 14px;
  }

  b {
    color: #666666;
    font-family: 'Helvetica Neue, Bold';
  }
`;

interface CardProps {
  isPar: boolean;
}
export const Card = styled.div<CardProps>`
  width: 100%;
  padding: 9px 16px;
  height: 72px;

  ${({ isPar }) =>
    isPar &&
    css`
      background-color: #e5e5e5;
    `}
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NameProduct = styled.span`
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-size: 14px;
  line-height: 17px;
  width: 190px;
`;
export const CardInfo = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-end;
`;

export const Value = styled.span`
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-size: 14px;
  line-height: 17px;
`;
export const Qtd = styled.span`
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-size: 14px;
  line-height: 17px;
`;
export const Ean = styled.span`
  color: #666666;
  font-family: Helvetica Neue, Regular;
  font-size: 13px;
  line-height: 17px;
  margin-top: 9px;
`;

export const FooterContent = styled.div`
  width: 100%;
  padding: 22px 16px 16px 16px;
  display: flex;
  justify-content: space-between;

  span {
    color: #666666;
    font-family: 'Helvetica Neue, Bold';
    font-size: 14px;
    line-height: 17px;
  }

  p {
    color: #666666;
    font-family: 'Helvetica Neue, Bold';
    font-size: 20px;
    line-height: 17px;
  }
`;

export const MoreButton = styled.div`
  border: solid 2px #e0030e;
  border-radius: 6px;
  width: 130px;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  position: relative;
  margin-top: 32px;

  span {
    font-family: Helvetica Neue, Regular;
    color: #df030e !important;
    font-size: 14px;
  }
`;
