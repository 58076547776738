import React, { FC, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from '../Pages/Home';
import List from '../Pages/List/index';
import Details from '../Pages/Details/Sobre';
import Encarte from '../Pages/Details/Encarte';
import Order from '../Pages/Order';
import OrderDetail from '../Pages/OrderDetail';
import { useCookies } from 'react-cookie';
import { DispatchHJ } from '../Utils/Hotjar';
import { PreHome } from '../Pages/PreHome';
import { CupomFiscalPrint } from '../Pages/CupomFiscalPrint';

const Routes: FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);

  useEffect(() => {
    if (cookies.persistentCep) {
      DispatchHJ({ event: 'trigger', payload: 'cliente_localizado' });
    } else {
      DispatchHJ({ event: 'trigger', payload: 'cliente_naolocalizado' });
    }
  }, [cookies]);

  return (
    <Switch>
      <Route path="/" exact component={PreHome} />
      <Route path="/local" exact component={Home} />
      <Route path="/pedidos" exact component={Order} />
      <Route path="/pedidos/detalhes" exact component={OrderDetail} />
      <Route path="/pedidos/cupom-fiscal" exact component={CupomFiscalPrint} />
      {/* <Route path="/list" component={List} /> */}
      <Route path="/list" exact component={List} />
      <Route path="/encarte" exact component={Encarte} />

      <Route path="/loja/:id" exact component={Details} />
      <Route path="/loja/:id/:tab" exact component={Details} />

      <Route path="/:uf" exact component={List} />
      <Route path="/:uf/:cidade" exact component={List} />
      <Route path="/:uf/:cidade/:bairro" exact component={List} />
    </Switch>
  );
};

export default Routes;
