import React, { FC, ReactNode, useCallback } from 'react';
import {
  BoxHeader,
  Title,
  Stores,
  Loader,
  BoxPlaceholder,
  RouteButton,
  MapContainer,
} from '../../styles';
import { WppProps } from '../../../../Utils/ListWpp';
import useWindowDimensions from '../../../../Utils/size';
import LoadingIcon from '../../../../Assets/loading.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import CardStoreV2 from '../../../../Components/CardStoreV2';
import handleClickCardStore from '../../../../Utils/ClickCardStore';
import PlaceholderStores from '../../../../Components/PlaceholderStores';
import RouteIcon from '../../../../Assets/route_icon.png';
import ViewStoreButton from '../../../../Components/ViewStoreButton';
import { CoordsInterface } from '../..';
import GoogleMaps from '../../../../Components/GoogleMaps';

interface MapModeProps {
  hasMore: boolean;
  TypeList: boolean;
  loadingFirst: boolean;
  activeButton: 'mapa' | 'lista';
  myList: any;
  makeFilter: (type?: 'mapaMobile') => ReactNode;
  makeWppButton: ReactNode;
  totalElements: number;
  currentLegions: any[];
  makeAddress: (data?: any) => any;
  storeSelected: any;
  selectedStore: any;
  isPar: boolean;
  handleDetails: (id: string, item: any, interaction?: string) => void;
  listWpp: WppProps;
  cookies: any;
  handleBuy: (id: string, item: any) => void;
  load: (data: any) => Promise<any>;
  currentPag: number;
  filter: 'all' | 'local' | 'normal';
  parsed: any;
  dadosLocal: any[];
  dados: any[];
  makeCardStore: (data: any, i: any, style?: any) => ReactNode;
  handleGeoLocation: (
    type: 'maps' | 'browser' | 'waze' | 'mapsBrowser'
  ) => void;
  visibleButtonMap: boolean;
  handleAnalytics: () => void;
  coordsTmpCenter: CoordsInterface;
  coordsCenter: CoordsInterface;
  pinsList: any[];
  handleDrag: (data?: any) => void;
  handleChangeMap: (data?: any) => void;
  handlePinClick: (data?: any) => void;
  handleZoom: (data?: any) => void;
  MapRef: any;
  changeState: (value: boolean) => void;
}

const MapMode: FC<MapModeProps> = ({
  loadingFirst,
  activeButton,
  myList,
  makeFilter,
  makeWppButton,
  totalElements,
  currentLegions,
  makeAddress,
  storeSelected,
  selectedStore,
  isPar,
  handleDetails,
  listWpp,
  cookies,
  handleBuy,
  load,
  currentPag,
  filter,
  parsed,
  dadosLocal,
  dados,
  makeCardStore,
  TypeList,
  hasMore,
  handleGeoLocation,
  visibleButtonMap,
  handleAnalytics,
  coordsTmpCenter,
  pinsList,
  coordsCenter,
  handleDrag,
  handleChangeMap,
  handlePinClick,
  handleZoom,
  MapRef,
  changeState,
}) => {
  const isMobile = useWindowDimensions();

  const handleClickViewStoreButton = useCallback(() => {
    load({
      pag: 1,
      lat: String(coordsTmpCenter.lat),
      lng: String(coordsTmpCenter.lng),
    });
    setTimeout(() => {
      handleAnalytics();
    }, 4000);
  }, [coordsTmpCenter, handleAnalytics, load]);

  return (
    <div style={{ width: '100%', maxWidth: 1280, margin: 'auto' }}>
      <BoxHeader style={{ marginBottom: 0 }}>
        {!!myList.length && (
          <>
            {!!myList.length && makeFilter()}
            <div style={{ marginLeft: 'auto' }}>{makeWppButton}</div>
          </>
        )}
      </BoxHeader>
      {!!myList.length && (
        <Title style={{ marginTop: 24, marginBottom: 24 }}>
          {totalElements} Americanas perto de você
        </Title>
      )}

      <div style={{ display: 'flex', width: '100%', maxWidth: 1280 }}>
        <div>
          <Stores isMap={TypeList} className="on-scrollbar" isPar={isPar}>
            {loadingFirst ? (
              <div
                style={{
                  width: 439,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Loader bottom={null} src={LoadingIcon} />
              </div>
            ) : (
              !!myList.length && (
                <InfiniteScroll
                  className="on-scrollbar"
                  dataLength={myList.length}
                  height={781}
                  next={() =>
                    load({
                      pag: currentPag + 1,
                      lat: String(parsed.lat),
                      lng: String(parsed.lng),

                      local: filter === 'local',
                    })
                  }
                  initialScrollY={0}
                  hasMore={hasMore}
                  loader={<Loader src={LoadingIcon} size="50px" bottom="0px" />}
                  endMessage={<Title size="16px">Não existem mais lojas</Title>}
                  refreshFunction={() =>
                    load({
                      pag: 1,
                      lat: String(parsed.lat),
                      lng: String(parsed.lng),

                      local: filter === 'local',
                    })
                  }
                  pullDownToRefreshThreshold={50}
                  pullDownToRefreshContent={
                    <h3 style={{ textAlign: 'center' }}>
                      &#8595; Puxe para recarregar
                    </h3>
                  }
                  releaseToRefreshContent={
                    <h3 style={{ textAlign: 'center' }}>
                      &#8593; Release to refresh
                    </h3>
                  }
                >
                  {myList.length &&
                    myList.map((i: any, index: any) => (
                      <CardStoreV2
                        currentLegions={currentLegions}
                        variant="NEW"
                        isMap={activeButton}
                        key={i.id}
                        title={i.name}
                        address={makeAddress(i)}
                        distance={i.distance}
                        isPar={isPar}
                        index={index}
                        schedules={i.schedules}
                        sellerStoreId={i.sellerStoreId}
                        canPickUpInStore={i.canPickUpInStore}
                        canShipFromStore={i.canShipFromStore}
                        selected={
                          i.id === storeSelected ||
                          i.sellerStoreId === selectedStore ||
                          i.sellerStoreId === `L${selectedStore}`
                        }
                        onClickCard={() =>
                          handleDetails(i.sellerStoreId, i, 'storecard')
                        }
                        onClickProducts={() =>
                          handleDetails(i.sellerStoreId, i)
                        }
                        onClickWpp={() =>
                          handleClickCardStore({
                            item: i,
                            type: 'whatsapp',
                            wppFromWeb: listWpp,
                            isMobile,
                            cookies,
                          })
                        }
                        item={i}
                        onClickBuy={() => handleBuy(i.sellerStoreId, i)}
                      />
                    ))}
                </InfiniteScroll>
              )
            )}

            {!loadingFirst && !myList.length && (
              <BoxPlaceholder
                style={{
                  margin: 0,
                  width: 433,
                  justifyContent: 'flex-start',
                }}
              >
                <PlaceholderStores
                  isMobile={isMobile}
                  local={!!parsed.local}
                  containerStyle={{
                    paddingTop: 0,
                    paddingBottom: 50,
                  }}
                  map
                />

                {parsed.local && !dadosLocal.length && (
                  <div
                    style={{
                      marginTop: 24,
                    }}
                  >
                    <Title
                      style={{
                        marginBottom: 14,
                        marginLeft: 14,
                        height: 'auto',
                      }}
                      size="14px"
                    >{`Mas aproveite tem ${
                      dados.length > 2 ? 2 : dados.length
                    } Americanas perto de você`}</Title>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {dados.map((i: any, index: any) => {
                        if (index < 2) {
                          return makeCardStore(i, index, {
                            marginBottom: 14,
                          });
                        }
                      })}
                    </div>
                  </div>
                )}
              </BoxPlaceholder>
            )}
          </Stores>
        </div>

        <div
          style={{
            width: '100%',
            marginLeft: 27,
            position: 'relative',
          }}
        >
          <RouteButton onClick={() => handleGeoLocation('mapsBrowser')}>
            <img src={RouteIcon} />
            <span>traçar rota</span>
          </RouteButton>
          <MapContainer>
            {visibleButtonMap && (
              <ViewStoreButton
                styleContainer={{
                  position: 'absolute',
                  top: 40,
                  left: '35%',
                }}
                isMobile={isMobile}
                onClick={handleClickViewStoreButton}
              />
            )}
            <GoogleMaps
              loading={loadingFirst}
              width="100%"
              height="782px"
              list={pinsList}
              coordsCenter={coordsCenter}
              zoom={15}
              handleDrag={handleDrag}
              handleChangeMap={handleChangeMap}
              handlePinClick={handlePinClick}
              selectedStore={selectedStore}
              handleZoom={handleZoom}
              ref={MapRef}
              changeState={changeState}
            />
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export { MapMode };
