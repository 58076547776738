/* eslint-disable no-self-assign */
/* eslint-disable no-fallthrough */
import ReactGA from 'react-ga';
import Credencials from '../Utils/Config';
declare const window: any;
ReactGA.initialize(Credencials.gaId);

interface StoreProps {
  name?: string;
  address?: string;
  open?: boolean;
  schedules?: any;
  canShipFromStore?: boolean;
  canPickUpInStore?: boolean;
  sellerStoreId: string | number;
  geolocation?: {
    lat: number;
    lng: number;
  };
}
interface PageViewParams {
  query: any;
  cookies: any;
  name: string;
  isMobile: boolean;
  store?: StoreProps;
}
interface PageEventsParams {
  isMobile: boolean;
  eventAction: string | undefined;
  eventCategory: string | undefined;
  eventLabel: string | undefined;
  eventValue: string | undefined;
  store?: StoreProps;
  cookies: any;
}

interface TestABParams {
  isMobile: boolean;
  experimentId: string | number;
  variant: string;
  cookies: any;
  store?: StoreProps;
}

const eventHandled = (e: any) => {
  console.log('disparou o evento: ', e);
};

export const handleStoreID = (id: string | number): string | number => {
  let x = String(id);

  switch (x.length) {
    case 1:
      x = `L00${x}`;
      break;
    case 2:
      x = `L0${x}`;
      break;
    case 3:
      x = `L${x}`;
      break;
    case 4:
      x = x;
    default:
      x = x;
      break;
  }

  return x;
};

const getEpar = (
  type: 'midia' | 'formato' | 'subformato' | 'parceiro' | 'campanha',
  epar: string
): string | undefined | string[] => {
  // bp_br_00_go_sch_brand_americanas_202002
  // midia = bp
  // formato = br
  // subformato = 00
  // parceiro = go
  // campanha = sch_brand_americanas_202002

  const eparTest = 'bp_pl_00_go_pc_gamer';
  let x;

  if (!epar) return;

  const dados = epar.split('_');
  const midia = dados[0];
  const formato = dados[1];
  const subformato = dados[2];
  const parceiro = dados[3];

  if (type === 'midia') {
    // x = midia === '00' ? undefined : midia;
    x = midia;
  }

  if (type === 'formato') {
    // x = formato === '00' ? undefined : formato;
    x = formato;
  }

  if (type === 'subformato') {
    // x = subformato === '00' ? undefined : subformato;
    x = subformato;
  }

  if (type === 'parceiro') {
    // x = parceiro === '00' ? undefined : parceiro;
    x = parceiro === '00' ? undefined : parceiro;
  }

  if (type === 'campanha') {
    let tempCamp = dados.filter((_: any, index: number) => index > 3);
    tempCamp = tempCamp.reduce((acc: any, curr: any, index: number) => {
      if (index === 0) {
        return curr;
      }
      acc = index === tempCamp.length - 1 ? `${acc}_${curr}` : `${acc}_${curr}`;

      return acc;
    }, '');

    x = tempCamp;
  }

  return x;
};

export const HandleTestAB = ({
  isMobile,
  experimentId,
  variant,
  cookies,
  store,
}: TestABParams): void => {
  const payload: any = {
    event: 'experiment_exposure',
    eventCategory: 'teste-ab',
    action: 'experiment_exposure',
    AppID: isMobile ? 'MOBILESITE' : 'NAOMOBILE',
    ABid: experimentId,
    ABvariant: variant,
    eventAction: 'experiment_exposure',
    eventLabel: undefined,
    eventValue: undefined,
    'b2w-pid': cookies['B2W-PID'] || undefined,
    'b2w-uid': cookies['B2W-UID'] || undefined,
    page: document.location.pathname,
    url: window.location.href,
    pagetype: 'NossasLojas',
    title: document.title,
  };

  if (store) {
    payload['O2OStoreID'] = handleStoreID(store.sellerStoreId);
    payload['O2OStoreName'] = store.name;
  }

  window.dataLayer.push(payload);
  console.log('Analytics teste A/B Enviado');
};

export const HandlePageView = ({
  query,
  cookies,
  name,
  isMobile,
  store,
}: PageViewParams): void => {
  const payload: any = {
    event: 'pageview_nossaslojas',
    'b2w-uid': cookies['B2W-UID'] || undefined,
    page: window.top.location.pathname,
    title: window.top.document.title,
    url: window.top.location.href,
    AppID: isMobile ? 'MOBILESITE' : 'NAOMOBILE',
    epar: cookies['b2wEPar'] || query['EPar'] || undefined,
    midia:
      cookies['b2wEPar'] || query['EPar']
        ? getEpar('midia', cookies['b2wEPar'] || query['EPar'])
        : undefined,
    formato:
      cookies['b2wEPar'] || query['EPar']
        ? getEpar('formato', cookies['b2wEPar'] || query['EPar'])
        : undefined,
    subformato:
      cookies['b2wEPar'] || query['EPar']
        ? getEpar('subformato', cookies['b2wEPar'] || query['EPar'])
        : undefined,
    parceiro:
      cookies['b2wEPar'] || query['EPar']
        ? getEpar('parceiro', cookies['b2wEPar'] || query['EPar'])
        : undefined,
    campanha:
      cookies['b2wEPar'] || query['EPar']
        ? getEpar('campanha', cookies['b2wEPar'] || query['EPar'])
        : undefined,
    gclid: query['gclid'] || undefined,
    chave: query['chave'] || undefined,
    pagetype: 'NossasLojas',
    marca: 'ACOM',
    opn: cookies['b2wOpn'] || query['opn'] || undefined,
    franq: cookies['b2wFranq'] || query['b2wFranq'] || undefined,
    b2wRegion: cookies['b2wRegion'] || undefined,
    'versão-site': 'Responsivo',
    afpid: query['afpid'] || undefined,
    'b2w-pid': cookies['B2W-PID'] || undefined,
    'b2w-sid': cookies['B2W-SID'] || undefined,
    b2wChannel: cookies['b2wChannel'] || undefined,
    pagename: name,
    cfinance: cookies['c_finance'] || undefined,
    cep: cookies['persistentCep'] || undefined,
    region:
      cookies['mesoRegion'] && cookies['macroRegion'] && cookies['legionRegion']
        ? `${cookies['mesoRegion']}, ${cookies['macroRegion']}, ${cookies['legionRegion']} `
        : undefined,
    'gtm.start': new Date().getTime(),
  };

  if (store) {
    payload['O2OStoreID'] = handleStoreID(store.sellerStoreId);
    payload['O2OStoreName'] = store.name;
  }

  console.log('Analytics payload pageView: ', payload);
  window.dataLayer.push(payload);
  console.log('Analytics Enviado');
};

export const HandleEvents = ({
  isMobile,
  eventAction,
  eventCategory,
  eventLabel,
  eventValue,
  store,
  cookies,
}: PageEventsParams): void => {
  const payload: any = {
    'b2w-uid': cookies['B2W-UID'],
    'b2w-pid': cookies['B2W-PID'] || undefined,
    AppID: isMobile ? 'MOBILESITE' : 'NAOMOBILE',
    event: 'event_nossaslojas',
    eventAction: eventAction || undefined,
    eventCategory: eventCategory || undefined,
    eventLabel: eventLabel || undefined,
    eventValue: eventValue || undefined,
    page: window.top.location.pathname,
    pagetype: 'NossasLojas',
    title: document.title,
    url: window.top.location.href,
  };

  if (store) {
    payload['O2OStoreID'] = handleStoreID(store.sellerStoreId);
    payload['O2OStoreName'] = store.name;
  }

  console.log('Analytics payload: ', payload);

  window.dataLayer.push(payload);
  console.log('Analytics Enviado');
};
