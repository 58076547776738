import React, { FC, ReactNode } from 'react';
import {
  ListaBox,
  Loader,
  BoxHeader,
  Title,
  Warning,
  Cards,
} from '../../styles';
import LoadingIcon from '../../../../Assets/loading.svg';
import useWindowDimensions from '../../../../Utils/size';
import { ReactComponent as IconWarning } from '../../../../Assets/info.svg';
import CardStoreV2 from '../../../../Components/CardStoreV2';
import handleClickCardStore from '../../../../Utils/ClickCardStore';
import Paginate from '../../../../Components/Paginate';
import { WppProps } from '../../../../Utils/ListWpp';
import PlaceholderStores from '../../../../Components/PlaceholderStores';

interface ListModeProps {
  loadingFirst: boolean;
  activeButton: 'mapa' | 'lista';
  myList: any;
  makeFilter: (type?: 'mapaMobile') => ReactNode;
  makeWppButton: ReactNode;
  totalElements: number;
  existsCovid: boolean;
  currentLegions: any[];
  makeAddress: (data?: any) => any;
  storeSelected: any;
  selectedStore: any;
  isPar: boolean;
  handleDetails: (id: string, item: any, interaction?: string) => void;
  listWpp: WppProps;
  cookies: any;
  handleBuy: (id: string, item: any) => void;
  load: (data: any) => Promise<any>;
  currentPag: number;
  totalPag: number;
  filter: 'all' | 'local' | 'normal';
  comparativeList: any[];
  pagAppend: number;
  parsed: any;
  dadosLocal: any[];
  dados: any[];
  makeCardStore: (data: any, i: any) => ReactNode;
}

const ListMode: FC<ListModeProps> = ({
  loadingFirst,
  activeButton,
  myList,
  makeFilter,
  makeWppButton,
  totalElements,
  existsCovid,
  currentLegions,
  makeAddress,
  storeSelected,
  selectedStore,
  isPar,
  handleDetails,
  listWpp,
  cookies,
  handleBuy,
  load,
  currentPag,
  totalPag,
  filter,
  comparativeList,
  pagAppend,
  parsed,
  dadosLocal,
  dados,
  makeCardStore,
}) => {
  const isMobile = useWindowDimensions();
  return (
    <ListaBox>
      {loadingFirst ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 100,
          }}
        >
          <Loader src={LoadingIcon} />
        </div>
      ) : (
        !loadingFirst && (
          <>
            <BoxHeader>
              {!!myList.length && makeFilter()}
              <div style={{ marginLeft: 'auto' }}>
                {!isMobile && makeWppButton}
              </div>
            </BoxHeader>

            {!!myList.length && (
              <Title style={{ marginTop: 24, marginBottom: 24 }}>
                {totalElements} Americanas perto de você
              </Title>
            )}

            {myList.length ? (
              <>
                {existsCovid && (
                  <Warning>
                    <IconWarning />
                    <span>
                      Temporariamente, algumas lojas encontram-se fechadas para
                      a visita. Aproveite e compre online!
                    </span>
                  </Warning>
                )}

                <Cards>
                  {myList.map((i: any, index: any) => (
                    <CardStoreV2
                      currentLegions={currentLegions}
                      variant="NEW"
                      isMap={activeButton}
                      key={i.id}
                      title={i.name}
                      address={makeAddress(i)}
                      distance={i.distance}
                      isPar={isPar}
                      index={index}
                      schedules={i.schedules}
                      sellerStoreId={i.sellerStoreId}
                      canPickUpInStore={i.canPickUpInStore}
                      canShipFromStore={i.canShipFromStore}
                      selected={
                        i.id === storeSelected ||
                        i.sellerStoreId === selectedStore ||
                        i.sellerStoreId === `L${selectedStore}`
                      }
                      onClickCard={() =>
                        handleDetails(i.sellerStoreId, i, 'storecard')
                      }
                      onClickProducts={() => handleDetails(i.sellerStoreId, i)}
                      onClickWpp={() =>
                        handleClickCardStore({
                          item: i,
                          type: 'whatsapp',
                          wppFromWeb: listWpp,
                          isMobile,
                          cookies,
                        })
                      }
                      item={i}
                      onClickBuy={() => handleBuy(i.sellerStoreId, i)}
                    />
                  ))}

                  <Paginate
                    loadFunction={load}
                    currentPag={currentPag}
                    total={totalPag}
                    local={filter === 'local'}
                    all={filter === 'all'}
                    comparativeList={comparativeList}
                    pagAppend={pagAppend}
                    filter={filter}
                  />
                </Cards>
              </>
            ) : (
              <>
                <PlaceholderStores local={!!parsed.local} />

                {parsed.local && !dadosLocal.length && (
                  <div
                    style={{
                      marginTop: 35,
                    }}
                  >
                    <Title style={{ marginBottom: 13.5 }}>{`Mas aproveite tem ${
                      dados.length > 3 ? 3 : dados.length
                    } Americanas perto de você`}</Title>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {dados.map((i: any, index: any) => {
                        if (index < 3) {
                          return makeCardStore(i, index);
                        }
                      })}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )
      )}
    </ListaBox>
  );
};

export { ListMode };
