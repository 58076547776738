import styled from 'styled-components';

interface ContainerProps {
  isMobile?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  margin-top: ${(props) => (props.isMobile ? '0px' : '24px')};
  border-radius: 5px;
  margin-bottom: ${(props) => (props.isMobile ? '0px' : '44px')};
`;
