import api from '../Services/api';
import Config from './Config';

export interface CatalogProps {
  type: 'pdf' | 'jpg';
  catalog: string | undefined;
}

export const getCatalog = async (): Promise<CatalogProps | undefined> => {
  try {
    const { data } = await api.get(Config.catalogSpacey);

    const catalogLinkPdf = data.component.children[0].children[0].balloonsTitle;
    const catalogLinkJpg = data.component.children[0].children[0].linkUrl;
    // console.log('catalogLink PDF: ', catalogLinkPdf);
    // console.log('catalogLink JPG: ', catalogLinkJpg);
    let catalogLink = catalogLinkPdf || catalogLinkJpg;

    catalogLink = catalogLink.includes('com://webview/')
      ? catalogLink.split('com://webview/')[1]
      : catalogLink;

    const searchRegExp = /(?:https?:\/\/)|(?:https?\/\/)/;
    catalogLink = catalogLink.replace(searchRegExp, '');
    // console.log('catalogLink Final: ', catalogLink);

    if (catalogLink) {
      return {
        catalog: `https://${catalogLink}`,
        type: catalogLinkPdf ? 'pdf' : 'jpg',
      };
    }

    return undefined;
  } catch (error) {
    console.log('error getCatalog: ', error);
  }
};
