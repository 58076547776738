import React, { FC, ReactNode } from 'react';
import useWindowDimensions from '../../../../Utils/size';
import {
  FakeBoxShadow,
  ContentMapMobile,
  MapContainer,
  WrapperStoresMobile,
  WhatsSolidButton,
  Loader,
} from '../../styles';
import OwlCarousel from 'react-owl-carousel';
import { isChrome, isIOS } from 'react-device-detect';
import GoogleMaps from '../../../../Components/GoogleMaps';
import { WppProps } from '../../../../Utils/ListWpp';
import LoadingIcon from '../../../../Assets/loading.svg';
import { CoordsInterface } from '../..';
import CardStoreV2 from '../../../../Components/CardStoreV2';
import PlaceholderStores from '../../../../Components/PlaceholderStores';
import handleClickCardStore from '../../../../Utils/ClickCardStore';

interface Props {
  loadingFirst: boolean;
  activeButton: 'mapa' | 'lista';
  myList: any[];
  makeFilter: () => ReactNode;
  parsed: any;
  dadosLocal: any[];
  dados: any[];
  makeCardStore: (a: any, b: any, c?: any) => ReactNode;
  existsCovid: boolean;
  totalElements: number;
  makeWppButton: ReactNode;
  tooltipWhatsView: boolean;
  listWpp: WppProps;
  changeState: (data: boolean) => void;
  loading: boolean;
  hasMore: boolean;
  TypeList: boolean;
  isPar: boolean;
  currentLegions: any;
  storeSelected: any;
  selectedStore: any;
  makeAddress: (data: any) => string;
  handleDetails: (a: any, b: any, c?: any) => void;
  cookies: any;
  handleBuy: (data?: any, b?: any) => void;
  totalPag: number;
  load: (data?: any) => Promise<any>;
  currentPag: number;
  filter: 'all' | 'normal' | 'local';

  coordsCenter: CoordsInterface;
  pinsList: any[];
  handleDrag: (data?: any) => void;
  handleChangeMap: (data?: any) => void;
  handlePinClick: (data?: any) => void;
  handleZoom: (data?: any) => void;
  MapRef: any;
  handleGeoLocation: (
    type: 'maps' | 'browser' | 'waze' | 'mapsBrowser'
  ) => void;
  showNavigationOptions: boolean;
  makeRouteButton: ReactNode;
  startPositionCarousel: number;
  eventChangeCarousel: (data: any) => void;
}

const MapMode: FC<Props> = ({
  loadingFirst,
  activeButton,
  myList,
  makeFilter,
  parsed,
  dadosLocal,
  dados,
  makeCardStore,
  existsCovid,
  totalElements,
  makeWppButton,
  tooltipWhatsView,
  listWpp,
  changeState,
  loading,
  hasMore,
  TypeList,
  isPar,
  currentLegions,
  storeSelected,
  selectedStore,
  makeAddress,
  handleDetails,
  cookies,
  handleBuy,
  totalPag,
  load,
  currentPag,
  filter,
  pinsList,
  coordsCenter,
  MapRef,
  handleChangeMap,
  handleDrag,
  handlePinClick,
  handleZoom,
  handleGeoLocation,
  showNavigationOptions,
  makeRouteButton,
  startPositionCarousel,
  eventChangeCarousel,
}) => {
  const isMobile = useWindowDimensions();
  return (
    <>
      <FakeBoxShadow type="up" />

      <ContentMapMobile>
        <MapContainer isMobile={isMobile}>
          <GoogleMaps
            loading={loading}
            width="100%"
            height="100%"
            list={pinsList}
            coordsCenter={coordsCenter}
            handlePinClick={handlePinClick}
            handleDrag={handleDrag}
            handleZoom={handleZoom}
            handleChangeMap={handleChangeMap}
            ref={MapRef}
            zoom={15}
            changeState={changeState}
          />
        </MapContainer>

        <WrapperStoresMobile
          isChrome={isChrome}
          isIOS={isIOS}
          value={isIOS ? window.innerHeight * 0.62 : window.innerHeight * 0.7}
        >
          <>
            {!loading && (
              <>
                {showNavigationOptions && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      marginBottom: 16,
                    }}
                  >
                    <WhatsSolidButton
                      isMobile={isMobile}
                      onClick={() => handleGeoLocation('browser')}
                    >
                      <span>Navegador</span>
                    </WhatsSolidButton>
                    <WhatsSolidButton
                      isMobile={isMobile}
                      onClick={() => handleGeoLocation('maps')}
                    >
                      <span>Maps</span>
                    </WhatsSolidButton>
                    <WhatsSolidButton
                      isMobile={isMobile}
                      onClick={() => handleGeoLocation('waze')}
                    >
                      <span>Waze</span>
                    </WhatsSolidButton>
                  </div>
                )}

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    marginBottom: 16,
                  }}
                >
                  {makeRouteButton}
                  {makeWppButton}
                </div>
              </>
            )}

            {loading ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <Loader src={LoadingIcon} />
              </div>
            ) : myList.length > 0 ? (
              <OwlCarousel
                key={`carousel_${myList[0].id}`}
                className="owl-theme"
                // margin={window.innerWidth * 0.05}
                // stagePadding={window.innerWidth * 0.07}
                nav={false}
                items={1}
                dots={false}
                onChange={(e: any) => {
                  eventChangeCarousel(e);
                  // changeStoreSelected(myList, e);
                }}
                startPosition={startPositionCarousel}
              >
                {myList.length &&
                  myList.map((i: any, index: any) => (
                    <CardStoreV2
                      containerStyle={{
                        width: '100%',
                      }}
                      currentLegions={currentLegions}
                      variant="NEW"
                      isMap={activeButton}
                      key={i.id}
                      title={i.name}
                      address={makeAddress(i)}
                      distance={i.distance}
                      isPar={isPar}
                      index={index}
                      schedules={i.schedules}
                      sellerStoreId={i.sellerStoreId}
                      canPickUpInStore={i.canPickUpInStore}
                      canShipFromStore={i.canShipFromStore}
                      carousel={true}
                      selected={
                        i.id === storeSelected ||
                        i.sellerStoreId === selectedStore ||
                        i.sellerStoreId === `L${selectedStore}`
                      }
                      onClickCard={() =>
                        handleDetails(i.sellerStoreId, i, 'storecard')
                      }
                      onClickProducts={() => handleDetails(i.sellerStoreId, i)}
                      onClickWpp={() =>
                        handleClickCardStore({
                          item: i,
                          type: 'whatsapp',
                          wppFromWeb: listWpp,
                          isMobile,
                          cookies,
                        })
                      }
                      item={i}
                      onClickBuy={() => handleBuy(i.sellerStoreId, i)}
                    />
                  ))}
              </OwlCarousel>
            ) : (
              <PlaceholderStores isMobile={isMobile} local={!!parsed.local} />
            )}
          </>
        </WrapperStoresMobile>
      </ContentMapMobile>
    </>
  );
};

export { MapMode };
