import React, { FC, useCallback, useState } from 'react';
import { Container, Content, Box } from './styles';
import ModalTooltip from '../ModalTooltip';
import useWindowDimensions from '../../Utils/size';
import { ActionsProps } from '../Legends';
import { useHistory } from 'react-router-dom';
import { HandleEvents } from '../../Utils/AnalyticsGA';
import { useCookies } from 'react-cookie';
import { HandleClickMenus } from '../../Utils/HandleClickMenu';

interface TooltipProps {
  title: string;
  className?: string;
  bottomCalc: string;
  mNegativeLeft: string;
  mLeft: string;
  link?: string;
  actions?: ActionsProps[];
  sizeFakeBox?: string;
}

const Tooltip: FC<TooltipProps> = ({
  title,
  className = '',
  bottomCalc,
  mNegativeLeft,
  mLeft,
  link,
  children,
  actions,
  sizeFakeBox,
}) => {
  const [cookies, setCookie, removeCookie] = useCookies<any>(['customer.id']);

  const [modalVisible, setModalVisible] = useState(false);
  const isMobile = useWindowDimensions();
  const history = useHistory();

  const handleClick = useCallback(
    (
      uri: string,
      type: 'logo' | 'minhaconta' | 'favoritos' | 'cesta' | undefined
    ) => {
      if (type) {
        HandleClickMenus({ uri, type, isMobile, cookies });
      }

      return;
      window.location.replace(uri);
    },
    [isMobile, cookies]
  );

  const handleClickAction = useCallback(
    (type: 'route' | 'open' | 'function', linkBtn: string) => {
      if (type === 'open') {
        window.location.href = linkBtn;
      } else if (type === 'route') {
        history.push(linkBtn);
      }
    },
    [history]
  );

  const makeItem = useCallback(
    (i: any, index: number) => {
      if (i.renderItem) {
        return (
          <Box
            // style={{ marginTop: index > 0 ? 30 * index : 0 }}
            onClick={() => handleClickAction(i.linkType, i.link)}
          >
            {i.renderItem()}
          </Box>
        );
      } else if (i.type) {
        return (
          <Box onClick={() => handleClick(i.link, i.type)}>
            <span>{i.label}</span>
          </Box>
        );
      } else {
        return (
          <Box>
            <a href={i.link || '#'}>{i.label}</a>
          </Box>
        );
      }
    },
    [handleClickAction, handleClick]
  );

  return (
    <div style={{ position: 'relative' }}>
      {/* <FakeArea
      // onMouseOver={() => setModalVisible(true)}
      // onMouseLeave={() => setModalVisible(false)}
      /> */}

      <Container
        sizeFakeBox={sizeFakeBox}
        className={className}
        isMobile={isMobile}
        onMouseOver={() => setModalVisible(true)}
        onMouseLeave={() => setModalVisible(false)}
      >
        <div className="popover__wrapper">
          <a href="#">{children}</a>

          {modalVisible && (
            <>
              <div className="popover__content">
                <Content
                  bottomCalc={bottomCalc}
                  mNegativeLeft={mNegativeLeft}
                  mLeft={mLeft}
                  actions={!!actions}
                >
                  {/* {modalVisible && */}
                  {modalVisible &&
                    actions?.map(
                      (i: ActionsProps, index: number) => makeItem(i, index)

                      // !actions?.length && <Box><a href={link || '#'}>{title}</a></Box>
                    )}
                </Content>
              </div>
            </>
          )}
        </div>
      </Container>

      <ModalTooltip visible={modalVisible} />
    </div>
  );
};

export default Tooltip;
