import styled, { css } from 'styled-components';

interface LoaderProps {
  size?: string;
  bottom?: string | null;
}

export const Loader = styled.img<LoaderProps>`
  width: ${(props) => props.size || '80px'};
  height: ${(props) => props.size || '80px'};
  margin: auto;
`;

export const LoaderBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface ContainerProps {
  isMobile: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      /* margin-top: 80px !important; */
    `}

  /* svg path {
    fill: #000000;
  } */

  .active {
    border: 0 !important;
  }

  .owl-prev {
    border: 0 !important;
    width: 82px !important;
    height: 82px !important;
    background: #ffffffcc 0% 0% no-repeat padding-box !important;
    border-radius: 50% !important;
    color: #ef1324 !important;
  }

  .owl-next {
    border: 0 !important;
    width: 82px !important;
    height: 82px !important;
    background: #ffffffcc 0% 0% no-repeat padding-box !important;
    border-radius: 50% !important;
    color: #ef1324 !important;
  }

  .disabled {
    opacity: 0 !important;
  }

  .owl-carousel {
    ${({ isMobile }) =>
      isMobile &&
      css`
        /* background-color: #f8f8f8 !important; */
      `}
  }

  .owl-dots {
    margin-top: 118px;
  }

  .owl-dots span {
    width: 30px !important;
    height: 10px !important;
    border-radius: 0px !important;
  }

  .owl-dot.active span {
    background-color: #ff0000 !important;
  }

  .owl-nav {
    margin-top: -190px;

    ${({ isMobile }) =>
      !isMobile &&
      css`
        margin-bottom: 110px !important;
      `}
    position: relative;
    z-index: 999999999;
    width: 100%;
    max-width: 1250px;
    /* margin-left: calc((100vw - 1280px) / 2); */
    display: flex;
    justify-content: space-between;
  }

  .truncate-overflow {
    /* max-width: 252px; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
  }

  .fb-page {
    width: 100%;
  }
  .my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */

    /* margin-bottom: 30px; */
  }

  #container {
    width: 100%;

    margin: 2em auto;
  }
  .cols {
    -moz-column-count: 3;
    -moz-column-gap: 3%;
    -moz-column-width: 30%;
    -webkit-column-count: 3;
    -webkit-column-gap: 3%;
    -webkit-column-width: 30%;
    column-count: 3;
    column-gap: 3%;
    column-width: 30%;
  }
  .box {
    margin-bottom: 20px;
  }
  .box.one {
    height: 200px;
    background-color: #d77575;
  }
  .box.two {
    height: 300px;
    background-color: #dcbc4c;
  }
  .box.three {
    background-color: #a3ca3b;
    height: 400px;
  }
  .box.four {
    background-color: #3daee3;
    height: 500px;
  }
  .box.five {
    background-color: #bb8ed8;
    height: 600px;
  }
  .box.six {
    background-color: #baafb1;
    height: 200px;
  }

  .row.make-columns {
    -moz-column-width: 19em;
    -webkit-column-width: 19em;
    -moz-column-gap: 1em;
    -webkit-column-gap: 1em;
  }

  .row.make-columns > div {
    display: inline-block;
    padding: 0.5rem;
    width: 100%;
  }

  /* demo only* */
  .panel {
    display: inline-block;
    height: 100%;
    width: 100%;
  }
`;

interface BoxTitleFeedProps {
  isMobile: boolean;
}
export const BoxTitleFeed = styled.div<BoxTitleFeedProps>`
  /* background-color: ${({ isMobile }) =>
    isMobile ? '#f8f8f8' : '#FFFFFF'}; */
  padding-top: 33px;
  padding-bottom: 21px;
`;
export const BoxMap = styled.div`
  position: relative;
  width: 100%;
  margin-top: 12px;
  z-index: 9;
`;

interface BoxFeedProps {
  isMobile: boolean;
}

export const BoxFeed = styled.div<BoxFeedProps>`
  position: relative;
  z-index: 9;
  ${(props) =>
    props.isMobile &&
    css`
      /* text-align: center; */
      padding: 0 15px;
    `}
`;

interface TitleFeedProps {
  isMobile: boolean;
}

export const TitleFeed = styled.span<TitleFeedProps>`
  font-family: 'Helvetica Neue, Bold';
  font-weight: bold;
  font-size: 22px;
  color: #4e4e4e;

  margin-top: 32px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-left: 20px;
      margin-top: 16px;
    `}
`;

interface PostsProps {
  isMobile: boolean;
}

export const Posts = styled.div<PostsProps>`
  /* display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  flex-wrap: wrap;
  flex-flow: wrap;
  margin-top: 38px; */
  width: 100%;

  /* -moz-column-count:3;
  -moz-column-gap: 3%;
  -moz-column-width: 30%;
  -webkit-column-count:3;
  -webkit-column-gap: 3%;
  -webkit-column-width: 30%; */

  column-count: 3;
  column-gap: 3%;
  column-width: 30%;

  ${(props) =>
    props.isMobile &&
    css`
      justify-content: center;
    `}
`;

interface CardPostProps {
  isMultiplo: boolean;
  isMobile: boolean;
  whitoutImg?: boolean;
}

export const CardPost = styled.div<CardPostProps>`
  border-radius: 5px;
  align-content: stretch;
  width: 100%;
  max-width: 400px;
  ${(props) =>
    !props.whitoutImg &&
    css`
      min-height: 486px;
    `}

  margin-right: 40px;
  margin-bottom: 25px;
  cursor: pointer;

  ${(props) =>
    props.isMultiplo &&
    css`
      margin-right: 0px !important;
    `}
`;

interface PhotoProps {
  bg: string;
}

export const Photo = styled.div<PhotoProps>`
  width: 100%;
  max-width: 400px;
  height: 400px;
  border-radius: 5px 5px 0 0;
  background-image: url(${(props) => props.bg});
  background-position: center;
  position: relative;
  z-index: 100;
  background-repeat: no-repeat;
  background-size: cover;
  border: solid 1px #e0e0e0;
`;

interface PhotoImgProps {
  isMobile: boolean;
}

export const PhotoImg = styled.img<PhotoImgProps>`
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 5px 5px 0 0;
  position: relative;
  z-index: 100;
  border: solid 1px #e0e0e0;
`;
// export const Photo = styled.img`
//   width: 400px;
//   height: 400px;
//   border-radius: 5px 5px 0 0;
//   position: relative;
//   z-index: 100;
// `;

export const Data = styled.span`
  font-family: 'Helvetica Neue, Regular';
  font-size: 14px;
  color: #999999;
`;

export const Description = styled.span`
  font-family: 'Helvetica Neue, Bold';
  font-size: 16px;
  color: #666666;
  font-weight: bold;
`;

interface InfoProps {
  withDescription?: boolean;
  whitoutImg?: boolean;
  isMobile: boolean;
}

export const Info = styled.div<InfoProps>`
  border: solid 1px #e0e0e0;
  border-radius: ${(props) => (props.whitoutImg ? '8px' : '0 0 8px 8px')};
  display: flex;
  flex-direction: column;
  padding: 0px 19px 20px 19px;
  margin-top: ${(props) => (props.isMobile ? '-11px' : '-5px')};
  position: relative;
  z-index: 90;
  text-align: left !important;
  background: white;
`;

interface ButtonArrowProps {
  open?: boolean;
}

export const ButtonArrow = styled.img<ButtonArrowProps>`
  margin: auto;
  width: 9px;
  height: 5px;
  cursor: pointer;
  position: relative;
  margin-top: 20px;
  z-index: 999999;

  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg);
    `}
`;

interface MoreButtonProps {
  isMobile: boolean;
}

export const MoreButton = styled.div<MoreButtonProps>`
  width: ${(props) => (props.isMobile ? '100%' : '400px')};
  height: 38px;
  border: solid 2px ${({ theme }) => theme.colors.redPrimary};
  border-radius: 6px;
  /* padding: 15px 30px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
  margin-top: ${({ isMobile }) => (isMobile ? '-10px' : '-60px')};
  z-index: 999999999999;
  position: relative;
  background-color: #ffffff;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-bottom: 30px;
    `}

  span {
    font-family: 'Helvetica Neue, Bold';
    color: ${({ theme }) => theme.colors.redPrimary};
    font-size: 14px;

    font: -webkit-control;
    font-weight: bold;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.redPrimary};

    span {
      color: #ffffff;
    }
  }
`;

export const FadeBox = styled.div`
  width: 100%;
  height: 177px;
  position: relative;
  z-index: 9999999;
  background: transparent
    linear-gradient(180deg, #ffffff00 0%, #f9f9f9 56%, #f9f9f9 100%) 0% 0%
    no-repeat padding-box;
  margin-top: -147px;
`;

export const Content = styled.div`
  /* background: #f8f8f8; */
  padding: 0px 0px 12px 0px;
  /* border-bottom: 1px solid #f0f0f0; */
`;

// export const Banner = styled.div`
//   width: 100%;
//   display: flex;
//   margin-top: 30px;

//   .fb-page {
//     margin: 0px;
//     padding: 0;
//   }
// `;

export const Frame = styled.iframe`
  width: 100%;
  height: 1250px;
  border: 0;
  overflow: hidden;
`;

interface ContentFbProps {
  isMobile: boolean;
}

export const ContentFb = styled.div<ContentFbProps>`
  border-radius: 17px;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      /* border: solid 1px #e0e0e0; */
      background-color: #fff;
    `}

  margin-top: ${(props) => (props.isMobile ? '24px' : '0px')};
  border-bottom: 0px;
  position: relative;
  z-index: 99;
  /* padding-bottom: 24px; */
`;

interface HeaderFbProps {
  isMobile: boolean;
}

export const HeaderFB = styled.div<HeaderFbProps>`
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 17px;
  padding: ${(props) => (props.isMobile ? '0px' : '9px')};
  margin-top: ${(props) => (props.isMobile ? '17px' : '0px')};

  ${({ isMobile }) =>
    !isMobile &&
    css`
      padding: 28px 24px 0 24px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 0 14px !important;
      padding-top: 21px;
    `}
`;

interface LogoHeaderProps {
  isMobile: boolean;
}

export const LogoHaeder = styled.img<LogoHeaderProps>`
  border-radius: 10px;
  margin-top: ${({ isMobile }) => (isMobile ? '0px' : '0px')};
  width: ${({ isMobile }) => (isMobile ? '51px' : '66px')};
  height: ${({ isMobile }) => (isMobile ? '51px' : '66px')}
    ${({ isMobile }) =>
      isMobile &&
      css`
        margin-left: 7px;
      `};
`;

interface HeaderInfoProps {
  isMobile: boolean;
}

export const HeaderInfo = styled.div<HeaderInfoProps>`
  margin-left: 24px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-left: 7px !important;
    `}
`;

interface TitleFbStoreProps {
  isMobile: boolean;
}

export const TitleFbStore = styled.h1<TitleFbStoreProps>`
  margin: 0;
  padding: 0px;
  color: #666666;
  font-size: ${(props) => (props.isMobile ? '16px' : '20px')};
  font-family: 'Helvetica Neue, Bold';
`;

interface LinkFbProps {
  isMobile: boolean;
}

export const LinkFb = styled.span<LinkFbProps>`
  color: #666666;
  font-size: ${(props) => (props.isMobile ? '14px' : '16px')};
  font-family: 'Helvetica Neue';
  font-weight: 100;
  margin-top: 6px;
`;

interface HeaderInfoPostProps {
  withText?: boolean;
}

export const HeaderInfoPost = styled.div<HeaderInfoPostProps>`
  display: flex;
  align-items: center;
  ${({ withText }) =>
    withText &&
    css`
      margin-bottom: 13px;
    `}

  margin-top: 20px;
`;

export const FbIcon = styled.img`
  width: 20px;
  height: 22px;
  margin-right: 8px;
`;

export const ContainerPhoto = styled.div`
  position: relative;
`;

export const ContentPhoto = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0 0;
`;

export const Play = styled.img`
  width: 59px;
  height: 60px;
`;

interface BannerProps {
  isMobile: boolean;
  hasFb: boolean;
}

export const Banner = styled.img<BannerProps>`
  width: 100%;
  height: ${(props) => (props.isMobile ? '137px' : '220px')};
  cursor: pointer;
  object-fit: contain;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      border-radius: 16px;
    `}

  ${({ hasFb }) =>
    !hasFb &&
    css`
      margin-bottom: 30px;
    `}

    ${({ isMobile }) =>
    isMobile &&
    css`
      object-fit: cover !important;
      border-radius: 8px;
    `}
`;

export const CustomArrow = styled.button`
  border: 0 !important;
  width: 82px !important;
  height: 82px !important;
  background: #ffffffcc 0% 0% no-repeat padding-box !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Nav = styled.div`
  width: 100%;
  max-width: 1250px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;

  position: relative;
  margin-top: -200px;
  z-index: 9;
  margin-bottom: 139px;

  svg {
    width: 34px;
    height: 21px;
    margin-top: 8px;
  }

  svg path {
    fill: #ef1324;
  }

  button {
    z-index: 9;
  }
`;

export const ArrowButtonRight = styled.button`
  border: 0 !important;
  width: 82px !important;
  height: 82px !important;
  background: #ffffffcc 0% 0% no-repeat padding-box !important;
  border-radius: 50% !important;
  color: #ef1324 !important;
  transform: rotate(-90deg);

  z-index: 9;
  position: absolute;
  top: 40%;
  right: 10px;
`;

export const ArrowButtonLeft = styled.button`
  border: 0 !important;
  width: 82px !important;
  height: 82px !important;
  background: #ffffffcc 0% 0% no-repeat padding-box !important;
  border-radius: 50% !important;
  color: #ef1324 !important;
  transform: rotate(90deg);

  z-index: 9;
  position: absolute;
  top: 40%;
  left: 10px;
`;

export const Arrow = styled.img`
  width: 50px;
  height: 50px;
  fill: rgb(230, 0, 20);
`;

export const BoxCarousel = styled.div`
  svg {
    width: 34px;
    height: 21px;
    margin-top: 8px;
  }

  svg path {
    fill: #ef1324;
  }

  button {
    z-index: 9;
  }
`;

export const Barrinha = styled.div`
  width: Calc(100% - 13px - 17px);
  height: 1px;
  background: #dedede;
  margin: auto;
`;

interface BoxSocialProps {
  isMobile?: boolean;
  error?: boolean;
}

export const BoxSocial = styled.div<BoxSocialProps>`
  background-color: #fff;
  border-radius: ${({ isMobile }) => (isMobile ? '0px' : '17px')};

  ${({ error }) =>
    error &&
    css`
      margin-bottom: 50px;
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 0 16px;
      border-radius: 16px;
    `}
`;

export const EncarteBanner = styled.button`
  margin: 0;
  border: 0px;
  background: #f80032;
  width: 100%;
  height: 152px;

  margin-top: -6px;
  padding-top: 8px;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 0 0 16px 16px;
`;

export const LeftEncarteBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-right: 13px;
  width: 50%;
  height: 100%;

  img {
    width: 148px;
    z-index: 120;
    position: absolute;
    bottom: 0;
    right: 11px;
  }

  z-index: 100;
`;

export const RightEncarteBanner = styled.div`
  position: relative;
  z-index: 100;
  margin-left: 13px;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    position: absolute;

    color: white;
    font-family: Helvetica Neue, Regular;
    font-size: 14px;
    font: -webkit-control;
    z-index: 200;
    line-height: 16px;
    top: 38px;
    left: -7px;
    font-size: 14px;
    text-align: left;
  }

  h2 {
    position: absolute;
    margin: 0;
    color: white;
    font-family: Helvetica Neue, Bold;
    font-size: 14px;
    font-weight: bold;
    font: -webkit-control;
    left: -7px;
    top: 108px;
    z-index: 200;
    font-weight: 700;
  }
`;

export const EncarteLine = styled.div`
  position: relative;
  width: 100%;
  height: 33px;
  background-color: #d1002a;
  z-index: 9;
  top: 0;
  left: 0;
`;

export const EncarteAlert = styled.div`
  display: flex;

  align-items: center;
  width: 100%;
  margin-top: 11px;

  padding: 0 10px;

  span {
    font-family: Helvetica Neue, Bold;

    font-weight: bold;
    font: -webkit-control;
    font-size: 14px;
    color: #666;
    margin-left: 17px;
    line-height: 16px;

    @media only screen and (max-width: 376px) {
      font-size: 13px;
    }
  }
`;

export const WrapperMobile = styled.div`
  padding: 0 16px;
`;

export const TitleSection = styled.p`
  color: #333333;
  font-size: 14px;
  font-family: 'Helvetica Neue, Bold';
  font: -webkit-control;
  font-weight: bold;
  margin-left: 15px;
  margin-bottom: 19px;
  display: block;
`;

export const CarouselContent = styled.div`
  display: flex;
`;

export const ContentAlignment = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  max-width: 1280px;
`;
