import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const Geo = async (value: any) => {
  try {
    const results = await geocodeByAddress(value);

    const coords = await getLatLng(results[0]);

    return { results, coords };
  } catch (error) {
    console.log('error Geo api: ', error);
    return error;
  }
};

export default Geo;
