import { HandleEvents } from './AnalyticsGA';

interface HandleProps {
  type: 'logo' | 'minhaconta' | 'favoritos' | 'cesta';
  uri: string;
  cookies: any;
  isMobile: boolean;
}

const HandleClickMenus = ({
  uri,
  type,
  isMobile,
  cookies,
}: HandleProps): any => {
  HandleEvents({
    isMobile,
    eventAction: 'header',
    eventCategory: 'nossaslojas',
    eventLabel: type,
    eventValue: undefined,
    cookies,
  });

  window.location.replace(uri);
};

export { HandleClickMenus };
