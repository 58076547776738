import React, { FC, useState, useCallback } from 'react';
import { FiChevronDown, FiChevronLeft, FiChevronUp } from 'react-icons/fi';
import { Container, Header, Content } from './styles';

interface Props {
  title: string;
  children: any;
  defaultOpen?: boolean;
  contentStyle?: object;
  analyticsEvent?: (data?: any) => void;
  containerStyle?: object;
  headerStyle?: object;
  cutBottomBorderOnShow?: boolean;
  inverseArrows?: boolean;
  arrowActiveColor?: string;
}

const Accordion: FC<Props> = ({
  title,
  children,
  defaultOpen = false,
  contentStyle = {},
  analyticsEvent,
  containerStyle = {},
  headerStyle = {},
  inverseArrows,
  arrowActiveColor,
}) => {
  const [open, setOpen] = useState(defaultOpen);

  const toggle = useCallback(() => {
    setOpen((prev: boolean) => !prev);
  }, []);

  return (
    <Container style={containerStyle}>
      <Header
        cutBottomBorderOnShow={!!open}
        style={headerStyle}
        onClick={() => {
          toggle();

          if (analyticsEvent) {
            analyticsEvent();
          }
        }}
      >
        <span>{title}</span>

        {!open ? (
          inverseArrows ? (
            <FiChevronDown size={20} color={'#666'} />
          ) : (
            <FiChevronUp size={20} color="#666" />
          )
        ) : inverseArrows ? (
          <FiChevronUp size={20} color={arrowActiveColor || '#666'} />
        ) : (
          <FiChevronDown size={20} color={'#666'} />
        )}
      </Header>

      {open && <Content style={contentStyle}>{children}</Content>}
    </Container>
  );
};

export { Accordion };
